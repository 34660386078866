<template>
<div name="student">

    <MobileHeader></MobileHeader>
    <div class="d-flex flex-row flex-column-fluid page">
        <div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">

            <Header></Header>

            <!--begin::Content-->
            <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
                <!--begin::Entry-->
                <div class="d-flex flex-column-fluid">
                    <!--begin::Container-->
                    <!--begin::Dashboard-->

                    <div class="container">
                        <div class="card card-custom gutter-b">
                            <!--begin::Body-->
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-xl-12">
                                        <div class="form-group">
                                            <label>Team Title</label>
                                            <input type="text" class="form-control form-control-solid form-control-lg" v-model="team_name" id="schedule_name" placeholder="Team Title">
                                        </div>
                                    </div>

                                    <div class="col-xl-12">
                                        <div class="form-group">
                                            <label>Team Member</label>
                                            <multiselect v-model="value" :options="options" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" placeholder="Pick some" label="name" track-by="name" :preselect-first="true">
                                                <template slot="selection" slot-scope="{ values, isOpen }">
                                                    <span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">
                                                        {{ values.length }} member selected
                                                    </span>
                                                </template>
                                            </multiselect>
                                        </div>
                                    </div>

                                </div>
                                
                            </div>
                            <div class="card-footer">
                                <button type="submit" class="btn btn-primary mr-2" @click="update_team">Submit</button>
                                
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
</template>

<script>
import axios from 'axios'
import MobileHeader from '../../components/MobileHeader'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import Multiselect from 'vue-multiselect'
import {
    VueEditor
} from "vue2-editor";

export default {
    name: 'EditTeam',
    components: {
        Header,
        Footer,
        MobileHeader,
        VueEditor,
        Multiselect,
    },
    data() {
        return {
            uid: localStorage.getItem("uid"),
            role: localStorage.getItem("role"),
            team_name: null,
            value:[],
            options:[],
           
        }
    },
    mounted() {
        if (this.uid == null || this.uid == "") {
            this.$router.push("/");
        }
        this.getIdTeam();
        this.getTeamUser();
    },
    methods: {
        update_team() {
            var ev = this;

            if (this.team_name == null || this.team_name == "") {
                ev.$toasted.global.error({
                    message: "please enter Name"
                });
            } else if (!this.value.length>0 || this.value == "" || this.value == null) {
                ev.$toasted.global.error({
                    message: "please select Team Member"
                });
            } else {

                axios({
                    method: 'POST',
                    url: this.$root.URL_ROOT + 'lms_api.php',
                    data: {
                        type: 'update_team',
                        role: this.role,
                        uid: this.uid,
                        team_name: this.team_name,
                        tid: this.$route.params.id,
                        member:this.value,
                        
                    }
                }).then(function (response) {
                    var data = response.data;
                    if (data.status == "success") {
                        ev.$toasted.global.success({
                            message: data.msg
                        });
                        ev.$router.push("/team");
                    }else{
                        ev.$toasted.global.error({
                            message: data.msg
                        });
                    }
                })
            }
        },
        getIdTeam(){
            var ev = this;

             axios({
                    method: 'POST',
                    url: this.$root.URL_ROOT + 'lms_api.php',
                    data: {
                        type: 'getIDTeam',
                        tid: this.$route.params.id,
                        
                    }
                }).then(function (response) {
                    var data=response.data;
                    console.log(data);
                    ev.team_name=data.name;
                    ev.value=data.member;
                })
        },
        getTeamUser(){
            var ev=this;
             axios({
                    method: 'POST',
                    url: this.$root.URL_ROOT + 'lms_api.php',
                    data: {
                        type: 'getTeamUser',
                        
                    }
                }).then(function (response) {
                    var data = response.data;
                    //console.log(data);
                    ev.options=data;
                })
        }
    },
}
</script>

<style scoped>
.btn-top-table {
    display: inline-block;
    padding: 10px;
    width: 50%;
}

.table {
    width: 100%;

}

.table-dt {
    overflow: scroll;
}

.tbl-action {
    margin: 2px;
    border: unset;
}
</style>
