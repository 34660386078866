<template>
<div name="student">
    <MobileHeader></MobileHeader>
    <div class="d-flex flex-row flex-column-fluid page">
        <div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
            <Header></Header>

            <!--begin::Content-->
            <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
                <!--begin::Entry-->
                <div class="d-flex flex-column-fluid">
                    <!--begin::Container-->
                    <!--begin::Dashboard-->

                    <div class="container">
                        <div class="card card-custom mt-5">
                            <!--begin::Header-->
                            <div class="card-header flex-wrap border-0 pt-6 pb-0">
                                <div class="card-title">
                                    <h3 class="card-label">
                                        Edit Enroll Course's
                                        <span class="d-block text-muted pt-2 font-size-sm">
                                            Edit Enroll Course's</span>
                                    </h3>
                                </div>
                                <div class="card-toolbar"></div>
                            </div>
                            <!--end::Header-->
                            <div class="card-body">
                                <!--begin::Search Form-->
                                <div class="mb-7">
                                    <div class="row align-items-center">
                                        <div class="col-lg-12 col-xl-12">
                                            <div class="row align-items-center">
                                                <div class="col-md-12 my-2">
                                                    <div>
                                                        <label class="mr-3 mb-2 d-none d-md-block">Student:</label>

                                                        <select disabled class="form-control" v-model="sid">
                                                            <option value="Select Student">
                                                                Select Student
                                                            </option>
                                                            <template v-for="cdata in student_data">
                                                                <option :key="cdata.id" :value="cdata.id">
                                                                    {{ cdata.username }}
                                                                </option>
                                                            </template>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-md-6 my-2 mrg-top-10">
                                                    <div>
                                                        <label class="mr-3 mb-2 d-none d-md-block">Course:</label>

                                                        <select disabled class="form-control" v-model="cid">
                                                            <option value="Select Course">
                                                                Select Course
                                                            </option>
                                                            <template v-for="cdata in course_data">
                                                                <option :key="cdata.id" :value="cdata.id">
                                                                    {{ cdata.course_name }}
                                                                </option>
                                                            </template>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div class="col-md-6 my-2 mrg-top-10">
                                                    <div class="">
                                                        <label>Expiry Date</label>
                                                        <div class="input-group date">
                                                            <input type="date" v-model="exdate" name="expiry_date" class="form-control" id="kt_datepicker_1" />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-md-12 my-2 mrg-top-10">
                                                    <div v-if="options">
                                                        <label class="mr-3 mb-2 d-none d-md-block">Select Chapter :</label>
                                                         <br>   
                                                        <label>
                                                            <input type='checkbox' @click='checkAll()' v-model='isCheckAll'> Check All
                                                        </label>
                                                        <div class="row container">
                                                            <template v-for="cdata in chapter_data">
                                                                <div class="col-3" :key="cdata.id">
                                                                    
                                                                    
                                                                    <label>
                                                                        <input type="checkbox" id="nick" class="form-check-input" :value="cdata.id" v-model="checkedNames" />
                                                                        {{ cdata.chapter_name }}
                                                                    </label>
                                                                </div>
                                                            </template>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-xl-12 mt-5 mt-lg-0">
                                            <input type="submit" name="assign" value="Change" @click="change_save_enroll" class="btn btn-light-primary px-6 font-weight-bold" />
                                        </div>
                                    </div>
                                    <div class="row align-items-center" id="week1"></div>
                                </div>
                                <!--end: Search Form-->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from "axios";
import MobileHeader from "../../components/MobileHeader";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Multiselect from "vue-multiselect";
import "../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css";

export default {
    name: "Task",
    components: {
        Header,
        Footer,
        MobileHeader,
        Multiselect,
    },
    data() {
        return {
            uid: localStorage.getItem("uid"),
            role: localStorage.getItem("role"),
            course_data: [],
            student_data: [],
            sid: "Select Student",
            cid: "Select Course",
            exdate: null,
            chapter_id: "Select Chapter",
            chapter_data: [],
            checkedNames: [],
            newAry: [],
            options: false,

            allChapterAry: [],
            allSelectedAry: [],
            allUncheckAry: [],
            isCheckAll: false,
        };
    },
    mounted() {
        if (this.uid == null || this.uid == "") {
            this.$router.push("/");
        }
        this.getAllChapter();
        this.getCourse();
        this.getStudentList();
        this.getEnrolDetail();
    },
    watch: {
        cid: function (vl) {
            this.getChapter();
        },
        checkedNames: function (vl) {
            //console.log(vl);
            this.checkedNames = vl;
            console.log(vl);
        },
        allSelectedAry: function (vl) {
            console.log(vl);
        },
    },
    methods: {
        checkAll: function () {
            this.isCheckAll = !this.isCheckAll;
            this.checkedNames = [];
            if (this.isCheckAll) {
                // Check all
                for (var key in this.chapter_data) {
                    this.checkedNames.push(this.chapter_data[key]['id']);
                }
            }
        },
        updateCheckall: function () {
            if (this.checkedNames.length == this.chapter_data.length) {
                this.isCheckAll = true;
            } else {
                this.isCheckAll = false;
            }
        },
        getCourse() {
            var ev = this;
            axios({
                method: "POST",
                url: this.$root.URL_ROOT + "lms_api.php",
                data: {
                    type: "getAllCourseDetails",
                },
            }).then(function (response) {
                //console.log(response.data);
                var data = response.data;
                ev.course_data = data;
            });
        },
        getStudentList() {
            var ev = this;
            axios({
                method: "POST",
                url: this.$root.URL_ROOT + "lms_api.php",
                data: {
                    type: "getStudentList",
                },
            }).then(function (response) {
                //console.log(response.data);
                var data = response.data;
                ev.student_data = data;
            });
        },
        change_save_enroll() {
            var ev = this;
            console.log(this.checkedNames.length);
            if (this.exdate == "" || this.exdate == null) {
                ev.$toasted.global.error({
                    message: "Select Expiry Date",
                });
            } else if(!this.checkedNames.length>0){
                ev.$toasted.global.error({
                    message: "please selete chapter",
                });
            } 
            else {
                axios({
                    method: "POST",
                    url: this.$root.URL_ROOT + "lms_api.php",
                    data: {
                        type: "change_save_enroll",
                        course_id: this.cid,
                        sid: this.sid,
                        uid: this.uid,
                        chapters: this.checkedNames,
                        course_exdate: this.exdate,
                        eid: this.$route.params.id,
                    },
                }).then(function (response) {
                    var data = response.data;
                    //console.log(data);
                    if (data.status == "success") {
                        ev.$toasted.global.success({
                            message: data.msg,
                        });
                        ev.$router.push("/assigncourse");
                    } else {
                        ev.$toasted.global.error({
                            message: data.msg,
                        });
                    }
                });
            }
        },
        getChapter() {
            var ev = this;
            axios({
                method: "POST",
                url: this.$root.URL_ROOT + "lms_api.php",
                data: {
                    type: "getEditChapterList",
                    cid: this.$route.params.cid,
                    uid: this.$route.params.uid,
                },
            }).then(function (response) {
                //console.log(response.data);
                var data = response.data;
                if (data.count > 0) {
                    ev.chapter_data = data.data;
                    ev.options = true;
                } else {
                    ev.$toasted.global.error({
                        message: "In this course don't have chapter",
                    });
                }
            });
        },
        getEnrolDetail() {
            var ev = this;
            axios({
                method: "POST",
                url: this.$root.URL_ROOT + "lms_api.php",
                data: {
                    type: "getEnrolDetail",
                    cid: this.$route.params.cid,
                    uid: this.$route.params.uid,
                },
            }).then(function (response) {
                // console.log("select");
                // console.log(response.data);
                var data = response.data.data;
                ev.sid = data.uid;
                ev.cid = data.cid;
                ev.exdate = data.exdate;
                for (var i = 0; i < data.chapter.length; i++) {
                    ev.checkedNames.push(data.chapter[i]["id"]);
                }
            });
        },
        getAllChapter() {
            var ev = this;
            axios({
                method: "POST",
                url: this.$root.URL_ROOT + "lms_api.php",
                data: {
                    type: "getAllChapterList",
                    cid: this.$route.params.cid,
                },
            }).then(function (response) {
                //console.log(response.data);
                var data = response.data;
            });
        },
    },
};
</script>

<style scoped>
.mrg-top-10 {
    margin-top: 10px;
}

.add-btn-s {
    background-color: #3798fb;
    border: none;
    margin: 1%;
}

.btn-top-table {
    display: inline-block;
    padding: 10px;
    width: 50%;
}

.table {
    width: 100%;
}

.table-dt {
    overflow: scroll;
}

.tbl-action {
    margin: 2px;
    border: unset;
}
</style>
