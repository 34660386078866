<template>
<div name="student">

    <MobileHeader></MobileHeader>
    <div class="d-flex flex-row flex-column-fluid page">
        <div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">

            <Header></Header>

            <!--begin::Content-->
            <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
                <!--begin::Entry-->
                <div class="d-flex flex-column-fluid">
                    <!--begin::Container-->
                    <!--begin::Dashboard-->

                    <div class="container">
                        <div class="card card-custom gutter-b">
                            <!--begin::Body-->
                            <div class="card-body">
                                <h2>{{allData.name}}</h2>
                                
                                <div class="container">
                                    <div class="d-flex mb-5">
                                    <div class="d-flex align-items-center mr-7">
                                        <span class="font-weight-bold mr-4">Due Date</span>
                                        <span class="btn btn-light-primary btn-sm font-weight-bold btn-upper btn-text">{{allData.due_date}}</span>
                                    </div>
                                    <div class="d-flex align-items-center mr-7">
                                        <span class="font-weight-bold mr-4">Start Time </span>
                                        <span class="btn btn-light-danger btn-sm font-weight-bold btn-upper btn-text dddd">{{allData.start_time}}</span>
                                    </div>

                                    <div class="d-flex align-items-center mr-7">
                                        <span class="font-weight-bold mr-4">End Time </span>
                                        <span class="btn btn-light-danger btn-sm font-weight-bold btn-upper btn-text dddd">{{allData.end_time}}</span>
                                    </div>

                                </div>

                                    <label>Task</label>
                                    <p v-html="allData.description"></p>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
</template>

<script>
import axios from 'axios'
import MobileHeader from '../../components/MobileHeader'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import {
    VueEditor
} from "vue2-editor";

export default {
    name: 'AddSchedule',
    components: {
        Header,
        Footer,
        MobileHeader,
        VueEditor,
    },
    data() {
        return {
            uid: localStorage.getItem("uid"),
            role: localStorage.getItem("role"),
            allData: [],
        }
    },
    mounted() {
        if (this.uid == null || this.uid == "") {
            this.$router.push("/");
        }
        this.getSchedule();
    },
    methods: {
        getSchedule() {
            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'lms_api.php',
                data: {
                    type: 'getScheduleById',
                    role: this.role,
                    uid: this.uid,
                    sid: this.$route.params.id,
                    
                }
            }).then(function (response) {
                var data = response.data;
                ev.allData = data;
                //console.log(data);
            })
        }
    },
}
</script>

<style scoped>
.btn-top-table {
    display: inline-block;
    padding: 10px;
    width: 50%;
}

.table {
    width: 100%;

}

.table-dt {
    overflow: scroll;
}

.tbl-action {
    margin: 2px;
    border: unset;
}
</style>
