<template>
<div>
    <input type="file" id="file" ref="video_r" @change="upload_video" accept="video/*" />

    <progress id="file" :value="vlp" max="100"> {{vlp}}}% </progress>

</div>
</template>

<script>
export default {
    components: {},
    data() {
        return {
            client_id: '552781a897c019edb1a4705a259008fde1d351d5',
            client_secret: 'u4vOcge41G+lnyO9V4yYag8XZVYpQ/BNCTPINzOfnQrTUCLfRI1J7vHhpDd0MN0uJu4ji6sY9yoG/dv+5ZZGK3uc15I87K4YGLsZOfADRPvcfMRKzY7aAmyY8XIF0zbb',
            access_token: 'ca2e480dd300b57c1900f707f793e3fb',
            client: null,
            vlp:0,
            
        }
    },
    mounted() {
          let Vimeo = require('vimeo').Vimeo;
            this.client = new Vimeo(this.client_id, this.client_secret, this.access_token);
            //console.log(this.client)
    },
    watch:{
        vlp:function(cl){
            this.vlp=cl
        }
    },
    methods: {

        upload_video() {
            var ev=this;
            let urr = null;
            let file_name = this.$refs.video_r.files[0];
            this.client.upload(
                file_name, {
                    'name': 'Untitled',
                    'description': 'The description goes here.'
                },
                function (uri) {
                    //console.log('Your video URI is: ' + uri);
                    urr = uri;
                    ev.fn2(uri);
                    //console.log(uri)
                },
                function (bytes_uploaded, bytes_total) {
                    var percentage = (bytes_uploaded / bytes_total * 100).toFixed(2)
                    //console.log(bytes_uploaded, bytes_total, percentage + '%')
                    ev.vlp=percentage;
                },
                function (error) {
                    console.log('Failed because: ' + error)
                }
            )

            
        },
        fn2(uri){
            this.$root.client.request(uri + '?fields=link', function (error, body, statusCode, headers) {
                if (error) {
                    //console.log('There was an error making the request.')
                    //console.log('Server reported: ' + error)
                    return
                }
                //console.log('Your video link is: ' + body.link)
            })
        }

    },
}
</script>
