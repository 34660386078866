<template>
<div name="student">

    <MobileHeader></MobileHeader>
    <div class="d-flex flex-row flex-column-fluid page">
        <div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">

            <Header></Header>

            <!--begin::Content-->
            <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
                <!--begin::Entry-->
                <div class="d-flex flex-column-fluid">
                    <!--begin::Container-->
                    <!--begin::Dashboard-->

                    <div class="container">
                        <div class="card card-custom gutter-b">
                            <!--begin::Body-->
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-xl-6">
                                        <div class="form-group">
                                            <label>SOP Name</label>
                                            <input type="text" class="form-control form-control-solid form-control-lg" v-model="sop_name"  placeholder="Titles">
                                        </div>
                                    </div>

                                    <div class="col-xl-6">
                                        <div class="form-group">
                                            <label>SOP Type</label>
                                            <input type="text" class="form-control form-control-solid form-control-lg" v-model="sop_type"  placeholder="Titles">
                                        </div>
                                    </div>

                                </div>
                                <div class="form-group">
                                    <label>Description</label>
                                    <div class="form-group">
                                        <vue-editor v-model="description"></vue-editor>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-4">
                                        <div class="form-group">
                                            <label>Due Date</label>
                                            <div class="input-group date">
                                                <input type="date" name="due_date" class="form-control" v-model="due_date" placeholder="Select date">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-4">
                                        <div class="form-group">
                                            <label>Start File Type</label>
                                            <select class="form-control" v-model="file_type">
                                                <option value="">Select File Type</option>
                                                <option value="image">Image</option>
                                                <option value="audio">Audio</option>
                                                <option value="pdf">PDF</option>
                                                <option value="video">Video</option>

                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-xl-4" v-if="show_file=='image'">
                                        <div class="form-group">
                                            <label>Select Image File</label>
                                            <div class="custom-file">
                                                <input type="file" class="custom-file-input" id="file" ref="file" @change="upload_file" accept="image/*" >
                                                <label class="custom-file-label" for="file">{{select_file_name==null?'Choose file':select_file_name}}</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-xl-4" v-if="show_file=='audio'">
                                        <div class="form-group">
                                            <label>Select Audio File</label>
                                            <div class="custom-file">
                                                <input type="file" class="custom-file-input" id="file" ref="file" @change="upload_file" accept="audio/*" >
                                                <label class="custom-file-label" for="file">{{select_file_name==null?'Choose file':select_file_name}}</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-xl-4" v-if="show_file=='pdf'">
                                        <div class="form-group">
                                            <label>Select PDF File</label>
                                            <div class="custom-file">
                                                <input type="file" class="custom-file-input" id="file" ref="file" @change="upload_file" accept="application/pdf, application/vnd.ms-excel" >
                                                <label class="custom-file-label" for="file">{{select_file_name==null?'Choose file':select_file_name}}</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-xl-4" v-if="show_file=='video'">
                                        <div class="form-group">
                                            <label>Select Video</label>
                                            <select class="form-control" v-model="vid">
                                                <option value="">Select Video</option>
                                                <template v-for="vdata in videoList">
                                                    <option :key="vdata.id" :value="vdata.id">{{vdata.title}}</option>
                                                </template>
                                            </select>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                            <div class="card-footer">
                                <button type="submit" class="btn btn-primary mr-2" @click="save_schedule">Submit</button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
</template>

<script>
import axios from 'axios'
import MobileHeader from '../../components/MobileHeader'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import {
    VueEditor
} from "vue2-editor";

export default {
    name: 'AddSops',
    components: {
        Header,
        Footer,
        MobileHeader,
        VueEditor,
    },
    data() {
        return {
            uid: localStorage.getItem("uid"),
            role: localStorage.getItem("role"),
            schedule_name: null,
            description: null,
            due_date: null,
            start_time: null,
            end_time: null,
            sop_name: null,
            sop_type: null,
            videoList: [],
            file_type: "",
            show_file: null,
            select_file_name: null,
            file: null,
            vid: "",

        }
    },
    mounted() {
        if (this.uid == null || this.uid == "") {
            this.$router.push("/");
        }
        this.allVideo();
    },
    watch: {
        file_type: function (vl) {
            this.show_file=vl;
        }
    },
    methods: {
        upload_file() {
            var ev = this;
            this.file = this.$refs.file.files[0];
            let formData = new FormData();
            this.select_file_name = this.file.name;
        },
        save_schedule() {
            var ev = this;
            var check = true;
            let formData = new FormData();
            if (this.sop_name == null || this.sop_name == "") {
                ev.$toasted.global.error({
                    message: "please enter sop name"
                });
            } else if (this.sop_type == null || this.sop_type == "") {
                ev.$toasted.global.error({
                    message: "please enter sop type"
                });
            } else if (this.description == null || this.description == "") {
                ev.$toasted.global.error({
                    message: "please enter description"
                });
            } else if (this.due_date == null || this.due_date == "") {
                ev.$toasted.global.error({
                    message: "please select due date"
                });
            } else {

                if (this.file_type != null || this.file_type != "") {

                    if (this.file_type == 'video') {
                        if (this.vid == null || this.vid == "") {
                            ev.$toasted.global.error({
                                message: 'please select video '
                            });
                            check = false;
                        } else {
                            check = true;
                        }
                    } else if (this.file_type == 'image' || this.file_type == 'audio') {
                        this.file = this.$refs.file.files[0];
                        
                        //console.log(this.file);
                        if (this.file == undefined){
                            ev.$toasted.global.error({
                                message: "please upload file"
                            });
                            check = false;
                        }else{
                            check=true;
                            formData.append('file', this.file);
                        }
                    } else {
                        check = true;
                    }
                    //console.log(check);
                }
               
               if (check == true) {

                   formData.append('role', this.role);
                   formData.append('uid', this.uid);
                   formData.append('sop_name', this.sop_name);
                   formData.append('sop_type', this.sop_type);
                   formData.append('description', this.description);
                   formData.append('due_date', this.due_date);
                   formData.append('file_type', this.file_type);
                   formData.append('vid', this.vid);
                   
                    axios.post(this.$root.URL_ROOT + 'lms_api.php?type=save_sos', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then(function (response) {
                        var data = response.data;
                        if (data.status == "success") {
                            ev.$toasted.global.success({
                                message: data.msg
                            });
                            ev.$router.push("/sops");
                        } else {
                            ev.$toasted.global.error({
                                message: data.msg
                            });
                        }
                    })
                }
                

            }
        },
        allVideo() {
            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'lms_api.php',
                data: {
                    type: 'videolibrary',
                }
            }).then(function (response) {
                var data = response.data;
                ev.videoList = data;

            })
        },
    },
}
</script>

<style scoped>
.btn-top-table {
    display: inline-block;
    padding: 10px;
    width: 50%;
}

.table {
    width: 100%;

}

.table-dt {
    overflow: scroll;
}

.tbl-action {
    margin: 2px;
    border: unset;
}
</style>
