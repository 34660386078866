import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Student from '../views/student/Student.vue'
import Course from '../views/course/Course.vue'
import AddStudent from '../views/student/AddStudent.vue'
import AdminLogin from '@/views/AdminLogin'
import StudentLogin from '@/views/StudentLogin'
import TeacherSignup from '@/views/TeacherSignup'
import StudentDashboard from '@/views/student/StudentDashboard'
import TeacherDashboard from '@/views/teacher/TeacherDashboard'
import StudentProfile from '@/views/student/StudentProfile'
import AddCourse from '@/views/course/AddCourse'
import ViewLesson from '@/views/course/ViewLesson'
import AddNewLesson from '@/views/course/AddNewLesson'
import VideoTest from '@/views/VideoTest'
import PreviewCourse from '@/views/course/PreviewCourse'
import EditLesson from '@/views/course/EditLesson'
import TeacherView from '@/views/teacher/TeacherView'
import EditStudent from '@/views/student/EditStudent'
import Order from '@/views/extra/Order'
import EnrollCourse from '@/views/course/EnrollCourse'
import Schedule from '@/views/extra/Schedule'
import AddSchedule from '@/views/extra/AddSchedule'
import ViewSchedule from '@/views/extra/ViewSchedule'
import EditSchedule from '@/views/extra/EditSchedule'
import Todo from '@/views/extra/Todo'
import AddTodo from '@/views/extra/AddTodo'
import EditTodo from '@/views/extra/EditTodo'
import ViewTodo from '@/views/extra/ViewTodo'
import Task from '@/views/extra/Task'
import AddTask from '@/views/extra/AddTask'
import EditTask from '@/views/extra/EditTask'
import ViewTask from '@/views/extra/ViewTask'
import Announcement from '@/views/extra/Announcement'
import AddAnnouncement from '@/views/extra/AddAnnouncement'
import EditAnnouncement from '@/views/extra/EditAnnouncement'
import ViewAnnouncement from '@/views/extra/ViewAnnouncement'
import Library from '@/views/extra/Library'
import Support from '@/views/extra/Support'
import StartCourse from '@/views/course/StartCourse'
import AdminDashboard from '@/views/admin/AdminDashboard'
import AssignCourse from '@/views/course/AssignCourse'
import AddAssign from '@/views/course/AddAssign'
import Team from '@/views/extra/Team'
import AddTeam from '@/views/extra/AddTeam'
import EditTeam from '@/views/extra/EditTeam'
import Coach from '@/views/extra/Coach'
import Role from '@/views/extra/Role'
import Sops from '@/views/extra/Sops'
import AddSops from '@/views/extra/AddSops'
import ViewSops from '@/views/extra/ViewSops'
import EditSops from '@/views/extra/EditSops'
import Kpis from '@/views/extra/Kpis'
import AddKpis from '@/views/extra/AddKpis'
import ViewKpis from '@/views/extra/ViewKpis'
import EditKpis from '@/views/extra/EditKpis'
import TestPreview from '@/views/TestPreview'
import AddLibrary from '@/views/extra/AddLibrary'
import AddTeacher from '@/views/teacher/AddTeacher'
import EditTeacher from '@/views/teacher/EditTeacher'
import EditCoach from '@/views/extra/EditCoach'
import SupportList from '@/views/extra/SupportList'
import SupportEdit from '@/views/extra/SupportEdit'
import TestDrag from '@/views/course/TestDrag'
import EditAssign from '@/views/course/EditAssign'
import ViewMyCourse from '@/views/student/ViewMyCourse'
import AssignSops from '@/views/extra/AssignSops'
import ViewAssignSops from '@/views/extra/ViewAssignSops'
import AddKpiData from '@/views/extra/AddKpiData'
import AssignKpis from '@/views/extra/AssignKpis'
import ViewAssignKpi from '@/views/extra/ViewAssignKpi'
import Notification from '@/views/extra/Notification'
import EmployeeLogin from '@/views/EmployeeLogin'
import EmployeeDashboard from '@/views/employee/EmployeeDashboard'
import AddEmployee from '@/views/employee/AddEmployee'
import EditEmployee from '@/views/employee/EditEmployee'
import Employee from '@/views/employee/Employee'
import LoomVideo from '@/views/extra/LoomVideo'
import GroupCourse from '@/views/course/GroupCourse'
import AddGroupCourse from '@/views/course/AddGroupCourse'
import EditGroupCourse from '@/views/course/EditGroupCourse'
import AdminPreView from '@/views/course/AdminPreView'
import AssignGroup from '@/views/course/AssignGroup'
import ViewAssignGroup from '@/views/course/ViewAssignGroup'
import EditAssignGroup from '@/views/course/EditAssignGroup'
import MyBundleCourse from '@/views/student/MyBundleCourse'
import ViewBundleCourse from '@/views/student/ViewBundleCourse'
import ViewBundleLesson from '@/views/student/ViewBundleLesson'
import AssignVideo from '@/views/extra/AssignVideo'
import UploadVideo from '@/views/extra/UploadVideo'
import ViewEnrollment from '@/views/course/ViewEnrollment'
import TeacherAnnouncement from '@/views/extra/TeacherAnnouncement'

Vue.use(VueRouter)

const routes = [{
        path: '/dashboard',
        name: 'Home',
        component: AdminDashboard
    },
    {
        path: '/students',
        name: 'Student',
        component: Student
    },
    {
        path: '/courses',
        name: 'Course',
        component: Course
    },
    {
        path: '/addstudent',
        name: 'AddStudent',
        component: AddStudent
    },
    {
        path: '/adminlogin',
        name: 'AdminLogin',
        component: AdminLogin
    },
    {
        path: '/',
        name: 'StudentLogin',
        component: StudentLogin
    },
    {
        path: '/TeacherLms/TeacherSignup',
        name: 'TeacherSignup',
        component: TeacherSignup
    },
    {
        path: '/student_dashboard',
        name: 'Home',
        component: StudentDashboard
    },
    {
        path: '/teacher_dashboard',
        name: 'TeacherDashboard',
        component: TeacherDashboard
    },
    {
        path: '/student_profile',
        name: 'StudentProfile',
        component: StudentProfile
    },
    {
        path: '/addcourse',
        name: 'Course',
        component: AddCourse
    },
    {
        path: '/viewlesson/:id',
        name: 'Course',
        component: ViewLesson
    },
    {
        path: '/addlesson/:id',
        name: 'Course',
        component: AddNewLesson
    },
    {
        path: '/videotest',
        name: 'VideoTest',
        component: VideoTest
    },
    {
        path: '/preview_course/:cid',
        name: 'PreviewCourse',
        component: PreviewCourse
    },
    {
        path: '/editlesson/:lid/:type/:cid',
        name: 'EditLesson',
        component: EditLesson
    },
    {
        path: '/teacherview',
        name: 'TeacherView',
        component: TeacherView
    },
    {
        path: '/editstudent/:sid',
        name: 'EditStudent',
        component: EditStudent
    },
    {
        path: '/order',
        name: 'Order',
        component: Order
    },
    {
        path: '/enrollcourse',
        name: 'EnrollCourse',
        component: EnrollCourse
    },
    {
        path: '/schedule',
        name: 'Schedule',
        component: Schedule
    },
    {
        path: '/addschedule',
        name: 'AddSchedule',
        component: AddSchedule
    },
    {
        path: '/viewschedule/:id',
        name: 'Schedule',
        component: ViewSchedule
    },
    {
        path: '/editschedule/:id',
        name: 'Schedule',
        component: EditSchedule
    },
    {
        path: '/todo',
        name: 'Todo',
        component: Todo
    },
    {
        path: '/addtodo',
        name: 'Todo',
        component: AddTodo
    },
    {
        path: '/viewtodo/:id',
        name: 'Todo',
        component: ViewTodo
    },
    {
        path: '/edittodo/:id',
        name: 'Todo',
        component: EditTodo
    },
    {
        path: '/library',
        name: 'Library',
        component: Library
    },
    {
        path: '/support',
        name: 'Support',
        component: Support
    },
    {
        path: '/startcourse',
        name: 'Course',
        component: StartCourse
    },
    {
        path: '/admindashboard',
        name: 'AdminDashboard',
        component: AdminDashboard
    },
    {
        path: '/task',
        name: 'Task',
        component: Task
    },
    {
        path: '/addtask',
        name: 'Task',
        component: AddTask
    },
    {
        path: '/viewtask/:id',
        name: 'Task',
        component: ViewTask
    },
    {
        path: '/edittask/:id',
        name: 'Task',
        component: EditTask
    },
    {
        path: '/announcement',
        name: 'Announcement',
        component: Announcement
    },
    {
        path: '/addannouncement',
        name: 'Announcement',
        component: AddAnnouncement
    },
    {
        path: '/viewannouncement/:id',
        name: 'Announcement',
        component: ViewAnnouncement
    },
    {
        path: '/editannouncement/:id',
        name: 'Announcement',
        component: EditAnnouncement
    },
    {
        path: '/assigncourse',
        name: 'AssignCourse',
        component: AssignCourse
    },
    {
        path: '/addassign',
        name: 'AddAssign',
        component: AddAssign
    },
    {
        path: '/team',
        name: 'Team',
        component: Team
    },
    {
        path: '/addteam',
        name: 'Team',
        component: AddTeam
    },
    {
        path: '/editteam/:id',
        name: 'Team',
        component: EditTeam
    },
    {
        path: '/coach',
        name: 'Coach',
        component: Coach
    },
    {
        path: '/editcoach/:id',
        name: 'Coach',
        component: EditCoach
    },
    {
        path: '/role',
        name: 'Role',
        component: Role
    },
    {
        path: '/sops',
        name: 'Sops',
        component: Sops
    },
    {
        path: '/addsops',
        name: 'Sops',
        component: AddSops
    },
    {
        path: '/viewsops/:id',
        name: 'Sops',
        component: ViewSops
    },
    {
        path: '/editsops/:id',
        name: 'Sops',
        component: EditSops
    },
    {
        path: '/kpis',
        name: 'Kpis',
        component: Kpis
    },
    {
        path: '/addkpis',
        name: 'Kpis',
        component: AddKpis
    },
    {
        path: '/viewkpis/:id',
        name: 'Kpis',
        component: ViewKpis
    },
    {
        path: '/editkpis/:id',
        name: 'Kpis',
        component: EditKpis
    },
    {
        path: '/testpreview/:id',
        name: 'TestPrrview',
        component: TestPreview
    },
    {
        path: '/addlibrary',
        name: 'Library',
        component: AddLibrary
    },
    {
        path: '/addteacher',
        name: 'Teacher',
        component: AddTeacher
    },
    {
        path: '/editteacher/:sid',
        name: 'Teacher',
        component: EditTeacher
    },
    {
        path: '/supportlist',
        name: 'SupportList',
        component: SupportList
    },
    {
        path: '/supportedit/:sid',
        name: 'SupportList',
        component: SupportEdit
    },
    {
        path: '/testdrag',
        name: 'TestDrag',
        component: TestDrag
    },
    {
        path: '/editassign/:cid/:uid',
        name: 'AssignCourse',
        component: EditAssign
    },
    {
        path: '/viewmycourse/:id',
        name: 'Course',
        component: ViewMyCourse
    },
    {
        path: '/assignsops',
        name: 'Sops',
        component: AssignSops
    },
    {
        path: '/viewassignsops',
        name: 'Sops',
        component: ViewAssignSops
    },
    {
        path: '/addkpidata',
        name: 'Kpis',
        component: AddKpiData
    },
    {
        path: '/assignkpis',
        name: 'Kpis',
        component: AssignKpis
    },
    {
        path: '/viewassignkpi',
        name: 'Kpis',
        component: ViewAssignKpi
    },
    {
        path: '/notification',
        name: 'Notification',
        component: Notification
    },
    {
        path: '/employeelogin',
        name: 'EmployeeLogin',
        component: EmployeeLogin
    },
    {
        path: '/employeedashboard',
        name: 'EmployeeDashboard',
        component: EmployeeDashboard
    },
    {
        path: '/addemployee',
        name: 'AddEmployee',
        component: AddEmployee
    },
    {
        path: '/editemployee/:id',
        name: 'Employee',
        component: EditEmployee
    },
    {
        path: '/employee',
        name: 'Employee',
        component: Employee
    },
    {
        path: '/loomvideo',
        name: 'LoomVideo',
        component: LoomVideo
    },
    {
        path: '/groupcourse',
        name: 'GroupCourse',
        component: GroupCourse
    },
    {
        path: '/addgroupcourse',
        name: 'GroupCourse',
        component: AddGroupCourse
    },
    {
        path: '/editgroupcourse/:id',
        name: 'GroupCourse',
        component: EditGroupCourse
    },
    {
        path: '/adminpreview/:id',
        name: 'Course',
        component: AdminPreView
    },
    {
        path: '/addassigngroup',
        name: 'ViewAssignGroup',
        component: AssignGroup
    },
    {
        path: '/viewassigngroup',
        name: 'ViewAssignGroup',
        component: ViewAssignGroup
    },
    {
        path: '/editassigngroup/:id',
        name: 'ViewAssignGroup',
        component: EditAssignGroup
    },
    {
        path: '/mybundlecourse',
        name: 'MyBundleCourse',
        component: MyBundleCourse
    },
    {
        path: '/viewbundlecourse/:id',
        name: 'MyBundleCourse',
        component: ViewBundleCourse
    },
    {
        path: '/viewbundlelesson/:id',
        name: 'MyBundleCourse',
        component: ViewBundleLesson
    },
    {
        path: '/assignvideo/:id',
        name: 'Library',
        component: AssignVideo
    },
    {
        path: '/uploadvideo/',
        name: 'UploadVideo',
        component: UploadVideo
    },
    {
        path: '/viewenrollment/:id',
        name: 'ViewEnrollment',
        component: ViewEnrollment
    },
    {
        path: '/teacherannouncement',
        name: 'TeacherAnnouncement',
        component: TeacherAnnouncement
    },

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: routes
})

export default router