<template>
<div>
    <MobileHeader></MobileHeader>
    <div class="d-flex flex-row flex-column-fluid page">
        <div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">

            <Header></Header>

            <!--begin::Content-->
            <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
                <!--begin::Entry-->
                <div class="d-flex flex-column-fluid">
                    <!--begin::Container-->
                    <div class="container">
                        <!--begin::Dashboard-->

                        <div class="d-flex flex-row">
                            <!--begin::Aside-->
                            <div class="flex-row-lg-auto offcanvas-mobile w-250px h-400px w-xl-300px h-xl-500px" id="kt_profile_aside">
                                <!--begin::Profile Card-->
                                <div class="card card-custom card-stretch">
                                    <!--begin::Body-->
                                    <div class="card-body pt-4">
                                        <!--begin::Toolbar-->
                                        <!--end::Toolbar-->
                                        <!--begin::User-->
                                        <div class="d-flex align-items-center">
                                            <div class="symbol symbol-60 symbol-xxl-100 mr-5 align-self-start align-self-xxl-center">
                                                <div class="symbol-label" @click="$refs.file.click()" :style="'background-image:url('+$root.URL_IMAGES+'User/'+avatar+')'">
                                                     <input class="hide" type="file" id="file" ref="file" @change="upload_file" accept="image/*" />
                                                     <i class="fa fa-edit pic-edit"></i>
                                                </div>
                                                <!--<i class="symbol-badge bg-success"></i>-->
                                            </div>
                                            <div>
                                                <a href="#" class="font-weight-bolder font-size-h5 text-dark-75 text-hover-primary">{{fname}}</a>

                                            </div>
                                        </div>
                                        <!--end::User-->
                                        <!--begin::Contact-->
                                        <div class="py-9">
                                            <div class="d-flex align-items-center justify-content-between mb-2">
                                                <span class="font-weight-bold mr-2">Email:</span>
                                                <a href="#" class="text-muted text-hover-primary">{{email}}</a>
                                            </div>
                                            <div class="d-flex align-items-center justify-content-between mb-2">
                                                <span class="font-weight-bold mr-2">Phone:</span>
                                                <span class="text-muted">{{mobile}}</span>
                                            </div>
                                            <div class="d-flex align-items-center justify-content-between">
                                                <span class="font-weight-bold mr-2">Location:</span>
                                                <span class="text-muted">{{city}} ,{{state}} ,{{country}}
                                                    
                                                </span>
                                            </div>
                                        </div>
                                        <!--end::Contact-->
                                        <!--begin::Nav-->
                                        <div class="navi navi-bold navi-hover navi-active navi-link-rounded" id="myDiv">
                                            <div class="navi-item mb-2">
                                                <a href="#" :class="open_box=='profile'?'navi-link py-4 active':'navi-link py-4'" id="pro" @click="open_box='profile'">
                                                    <span class="navi-icon mr-2">
                                                        <span class="svg-icon">
                                                            <!--begin::Svg Icon | path:assets/media/svg/icons/Design/Layers.svg-->
                                                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                    <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                                                    <path d="M12.9336061,16.072447 L19.36,10.9564761 L19.5181585,10.8312381 C20.1676248,10.3169571 20.2772143,9.3735535 19.7629333,8.72408713 C19.6917232,8.63415859 19.6104327,8.55269514 19.5206557,8.48129411 L12.9336854,3.24257445 C12.3871201,2.80788259 11.6128799,2.80788259 11.0663146,3.24257445 L4.47482784,8.48488609 C3.82645598,9.00054628 3.71887192,9.94418071 4.23453211,10.5925526 C4.30500305,10.6811601 4.38527899,10.7615046 4.47382636,10.8320511 L4.63,10.9564761 L11.0659024,16.0730648 C11.6126744,16.5077525 12.3871218,16.5074963 12.9336061,16.072447 Z" fill="#000000" fill-rule="nonzero"></path>
                                                                    <path d="M11.0563554,18.6706981 L5.33593024,14.122919 C4.94553994,13.8125559 4.37746707,13.8774308 4.06710397,14.2678211 C4.06471678,14.2708238 4.06234874,14.2738418 4.06,14.2768747 L4.06,14.2768747 C3.75257288,14.6738539 3.82516916,15.244888 4.22214834,15.5523151 C4.22358765,15.5534297 4.2250303,15.55454 4.22647627,15.555646 L11.0872776,20.8031356 C11.6250734,21.2144692 12.371757,21.2145375 12.909628,20.8033023 L19.7677785,15.559828 C20.1693192,15.2528257 20.2459576,14.6784381 19.9389553,14.2768974 C19.9376429,14.2751809 19.9363245,14.2734691 19.935,14.2717619 L19.935,14.2717619 C19.6266937,13.8743807 19.0546209,13.8021712 18.6572397,14.1104775 C18.654352,14.112718 18.6514778,14.1149757 18.6486172,14.1172508 L12.9235044,18.6705218 C12.377022,19.1051477 11.6029199,19.1052208 11.0563554,18.6706981 Z" fill="#000000" opacity="0.3"></path>
                                                                </g>
                                                            </svg>
                                                            <!--end::Svg Icon-->
                                                        </span>
                                                    </span>
                                                    <span class="navi-text font-size-lg">Profile Overview</span>
                                                </a>
                                            </div>
                                            <div class="navi-item mb-2">
                                                <a href="#" :class="open_box=='personal_info'?'navi-link py-4 sidebar active':'navi-link py-4 sidebar'" id="psnl" @click="open_box='personal_info'">
                                                    <span class="navi-icon mr-2">
                                                        <span class="svg-icon">
                                                            <!--begin::Svg Icon | path:assets/media/svg/icons/General/User.svg-->
                                                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                    <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                                                    <path d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                                                                    <path d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z" fill="#000000" fill-rule="nonzero"></path>
                                                                </g>
                                                            </svg>
                                                            <!--end::Svg Icon-->
                                                        </span>
                                                    </span>
                                                    <span class="navi-text font-size-lg">Personal Information</span>
                                                </a>
                                            </div>
                                            <div class="navi-item mb-2">
                                                <a href="#" :class="open_box=='address'?'navi-link py-4 sidebar active':'navi-link py-4 sidebar'" id="addres" @click="open_box='address'">
                                                    <span class="navi-icon mr-2">
                                                        <span class="svg-icon">
                                                            <!--begin::Svg Icon | path:/metronic/theme/html/demo7/dist/assets/media/svg/icons/Code/Compiling.svg-->
                                                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                    <rect x="0" y="0" width="24" height="24"></rect>
                                                                    <path d="M2.56066017,10.6819805 L4.68198052,8.56066017 C5.26776695,7.97487373 6.21751442,7.97487373 6.80330086,8.56066017 L8.9246212,10.6819805 C9.51040764,11.267767 9.51040764,12.2175144 8.9246212,12.8033009 L6.80330086,14.9246212 C6.21751442,15.5104076 5.26776695,15.5104076 4.68198052,14.9246212 L2.56066017,12.8033009 C1.97487373,12.2175144 1.97487373,11.267767 2.56066017,10.6819805 Z M14.5606602,10.6819805 L16.6819805,8.56066017 C17.267767,7.97487373 18.2175144,7.97487373 18.8033009,8.56066017 L20.9246212,10.6819805 C21.5104076,11.267767 21.5104076,12.2175144 20.9246212,12.8033009 L18.8033009,14.9246212 C18.2175144,15.5104076 17.267767,15.5104076 16.6819805,14.9246212 L14.5606602,12.8033009 C13.9748737,12.2175144 13.9748737,11.267767 14.5606602,10.6819805 Z" fill="#000000" opacity="0.3"></path>
                                                                    <path d="M8.56066017,16.6819805 L10.6819805,14.5606602 C11.267767,13.9748737 12.2175144,13.9748737 12.8033009,14.5606602 L14.9246212,16.6819805 C15.5104076,17.267767 15.5104076,18.2175144 14.9246212,18.8033009 L12.8033009,20.9246212 C12.2175144,21.5104076 11.267767,21.5104076 10.6819805,20.9246212 L8.56066017,18.8033009 C7.97487373,18.2175144 7.97487373,17.267767 8.56066017,16.6819805 Z M8.56066017,4.68198052 L10.6819805,2.56066017 C11.267767,1.97487373 12.2175144,1.97487373 12.8033009,2.56066017 L14.9246212,4.68198052 C15.5104076,5.26776695 15.5104076,6.21751442 14.9246212,6.80330086 L12.8033009,8.9246212 C12.2175144,9.51040764 11.267767,9.51040764 10.6819805,8.9246212 L8.56066017,6.80330086 C7.97487373,6.21751442 7.97487373,5.26776695 8.56066017,4.68198052 Z" fill="#000000"></path>
                                                                </g>
                                                            </svg>
                                                            <!--end::Svg Icon-->
                                                        </span>
                                                    </span>
                                                    <span class="navi-text font-size-lg">Setup Locations</span>
                                                </a>
                                            </div>
                                            <div class="navi-item mb-2">
                                                <a href="#" :class="open_box=='change_pswd'?'navi-link py-4 sidebar active':'navi-link py-4 sidebar'" id="pwd" @click="open_box='change_pswd'">
                                                    <span class="navi-icon mr-2">
                                                        <span class="svg-icon">
                                                            <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Shield-user.svg-->
                                                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                    <rect x="0" y="0" width="24" height="24"></rect>
                                                                    <path d="M4,4 L11.6314229,2.5691082 C11.8750185,2.52343403 12.1249815,2.52343403 12.3685771,2.5691082 L20,4 L20,13.2830094 C20,16.2173861 18.4883464,18.9447835 16,20.5 L12.5299989,22.6687507 C12.2057287,22.8714196 11.7942713,22.8714196 11.4700011,22.6687507 L8,20.5 C5.51165358,18.9447835 4,16.2173861 4,13.2830094 L4,4 Z" fill="#000000" opacity="0.3"></path>
                                                                    <path d="M12,11 C10.8954305,11 10,10.1045695 10,9 C10,7.8954305 10.8954305,7 12,7 C13.1045695,7 14,7.8954305 14,9 C14,10.1045695 13.1045695,11 12,11 Z" fill="#000000" opacity="0.3"></path>
                                                                    <path d="M7.00036205,16.4995035 C7.21569918,13.5165724 9.36772908,12 11.9907452,12 C14.6506758,12 16.8360465,13.4332455 16.9988413,16.5 C17.0053266,16.6221713 16.9988413,17 16.5815,17 C14.5228466,17 11.463736,17 7.4041679,17 C7.26484009,17 6.98863236,16.6619875 7.00036205,16.4995035 Z" fill="#000000" opacity="0.3"></path>
                                                                </g>
                                                            </svg>
                                                            <!--end::Svg Icon-->
                                                        </span>
                                                    </span>
                                                    <span class="navi-text font-size-lg">Change Password</span>

                                                </a>
                                            </div>
                                        </div>
                                        <!--end::Nav-->
                                    </div>
                                    <!--end::Body-->
                                </div>
                                <!--end::Profile Card-->
                            </div>
                            <!--end::Aside-->
                            <!--begin::Content-->
                            <div class="flex-row-lg-fluid ml-lg-8">
                                <!--begin::Row-->
                                <div class="w-750px w-xl-750px" v-if="open_box=='profile'" id="profile">
                                    <div class="row">
                                        <template v-for="mdata in myData">
                                            <!--begin::Col-->
                                            <div :key="mdata.id" class="col-xl-6">
                                                <!--begin::Card-->
                                                <div class="card card-custom gutter-b ">
                                                    <!--begin::Body-->
                                                    <div class="card-body">
                                                        <!--begin::Info-->
                                                        <div class="d-flex align-items-center">
                                                            <!--begin::Pic-->
                                                            <div class="flex-shrink-0 mr-4 symbol symbol-60 symbol-circle">
                                                                <img alt="Pic" :src="$root.URL_IMAGES+'Course/'+mdata.course_image">
                                                            </div>
                                                            <!--end::Pic-->
                                                            <!--begin::Info-->
                                                            <div class="d-flex flex-column mr-auto">
                                                                <!--begin: Title-->
                                                                <div class="d-flex flex-column mr-auto">
                                                                    <router-link :to="'/preview_course/'+mdata.cid" class="text-dark text-hover-primary font-size-h4 font-weight-bolder mb-1">{{mdata.course_name}}</router-link>
                                                                </div>
                                                                <!--end::Title-->
                                                            </div>
                                                            <!--end::Info-->
                                                        </div>
                                                        <!--end::Info-->
                                                        <!--begin::Description-->
                                                        <div class="mb-10 mt-5 font-weight-bold" style="text-align:center;"> 
                                                            <h4>{{mdata.chapter_name}}</h4>
                                                        </div>
                                                        <!--end::Description-->
                                                        <!--begin::Data-->
                                                        <div class="d-flex mb-5">
                                                            <div class="d-flex align-items-center mr-7">
                                                                <span class="font-weight-bold mr-4">Start</span>
                                                                <span class="btn btn-light-primary btn-sm font-weight-bold btn-upper btn-text">{{mdata.start_date}}</span>
                                                            </div>

                                                            <div class="d-flex align-items-center">
                                                                <span class="font-weight-bold mr-4">Due</span>
                                                                <span class="btn btn-light-danger btn-sm font-weight-bold btn-upper btn-text dddd">{{mdata.exdate}}</span>
                                                            </div>
                                                        </div>
                                                        <!--end::Data-->
                                                        <!--begin::Progress-->
                                                        <div class="d-flex mb-5 align-items-cente ">
                                                            <span class="d-block font-weight-bold mr-5">Progress</span>
                                                            <div class="d-flex flex-row-fluid align-items-center">
                                                                <div class="progress progress-xs mt-2 mb-2 w-100">
                                                                    <div class="progress-bar bg-success" role="progressbar" :style="'width:'+mdata.per+'%'" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                                                </div>
                                                                <span class="ml-3 font-weight-bolder"> {{mdata.per}}% </span>
                                                            </div>
                                                        </div>
                                                        <!--ebd::Progress-->
                                                    </div>
                                                    <!--end::Body-->
                                                    <div class="card-footer d-flex align-items-center">
                                                        <div class="d-flex">
                                                            <div class="d-flex align-items-center mr-10">
                                                                <p id="add" style="display:none;">1</p>
                                                                <!-- 	    <a href="https://levelup-learning.in/ImpactSchool/public/ViewLesson/1" class="btn btn-light-primary font-weight-bold" style="display:flex;"><i class="fas fa-arrow-alt-circle-right"></i>Preview</a> -->
                                                                <!--</a>-->

                                                                <!--<a href="#" class="btn btn-light-danger font-weight-bold ml-15" id="statuss'.$allcat->course_id.'">False</a>-->
                                                                <router-link :to="'/viewmycourse/'+mdata.id">
                                                                    <button type="button" class="btn btn-light-primary font-weight-bold ml-20 ennroll" style="display:flex;" id="disable1">
                                                                        <i class="fas fa-arrow-alt-circle-right"></i>
                                                                        View

                                                                    </button></router-link>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!--end:: Card-->
                                            </div>
                                            <!--end::Col-->

                                        </template>

                                    </div>
                                </div>
                                <!--end::Row-->
                                <!--begin::Row-->
                                <div class="w-750px w-xl-750px" v-if="open_box=='personal_info'" id="personal_info">
                                    <!--begin::Form-->
                                    <div class="card card-custom">
                                        <!--begin::Header-->
                                        <div class="card-header py-3">
                                            <div class="card-title align-items-start flex-column">
                                                <h3 class="card-label font-weight-bolder text-dark">Personal Information</h3>
                                                <span class="text-muted font-weight-bold font-size-sm mt-1">Update your personal informaiton</span>
                                            </div>
                                            <div class="card-toolbar">
                                                <input type="submit" name="personal" class="btn btn-success mr-2" @click="save_profile" value="Save Changes">
                                            </div>
                                        </div>
                                        <!--end::Header-->
                                        <!--begin::Body-->
                                        <div class="card-body">
                                            <div class="form-group">
                                                <label>Name</label>
                                                <input type="text" v-model="fname" class="form-control form-control-solid form-control-lg" name="name">
                                            </div>
                                            <!--end::Input-->
                                            <div class="row">
                                                <div class="col-xl-6">
                                                    <!--begin::Input-->
                                                    <div class="form-group">
                                                        <label>Phone</label>
                                                        <input type="tel" v-model="mobile" class="form-control form-control-solid form-control-lg" name="phone">
                                                        <span class="form-text text-muted">Please enter your phone number.</span>
                                                    </div>
                                                    <!--end::Input-->
                                                </div>
                                                <div class="col-xl-6">
                                                    <!--begin::Input-->
                                                    <div class="form-group">
                                                        <label>Email</label>
                                                        <input type="email" v-model="email" class="form-control form-control-solid form-control-lg" name="email">
                                                        <span class="form-text text-muted">Please enter your email address.</span>
                                                    </div>
                                                    <!--end::Input-->
                                                </div>
                                            </div>
                                        </div>
                                        <!--end::Body-->
                                    </div>

                                    <!--end::Form-->
                                </div>
                                <!--end::Row-->
                                <!--begin::Row-->
                                <div class="w-750px w-xl-750px" v-if="open_box=='change_pswd'" id="change_pswd">
                                    <!--begin::Form-->
                                    <div class="card card-custom">
                                        <!--begin::Header-->
                                        <div class="card-header py-3">
                                            <div class="card-title align-items-start flex-column">
                                                <h3 class="card-label font-weight-bolder text-dark">Change Password</h3>
                                                <span class="text-muted font-weight-bold font-size-sm mt-1">Change your account password</span>
                                            </div>
                                            <div class="card-toolbar">
                                                <input type="submit" name="pass" class="btn btn-success mr-2" @click="change_pass" value="Save Changes">

                                            </div>
                                        </div>
                                        <!--end::Header-->
                                        <div class="card-body">

                                            <div class="form-group row">
                                                <label class="col-xl-3 col-lg-3 col-form-label text-alert">New Password</label>
                                                <div class="col-lg-9 col-xl-6">
                                                    <input type="password" v-model="new_pass" class="form-control form-control-lg form-control-solid" name="password" placeholder="New Password">
                                                </div>
                                            </div>

                                        </div>
                                        <!--end::Form-->
                                    </div>

                                </div>
                                <!--end::Row-->

                                <!--begin::Row-->
                                <div class="w-750px w-xl-750px" v-if="open_box=='address'" id="address">
                                    <!--begin::Form-->

                                    <div class="card card-custom ">
                                        <!--begin::Header-->
                                        <div class="card-header py-3">
                                            <div class="card-title align-items-start flex-column">
                                                <h3 class="card-label font-weight-bolder text-dark">Setup Your Current Location</h3>
                                                <span class="text-muted font-weight-bold font-size-sm mt-1">Update your address informaiton</span>
                                            </div>
                                            <div class="card-toolbar">
                                                <input type="submit" name="add" class="btn btn-success mr-2" @click="save_address" value="Save Changes">

                                            </div>
                                        </div>
                                        <!--end::Header-->
                                        <!--begin::Body-->
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-xl-6">
                                                    <!--begin::Input-->
                                                    <div class="form-group">
                                                        <label>Address Line 1</label>
                                                        <input type="text" v-model="add1" class="form-control form-control-solid form-control-lg" name="address1" placeholder="Address Line 1">
                                                        <span class="form-text text-muted">Please enter your Address.</span>
                                                    </div>
                                                    <!--end::Input-->
                                                </div>
                                                <div class="col-xl-6">
                                                    <!--begin::Input-->
                                                    <div class="form-group">
                                                        <label>Address Line 2</label>
                                                        <input type="text" v-model="add2" class="form-control form-control-solid form-control-lg" name="address2" placeholder="Address Line 2">
                                                        <span class="form-text text-muted">Please enter your Address.</span>
                                                    </div>
                                                    <!--end::Input-->
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-xl-6">
                                                    <!--begin::Input-->
                                                    <div class="form-group">
                                                        <label>Postcode</label>
                                                        <input type="text" v-model="pincode" class="form-control form-control-solid form-control-lg" name="postcode" placeholder="Postcode">
                                                        <span class="form-text text-muted">Please enter your Postcode.</span>
                                                    </div>
                                                    <!--end::Input-->
                                                </div>
                                                <div class="col-xl-6">
                                                    <!--begin::Input-->
                                                    <div class="form-group">
                                                        <label>City</label>
                                                        <input type="text" v-model="city" class="form-control form-control-solid form-control-lg" name="city" placeholder="City">
                                                        <span class="form-text text-muted">Please enter your City.</span>
                                                    </div>
                                                    <!--end::Input-->
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-xl-6">
                                                    <!--begin::Input-->
                                                    <div class="form-group">
                                                        <label>State</label>
                                                        <input type="text" v-model="state" class="form-control form-control-solid form-control-lg" name="state" placeholder="State">
                                                        <span class="form-text text-muted">Please enter your State.</span>
                                                    </div>
                                                    <!--end::Input-->
                                                </div>
                                                <div class="col-xl-6">
                                                    <!--begin::Select-->
                                                    <div class="form-group">
                                                        <label>Country</label>
                                                        <input type="text" v-model="country" class="form-control form-control-solid form-control-lg" name="country" placeholder="Country">
                                                        <span class="form-text text-muted">Please enter your Country.</span>
                                                    </div>
                                                    <!--end::Input-->
                                                </div>
                                            </div>
                                        </div>
                                        <!--end::Body-->
                                    </div>

                                    <!--end::Form-->
                                </div>
                                <!--end::Row-->
                                <!--begin::Pagination-->

                                <!--end::Pagination-->
                            </div>
                            <!--end::Content-->
                        </div>

                        <!--end::Dashboard-->
                    </div>
                    <!--end::Container-->
                </div>
                <!--end::Entry-->
            </div>
            <!--end::Content-->

            <Footer></Footer>

        </div>
    </div>

</div>
</template>

<script>
import axios from 'axios'
import MobileHeader from '../../components/MobileHeader'
import Header from '../../components/Header'
import Footer from '../../components/Footer'

export default {
    name: 'EmployeeDashboard',
    components: {
        Header,
        Footer,
        MobileHeader,

    },
    data() {
        return {
            cartData: [],
            uid: localStorage.getItem('uid'),
            ip_address: JSON.parse(localStorage.getItem('ip_address')),
            cartcount: 0,
            total: 0,
            btn_chk: false,
            pincode: '',
            tm: '',
            myData: [],
            open_box: 'profile',
            avatar:null,

            fname: null,
            email: null,
            mobile: null,
            state: null,
            city: null,
            country: null,
            add1: null,
            add2: null,
            pincode: null,
            new_pass: null,
            file:null,

        }
    },
    mounted() {
        /// /console.log(this.$gmapOptions.load.key)
        // this.ip_address = JSON.parse(localStorage.getItem('ip_address'))
        //this.getData()
        window.scrollTo(0, 0)
        this.getMyEnroll();
        this.getUserDetail();
    },
    methods: {
        save_profile() {
            var ev = this;
            if (this.fname == '' || this.fname == null) {
                ev.$toasted.global.error({
                    message: 'Please enter name'
                });
            } else if (this.mobile == "" || this.mobile == null) {
                ev.$toasted.global.error({
                    message: 'Please enter mobile'
                });
            } else if (this.email == "" || this.email == null) {
                ev.$toasted.global.error({
                    message: 'Please enter email'
                });
            } else {
                axios({
                    method: 'POST',
                    url: this.$root.URL_ROOT + 'lms_api.php',
                    data: {
                        type: 'update_student_profile',
                        sid: this.uid,
                        fname: this.fname,
                        email: this.email,
                        mobile: this.mobile,
                    }
                }).then(function (response) {
                    var data = response.data;
                    if (data.status == 'success') {
                        ev.$toasted.global.success({
                            message: data.msg
                        });
                    } else {
                        ev.$toasted.global.error({
                            message: data.msg
                        });
                    }
                })
            }
        },
        save_address() {
            var ev = this;
            if (this.add1 == '' || this.add1 == null) {
                ev.$toasted.global.error({
                    message: 'Please enter address 1'
                });
            } else if (this.add2 == "" || this.add2 == null) {
                ev.$toasted.global.error({
                    message: 'Please enter address 2'
                });
            } else if (this.pincode == "" || this.pincode == null) {
                ev.$toasted.global.error({
                    message: 'Please enter postcode'
                });
            } else if (this.city == "" || this.city == null) {
                ev.$toasted.global.error({
                    message: 'Please enter city'
                });
            } else if (this.state == "" || this.state == null) {
                ev.$toasted.global.error({
                    message: 'Please enter state'
                });
            } else if (this.country == "" || this.country == null) {
                ev.$toasted.global.error({
                    message: 'Please enter country'
                });
            } else {
                axios({
                    method: 'POST',
                    url: this.$root.URL_ROOT + 'lms_api.php',
                    data: {
                        type: 'update_student_address',
                        sid: this.uid,
                        add1: this.add1,
                        add2: this.add2,
                        city: this.city,
                        state: this.state,
                        country: this.country,
                        pincode: this.pincode,
                    }
                }).then(function (response) {
                    var data = response.data;
                    if (data.status == 'success') {
                        ev.$toasted.global.success({
                            message: data.msg
                        });
                    } else {
                        ev.$toasted.global.error({
                            message: data.msg
                        });
                    }
                })
            }
        },
        change_pass() {
            var ev = this;
            if (this.new_pass == '' || this.new_pass == null) {
                ev.$toasted.global.error({
                    message: 'Please enter new password'
                });
            } else {
                axios({
                    method: 'POST',
                    url: this.$root.URL_ROOT + 'lms_api.php',
                    data: {
                        type: 'update_student_password',
                        sid: this.uid,
                        new_pass: this.new_pass,
                    }
                }).then(function (response) {
                    var data = response.data;
                    if (data.status == 'success') {
                        ev.$toasted.global.success({
                            message: data.msg
                        });
                        ev.new_pass=null;
                    } else {
                        ev.$toasted.global.error({
                            message: data.msg
                        });
                    }
                })
            }
        },
        getUserDetail() {
            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'lms_api.php',
                data: {
                    type: 'getUserDatas',
                    sid: this.uid,
                }
            }).then(function (response) {
                var data = response.data;
               //console.log(data);
                ev.fname=data.username;
                ev.email=data.email;
                ev.mobile=data.mobile;
                ev.add1=data.add1;
                ev.add2=data.add2;
                ev.city=data.city;
                ev.state=data.state;
                ev.country=data.country;
                ev.pincode=data.zip;
                ev.avatar=data.avatar;
            })
        },
        upload_file() {
            var ev = this;
            this.file = this.$refs.file.files[0];

            let formData = new FormData();
            //console.log(this.file.name);
            this.save_userimage();

        },
        save_userimage: function () {
            var ev = this;
            this.file = this.$refs.file.files[0];

            let formData = new FormData();

            formData.append('file', this.file);
            formData.append('uid', this.uid);
            if (this.file == undefined) {
                ev.$toasted.global.error({
                    message: "please upload image"
                });
            } else {
               
                axios.post(this.$root.URL_ROOT + 'lms_api.php?type=upload_user_image', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(function (response) {
                        var data = response.data;
                        //console.log(data);
                        if (data.status == "success") {
                            ev.$toasted.global.success({
                                message: data.msg
                            });
                            ev.getUserDetail();
                            
                        } else {
                            ev.$toasted.global.error({
                                message: data.msg
                            });
                        }
                    })

            }

        },
        getMyEnroll() {
            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'lms_api.php',
                data: {
                    type: 'getMyEnroll',
                    sid: this.uid,
                }
            }).then(function (response) {
                //console.log(response.data)
                var data = response.data;
                ev.myData = data;

            })
        },
        show_warn() {
            this.$toasted.global.error({
                message: 'Enter valid Pincode'
            })
        },
        update_cart_pre(cid, pid, pname, qty, price, mode) {
            var t = this.update_cart(cid, pid, pname, qty, price, mode)
            /// /console.log(t)
            setTimeout(() => {
                if (t != null) {
                    this.getData()
                }
            }, 1000)
        },
        change_pin() {
            var ev = this
            var pin = this.pincode
            var len = pin.length
            if (len > 5 && len < 7) {
                axios({
                    method: 'POST',
                    url: this.$root.URL_ROOT + 'api.php',
                    data: {
                        type: 'check_pin',
                        pincode: pin
                    }
                }).then(function (response) {
                    var data = response.data
                    if (data.status == 'success') {
                        ev.tm = data.data.time
                        ev.btn_chk = true
                    } else {
                        ev.tm = 'is currently not available'
                        ev.btn_chk = false
                    }
                    //console.log('pin')
                    //console.log(data)
                })
            }
        },
        chng_pin() {
            this.pincode = ''
            this.tm = ''
        },
        delete_cart_pre(cid) {
            var t = this.delete_cart(cid)

            setTimeout(() => {
                this.getData()
            }, 1000)
        },
        go_order() {
            this.$router.push('placeorder')
        },
        getData() {
            var self = this
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'api.php',
                data: {
                    type: 'mycartdata',
                    uid: this.uid,
                    ip_address: this.ip_address
                }
            }).then(function (response) {
                var data = response.data
                self.cartData = data.mycartdata
                self.cartcount = data.cart_count
                self.total = data.total
                //console.log("mycartdata");
                //console.log(response)
                //console.log("dededeed");
            })
        },


    }
}
</script>

<style scoped>
.width-fr {
    width: max-content;
}
.pic-edit{
    position: absolute;
    top: -10px;
    right: -15px;
    background-color: #3699ff;
    padding: 7px;
    color: #e1f0ff;
    border-radius: 60%;
    border: 1px solid;
    font-size: 15px;
    cursor: pointer;
}
</style>
