import { render, staticRenderFns } from "./AddKpis.vue?vue&type=template&id=e87c77a6&scoped=true&"
import script from "./AddKpis.vue?vue&type=script&lang=js&"
export * from "./AddKpis.vue?vue&type=script&lang=js&"
import style0 from "./AddKpis.vue?vue&type=style&index=0&id=e87c77a6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e87c77a6",
  null
  
)

export default component.exports