<template>
<div>
    <MobileHeader></MobileHeader>
    <div class="d-flex flex-row flex-column-fluid page">
        <div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">

            <Header></Header>

            <!--begin::Content-->
            <div class="content d-flex flex-column flex-column-fluid" id="kt_content">

                <div class="card card-custom" style="width:-webkit-fill-available;">
                    <div class="card-body p-0">
                        <div class="mb-7">
                            <div class="row align-items-center">
                                <div class="col-lg-7 col-xl-7">
                                    <div class="row align-items-center">
                                        <div class="col-md-6 my-2 my-md-0">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-5 col-xl-5 mt-5 mt-lg-0">
                                    <div class="btn-top-table pull-right mrg-right">
                                        <button type="button" class="btn btn-primary font-weight-bolder" @click="pop_val='add'">
                                            <i aria-hidden="true" class="fa fa-plus-circle"></i>
                                            Add New Chapter
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--begin: Wizard-->
                        <div class="wizard wizard-2" id="kt_wizard" data-wizard-state="first" data-wizard-clickable="true">
                            <!--begin: Wizard Nav-->
                            <div class="wizard-nav border-right py-8 px-8 px-lg-10">
                                <div class="chapter-list">
                                    <div class="card course_card mb-2">
                                        <div class="cards mt-3">
                                            <h3 class="ml-3 corse_nm">{{course_name_show}}</h3>
                                        </div>
                                    </div>

                                    <draggable tag="ul" :list="list" class="list-group" handle=".handle">

                                        <template v-for="(items,index) in allChapter">
                                            <div :key="index" class="wizard-steps pd-bottom">
                                                <div class="accordion accordion-toggle-arrow" id="accordionExample1">
                                                    <!--begin::Wizard Step 1 Nav-->
                                                    <div class="card">
                                                        <div class="card-header">
                                                            <div class="row">
                                                                <div class="col-2">
                                                                    <img src="../../assets/icons/edit.png" @click="open_edit_desk_fun(index)" class="edit_icon">
                                                                </div>
                                                                <div class="col-10">
                                                                    <div class="card-title handle" data-toggle="collapse" data-target="#collapseOne11" @click="getList2(items.chapter_id);open_box(items.chapter_id)" aria-expanded="false">
                                                                        {{items.chapter_name}}
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div class="crad-body edit-btn-box" v-if="open_edit_desk==index">
                                                            <div class="row">
                                                                <div class="col-12 btn-mng">
                                                                    <button class="btn btn-primary" @click="pop_val='edit';setChapter(items.chapter_id)"><i class="fa fa-edit"></i>Edit Chapter</button>
                                                                </div>
                                                                <div class="col-12 btn-mng">
                                                                    <button class="btn btn-danger" @click="delete_chapter(items.chapter_id)"><i class="fa fa-trash"></i>Delete Chapter</button>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div id="collapseOne11" :class="open_val==items.chapter_id ?'collapse show':'collapse'" data-parent="#accordionExample1" style="">
                                                            <div class="card-body">
                                                                <div class="row pd-bottom">
                                                                    <div class="col-12 mg-bottom-10">
                                                                        <button class="btn btn-primary font-weight-bolder text-uppercase  next" @click="show_option();chapter_ids=items.chapter_id">Add Lesson</button>
                                                                    </div>
                                                                    <div class="col-12 brdr-option" v-if="show_option_var">
                                                                        <div class="row">
                                                                            <div class="col-6 mg-bottom-10">
                                                                                <a :class="open_form=='text'?'navi-link py-4 active':'navi-link py-4'" @click="open_form='text'">
                                                                                    <span class="navi-icon mr-2">
                                                                                        <img src="../../assets/icons/text_icon.svg" class="icon-svg">
                                                                                    </span>
                                                                                    <span class="navi-text font-size-lg">Text</span>
                                                                                </a>
                                                                            </div>
                                                                            <div class="col-6 mg-bottom-10">
                                                                                <a :class="open_form=='pdf'?'navi-link py-4 active':'navi-link py-4'" @click="open_form='pdf'">
                                                                                    <span class="navi-icon mr-2">
                                                                                        <img src="../../assets/icons/pdf_icon.svg" class="icon-svg">
                                                                                    </span>
                                                                                    <span class="navi-text font-size-lg">PDF</span>
                                                                                </a>
                                                                            </div>

                                                                            <div class="col-6 mg-bottom-10">
                                                                                <a :class="open_form=='video'?'navi-link py-4 active':'navi-link py-4'" @click="open_form='video'">
                                                                                    <span class="navi-icon mr-2">
                                                                                        <img src="../../assets/icons/video_icon.svg" class="icon-svg">
                                                                                    </span>
                                                                                    <span class="navi-text font-size-lg"> Video</span>
                                                                                </a>
                                                                            </div>

                                                                            <div class="col-6 mg-bottom-10 hide">
                                                                                <a :class="open_form=='recording'?'navi-link py-4 active':'navi-link py-4'" @click="open_form='recording'">
                                                                                    <span class="navi-icon mr-2">
                                                                                        <img src="../../assets/icons/recording_icon.svg" class="icon-svg">
                                                                                    </span>
                                                                                    <span class="navi-text font-size-lg">Video Recording</span>
                                                                                </a>
                                                                            </div>

                                                                            <div class="col-6 mg-bottom-10">
                                                                                <a :class="open_form=='audio'?'navi-link py-4 active':'navi-link py-4'" @click="open_form='audio'">
                                                                                    <span class="navi-icon mr-2">
                                                                                        <img src="../../assets/icons/audio_icon.svg" class="icon-svg">
                                                                                    </span>
                                                                                    <span class="navi-text font-size-lg">Audio</span>
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <draggable tag="ul" :list="list2" class="list-group" handle=".handle">
                                                                    <template v-for="ldata in list2">
                                                                        <div :key="ldata.id" class="wizard-step mb-2 handle" data-wizard-type="step" data-wizard-state="current">
                                                                            <div class="wizard-label" @click="getLessonById(ldata.id,ldata.id);">
                                                                                <div class="row">
                                                                                    <div class="col-2">
                                                                                        <span class="my-handle"> :::: </span>
                                                                                    </div>
                                                                                    <div class="col-8">
                                                                                        <h3 class="wizard-title">{{ldata.title}}</h3>
                                                                                    </div>
                                                                                    <div class="col-2">
                                                                                        <span class="navi-icon mr-2">

                                                                                            <img v-if="ldata.type=='text'" src="../../assets/icons/text_icon.svg" class="icon-svg">
                                                                                            <img v-if="ldata.type=='pdf'" src="../../assets/icons/pdf_icon.svg" class="icon-svg">
                                                                                            <img v-if="ldata.type=='video'" src="../../assets/icons/video_icon.svg" class="icon-svg">
                                                                                            <img v-if="ldata.type=='audio'" src="../../assets/icons/audio_icon.svg" class="icon-svg">

                                                                                        </span>
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </template>
                                                                </draggable>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                    </draggable>
                                </div>

                            </div>

                            <div class="col-md-8" v-if="open_form">
                                <div class="card card-custom mt-5">
                                    <div class="card-body">

                                        <div class="container" v-if="open_form=='text'">

                                            <div class="card-body">

                                                <div class="form-group">
                                                    <label>Title <span class="text-danger">*</span></label>
                                                    <input type="text" class="form-control" v-model="title" placeholder="Enter title" />
                                                </div>

                                                <div class="form-group">
                                                    <label>Content <span class="text-danger">*</span></label>
                                                    <vue-editor v-model="content"></vue-editor>
                                                </div>

                                            </div>
                                            <div class="card-footer">
                                                <button type="reset" class="btn btn-primary mr-2" @click="text_lesson_save">Submit</button>
                                                <button type="reset" class="btn btn-secondary" @click="setRout">Cancel</button>
                                            </div>

                                        </div>

                                        <div class="container" v-if="open_form=='pdf'">

                                            <div class="card-body">
                                                <div class="form-group">
                                                    <label>Title <span class="text-danger">*</span></label>
                                                    <input type="text" class="form-control" v-model="title" placeholder="Enter title" />
                                                </div>

                                                <div class="form-group">
                                                    <label>Upload PDF <span class="text-danger">*</span></label>
                                                    <div class="upload-area" @click="$refs.file.click()">
                                                        <img class="icon-upload" src="../../assets/icons/upload_icon.svg">
                                                        <br>
                                                        <span class='select-file-span'>{{select_file_name}}</span>
                                                    </div>
                                                    <span class="text-danger">*** Note Max File Size 100 MB</span>
                                                </div>
                                                <input class="hide" type="file" id="file" ref="file" @change="upload_file" accept="application/pdf, application/vnd.ms-excel" />

                                            </div>
                                            <div class="card-footer">
                                                <button type="reset" class="btn btn-primary mr-2" @click='pdf_lesson_save'>Submit</button>
                                                <button type="reset" class="btn btn-secondary" @click="setRout">Cancel</button>
                                            </div>

                                        </div>

                                        <div class="container" v-if="open_form=='video'">

                                            <div class="card-body">
                                                <div class="form-group">
                                                    <label>Title <span class="text-danger">*</span></label>
                                                    <input type="text" class="form-control" v-model="title" placeholder="Enter title" />
                                                </div>

                                                <div class="form-group">
                                                    <label>Upload Video <span class="text-danger">*</span></label>
                                                    <select class="form-control" v-model="vid">
                                                        <option value="">Select Video</option>
                                                        <template v-for="vdata in videoList">
                                                            <option :key="vdata.id" :value="vdata.id">{{vdata.title}}</option>
                                                        </template>

                                                    </select>
                                                </div>

                                                <div class="form-group">
                                                    <label>Content <span class="text-danger">*</span></label>
                                                    <vue-editor v-model="video_content"></vue-editor>
                                                </div>

                                                
                                            </div>

                                            <div class="card-footer">
                                                <button type="reset" class="btn btn-primary mr-2" @click="video_lesson_save">Submit</button>
                                                <button type="reset" class="hide btn btn-secondary" @click="setRout">Cancel</button>

                                            </div>

                                        </div>

                                        <div class="container" v-if="open_form=='recording'">

                                            <div class="card-body">

                                            </div>

                                        </div>

                                        <div class="container" v-if="open_form=='audio'">

                                            <div class="card-body">

                                                <div class="form-group">
                                                    <label>Title <span class="text-danger">*</span></label>
                                                    <input type="text" class="form-control" v-model="title" placeholder="Enter title" />
                                                </div>

                                                <div class="form-group">
                                                    <label>Upload Audio <span class="text-danger">*</span></label>
                                                    <div class="upload-area" @click="$refs.audio_r.click()">
                                                        <img class="icon-upload" src="../../assets/icons/upload_icon.svg">
                                                        <br>
                                                        <span class='select-file-span'>{{select_audio_file}}</span>
                                                    </div>
                                                    <span class="text-danger">*** Note Max File Size 100 MB</span>
                                                </div>
                                                <input class="hide" type="file" id="file" ref="audio_r" @change="audio_upload_file" accept="audio/*" />

                                            </div>
                                            <div class="card-footer">
                                                <button type="reset" class="btn btn-primary mr-2" @click="audio_lesson_save">Submit</button>
                                                <button type="reset" class="btn btn-secondary" @click="setRout">Cancel</button>
                                            </div>

                                        </div>

                                        <div class="container" v-if="open_form=='edit_text'">
                                            <button class="btn btn-danger pull-right" @click="delete_lesson">
                                                <i class="fa fa-trash"></i>
                                                Delete Lesson
                                            </button>
                                            <div class="card-body">

                                                <div class="form-group">
                                                    <label>Title <span class="text-danger">*</span></label>
                                                    <input type="text" class="form-control" v-model="edit_title" placeholder="Enter title" />
                                                </div>

                                                <input type="hidden" v-model="lid" />

                                                <div class="form-group">
                                                    <label>Content <span class="text-danger">*</span></label>
                                                    <vue-editor v-model="edit_content"></vue-editor>
                                                </div>

                                            </div>
                                            <div class="card-footer">
                                                <button type="reset" class="btn btn-primary mr-2" @click="edit_text_lesson_save">Submit</button>
                                                <button type="reset" class="btn btn-secondary" @click="setRout">Cancel</button>
                                            </div>

                                        </div>

                                        <div class="container" v-if="open_form=='edit_pdf'">
                                            <button class="btn btn-danger pull-right" @click="delete_lesson">
                                                <i class="fa fa-trash"></i>
                                                Delete Lesson
                                            </button>
                                            <div class="card-body">
                                                <div class="form-group">
                                                    <label>Title <span class="text-danger">*</span></label>
                                                    <input type="text" class="form-control" v-model="edit_title" placeholder="Enter title" />
                                                </div>

                                                <div class="form-group">
                                                    <label>Upload PDF <span class="text-danger">*</span></label>
                                                    <div class="upload-area" @click="$refs.file.click()">
                                                        <img class="icon-upload" src="../../assets/icons/upload_icon.svg">
                                                        <br>
                                                        <span class='select-file-span'>{{edit_select_file_name}}</span>
                                                    </div>
                                                    <span class="text-danger">*** Note Max File Size 100 MB</span>
                                                </div>

                                                <div class="form-group pdf-boxs">
                                                    <iframe class="pdf-view" :src="$root.URL_IMAGES+'pdf/'+edit_select_file_name" type="application/pdf" data="[ImageURLHere]#zoom=65%&scrollbar=0&toolbar=0&navpanes=0"></iframe>
                                                </div>
                                                <input class="hide" type="file" id="file" ref="file" @change="edit_upload_file" accept="application/pdf, application/vnd.ms-excel" />

                                            </div>
                                            <div class="card-footer">
                                                <button type="reset" class="btn btn-primary mr-2" @click='edit_pdf_lesson_save'>Submit</button>
                                                <button type="reset" class="btn btn-secondary" @click="setRout">Cancel</button>
                                            </div>

                                        </div>

                                        <div class="container" v-if="open_form=='edit_video'">
                                            <button class="btn btn-danger pull-right" @click="delete_lesson">
                                                <i class="fa fa-trash"></i>
                                                Delete Lesson
                                            </button>
                                            <div class="card-body">
                                                <div class="form-group">
                                                    <label>Title <span class="text-danger">*</span></label>
                                                    <input type="text" class="form-control" v-model="edit_title" placeholder="Enter title" />
                                                </div>

                                            
                                                <div class="form-group">
                                                    <label>Upload Video <span class="text-danger">*</span></label>
                                                    <select class="form-control" v-model="edit_vid">
                                                        <option value="">Select Video</option>
                                                        <template v-for="vdata in videoList">
                                                            <option :key="vdata.id" :value="vdata.id">{{vdata.title}}</option>
                                                        </template>

                                                    </select>
                                                </div>

                                                 <div class="form-group">
                                                    <label>Content <span class="text-danger">*</span></label>
                                                    <vue-editor v-model="edit_video_content"></vue-editor>
                                                </div>

                                                <div class="form-group">
                                                    <iframe v-if="video_type=='local'" :src="'https://player.vimeo.com/video/'+set_video+'?badge=0&amp;autopause=0&amp;player_id=0&amp;'" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="width:100%;height:400px;" :title="newAry.title"></iframe>
                                                    
                                                     <iframe v-if="video_type=='loom'" :src="set_video" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="width:100%;height:400px;" :title="newAry.title"></iframe>

                                                </div>
                                            </div>

                                            <div class="card-footer">
                                                <button type="reset" class="btn btn-primary mr-2" @click="edit_video_lesson_save">Submit</button>
                                                <button type="reset" class="hide btn btn-secondary" @click="setRout">Cancel</button>

                                            </div>

                                        </div>

                                        <div class="container" v-if="open_form=='edit_recording'">

                                            <div class="card-body">

                                            </div>

                                        </div>

                                        <div class="container" v-if="open_form=='edit_audio'">
                                            <button class="btn btn-danger pull-right" @click="delete_lesson">
                                                <i class="fa fa-trash"></i>
                                                Delete Lesson
                                            </button>
                                            <div class="card-body">

                                                <div class="form-group">
                                                    <label>Title <span class="text-danger">*</span></label>
                                                    <input type="text" class="form-control" v-model="edit_title" placeholder="Enter title" />
                                                </div>

                                                <div class="form-group">
                                                    <label>Upload Audio <span class="text-danger">*</span></label>
                                                    <div class="upload-area" @click="$refs.audio_r.click()">
                                                        <img class="icon-upload" src="../../assets/icons/upload_icon.svg">
                                                        <br>
                                                        <span class='select-file-span'>{{edit_select_audio_file}}</span>
                                                    </div>
                                                    <span class="text-danger">*** Note Max File Size 100 MB</span>
                                                </div>
                                                <input class="hide" type="file" id="file" ref="audio_r" @change="edit_audio_upload_file" accept="audio/*" />

                                            </div>
                                            <div class="card-footer">
                                                <button type="reset" class="btn btn-primary mr-2" @click="audio_lesson_save">Submit</button>
                                                <button type="reset" class="btn btn-secondary" @click="setRout">Cancel</button>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>

                            <!--end: Wizard Nav-->
                            <!--begin: Wizard Body-->
                            <div class="wizard-body py-8 px-8 px-lg-10 bottom-pedding" v-else>
                                <!--begin: Wizard Form-->
                                <div class="row">
                                    <div class="offset-xxl-2 col-xxl-8">
                                        <div class="empty-state">

                                            <h2 class="empty-state__title">Choose a chapter or lesson to get started</h2>
                                            <p class="empty-state__description mb-3">
                                                Preview your course as a student to make sure everything is looking sharp.
                                            </p>
                                            <!---->

                                        </div>
                                    </div>
                                    <!--end: Wizard-->
                                </div>
                                <!--end: Wizard Form-->
                            </div>
                            <!--end: Wizard Body-->

                        </div>
                        <!--end: Wizard-->
                    </div>
                </div>

            </div>
            <!--end::Content-->

            <Footer></Footer>

        </div>
    </div>

    <div class="main-popup-overlay" v-if="pop_val=='add'">

        <div class="content-start-popup">

            <div class="popup-header">
                <h2>Enter Chapter Name</h2>
            </div>
            <div class="popup-main-content">
                <div class="container">
                    <div class="form-data">
                        <div class="row">
                            <div class="col-md-12">
                                <input class="form-control" v-model="chapter_name" type="text" placeholder="Enter Chapter Name" />
                            </div>
                            <div class="col-md-12">
                                <div class="btn-div-popup">
                                    <button class="btn btn-info btn-popup-save" @click="save_chapter">Save</button>
                                    <button class="btn btn-danger btn-popup-cancel" @click="pop_val=false;chapter_name=null;">Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="main-popup-overlay" v-if="pop_val=='edit'">

        <div class="content-start-popup">

            <div class="popup-header">
                <h2>Edit Chapter Name</h2>
            </div>
            <div class="popup-main-content">
                <div class="container">
                    <div class="form-data">
                        <div class="row">
                            <div class="col-md-12">
                                <input class="form-control" v-model="edit_chapter_name" type="text" placeholder="Enter Chapter Name" />
                                
                            </div>
                            <div class="col-md-12">
                                <div class="btn-div-popup">
                                    <button class="btn btn-info btn-popup-save" @click="edit_chapter">Save</button>
                                    <button class="btn btn-danger btn-popup-cancel" @click="pop_val=false;edit_chapter_name=null;">Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
</template>

<script>
let id = 3;

import axios from 'axios'
import MobileHeader from '../../components/MobileHeader'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import draggable from 'vuedraggable'
import {
    VueEditor
} from "vue2-editor";

export default {
    name: 'Home',
    display: "Handle",
    instruction: "Drag using the handle icon",
    order: 5,
    components: {
        Header,
        Footer,
        MobileHeader,
        draggable,
        VueEditor,
    },
    data() {
        return {
            uid: localStorage.getItem('uid'),
            open_val: null,
            LessonAry: [],
            pop_val: false,
            course_name: null,
            page: 1,
            totalCount: 0,
            newAry: [],
            perPage: 5,
            select_keys: 0,
            chapter_name: null,
            allChapter: [],
            course_data: [],
            list: [],
            list2: [],
            dragging: false,
            chapter_ids: null,
            edit_chapter_name:null,
            chapter_detail:[],
            course_name_show:null,

            open_form: null,
            show_option_var: false,

            content: null,
            title: null,
            cid: this.$route.params.id,
            file: "",
            select_file_name: null,
            audio_file: "",
            select_audio_file: null,
            video_file: "",
            select_video_file: null,
            urr: null,
            percentage: 0,
            upload_process: false,
            videoList: [],
            vid: "",

            edit_content: null,
            edit_title: null,
            edit_file: "",
            edit_select_file_name: null,
            edit_audio_file: "",
            edit_select_audio_file: null,
            edit_video_file: "",
            edit_select_video_file: null,
            edit_vid: "",
            lid: null,
            set_video:null,

            open_edit_desk:null,

            video_type: null,


            video_content:null,    
            edit_video_contant:null,

        }
    },
    mounted() {

        window.scrollTo(0, 0)

        this.getLesson();
        this.getAllChapter();
        this.allVideo();

    },
    computed: {
        draggingInfo() {
            return this.dragging ? "under drag" : "";
        }
    },
    watch: {
        list: function (vl) {
            
            this.change_chapter();
        },
        list2: function (vl) {
            
            this.change_lesson();
        },
        allChapter: function (vl) {
            this.allChapter = vl;
        },
        course_data: function (vl) {
            this.course_data = vl;
        }
    },
    methods: {
        open_edit_desk_fun(ids){
            this.open_val=null;
            if(this.open_edit_desk==null){
                this.open_edit_desk=ids;
            }else{
                this.open_edit_desk=null;
            }
            
        },
        getLesson() {
            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'lms_api.php',
                data: {
                    type: 'getLesson',
                    cid: this.$route.params.id,
                }
            }).then(function (response) {
                var data = response.data;
                ev.course_data = data.data;
                ev.newAry = data.data;
                ev.totalCount = data.count;
                if (ev.totalCount > 0) {
                    var ids = ev.course_data[0].id
                    // ev.getLessonById(ids, 0);
                }
                //ev.changePage();

            })

        },
        save_chapter() {
            var ev = this;

            if (this.chapter_name == null || this.chapter_name == "") {
                ev.$toasted.global.error({
                    message: "please enter chapter name"
                });
            } else {
                axios({
                    method: 'POST',
                    url: this.$root.URL_ROOT + 'lms_api.php',
                    data: {
                        type: 'chapter_save',
                        chapter_name: this.chapter_name,
                        uid: this.uid,
                        role: this.role,
                        cid: this.$route.params.id,

                    }
                }).then(function (response) {
                    //console.log(response.data);
                    var data = response.data;
                    if (data.status == "success") {
                        ev.$toasted.global.success({
                            message: data.msg
                        });
                        ev.chapter_name = null;
                        ev.pop_val = false;
                         ev.getLesson();
                        ev.getAllChapter();
                        ev.allVideo();
                        //ev.allCourse();
                    } else {
                        ev.$toasted.global.error({
                            message: data.msg
                        });
                    }

                })
            }
        },
        setChapter(ids){
              var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'lms_api.php',
                data: {
                    type: 'getChapterData',
                    cid: ids,

                }
            }).then(function (response) {
                var data = response.data;
               //console.log(data);
                ev.edit_chapter_name=data.chapter_name;
                ev.chapter_detail=data;
            })
        },
        edit_chapter(){
            var ev = this;

            if (this.edit_chapter_name == null || this.edit_chapter_name == "") {
                ev.$toasted.global.error({
                    message: "please enter chapter name"
                });
            } else {
                axios({
                    method: 'POST',
                    url: this.$root.URL_ROOT + 'lms_api.php',
                    data: {
                        type: 'edit_chapter',
                        chapter_name: this.edit_chapter_name,
                        cid: this.chapter_detail.id,
                    }
                }).then(function (response) {
                    var data=response.data;
                    //console.log(data);
                    if(data.status=="success"){
                        ev.$toasted.global.success({
                            message: data.msg
                        });
                        ev.edit_chapter_name = null;
                        ev.pop_val = false;
                        ev.getLesson();
                        ev.getAllChapter();
                        ev.allVideo();
                    }else{
                        ev.$toasted.global.error({
                            message: data.msg
                        });
                    }
                })
            } 
        },
        getAllChapter() {
            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'lms_api.php',
                data: {
                    type: 'getAllChapter',
                    cid: this.$route.params.id,

                }
            }).then(function (response) {
                var data = response.data;
                //console.log(data);
                ev.course_data = data.course;
                ev.course_name_show=data.course.course_name;
                //console.log(ev.course_data)

                ev.allChapter = data.chapter;
                ev.list = data.chapter;

                if (data.status == "success") {

                } else {

                }

            })

        },
        getLessonById(ids, keys) {
            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'lms_api.php',
                data: {
                    type: 'getLessonById',
                    lid: ids,
                }
            }).then(function (response) {

                //console.log(response.data);
                ev.LessonAry = response.data;
                ev.set_video=ev.LessonAry.video;
                ev.open_form = "edit_" + ev.LessonAry.type;
                ev.edit_title = ev.LessonAry.title;
                ev.video_type= ev.LessonAry.video_type;
                ev.lid = ev.LessonAry.id;
                ev.chapter_ids = ev.LessonAry.chapter_id;
                if (ev.LessonAry.type == 'text') {
                    ev.edit_content = ev.LessonAry.content;
                } else if (ev.LessonAry.type == 'pdf') {
                    ev.edit_select_file_name = ev.LessonAry.pdf;
                } else if (ev.LessonAry.type == 'video') {
                    ev.edit_vid = ev.LessonAry.vid;
                    
                }
                ev.select_keys = keys + 1;
            })
        },
        getList2(ids) {
            
            var ev = this;
            ev.list2=null;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'lms_api.php',
                data: {
                    type: 'getList2',
                    chapter_id: ids,

                }
            }).then(function (response) {
                var data = response.data;
                //console.log(data);
                ev.list2 = data
            })
        },
        open_box(ids) {
          
            this.open_edit_desk=null;
             
            if (this.open_val == ids) {
                this.open_val = null;
            } else {
                this.open_val = ids;
            }
           

        },
        change_lesson() {
            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'lms_api.php',
                data: {
                    type: 'change_lesson',
                    list2: this.list2,

                }
            }).then(function (response) {
                var data = response.data;

            })
        },
        change_chapter(){
             var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'lms_api.php',
                data: {
                    type: 'change_chapter',
                    list: this.list,

                }
            }).then(function (response) {
                var data = response.data;

            })
        },
        show_option() {
            if (this.show_option_var == true) {
                this.show_option_var = false;
            } else {
                this.show_option_var = true;
            }
        },

        setRout() {
            this.$router.push("/viewlesson/" + this.cid);
        },
        allCourse() {
            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'lms_api.php',
                data: {
                    type: 'allCourse',
                }
            }).then(function (response) {
                //console.log(response.data)
                var data = response.data;
                ev.course_data = data.data;
                ev.newAry = data.data;
                ev.totalCount = data.count;
                ev.changePage();

            })
        },
        allVideo() {
            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'lms_api.php',
                data: {
                    type: 'videolibrary',
                    uid: this.uid,
                }
            }).then(function (response) {
                var data = response.data;
                ev.videoList = data.data;
                console.log(ev.videoList);

                

            })
        },
        text_lesson_save() {
            var ev = this;
            if (this.title == null || this.title == "") {
                ev.$toasted.global.error({
                    message: "please enter title"
                });
            } else if (this.content == null || this.content == "") {
                ev.$toasted.global.error({
                    message: "please enter content"
                });
            } else {

                axios({
                    method: 'POST',
                    url: this.$root.URL_ROOT + 'lms_api.php',
                    data: {
                        type: 'text_lesson_save',
                        cid: this.cid,
                        title: this.title,
                        content: this.content,
                        ctype: 'text',
                        chapter_id: this.chapter_ids,
                        uid:this.uid,
                    }
                }).then(function (response) {
                    //console.log(response.data);
                    var data = response.data
                    if (data.status == 'success') {
                        ev.$toasted.global.success({
                            message: data.msg
                        });
                        ev.getAllChapter();
                        ev.getList2(ev.chapter_ids);
                        ev.show_option_var = false;
                        ev.title = null;
                        ev.content = null;
                        //ev.$router.push("/viewlesson/" + ev.$route.params.id);
                    } else {
                        ev.$toasted.global.error({
                            message: data.msg
                        });
                    }
                })
            }
        },
        upload_file() {
            var ev = this;
            this.file = this.$refs.file.files[0];

            let formData = new FormData();
            this.select_file_name = this.file.name;

        },
        pdf_lesson_save: function () {
            var ev = this;
            this.file = this.$refs.file.files[0];

            let formData = new FormData();

            formData.append('file', this.file);
            formData.append('title', this.title);
            formData.append('cid', this.cid);
            formData.append('chapter_id', this.chapter_ids);
            formData.append('uid', this.uid);

            if (this.title == null || this.title == "") {
                ev.$toasted.global.error({
                    message: "please enter title"
                });
            } else if (this.file == undefined) {
                ev.$toasted.global.error({
                    message: "please upload pdf file"
                });
            } else {
                this.select_file_name = this.file.name;

                axios.post(this.$root.URL_ROOT + 'lms_api.php?type=upload_pdf', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(function (response) {
                        var data = response.data;
                        if (data.status == "success") {
                            ev.$toasted.global.success({
                                message: data.msg
                            });
                            ev.getAllChapter();
                            ev.getList2(ev.chapter_ids);
                            ev.show_option_var = false;
                            ev.title = null;
                            ev.file = null;
                            ev.select_file_name = null;
                            //ev.$router.push("/viewlesson/" + ev.cid);
                            //ev.$router.push("/viewlesson/" + ev.$route.params.cid);
                        } else {
                            ev.$toasted.global.error({
                                message: data.msg
                            });
                        }
                    })

            }

        },
        audio_upload_file() {
            var ev = this;
            this.audio_file = this.$refs.audio_r.files[0];

            let formData = new FormData();
            this.select_audio_file = this.audio_file.name;

        },
        audio_lesson_save: function () {
            var ev = this;
            this.audio_file = this.$refs.audio_r.files[0];

            let formData = new FormData();

            formData.append('file', this.audio_file);
            formData.append('title', this.title);
            formData.append('cid', this.cid);
            formData.append('uid', this.uid);
            formData.append('chapter_id', this.chapter_ids);
            if (this.title == null || this.title == "") {
                ev.$toasted.global.error({
                    message: "please enter title"
                });
            } else if (this.file == undefined) {
                ev.$toasted.global.error({
                    message: "please upload audio file"
                });
            } else {
                //this.select_audio_file = this.file.name;

                axios.post(this.$root.URL_ROOT + 'lms_api.php?type=upload_audio', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(function (response) {
                        var data = response.data;
                        if (data.status == "success") {
                            ev.$toasted.global.success({
                                message: data.msg
                            });
                            ev.getAllChapter();
                            ev.getList2(ev.chapter_ids);
                            ev.show_option_var = false;
                            ev.title = null;
                            ev.audio_file = null;
                            ev.select_audio_file = null;
                            //ev.$router.push("/viewlesson/" + ev.cid);
                            //ev.$router.push("/viewlesson/" + ev.$route.params.cid);
                        } else {
                            ev.$toasted.global.error({
                                message: data.msg
                            });
                        }
                    })

            }

        },
        video_upload_file() {
            var ev = this;
            this.video_file = this.$refs.video_r.files[0];

            let formData = new FormData();
            this.select_video_file = this.video_file.name;

        },
        video_lesson_save_old: function () {
            var ev = this;
            this.video_file = this.$refs.video_r.files[0];

            let formData = new FormData();

            formData.append('file', this.video_file);
            formData.append('title', this.title);
            formData.append('cid', this.cid);
            if (this.title == null || this.title == "") {
                ev.$toasted.global.error({
                    message: "please enter title"
                });
            } else if (this.file == undefined) {
                ev.$toasted.global.error({
                    message: "please upload video file"
                });
            } else {
                //this.select_audio_file = this.file.name;

                axios.post(this.$root.URL_ROOT + 'lms_api.php?type=upload_video', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(function (response) {
                        var data = response.data;
                        if (data.status == "success") {
                            ev.$toasted.global.success({
                                message: data.msg
                            });
                            ev.$router.push("/viewlesson/" + ev.cid);

                        } else {
                            ev.$toasted.global.error({
                                message: data.msg
                            });
                        }
                    })

            }

        },
        video_lesson_save: function () {
            var ev = this;
            if (this.title == null || this.title == "") {
                ev.$toasted.global.error({
                    message: "please enter title"
                });
            } else if (this.vid == null || this.vid == "") {
                ev.$toasted.global.error({
                    message: "please select video file"
                });
            } else {
                axios({
                    method: 'POST',
                    url: this.$root.URL_ROOT + 'lms_api.php',
                    data: {
                        type: 'upload_video',
                        cid: this.cid,
                        title: this.title,
                        vid: this.vid,
                        chapter_id: this.chapter_ids,
                        uid: this.uid,
                        video_contant:this.video_contant,
                    }
                }).then(function (response) {
                    var data = response.data;
                    //console.log(data)
                    if (data.status == 'success') {
                        ev.$toasted.global.success({
                            message: data.msg
                        });
                        ev.getAllChapter();
                        ev.getList2(ev.chapter_ids);
                        ev.show_option_var = false;
                        ev.title = null;
                        ev.vid = null;
                        //ev.$router.push("/viewlesson/" + ev.$route.params.cid);
                    } else {
                        ev.$toasted.global.error({
                            message: data.msg
                        });
                    }
                })

            }

        },

        //edit

        edit_text_lesson_save() {
            var ev = this;
            if (this.edit_title == null || this.edit_title == "") {
                ev.$toasted.global.error({
                    message: "please enter title"
                });
            } else if (this.edit_content == null || this.edit_content == "") {
                ev.$toasted.global.error({
                    message: "please enter content"
                });
            } else {

                axios({
                    method: 'POST',
                    url: this.$root.URL_ROOT + 'lms_api.php',
                    data: {
                        type: 'edit_text_lesson_save',
                        title: this.edit_title,
                        content: this.edit_content,
                        lid: this.lid
                    }
                }).then(function (response) {
                    //console.log(response.data);
                    var data = response.data
                    if (data.status == "success") {
                        ev.$toasted.global.success({
                            message: data.msg
                        });
                        //ev.$router.push("/viewlesson/" + ev.cid);
                        //ev.$router.push("/viewlesson/"+ev.$route.params.cid);

                    } else {
                        ev.$toasted.global.error({
                            message: data.msg
                        });
                    }
                })
            }
        },
        edit_upload_file() {
            var ev = this;
            this.edit_file = this.$refs.file.files[0];

            let formData = new FormData();
            this.edit_select_file_name = this.edit_file.name;
            this.check_pdf = true;

        },
        edit_pdf_lesson_save: function () {
            var ev = this;
            this.edit_file = this.$refs.file.files[0];

            let formData = new FormData();

            formData.append('file', this.edit_file);
            formData.append('title', this.edit_title);
            formData.append('lid', this.lid);
            if (this.edit_title == null || this.edit_title == "") {
                ev.$toasted.global.error({
                    message: "please enter title"
                });
            } else {
                if (this.check_pdf) {
                    this.edit_select_file_name = this.edit_file.name;
                }

                axios.post(this.$root.URL_ROOT + 'lms_api.php?type=edit_upload_pdf', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(function (response) {
                        var data = response.data;
                        if (data.status == "success") {
                            ev.$toasted.global.success({
                                message: data.msg
                            });
                            //ev.$router.push("/viewlesson/" + ev.cid);
                            //ev.$router.push("/viewlesson/"+ev.$route.params.cid);

                        } else {
                            ev.$toasted.global.error({
                                message: data.msg
                            });
                        }
                    })

            }

        },
        edit_audio_upload_file() {
            var ev = this;
            this.edit_audio_file = this.$refs.audio_r.files[0];

            let formData = new FormData();
            this.edit_select_audio_file = this.audio_file.name;

        },
        edit_audio_lesson_save: function () {
            var ev = this;
            this.edit_audio_file = this.$refs.audio_r.files[0];

            let formData = new FormData();

            formData.append('file', this.edit_audio_file);
            formData.append('title', this.edit_title);
            formData.append('lid', this.lid);
            if (this.edit_title == null || this.edit_title == "") {
                ev.$toasted.global.error({
                    message: "please enter title"
                });
            } else {
                //this.select_audio_file = this.file.name;

                axios.post(this.$root.URL_ROOT + 'lms_api.php?type=edit_upload_audio', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(function (response) {
                        var data = response.data;
                        if (data.status == "success") {
                            ev.$toasted.global.success({
                                message: data.msg
                            });
                            //ev.$router.push("/viewlesson/" + ev.cid);
                            //ev.$router.push("/viewlesson/"+ev.$route.params.cid);

                        } else {
                            ev.$toasted.global.error({
                                message: data.msg
                            });
                        }
                    })

            }

        },
        edit_video_upload_file() {
            var ev = this;
            this.edit_video_file = this.$refs.video_r.files[0];

            let formData = new FormData();
            this.edit_select_video_file = this.video_file.name;

        },

        edit_video_lesson_save_old: function () {
            var ev = this;
            this.video_file = this.$refs.video_r.files[0];

            let formData = new FormData();

            formData.append('file', this.video_file);
            formData.append('title', this.title);
            formData.append('cid', this.cid);
            if (this.title == null || this.title == "") {
                ev.$toasted.global.error({
                    message: "please enter title"
                });
            } else if (this.file == undefined) {
                ev.$toasted.global.error({
                    message: "please upload video file"
                });
            } else {
                //this.select_audio_file = this.file.name;

                axios.post(this.$root.URL_ROOT + 'lms_api.php?type=upload_video', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(function (response) {
                        var data = response.data;
                        if (data.status == "success") {
                            ev.$toasted.global.success({
                                message: data.msg
                            });
                            ev.$router.push("/viewlesson/" + ev.cid);

                        } else {
                            ev.$toasted.global.error({
                                message: data.msg
                            });
                        }
                    })

            }

        },
        edit_video_lesson_save: function () {
            var ev = this;

            if (this.edit_title == null || this.edit_title == "") {
                ev.$toasted.global.error({
                    message: "please enter title"
                });
            } else if (this.edit_file == undefined) {
                ev.$toasted.global.error({
                    message: "please upload video "
                });
            } else {
                axios({
                    method: 'POST',
                    url: this.$root.URL_ROOT + 'lms_api.php',
                    data: {
                        type: 'edit_upload_video',
                        cid: this.cid,
                        title: this.edit_title,
                        vid: this.edit_vid,
                        lid: this.lid,
                        video_content: this.edit_video_content

                    }
                }).then(function (response) {
                    var data = response.data;
                    //console.log(data)
                    if (data.status == 'success') {
                        ev.$toasted.global.success({
                            message: data.msg
                        });
                        //ev.$router.push("/viewlesson/"+ev.$route.params.cid);
                    } else {
                        ev.$toasted.global.error({
                            message: data.msg
                        });
                    }
                })

            }

        },

        
        delete_chapter(ids){
            var ev = this;
           
                axios({
                    method: 'POST',
                    url: this.$root.URL_ROOT + 'lms_api.php',
                    data: {
                        type: 'delete_chapter',
                        cid: ids,
                    }
                }).then(function (response) {
                    var data = response.data;
                    //console.log(data);
                    if(data.status=='success'){
                        ev.$toasted.global.success({
                          message: data.msg
                        });
                        ev.getLesson();
                        ev.getAllChapter();
                        ev.open_edit_desk=null;
                    }else{
                        ev.$toasted.global.error({
                            message: data.msg
                        });
                    }
                })
        },
        delete_lesson(){
            var ev = this;
                axios({
                    method: 'POST',
                    url: this.$root.URL_ROOT + 'lms_api.php',
                    data: {
                        type: 'delete_lesson',
                        lid: this.lid,
                    }
                }).then(function (response) {
                    var data = response.data;
                    //console.log(data);
                    if(data.status=='success'){
                        ev.$toasted.global.success({
                          message: data.msg
                        });
                        ev.getLesson();
                        ev.getAllChapter();
                        ev.getList2(ev.chapter_ids);
                        ev.open_form=null;
                        
                    }else{
                        ev.$toasted.global.error({
                            message: data.msg
                        });
                    }
                })
        }


    }
}
</script>

<style scoped>
.bottom-pedding{
    padding-bottom: 25% !important;
}
.btn-mng{
    text-align: center;
    margin-bottom: 10px;
}
.edit-btn-box{
    margin: 10px 10px;
}
.upload-area{
    cursor: pointer;
}
.pdf-view {
    width: 100%;
    height: 600px;
}
.pdf-boxs{
        padding: 10px;
    background-color: #e2e2e2;
    border-radius: 10px;

}
.course_card{
    background-color: #3798fb;
    color: #fff;
    text-align: center;
}
.corse_nm{
    text-transform: capitalize;
}
.edit_icon {
    width: 35px;
    padding-left: 10px;
    padding-top: 10px;
}

.navi-link {
    cursor: pointer;
}

.brdr-option {
    border: 1px solid #3798fb;
    padding: 11px;
    border-radius: 7px;
}

.mg-bottom-10 {
    margin-bottom: 10px;
}

.font-size-lg {
    font-size: 1rem !important;
}

.glyphicon-move {
    cursor: move;
    cursor: -webkit-grabbing;
}

.pd-bottom {
    padding-bottom: 10px;
}

.process {
    font-size: 22px;
    font-weight: bold;
    color: #3698fb;
}

.select-file-span {
    color: #3698fb;
    margin-top: 20px;
    font-size: 16px;
}

.icon-upload {
    width: 40px;
    margin-bottom: 15px;
}

.upload-area {
    padding: 5%;
    text-align: center;
    border: 1px solid #3898fb;
    border-radius: 10px;
}

@media only screen and (max-width: 600px) {

    .main-popup-overlay {
        padding-top: 30% !important;
        padding-left: 5% !important;
        padding-right: 5% !important;
    }
}

.btn-popup-cancel {
    float: right;
    width: 120px;
}

.main-popup {
    position: fixed;
    width: 100%;
    padding: 20%;
}

.popup-header {
    text-align: center;
}

.btn-div-popup {
    padding-top: 5%;

}

.btn-popup-save {
    float: left;
    width: 120px;

}

.content-start-popup {
    background: #ffffff;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 10%;
    padding-bottom: 10%;
    border-radius: 15px;
}

.main-popup-overlay {
    position: fixed;
    width: 100%;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: #0404046e;
    padding-top: 20%;
    padding-right: 30%;
    padding-left: 30%;
    z-index: 999999999;
}

.my-handle {
    cursor: move;
    cursor: -webkit-grabbing;
}

.icon-svg {
    height: 18px;
}

.pull-right {
    float: right;
}

.mrg-right {
    padding-right: 10%;
    padding-top: 5%;
}
</style>
