<template>
<div id="kt_body" class="header-fixed header-mobile-fixed header-bottom-enabled subheader-enabled page-loading">

    <!--begin::Main-->
    <div class="d-flex flex-column flex-root">
        <!--begin::Login-->
        <div class="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white login-div-height" id="kt_login">
            <!--begin::Aside assets/media/bg/bg-4.jpg   -->
            <div class="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10 login-bg">
                <!--begin: Aside Container-->
                <div class="d-flex flex-row-fluid flex-column justify-content-between">
                    <!--begin: Aside header-->
                    <a href="#" class="flex-column-auto mt-5 pb-lg-0 pb-10">
                        <img src="../assets/media/logos/ip_logo.png" class="max-h-70px" alt="" />
                    </a>
                    <!--end: Aside header-->
                    <!--begin: Aside content-->
                    <div class="flex-column-fluid d-flex flex-column justify-content-center">
                        <h3 class="font-size-h1 mb-5 text-white">Welcome to Metronic!</h3>
                        <p class="font-weight-lighter text-white opacity-80">The ultimate Bootstrap, Angular 8, React &amp; VueJS admin theme framework for next generation web apps.</p>
                    </div>
                    <!--end: Aside content-->
                    <!--begin: Aside footer for desktop-->
                    <div class="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
                        <div class="opacity-70 font-weight-bold text-white">© 2021 Metronic</div>
                        <div class="d-flex hide">
                            <a href="#" class="text-white">Privacy</a>
                            <a href="#" class="text-white ml-10">Legal</a>
                            <a href="#" class="text-white ml-10">Contact</a>
                        </div>
                    </div>
                    <!--end: Aside footer for desktop-->
                </div>
                <!--end: Aside Container-->
            </div>
            <!--begin::Aside-->
            <!--begin::Content-->
            <div class="d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden">
                <!--begin::Content header-->
                <div class="hide position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
                    <span class="font-weight-bold text-dark-50">Dont have an account yet?</span>
                    <a href="javascript:;" class="font-weight-bold ml-2" id="kt_login_signup" @click="open_div=4">Sign Up!</a>
                </div>
                <!--end::Content header-->
                <!--begin::Content body-->
                <div class="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">

                    <!--begin::Signup-->
                    <div v-if="open_div==1" class="login-form login-signin">
                        <div class="text-center mb-10 mb-lg-20">
                            <h3 class="font-size-h1">Sign Up Teacher</h3>
                            <p class="text-muted font-weight-bold">Enter your details to create your account</p>
                        </div>
                        <!--begin::Form-->
                        <div class="form-group">
                            <input class="form-control form-control-solid h-auto py-5 px-6" v-model="fname" type="text" placeholder="Fullname" name="fullname" autocomplete="off" />
                        </div>
                        <div class="form-group">
                            <input class="form-control form-control-solid h-auto py-5 px-6" v-model="email" type="email" placeholder="Email" name="email" autocomplete="off" />
                        </div>
                        <div class="form-group">
                            <input class="form-control form-control-solid h-auto py-5 px-6" v-model="mobile" type="number" placeholder="Mobile" name="mobile" autocomplete="off" />
                        </div>
                        <div class="form-group">
                            <input class="form-control form-control-solid h-auto py-5 px-6" v-model="pass" type="password" placeholder="Password" name="password" autocomplete="off" />
                        </div>
                        <div class="form-group">
                            <input class="form-control form-control-solid h-auto py-5 px-6" v-model="cpass" type="password" placeholder="Confirm password" name="cpassword" autocomplete="off" />
                        </div>
                        <div class="form-group hide">
                            <label class="checkbox mb-0">
                                <input type="checkbox" name="agree" />
                                <span></span>I Agree the
                                <a href="#">terms and conditions</a></label>
                        </div>
                        <div class="form-group d-flex flex-wrap flex-center">
                            <button type="button" id="kt_login_signup_submit" @click="student_singup" class="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4">Submit</button>
                            <button type="button" id="kt_login_signup_cancel hide" class="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4">Cancel</button>
                        </div>

                        <!--end::Form-->
                    </div>
                    <!--end::Signup-->

                    <!--begin::Forgot-->
                    <div v-if="open_div==2" class="login-form login-forgot">
                        <div class="text-center mb-10 mb-lg-20">
                            <h3 class="font-size-h1">Forgotten Password ?</h3>
                            <p class="text-muted font-weight-bold">Enter your email to reset your password</p>
                        </div>
                        <!--begin::Form-->
                        <form class="form" novalidate="novalidate" id="kt_login_forgot_form">
                            <div class="form-group">
                                <input class="form-control form-control-solid h-auto py-5 px-6" type="email" placeholder="Email" name="email" autocomplete="off" />
                            </div>
                            <div class="form-group d-flex flex-wrap flex-center">
                                <button type="button" id="kt_login_forgot_submit" class="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4">Submit</button>
                                <button type="button" id="kt_login_forgot_cancel" class="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4">Cancel</button>
                            </div>
                        </form>
                        <!--end::Form-->
                    </div>
                    <!--end::Forgot-->
                </div>
                <!--end::Content body-->
                <!--begin::Content footer for mobile-->
                <div class="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
                    <div class="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">© 2021 Metronic</div>
                    <div class="d-flex order-1 order-sm-2 my-2 hide">
                        <a href="#" class="text-dark-75 text-hover-primary">Privacy</a>
                        <a href="#" class="text-dark-75 text-hover-primary ml-4">Legal</a>
                        <a href="#" class="text-dark-75 text-hover-primary ml-4">Contact</a>
                    </div>
                </div>
                <!--end::Content footer for mobile-->
            </div>
            <!--end::Content-->
        </div>
        <!--end::Login-->
    </div>
    <!--end::Main-->

</div>
</template>

<script>
import axios from 'axios'

export default {
    components: {

    },
    data() {
        return {
            student_username: null,
            student_password: null,
            teacher_username: null,
            teacher_password: null,
            fname: null,
            email: null,
            mobile: null,
            pass: null,
            cpass: null,
            open_div: 1,
            reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
        }
    },
    mounted() {

    },
    methods: {
        
        student_singup() {
            var ev = this;
            var check_email = this.reg.test(this.email);
            //console.log(this.reg.test(this.email));
            //console.log(this.mobile.length);
            if (this.fname == null || this.fname == "") {
                ev.$toasted.global.error({
                    message: "please enter full name"
                });
            } else if (this.email == null || this.email == "") {
                ev.$toasted.global.error({
                    message: "please enter email"
                });
            } else if (check_email == false) {
                ev.$toasted.global.error({
                    message: "please enter valid email"
                });
            } else if (this.mobile == null || this.mobile == "") {
                ev.$toasted.global.error({
                    message: "please enter mobile no."
                });
            } else if (this.mobile.length < 0 || this.mobile.length > 10 || this.mobile.length < 10) {
                ev.$toasted.global.error({
                    message: "please enter valid mobile no."
                });
            } else if (this.pass == null || this.pass == "") {
                ev.$toasted.global.error({
                    message: "please enter password"
                });
            } else if (this.cpass == null || this.cpass == "") {
                ev.$toasted.global.error({
                    message: "please enter confirm password"
                });
            } else if (this.pass != this.cpass) {
                ev.$toasted.global.error({
                    message: "password not match"
                });
            } else {

                axios({
                    method: 'POST',
                    url: this.$root.URL_ROOT + 'lms_api.php',
                    data: {
                        type: 'student_singup',
                        fname: this.fname,
                        email: this.email,
                        mobile: this.mobile,
                        pass: this.pass,
                    }
                }).then(function (response) {
                    //console.log(response.data);
                    var data = response.data;
                    if (data.status == "success") {
                        ev.$toasted.global.success({
                            message: data.msg
                        })
                        ev.fname = null;
                        ev.email = null;
                        ev.mobile = null;
                        ev.pass = null;
                        ev.cpass = null;

                        ev.open_div = 2;
                    } else {
                        ev.$toasted.global.error({
                            message: data.msg
                        })
                    }

                })
            }

        }
    }
}
</script>

<style scoped>
.btn-select {
    width: 100%;
    font-size: 20px;
    font-weight: bold;

}
</style>
