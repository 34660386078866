<template>
<div id="kt_header" class="header flex-column header-fixed">
    <!--begin::Top-->
    <div class="header-top">
        <!--begin::Container-->
        <div class="container">
            <!--begin::Left-->
            <div class="d-none d-lg-flex align-items-center mr-3">
                <!--begin::Logo-->
                <a :href="$root.HomeUrl" class="" style="margin-right:2rem;">
                    <!--<img alt="Logo" src="../assets/media/logos/ip_logo.png"  class="max-h-35px" />-->
                    <!--<img alt="Logo" src="../assets/media/logos/logo2.png"  class="max-h-35px" />--->
                    <span class="logo-heading">IS</span>
                </a>
                <!--end::Logo-->
                <!--begin::Tab Navs(for desktop mode)-->
                <ul class="header-tabs nav align-self-end font-size-lg" v-if="top_menu==true" role="tablist">
                    <!--begin::Item-->
                    <li class="nav-item">
                        <a :href="$root.HomeUrl" class="nav-link py-4 px-6 active" data-toggle="tab" data-target="#kt_header_tab_1" role="tab">
                            <img src="../assets/icons/home.png" style="width:35px;">
                        </a>
                    </li>

                    <!--end::Item-->
                </ul>
                <!--begin::Tab Navs-->

                <!--begin::Tab Navs(for desktop mode)-->
                <ul class="header-tabs nav align-self-end font-size-lg" v-if="top_menu==false" role="tablist">
                    <!--begin::Item-->
                    <li class="nav-item">
                        <a @click="top_menu_click('dashboard')" :class="menu_box_check=='dashboard'?'nav-link py-4 px-6 active': 'nav-link py-4 px-6' " data-toggle="tab" data-target="#kt_header_tab_1" role="tab">Dashboard</a>
                    </li>
                    <li class="nav-item mr3">
                        <a @click="top_menu_click('manage_content')" :class="menu_box_check=='manage_content'?'nav-link py-4 px-6 active': 'nav-link py-4 px-6' " data-toggle="tab" data-target="#kt_header_tab_1" role="tab">Manage Content</a>
                    </li>
                    <li class="nav-item">
                        <a @click="top_menu_click('manage_student')" :class="menu_box_check=='manage_student'?'nav-link py-4 px-6 active': 'nav-link py-4 px-6' " data-toggle="tab" data-target="#kt_header_tab_1" role="tab">Students</a>
                    </li>
                    <li class="nav-item">
                        <a @click="top_menu_click('manage_employee')" :class="menu_box_check=='manage_employee'?'nav-link py-4 px-6 active': 'nav-link py-4 px-6' " data-toggle="tab" data-target="#kt_header_tab_1" role="tab">Employee</a>
                    </li>
                    <li class="nav-item">
                        <a @click="top_menu_click('teacher')" :class="menu_box_check=='teacher'?'nav-link py-4 px-6 active': 'nav-link py-4 px-6' " data-toggle="tab" data-target="#kt_header_tab_1" role="tab">Instructor</a>
                    </li>
                    <li class="nav-item">
                        <a @click="top_menu_click('team')" :class="menu_box_check=='team'?'nav-link py-4 px-6 active': 'nav-link py-4 px-6' " data-toggle="tab" data-target="#kt_header_tab_1" role="tab">Team</a>
                    </li>
                    <li class="nav-item">
                        <a @click="top_menu_click('coach')" :class="menu_box_check=='coach'?'nav-link py-4 px-6 active': 'nav-link py-4 px-6' " data-toggle="tab" data-target="#kt_header_tab_1" role="tab">Coach</a>
                    </li>
                    <li class="nav-item">
                        <a @click="top_menu_click('admin')" :class="menu_box_check=='admin'?'nav-link py-4 px-6 active': 'nav-link py-4 px-6' " data-toggle="tab" data-target="#kt_header_tab_1" role="tab">Admin</a>
                    </li>
                    <li class="nav-item">
                        <a @click="top_menu_click('internal')" :class="menu_box_check=='internal'?'nav-link py-4 px-6 active': 'nav-link py-4 px-6' " data-toggle="tab" data-target="#kt_header_tab_1" role="tab">Internal</a>
                    </li>

                    <!--end::Item-->
                </ul>
                <!--begin::Tab Navs-->

            </div>
            <!--end::Left-->
            <!--begin::Topbar-->
            <div class="topbar bg-primary">
                <!--begin::Search-->
                <div class="dropdown">
                    <!--begin::Toggle-->
                    <div class="topbar-item" data-toggle="dropdown" data-offset="10px,0px">

                    </div>
                    <!--end::Toggle-->
                    <!--begin::Dropdown-->
                    <div class="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg">

                    </div>
                    <!--end::Dropdown-->
                </div>
                <!--end::Search-->

                
                
                
                <!--begin::User-->
                <div class="topbar-item">
                    <div class="btn btn-icon btn-hover-transparent-white w-sm-auto d-flex align-items-center btn-lg px-2234" @click="open_side_menu=true" id="kt_quick_user_toggle">
                        <div class="d-flex flex-column text-right pr-sm-3">
                            <span class="text-white opacity-50 font-weight-bold font-size-sm d-none d-sm-inline hide">Sean</span>
                            <span class="text-white font-weight-bolder font-size-sm d-none d-sm-inline nw-wrap">{{StudentDetail.username}}</span>
                        </div>
                        <span class="symbol symbol-35">
                            <span class="symbol-label font-size-h5 font-weight-bold text-white bg-white-o-30">{{first_letter}}</span>
                        </span>
                    </div>
                </div>
                <!--end::User-->
            </div>
            <!--end::Topbar-->
        </div>
        <!--end::Container-->
    </div>
    <!--end::Top-->
    <!--begin::Bottom-->
    <div class="header-bottom">
        <!--begin::Container-->
        <div class="container">
            <!--begin::Header Menu Wrapper-->
            <!-------header teacher------->
            <div class="header-navs header-navs-left" v-if="role==1" id="kt_header_navs">
                <!--begin::Tab Navs(for tablet and mobile modes)-->
                <ul class="header-tabs p-5 p-lg-0 d-flex d-lg-none nav nav-bold nav-tabs" role="tablist">
                    <!--begin::Item-->
                    <li class="nav-item mr-2">
                        <a href="#" class="nav-link btn btn-clean active" data-toggle="tab" data-target="#kt_header_tab_1" role="tab">
                            <img src="../assets/icons/home.png" style="width:35px;">
                        </a>
                    </li>
                    <!--end::Item-->

                </ul>
                <!--begin::Tab Navs-->
                <!--begin::Tab Content-->
                <div class="tab-content">
                    <!--begin::Tab Pane-->
                    <div class="tab-pane py-5 p-lg-0 show active" id="kt_header_tab_1">
                        <!--begin::Menu-->
                        <div id="kt_header_menu" class="header-menu header-menu-mobile header-menu-layout-default">
                            <!--begin::Nav-->
                            <ul class="menu-nav">

                                <li :class="pageName=='Home' ? 'menu-item menu-item-active' : 'menu-item menu-item'" aria-haspopup="true">
                                    <router-link to="/teacher_dashboard" class="menu-link">
                                        <span class="menu-text">Dashboard</span>
                                    </router-link>
                                </li>

                                <li :class="pageName=='Course' ? 'menu-item menu-item-active' : 'menu-item menu-item'" aria-haspopup="true">
                                    <router-link to="/courses" class="menu-link">
                                        <span class="menu-text">Courses</span>
                                    </router-link>
                                </li>

                                <li :class="pageName=='Student' ? 'menu-item menu-item-active' : 'menu-item menu-item'" aria-haspopup="true">
                                    <router-link to="/Students" class="menu-link">
                                        <span class="menu-text">Students</span>
                                    </router-link>
                                </li>

                                <li :class="pageName=='Teacher' ? 'menu-item menu-item-active' : 'menu-item menu-item'" aria-haspopup="true">
                                    <router-link to="/teacherview" class="menu-link">
                                        <span class="menu-text">Teachers</span>
                                    </router-link>
                                </li>

                                <li :class="pageName=='Order' ? 'menu-item menu-item-active' : 'menu-item menu-item'" aria-haspopup="true">
                                    <router-link to="/order" class="menu-link">
                                        <span class="menu-text">Orders</span>
                                    </router-link>
                                </li>

                                <li :class="pageName=='Library' ? 'menu-item menu-item-active' : 'menu-item menu-item'" aria-haspopup="true">
                                    <router-link to="/library" class="menu-link">
                                        <span class="menu-text">Video Library</span>
                                    </router-link>
                                </li>

                                <li :class="pageName=='TeacherAnnouncement' ? 'menu-item menu-item-active' : 'menu-item menu-item'" aria-haspopup="true">
                                    <router-link to="/teacherannouncement" class="menu-link">
                                        <span class="menu-text">Announcement</span>
                                    </router-link>
                                </li>

                            </ul>
                            <!--end::Nav-->
                        </div>
                        <!--end::Menu-->
                    </div>
                    <!--begin::Tab Pane-->
                    <!--begin::Tab Pane-->
                    <div class="tab-pane p-5 p-lg-0 justify-content-between" id="kt_header_tab_2">
                        <div class="d-flex flex-column flex-lg-row align-items-start align-items-lg-center">
                            <!--begin::Actions-->
                            <a href="#" class="btn btn-light-success font-weight-bold mr-3 my-2 my-lg-0">Latest Orders</a>
                            <a href="#" class="btn btn-light-primary font-weight-bold my-2 my-lg-0">Customer Service</a>
                            <!--end::Actions-->
                        </div>
                        <div class="d-flex align-items-center">
                            <!--begin::Actions-->
                            <a href="#" class="btn btn-danger font-weight-bold my-2 my-lg-0">Generate Reports</a>
                            <!--end::Actions-->
                        </div>
                    </div>
                    <!--begin::Tab Pane-->
                </div>
                <!--end::Tab Content-->
            </div>
            <!----end teacher header----->

            <!-------header student------->
            <div class="header-navs header-navs-left" v-if="role==2" id="kt_header_navs">
                <!--begin::Tab Navs(for tablet and mobile modes)-->
                <ul class="header-tabs p-5 p-lg-0 d-flex d-lg-none nav nav-bold nav-tabs" role="tablist">
                    <!--begin::Item-->
                    <li class="nav-item mr-2">
                        <a href="#" class="nav-link btn btn-clean active" data-toggle="tab" data-target="#kt_header_tab_1" role="tab">
                            <img src="../assets/icons/home.png" style="width:35px;">
                        </a>
                    </li>
                    <!--end::Item-->

                </ul>
                <!--begin::Tab Navs-->
                <!--begin::Tab Content-->
                <div class="tab-content">
                    <!--begin::Tab Pane-->
                    <div class="tab-pane py-5 p-lg-0 show active" id="kt_header_tab_1">
                        <!--begin::Menu-->
                        <div id="kt_header_menu" class="header-menu header-menu-mobile header-menu-layout-default">
                            <!--begin::Nav-->
                            <ul class="menu-nav">

                                <li :class="pageName=='Home' ? 'menu-item menu-item-active' : 'menu-item menu-item'" aria-haspopup="true">
                                    <router-link to="/student_dashboard" class="menu-link">
                                        <span class="menu-text">Dashboard</span>
                                    </router-link>
                                </li>

                                <li :class="pageName=='EnrollCourse' ? 'menu-item menu-item-active hide' : 'menu-item menu-item hide'" aria-haspopup="true">
                                    <router-link to="/enrollcourse" class="menu-link">
                                        <span class="menu-text">Courses</span>
                                    </router-link>
                                </li>
                                <li :class="pageName=='MyBundleCourse' ? 'menu-item menu-item-active' : 'menu-item menu-item'" aria-haspopup="true">
                                    <router-link to="/mybundlecourse" class="menu-link">
                                        <span class="menu-text">My Bundle Course</span>
                                    </router-link>
                                </li>
                                <!----
                                    <li :class="pageName=='Library' ? 'menu-item menu-item-active' : 'menu-item menu-item'" aria-haspopup="true">
                                        <router-link to="/library" class="menu-link">
                                            <span class="menu-text">Video Library</span>
                                        </router-link>
                                    </li>
                                    ----->
                                <li :class="pageName=='Schedule' ? 'menu-item menu-item-active' : 'menu-item menu-item'" aria-haspopup="true">
                                    <router-link to="/schedule" class="menu-link">
                                        <span class="menu-text">Schedule</span>
                                    </router-link>
                                </li>

                                <li :class="pageName=='Todo' ? 'menu-item menu-item-active' : 'menu-item menu-item'" aria-haspopup="true">
                                    <router-link to="/todo" class="menu-link">
                                        <span class="menu-text">TODO</span>
                                    </router-link>
                                </li>

                                <li :class="pageName=='Support' ? 'menu-item menu-item-active' : 'menu-item menu-item'" aria-haspopup="true">
                                    <router-link to="/support" class="menu-link">
                                        <span class="menu-text">Support</span>
                                    </router-link>
                                </li>

                            </ul>
                            <!--end::Nav-->
                        </div>
                        <!--end::Menu-->
                    </div>
                    <!--begin::Tab Pane-->
                    <!--begin::Tab Pane-->
                    <div class="tab-pane p-5 p-lg-0 justify-content-between" id="kt_header_tab_2">
                        <div class="d-flex flex-column flex-lg-row align-items-start align-items-lg-center">
                            <!--begin::Actions-->
                            <a href="#" class="btn btn-light-success font-weight-bold mr-3 my-2 my-lg-0">Latest Orders</a>
                            <a href="#" class="btn btn-light-primary font-weight-bold my-2 my-lg-0">Customer Service</a>
                            <!--end::Actions-->
                        </div>
                        <div class="d-flex align-items-center">
                            <!--begin::Actions-->
                            <a href="#" class="btn btn-danger font-weight-bold my-2 my-lg-0">Generate Reports</a>
                            <!--end::Actions-->
                        </div>
                    </div>
                    <!--begin::Tab Pane-->
                </div>
                <!--end::Tab Content-->
            </div>
            <!----end student header----->

            <!-------header employee------->
            <div class="header-navs header-navs-left" v-if="role==5" id="kt_header_navs">
                <!--begin::Tab Navs(for tablet and mobile modes)-->
                <ul class="header-tabs p-5 p-lg-0 d-flex d-lg-none nav nav-bold nav-tabs" role="tablist">
                    <!--begin::Item-->
                    <li class="nav-item mr-2">
                        <a href="#" class="nav-link btn btn-clean active" data-toggle="tab" data-target="#kt_header_tab_1" role="tab">
                            <img src="../assets/icons/home.png" style="width:35px;">
                        </a>
                    </li>
                    <!--end::Item-->

                </ul>
                <!--begin::Tab Navs-->
                <!--begin::Tab Content-->
                <div class="tab-content">
                    <!--begin::Tab Pane-->
                    <div class="tab-pane py-5 p-lg-0 show active" id="kt_header_tab_1">
                        <!--begin::Menu-->
                        <div id="kt_header_menu" class="header-menu header-menu-mobile header-menu-layout-default">
                            <!--begin::Nav-->
                            <ul class="menu-nav">

                                <li :class="pageName=='EmployeeDashboard' ? 'menu-item menu-item-active' : 'menu-item menu-item'" aria-haspopup="true">
                                    <router-link to="/employeedashboard" class="menu-link">
                                        <span class="menu-text">Dashboard</span>
                                    </router-link>
                                </li>

                                <li :class="pageName=='Support' ? 'menu-item menu-item-active' : 'menu-item menu-item'" aria-haspopup="true">
                                    <router-link to="/support" class="menu-link">
                                        <span class="menu-text">Support</span>
                                    </router-link>
                                </li>

                                <li :class="pageName=='Sops' ? 'menu-item menu-item-active' : 'menu-item menu-item'" aria-haspopup="true">
                                    <router-link to="/sops" class="menu-link">
                                        <span class="menu-text">SOP's</span>
                                    </router-link>
                                </li>
                                <li :class="pageName=='Kpis' ? 'menu-item menu-item-active' : 'menu-item menu-item'" aria-haspopup="true">
                                    <router-link to="/kpis" class="menu-link">
                                        <span class="menu-text">KPI's</span>
                                    </router-link>
                                </li>

                            </ul>
                            <!--end::Nav-->
                        </div>
                        <!--end::Menu-->
                    </div>
                    <!--begin::Tab Pane-->
                    <!--begin::Tab Pane-->
                    <div class="tab-pane p-5 p-lg-0 justify-content-between" id="kt_header_tab_2">
                        <div class="d-flex flex-column flex-lg-row align-items-start align-items-lg-center">
                            <!--begin::Actions-->
                            <a href="#" class="btn btn-light-success font-weight-bold mr-3 my-2 my-lg-0">Latest Orders</a>
                            <a href="#" class="btn btn-light-primary font-weight-bold my-2 my-lg-0">Customer Service</a>
                            <!--end::Actions-->
                        </div>
                        <div class="d-flex align-items-center">
                            <!--begin::Actions-->
                            <a href="#" class="btn btn-danger font-weight-bold my-2 my-lg-0">Generate Reports</a>
                            <!--end::Actions-->
                        </div>
                    </div>
                    <!--begin::Tab Pane-->
                </div>
                <!--end::Tab Content-->
            </div>
            <!----end employee header----->

            <!-------header student------->
            <div class="header-navs header-navs-left" v-if="role==10" id="kt_header_navs">
                <!--begin::Tab Navs(for tablet and mobile modes)-->
                <ul class="header-tabs p-5 p-lg-0 d-flex d-lg-none nav nav-bold nav-tabs" role="tablist">
                    <!--begin::Item-->
                    <li class="nav-item mr-2">
                        <a href="#" class="nav-link btn btn-clean active" data-toggle="tab" data-target="#kt_header_tab_1" role="tab">
                            <img src="../assets/icons/home.png" style="width:35px;">
                        </a>
                    </li>
                    <!--end::Item-->

                </ul>
                <!--begin::Tab Navs-->
                <!--begin::Tab Content-->
                <div class="tab-content">
                    <!--begin::Tab Pane-->
                    <div class="tab-pane py-5 p-lg-0 show active" id="kt_header_tab_1">
                        <!--begin::Menu-->
                        <div id="kt_header_menu" class="header-menu header-menu-mobile header-menu-layout-default">
                            <!--begin::Nav-->
                            <ul class="menu-nav" v-if="menu_box_check=='dashboard'">

                                <li :class="pageName=='AdminDashboard' ? 'menu-item menu-item-active' : 'menu-item menu-item'" aria-haspopup="true">
                                    <router-link to="/admindashboard" class="menu-link">
                                        <span class="menu-text">
                                            <img src="../assets/icons/home.png" style="width:35px;">
                                        </span>
                                    </router-link>
                                </li>
                                <li :class="pageName=='Task' ? 'menu-item menu-item-active' : 'menu-item menu-item'" aria-haspopup="true">
                                    <router-link to="/task" class="menu-link">
                                        <span class="menu-text">Task</span>
                                    </router-link>
                                </li>
                                <li :class="pageName=='Announcement' ? 'menu-item menu-item-active' : 'menu-item menu-item'" aria-haspopup="true">
                                    <router-link to="/announcement" class="menu-link">
                                        <span class="menu-text">Announcement</span>
                                    </router-link>
                                </li>

                            </ul>
                            <!--end::Nav-->

                            <!--begin::Nav-->
                            <ul class="menu-nav" v-if="menu_box_check=='manage_content'">

                                <li :class="pageName=='Course' ? 'menu-item menu-item-active' : 'menu-item menu-item'" aria-haspopup="true">
                                    <router-link to="/courses" class="menu-link">
                                        <span class="menu-text">Courses</span>
                                    </router-link>
                                </li>
                                <li :class="pageName=='GroupCourse' ? 'menu-item menu-item-active' : 'menu-item menu-item'" aria-haspopup="true">
                                    <router-link to="/groupcourse" class="menu-link">
                                        <span class="menu-text">Bundle Course</span>
                                    </router-link>
                                </li>
                                <li :class="pageName=='ViewAssignGroup' ? 'menu-item menu-item-active' : 'menu-item menu-item'" aria-haspopup="true">
                                    <router-link to="/viewassigngroup" class="menu-link">
                                        <span class="menu-text">View Assign Bundle Course</span>
                                    </router-link>
                                </li>


                                <li :class="pageName=='AssignCourse' ? 'menu-item menu-item-active' : 'menu-item menu-item'" aria-haspopup="true">
                                    <router-link to="/assigncourse" class="menu-link">
                                        <span class="menu-text">View Assign Courses</span>
                                    </router-link>
                                </li>
                                <li :class="pageName=='Library' ? 'menu-item menu-item-active' : 'menu-item menu-item'" aria-haspopup="true">
                                    <router-link to="/library" class="menu-link">
                                        <span class="menu-text">Video Library</span>
                                    </router-link>
                                </li>
                                <li :class="pageName=='UploadVideo' ? 'menu-item menu-item-active' : 'menu-item menu-item'" aria-haspopup="true">
                                    <router-link to="/uploadvideo" class="menu-link">
                                        <span class="menu-text">Upload Video</span>
                                    </router-link>
                                </li>
                                <li :class="pageName=='LoomVideo' ? 'menu-item menu-item-active' : 'menu-item menu-item'" aria-haspopup="true">
                                    <router-link to="/loomvideo" class="menu-link">
                                        <span class="menu-text">Record Loom Video</span>
                                    </router-link>
                                </li>

                            </ul>
                            <!--end::Nav-->

                            <!--begin::Nav-->
                            <ul class="menu-nav" v-if="menu_box_check=='manage_student'">

                                <li :class="pageName=='Student' ? 'menu-item menu-item-active' : 'menu-item menu-item'" aria-haspopup="true">
                                    <router-link to="/students" class="menu-link">
                                        <span class="menu-text">All Students</span>
                                    </router-link>
                                </li>
                                <li :class="pageName=='AddStudent' ? 'menu-item menu-item-active' : 'menu-item menu-item'" aria-haspopup="true">
                                    <router-link to="/addstudent" class="menu-link">
                                        <span class="menu-text">Add Student</span>
                                    </router-link>
                                </li>

                            </ul>
                            <!--end::Nav-->

                            <!--begin::Nav-->
                            <ul class="menu-nav" v-if="menu_box_check=='manage_employee'">

                                <li :class="pageName=='Employee' ? 'menu-item menu-item-active' : 'menu-item menu-item'" aria-haspopup="true">
                                    <router-link to="/employee" class="menu-link">
                                        <span class="menu-text">All Employee</span>
                                    </router-link>
                                </li>
                                <li :class="pageName=='AddEmployee' ? 'menu-item menu-item-active' : 'menu-item menu-item'" aria-haspopup="true">
                                    <router-link to="/addemployee" class="menu-link">
                                        <span class="menu-text">Add Employeee</span>
                                    </router-link>
                                </li>

                            </ul>
                            <!--end::Nav-->

                            <!--begin::Nav-->
                            <ul class="menu-nav" v-if="menu_box_check=='teacher'">

                                <li :class="pageName=='TeacherView' ? 'menu-item menu-item-active' : 'menu-item menu-item'" aria-haspopup="true">
                                    <router-link to="/teacherview" class="menu-link">
                                        <span class="menu-text">Teachers</span>
                                    </router-link>
                                </li>

                            </ul>
                            <!--end::Nav-->

                            <!--begin::Nav-->
                            <ul class="menu-nav" v-if="menu_box_check=='team'">

                                <li :class="pageName=='Team' ? 'menu-item menu-item-active' : 'menu-item menu-item'" aria-haspopup="true">
                                    <router-link to="/team" class="menu-link">
                                        <span class="menu-text">Team</span>
                                    </router-link>
                                </li>

                            </ul>
                            <!--end::Nav-->

                            <!--begin::Nav-->
                            <ul class="menu-nav" v-if="menu_box_check=='coach'">

                                <li :class="pageName=='Coach' ? 'menu-item menu-item-active' : 'menu-item menu-item'" aria-haspopup="true">
                                    <router-link to="/coach" class="menu-link">
                                        <span class="menu-text">Coach Request's</span>
                                    </router-link>
                                </li>
                                <li :class="pageName=='Support' ? 'menu-item menu-item-active' : 'menu-item menu-item'" aria-haspopup="true">
                                    <router-link to="/support" class="menu-link">
                                        <span class="menu-text">Support</span>
                                    </router-link>
                                </li>
                                <li :class="pageName=='SupportList' ? 'menu-item menu-item-active' : 'menu-item menu-item'" aria-haspopup="true">
                                    <router-link to="/supportlist" class="menu-link">
                                        <span class="menu-text">Support Request's</span>
                                    </router-link>
                                </li>

                            </ul>
                            <!--end::Nav-->

                            <!--begin::Nav-->
                            <ul class="menu-nav" v-if="menu_box_check=='admin'">

                                <li :class="pageName=='Order' ? 'menu-item menu-item-active' : 'menu-item menu-item'" aria-haspopup="true">
                                    <router-link to="/order" class="menu-link">
                                        <span class="menu-text">Orders</span>
                                    </router-link>
                                </li>
                                <li :class="pageName=='Role' ? 'menu-item menu-item-active' : 'menu-item menu-item'" aria-haspopup="true">
                                    <router-link to="/role" class="menu-link">
                                        <span class="menu-text">Roles</span>
                                    </router-link>
                                </li>

                            </ul>
                            <!--end::Nav-->

                            <!--begin::Nav-->
                            <ul class="menu-nav" v-if="menu_box_check=='internal'">

                                <li :class="pageName=='Sops' ? 'menu-item menu-item-active' : 'menu-item menu-item'" aria-haspopup="true">
                                    <router-link to="/sops" class="menu-link">
                                        <span class="menu-text">SOP's</span>
                                    </router-link>
                                </li>
                                <li :class="pageName=='Kpis' ? 'menu-item menu-item-active' : 'menu-item menu-item'" aria-haspopup="true">
                                    <router-link to="/kpis" class="menu-link">
                                        <span class="menu-text">KPI's</span>
                                    </router-link>
                                </li>

                            </ul>
                            <!--end::Nav-->

                        </div>
                        <!--end::Menu-->
                    </div>
                    <!--begin::Tab Pane-->
                    <!--begin::Tab Pane-->
                    <div class="tab-pane p-5 p-lg-0 justify-content-between" id="kt_header_tab_2">
                        <div class="d-flex flex-column flex-lg-row align-items-start align-items-lg-center">
                            <!--begin::Actions-->
                            <a href="#" class="btn btn-light-success font-weight-bold mr-3 my-2 my-lg-0">Latest Orders</a>
                            <a href="#" class="btn btn-light-primary font-weight-bold my-2 my-lg-0">Customer Service</a>
                            <!--end::Actions-->
                        </div>
                        <div class="d-flex align-items-center">
                            <!--begin::Actions-->
                            <a href="#" class="btn btn-danger font-weight-bold my-2 my-lg-0">Generate Reports</a>
                            <!--end::Actions-->
                        </div>
                    </div>
                    <!--begin::Tab Pane-->
                </div>
                <!--end::Tab Content-->
            </div>
            <!----end student header----->

            <div id="set_div"></div>
            <!--end::Header Menu Wrapper-->
        </div>
        <!--end::Container-->
    </div>
    <!--end::Bottom-->

    <div id="kt_quick_user" :class="open_side_menu==true?'offcanvas offcanvas-right p-10 offcanvas-on':'offcanvas offcanvas-right p-10'">
        <!--begin::Header-->
        <div class="offcanvas-header d-flex align-items-center justify-content-between pb-5" kt-hidden-height="40" style="">
            <h3 class="font-weight-bold m-0">User Profile
                <small class="text-muted font-size-sm ml-2"></small></h3>
            <a href="#" class="btn btn-xs btn-icon btn-light btn-hover-primary" @click="open_side_menu=false" id="kt_quick_user_close">
                <i class="ki ki-close icon-xs text-muted"></i>
            </a>
        </div>
        <!--end::Header-->
        <!--begin::Content-->
        <div class="offcanvas-content pr-5 mr-n5 scroll ps ps--active-y" style="height: 266px; overflow: hidden;">
            <!--begin::Header-->
            <div class="d-flex align-items-center mt-5">
                <div class="symbol symbol-100 mr-5">
                    <div class="symbol-label">
                        <img src="../assets/media/users/blank.png" style="width:100%">
                    </div>
                    <i class="symbol-badge bg-success"></i>
                </div>
                <div class="d-flex flex-column">
                    <a href="#" class="font-weight-bold font-size-h5 text-dark-75 text-hover-primary">{{StudentDetail.username}}</a>

                    <div class="navi mt-2">
                        <a href="#" class="navi-item">
                            <span class="navi-link p-0 pb-2">
                                <span class="navi-icon mr-1">
                                    <span class="svg-icon svg-icon-lg svg-icon-primary">
                                        <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Mail-notification.svg-->
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                <rect x="0" y="0" width="24" height="24"></rect>
                                                <path d="M21,12.0829584 C20.6747915,12.0283988 20.3407122,12 20,12 C16.6862915,12 14,14.6862915 14,18 C14,18.3407122 14.0283988,18.6747915 14.0829584,19 L5,19 C3.8954305,19 3,18.1045695 3,17 L3,8 C3,6.8954305 3.8954305,6 5,6 L19,6 C20.1045695,6 21,6.8954305 21,8 L21,12.0829584 Z M18.1444251,7.83964668 L12,11.1481833 L5.85557487,7.83964668 C5.4908718,7.6432681 5.03602525,7.77972206 4.83964668,8.14442513 C4.6432681,8.5091282 4.77972206,8.96397475 5.14442513,9.16035332 L11.6444251,12.6603533 C11.8664074,12.7798822 12.1335926,12.7798822 12.3555749,12.6603533 L18.8555749,9.16035332 C19.2202779,8.96397475 19.3567319,8.5091282 19.1603533,8.14442513 C18.9639747,7.77972206 18.5091282,7.6432681 18.1444251,7.83964668 Z" fill="#000000"></path>
                                                <circle fill="#000000" opacity="0.3" cx="19.5" cy="17.5" r="2.5"></circle>
                                            </g>
                                        </svg>
                                        <!--end::Svg Icon-->
                                    </span>
                                </span>
                                <span class="navi-text text-muted text-hover-primary">{{StudentDetail.email}}</span>
                            </span>
                        </a>
                        <button class="btn btn-sm btn-light-primary font-weight-bolder py-2 px-5" @click="logout">Sign Out</button>
                    </div>
                </div>
            </div>
            <!--end::Header-->
            <!--begin::Separator-->
            <div class="separator separator-dashed mt-8 mb-5"></div>
            <!--end::Separator-->
            <!--begin::Nav-->
            <div class="navi navi-spacer-x-0 p-0">
                <!--begin::Item-->
                <a class="navi-item">
                    <router-link to="/notification">
                        <div class="navi-link">

                            <div class="symbol symbol-40 bg-light mr-3">
                                <div class="symbol-label">
                                    <span class="svg-icon svg-icon-md svg-icon-success">
                                        <!--begin::Svg Icon | path:assets/media/svg/icons/General/Notification2.svg-->
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                <rect x="0" y="0" width="24" height="24"></rect>
                                                <path d="M13.2070325,4 C13.0721672,4.47683179 13,4.97998812 13,5.5 C13,8.53756612 15.4624339,11 18.5,11 C19.0200119,11 19.5231682,10.9278328 20,10.7929675 L20,17 C20,18.6568542 18.6568542,20 17,20 L7,20 C5.34314575,20 4,18.6568542 4,17 L4,7 C4,5.34314575 5.34314575,4 7,4 L13.2070325,4 Z" fill="#000000"></path>
                                                <circle fill="#000000" opacity="0.3" cx="18.5" cy="5.5" r="2.5"></circle>
                                            </g>
                                        </svg>
                                        <!--end::Svg Icon-->
                                    </span>
                                </div>
                            </div>
                            <div class="navi-text">
                                <div class="font-weight-bold">Notification</div>

                            </div>
                        </div>
                    </router-link>
                </a>
                <!--end:Item-->

            </div>
            <!--end::Nav-->
            <!--begin::Separator-->
            <div class="separator separator-dashed my-7"></div>
            <!--end::Separator-->
            <!--begin::Notifications-->
            <div>

            </div>
            <!--end::Notifications-->
            <div class="ps__rail-x" style="left: 0px; bottom: 0px;">
                <div class="ps__thumb-x" tabindex="0" style="left: 0px; width: 0px;"></div>
            </div>
            <div class="ps__rail-y" style="top: 0px; height: 266px; right: 0px;">
                <div class="ps__thumb-y" tabindex="0" style="top: 0px; height: 81px;"></div>
            </div>
        </div>
        <!--end::Content-->
    </div>
</div>
</template>

<script>
import axios from 'axios'
export default {
    name: 'Home',

    data() {
        return {
            pageName: null,
            open_side_menu: false,
            StudentDetail: [],
            uid: localStorage.getItem("uid"),
            role: localStorage.getItem("role"),
            first_letter: null,
            top_menu: null,
            menu_box_check: localStorage.getItem("menus"),
        }
    },
    mounted() {
        //console.log("pages")
        //console.log(this.$route.name);
        this.pageName = this.$route.name;
        this.getUserDetail();
        if (this.role == 1 || this.role == 2) {
            this.top_menu = true;
        } else if (this.role == 10) {
            this.top_menu = false;
        } else if (this.role == 5) {
            this.top_menu = true;
        }
        if (this.menu_box_check == "" || this.menu_box_check == null) {
            //console.log("in in");
            this.menu_box_check = 'dashboard';
        }

    },
    methods: {
        getUserDetail() {
            var ev = this;
            axios({
                method: "POST",
                url: this.$root.URL_ROOT + "lms_api.php",
                data: {
                    type: "getUserDetail",
                    uid: this.uid,
                },
            }).then(function (response) {
                //console.log(response.data);
                var data = response.data;
                ev.StudentDetail = data;
                var letter = ev.StudentDetail.username.charAt(0);
                ev.first_letter = letter.toUpperCase();

            });
        },
        logout() {
            localStorage.removeItem("uid");
            localStorage.removeItem("role");
            this.$router.push("/");
        },
        top_menu_click(txt) {
            this.menu_box_check = txt;
            localStorage.setItem('menus', this.menu_box_check);
            if (txt == 'dashboard') {
                this.$router.push('/admindashboard');
            } else if (txt == 'manage_content') {
                this.$router.push('/courses');
            } else if (txt == 'manage_student') {
                this.$router.push('/students');
            } else if (txt == 'manage_employee') {
                this.$router.push('/employee');
            } else if (txt == 'teacher') {
                this.$router.push('/teacherview');
            } else if (txt == 'team') {
                this.$router.push('/team');
            } else if (txt == 'coach') {
                this.$router.push('/coach');
            } else if (txt == 'admin') {
                this.$router.push('/order');
            } else if (txt == 'internal') {
                this.$router.push('/sops');
            }

        },

    }

}
</script>

<style scoped>
@media (min-width: 992px) {
    .header-tabs .nav-item .nav-link.active {
        background-color: #ffff;
    }
}

.nav-link {
    cursor: pointer;
}

.navi-item {
    cursor: pointer;
}
</style>
