<template>
<div name="student">

    <MobileHeader></MobileHeader>
    <div class="d-flex flex-row flex-column-fluid page">
        <div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">

            <Header></Header>

            <!--begin::Content-->
            <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
                <!--begin::Entry-->
                <div class="d-flex flex-column-fluid">
                    <!--begin::Container-->
                    <!--begin::Dashboard-->

                    <div class="container">
                        <div class="row">
                            <!--begin::Content-->
                            <template v-for="cdata in courseData">
                                <!--begin::Col-->
                                <div class="col-xl-4" :key="cdata.id">
                                    <!--begin::Card-->
                                    <div class="card card-custom gutter-b card-stretch">
                                        <!--begin::Body-->
                                        <div class="card-body">
                                            <!--begin::Info-->
                                            <div class="d-flex align-items-center">
                                                <!--begin::Pic-->
                                                <div class="flex-shrink-0 mr-4 symbol symbol-60 symbol-circle">
                                                    <img alt="Pic" :src="$root.URL_IMAGES+'Course/'+cdata.course_image">

                                                </div>
                                                <!--end::Pic-->
                                                <!--begin::Info-->
                                                <div class="d-flex flex-column mr-auto">
                                                    <!--begin: Title-->
                                                    <div class="d-flex flex-column mr-auto">
                                                        <a class="text-dark text-hover-primary font-size-h4 font-weight-bolder mb-1">
                                                            {{cdata.course_name}}
                                                        </a>
                                                        <!--<span class="text-muted font-weight-bold">Creates Limitless possibilities</span>-->
                                                    </div>
                                                    <!--end::Title-->
                                                </div>
                                                <!--end::Info-->
                                            </div>
                                            <!--end::Info-->
                                            <!--begin::Description-->
                                            <div class="mb-10 mt-5 font-weight-bold"> </div>
                                            <!--end::Description-->
                                            <!--begin::Data-->
                                            <div class="d-flex mb-5">
                                                <div class="d-flex align-items-center mr-7">
                                                    <span class="font-weight-bold mr-4">Start</span>
                                                    <span class="btn btn-light-primary btn-sm font-weight-bold btn-upper btn-text">{{cdata.start_date}}</span>
                                                </div>
                                                <div class="d-flex align-items-center hide">
                                                    <span class="font-weight-bold mr-4">Due</span>
                                                    <span class="btn btn-light-danger btn-sm font-weight-bold btn-upper btn-text dddd">30 Jun, 21</span>
                                                </div>
                                                
                                            </div>
                                            <!--end::Data-->
                                            <!--begin::Progress-->

                                            <!--ebd::Progress-->
                                        </div>
                                        <!--end::Body-->
                                        <div class="card-footer d-flex align-items-center">
                                            <div class="d-flex">
                                                <div class="d-flex align-items-center mr-10 hide">
                                                    <a href="#" class="btn btn-sm btn-success font-weight-bold">
                                                        Free
                                                    </a>
                                                </div>
                                                <div class="d-flex align-items-center ml-40 hide">
                                                    <!--<a href="https://levelup-learning.in/ImpactSchool/public/ViewLesson/1" class="btn btn-light-primary font-weight-bold"><i class="fas fa-arrow-alt-circle-right  mr-2"></i>Enroll</a>-->

                                                    <a href="#"><button type="button" class="btn btn-light-primary font-weight-bold" style="display:flex;"><i class="fas fa-arrow-alt-circle-right  mr-2"></i>Enroll</button> </a>
                                                    <!-- Modal-->
                                                    
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <!--end:: Card-->
                                </div>
                                <!--end::Col-->
                            </template>
                            <!--end::Content-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
</template>

<script>
import axios from 'axios'
import MobileHeader from '../../components/MobileHeader'
import Header from '../../components/Header'
import Footer from '../../components/Footer'

export default {
    name: 'EnrollCourse',
    components: {
        Header,
        Footer,
        MobileHeader,

    },
    data() {
        return {
            uid:localStorage.getItem("uid"),
            role:localStorage.getItem("role"),
            courseData:[],
        }
    },
    mounted(){
            if(this.uid==null || this.uid=="" ){
                this.$router.push("/");
            }
            this.getCourse();
    },
    methods: {
        getCourse(){
            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'lms_api.php',
                data: {
                    type: 'allCourse',
                    role:this.role,
                }
            }).then(function (response) {
                var data=response.data;
                ev.courseData=data.data
                console.log(ev.courseData)
            })  
        },
        
    },
}
</script>

<style scoped>

</style>
