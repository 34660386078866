<template>
<div name="student">
    <MobileHeader></MobileHeader>
    <div class="d-flex flex-row flex-column-fluid page">
        <div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
            <Header></Header>

            <!--begin::Content-->
            <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
                <!--begin::Entry-->
                <div class="d-flex flex-column-fluid">
                    <!--begin::Entry-->
                    <div class="d-flex flex-column-fluid">
                        <!--begin::Container-->
                        <div class="container">
                            <!--begin::Profile Personal Information-->
                            <div class="d-flex flex-row">
                                <!--begin::Aside-->
                                <div class="flex-row-auto offcanvas-mobile w-250px w-xxl-350px" id="kt_profile_aside">
                                    <!--begin::Profile Card-->
                                    <div class="card card-custom card-stretch">
                                        <!--begin::Body-->
                                        <div class="card-body pt-4">
                                            <!--begin::Toolbar-->
                                            <div class="d-flex justify-content-end">
                                                <div class="dropdown dropdown-inline">
                                                    <a href="#" class="btn btn-clean btn-hover-light-primary btn-sm btn-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    </a>
                                                </div>
                                            </div>
                                            <!--end::Toolbar-->
                                            <!--begin::User-->
                                            <div class="d-flex align-items-center">
                                                <div>
                                                    <a href="#" class="font-weight-bolder font-size-h5 text-dark-75 text-hover-primary">{{ StudentDetail.username }}</a>
                                                </div>
                                            </div>
                                            <!--end::User-->
                                            <!--begin::Contact-->
                                            <div class="py-9">
                                                <div class="d-flex align-items-center justify-content-between mb-2">
                                                    <span class="font-weight-bold mr-2">Email:</span>
                                                    <a href="#" class="text-muted text-hover-primary">{{
                              StudentDetail.email
                            }}</a>
                                                </div>
                                                <div class="d-flex align-items-center justify-content-between mb-2">
                                                    <span class="font-weight-bold mr-2">Phone:</span>
                                                    <span class="text-muted">{{
                              StudentDetail.mobile
                            }}</span>
                                                </div>
                                                <div class="d-flex align-items-center justify-content-between">
                                                    <span class="font-weight-bold mr-2">Location:</span>
                                                    <span class="text-muted">{{ StudentDetail.city}}</span>
                                                </div>
                                            </div>
                                            <!--end::Contact-->
                                            <!--begin::Nav-->
                                            <div class="navi navi-bold navi-hover navi-active navi-link-rounded">
                                                <div class="navi-item mb-2" @click="open_box = 'information'">
                                                    <a :class="open_box == 'information'? 'navi-link py-4 active': 'navi-link py-4'">
                                                        <span class="navi-icon mr-2">
                                                            <span class="svg-icon">
                                                                <!--begin::Svg Icon | path:assets/media/svg/icons/General/User.svg-->
                                                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                        <polygon points="0 0 24 0 24 24 0 24" />
                                                                        <path d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" />
                                                                        <path d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z" fill="#000000" fill-rule="nonzero" />
                                                                    </g>
                                                                </svg>
                                                                <!--end::Svg Icon-->
                                                            </span>
                                                        </span>
                                                        <span class="navi-text font-size-lg">Teacher Information</span>
                                                    </a>
                                                </div>

                                                <div class="navi-item mb-2" @click="open_box='change_password'">
                                                    <a :class="open_box == 'change_password'? 'navi-link py-4 active': 'navi-link py-4'">
                                                        <span class="navi-icon mr-2">
                                                            <span class="svg-icon">
                                                                <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Shield-user.svg-->
                                                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                        <rect x="0" y="0" width="24" height="24" />
                                                                        <path d="M4,4 L11.6314229,2.5691082 C11.8750185,2.52343403 12.1249815,2.52343403 12.3685771,2.5691082 L20,4 L20,13.2830094 C20,16.2173861 18.4883464,18.9447835 16,20.5 L12.5299989,22.6687507 C12.2057287,22.8714196 11.7942713,22.8714196 11.4700011,22.6687507 L8,20.5 C5.51165358,18.9447835 4,16.2173861 4,13.2830094 L4,4 Z" fill="#000000" opacity="0.3" />
                                                                        <path d="M12,11 C10.8954305,11 10,10.1045695 10,9 C10,7.8954305 10.8954305,7 12,7 C13.1045695,7 14,7.8954305 14,9 C14,10.1045695 13.1045695,11 12,11 Z" fill="#000000" opacity="0.3" />
                                                                        <path d="M7.00036205,16.4995035 C7.21569918,13.5165724 9.36772908,12 11.9907452,12 C14.6506758,12 16.8360465,13.4332455 16.9988413,16.5 C17.0053266,16.6221713 16.9988413,17 16.5815,17 C14.5228466,17 11.463736,17 7.4041679,17 C7.26484009,17 6.98863236,16.6619875 7.00036205,16.4995035 Z" fill="#000000" opacity="0.3" />
                                                                    </g>
                                                                </svg>
                                                                <!--end::Svg Icon-->
                                                            </span>
                                                        </span>
                                                        <span class="navi-text font-size-lg">Change Passwort</span>
                                                    </a>
                                                </div>

                                                
                                            </div>
                                            <!--end::Nav-->
                                        </div>
                                        <!--end::Body-->
                                    </div>
                                    <!--end::Profile Card-->
                                </div>
                                <!--end::Aside-->

                                <!--begin::Content informtion -->
                                <div class="flex-row-fluid ml-lg-8" v-if="open_box == 'information'">
                                    <!--begin::Card-->
                                    <div class="card card-custom card-stretch">
                                        <!--begin::Header-->
                                        <div class="card-header py-3">
                                            <div class="card-title align-items-start flex-column">
                                                <h3 class="card-label font-weight-bolder text-dark">
                                                    Teacher Information
                                                </h3>
                                                <span class="text-muted font-weight-bold font-size-sm mt-1">Update Teacher informaiton</span>
                                            </div>
                                            <div class="card-toolbar">
                                                <button type="reset" class="btn btn-success mr-2" @click="update_student">
                                                    Save Changes
                                                </button>
                                            </div>
                                        </div>
                                        <!--end::Header-->
                                        <!--begin::Form-->
                                        <form class="form">
                                            <!--begin::Body-->
                                            <div class="card-body">
                                                <div class="row">
                                                    <label class="col-xl-3"></label>
                                                    <div class="col-lg-9 col-xl-6">
                                                        <h5 class="font-weight-bold mb-6">
                                                            Teacher Info
                                                        </h5>
                                                    </div>
                                                </div>

                                                <div class="form-group row">
                                                    <label class="col-xl-3 col-lg-3 col-form-label">Full Name</label>
                                                    <div class="col-lg-9 col-xl-6">
                                                        <input class="form-control form-control-lg form-control-solid" v-model="fullname" type="text" />
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <label class="col-xl-3"></label>
                                                    <div class="col-lg-9 col-xl-6">
                                                        <h5 class="font-weight-bold mt-10 mb-6">
                                                            Contact Info
                                                        </h5>
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <label class="col-xl-3 col-lg-3 col-form-label">Contact Phone</label>
                                                    <div class="col-lg-9 col-xl-6">
                                                        <div class="input-group input-group-lg input-group-solid">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">
                                                                    <i class="la la-phone"></i>
                                                                </span>
                                                            </div>
                                                            <input type="text" class="form-control form-control-lg form-control-solid" v-model="mobile" placeholder="Phone" />
                                                        </div>
                                                        <span class="form-text text-muted">We'll never share your email with anyone
                                                            else.</span>
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <label class="col-xl-3 col-lg-3 col-form-label">Email Address</label>
                                                    <div class="col-lg-9 col-xl-6">
                                                        <div class="input-group input-group-lg input-group-solid">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">
                                                                    <i class="la la-at"></i>
                                                                </span>
                                                            </div>
                                                            <input type="text" class="form-control form-control-lg form-control-solid" v-model="email" placeholder="Email" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!--end::Body-->
                                        </form>
                                        <!--end::Form-->
                                    </div>
                                </div>
                                <!--end::Content-->

                                

                                <!--begin::Content enroll-->
                                <div class="flex-row-fluid ml-lg-8" v-if="open_box == 'change_password'">
                                    <div class="tab-pane fade active show" id="kt_tab_pane_7_2" role="tabpanel" aria-labelledby="kt_tab_pane_7_2">
                                        <!--begin::Form-->

                                        <!--begin::Card-->
                                        <div class="card card-custom">
                                            <!--begin::Header-->
                                            <div class="card-header py-3">
                                                <div class="card-title align-items-start flex-column">
                                                    <h3 class="card-label font-weight-bolder text-dark">Change Password</h3>
                                                    <span class="text-muted font-weight-bold font-size-sm mt-1">Change your account password</span>
                                                </div>
                                                <div class="card-toolbar">
                                                    <button class="btn btn-success mr-2" @click="change_pass">Save Changes</button>

                                                </div>
                                            </div>
                                            <!--end::Header-->
                                            <div class="card-body">
                                                <!--begin::Alert-->

                                                <!--end::Alert-->
                                                <div class="form-group row">
                                                    <label class="col-xl-3 col-lg-3 col-form-label text-alert">New Password</label>
                                                    <div class="col-lg-9 col-xl-6">
                                                        <input type="password" class="form-control form-control-lg form-control-solid" v-model="new_password" name="password" placeholder="New password">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <!--end::Form-->
                                    </div>
                                </div>
                                <!--end::Content-->

                            </div>
                            <!--end::Profile Personal Information-->
                        </div>
                        <!--end::Container-->
                    </div>
                    <!--end::Entry-->
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from "axios";
import MobileHeader from "../../components/MobileHeader";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Pagination from "vue-pagination-2";

export default {
    name: "TeacherView",
    components: {
        Header,
        Footer,
        MobileHeader,
        Pagination,
    },
    data() {
        return {
            uid: localStorage.getItem("uid"),
            role: localStorage.getItem("role"),
            sid: this.$route.params.sid,
            StudentDetail: [],
            fullname: null,
            email: null,
            mobile: null,
            open_box: "information",
            course_data: [],
            enrollData: [],
            course_exdate: null,
            course_id: null,
            new_password: null,
        };
    },
    mounted() {
        if (this.uid == null || this.uid == "") {
            this.$router.push("/");
        }
        //console.log(this.sid);
        this.getStudentDetail();
        this.getCourse();
        this.getEnrollCourse();
    },
    watch: {},
    methods: {
        getStudentDetail() {
            var ev = this;
            axios({
                method: "POST",
                url: this.$root.URL_ROOT + "lms_api.php",
                data: {
                    type: "getStudentDetail",
                    sid: this.sid,
                },
            }).then(function (response) {
                //console.log(response.data);
                var data = response.data;
                ev.StudentDetail = data;
                ev.fullname = ev.StudentDetail.username;
                ev.email = ev.StudentDetail.email;
                ev.mobile = ev.StudentDetail.mobile;
            });
        },
        update_student() {
            var ev = this;

            if (ev.fullname == null || ev.fullname == "") {
                ev.$toasted.global.error({
                    message: "please enter fullname",
                });
            } else if (ev.email == null || ev.email == "") {
                ev.$toasted.global.error({
                    message: "please enter email",
                });
            } else if (ev.mobile == null || ev.mobile == "") {
                ev.$toasted.global.error({
                    message: "please enter contact no. ",
                });
            } else {
                axios({
                    method: "POST",
                    url: this.$root.URL_ROOT + "lms_api.php",
                    data: {
                        type: "update_student",
                        sid: this.sid,
                        fullname: this.fullname,
                        email: this.email,
                        mobile: this.mobile,
                    },
                }).then(function (response) {
                    //console.log(response.data);
                    ev.getStudentDetail();
                    ev.$toasted.global.success({
                        message: "Update successfully",
                    });
                });
            }
        },
        getCourse() {
            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'lms_api.php',
                data: {
                    type: 'getAllCourseDetails',
                }
            }).then(function (response) {
                var data = response.data;
                ev.course_data = data;
            })
        },
        getEnrollCourse() {
            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'lms_api.php',
                data: {
                    type: 'getEnrollCourse',
                    sid: this.sid,

                }
            }).then(function (response) {
                //console.log(response.data);
                var data = response.data;
                ev.enrollData = data;
            })
        },
        save_enroll() {
            var ev = this;
            if (this.course_id == null || this.course_id == "") {
                ev.$toasted.global.error({
                    message: "please select course"
                });
            } else if (this.course_exdate == null || this.course_exdate == "") {
                ev.$toasted.global.error({
                    message: "please select date"
                });
            } else {
                axios({
                    method: 'POST',
                    url: this.$root.URL_ROOT + 'lms_api.php',
                    data: {
                        type: 'save_enroll',
                        sid: this.sid,
                        course_id: this.course_id,
                        course_exdate: this.course_exdate,
                        uid: this.uid,
                    }
                }).then(function (response) {
                    var data = response.data;
                    if (data.status == "success") {
                        ev.course_exdate = null;
                        ev.course_id = null;
                        ev.getEnrollCourse();
                        ev.$toasted.global.success({
                            message: "Enroll Successfully"
                        });
                    }
                })
            }
        },
        change_pass() {
            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'lms_api.php',
                data: {
                    type: 'change_pass_student',
                    new_password: this.new_password,
                    sid: this.sid,

                }
            }).then(function (response) {
                var data = response.data;
                if (data.status == 'success') {
                    ev.new_password = null;
                    ev.$toasted.global.success({
                        message: "password change successfully"
                    });
                } else {
                    ev.$toasted.global.error({
                        message: "something wrong"
                    });
                }
            })
        },
    },
};
</script>

<style scoped>
.bdr-none {
    border: none;
}

.navi-link {
    cursor: pointer;
}
</style>
