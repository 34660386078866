<template>
<div name="student">

    <MobileHeader></MobileHeader>
    <div class="d-flex flex-row flex-column-fluid page">
        <div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">

            <Header></Header>

            <!--begin::Content-->
            <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
                <!--begin::Entry-->
                <div class="d-flex flex-column-fluid">
                    <!--begin::Container-->
                    <!--begin::Dashboard-->

                    <div class="container">
                        <div class="card card-custom gutter-b">
                            <!--begin::Body-->
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-xl-12">
                                        <div class="form-group">
                                            <label>Task Title</label>
                                            <input type="text" class="form-control form-control-solid form-control-lg" v-model="schedule_name" id="schedule_name" placeholder="Task Title">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label>Task</label>
                                    <div class="form-group">
                                        <vue-editor v-model="description"></vue-editor>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-6">
                                        <div class="form-group">
                                            <label>Task Assign to</label>
                                            <select class="form-control" v-model="assign_uid" >
                                                <option value="">Select </option>
                                                <template v-for="adata in allUser">
                                                    <option :key="adata.id" :value="adata.id">{{adata.username}}</option>
                                                </template>
                                            </select>
                                        </div>    
                                    </div>

                                    <div class="col-xl-6">
                                        <div class="form-group">
                                            <label>Attachment</label>
                                            <div class="btn upload-btn" @click="$refs.file.click()">
                                                        <img class="icon-upload hide" src="../../assets/icons/upload_icon.svg">
                                                        Upload Attachment
                                                        <br>
                                                        
                                            </div>
                                            <p class='select-file-span'>{{select_file_name}}</p>
                                             <input class="hide" type="file" id="file" ref="file" @change="upload_file" accept="" />
                                        </div>    
                                    </div>

                                </div>
                                <div class="row">
                                    <div class="col-xl-4">
                                        <div class="form-group">
                                            <label>Due Date</label>
                                            <div class="input-group date">
                                                <input type="date" name="due_date" class="form-control" v-model="due_date" placeholder="Select date">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-4">
                                        <div class="form-group">
                                            <label>Start Time</label>
                                            <input class="form-control" id="kt_timepicker_1" v-model="start_time" placeholder="Select start time" name="start_time" type="time">
                                        </div>
                                    </div>
                                    <div class="col-xl-4">
                                        <div class="form-group">
                                            <label>End Time</label>
                                            <input class="form-control" id="kt_timepicker_1" v-model="end_time" placeholder="Select end time" name="end_time" type="time">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <button type="submit" class="btn btn-primary mr-2" @click="save_schedule">Submit</button>
                                <button type="button" onclick="location.reload();" class="btn btn-secondary">Cancel</button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
</template>

<script>
import axios from 'axios'
import MobileHeader from '../../components/MobileHeader'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import {
    VueEditor
} from "vue2-editor";

export default {
    name: 'AddTask',
    components: {
        Header,
        Footer,
        MobileHeader,
        VueEditor,
    },
    data() {
        return {
            uid: localStorage.getItem("uid"),
            role: localStorage.getItem("role"),
            schedule_name: null,
            description: null,
            due_date: null,
            start_time: null,
            end_time: null,
            select_file_name:null,
            file:null,
            allUser:[],
            assign_uid:"",

        }
    },
    mounted() {
        if (this.uid == null || this.uid == "") {
            this.$router.push("/");
        }
        this.getAllUsers();
    },
    methods: {
        upload_file() {
            var ev = this;
            this.file = this.$refs.file.files[0];

            let formData = new FormData();
            this.select_file_name = this.file.name;

        },
        save_schedule() {
            var ev = this;
            this.file = this.$refs.file.files[0];

            let formData = new FormData();

            if (this.schedule_name == null || this.schedule_name == "") {
                ev.$toasted.global.error({
                    message: "please enter title"
                });
            } else if (this.description == null || this.description == "") {
                ev.$toasted.global.error({
                    message: "please enter task"
                });
            } else if(this.assign_uid== null || this.assign_uid == "") {
                ev.$toasted.global.error({
                    message: "please select assign to"
                });
            } else if (this.due_date == null || this.due_date == "") {
                ev.$toasted.global.error({
                    message: "please select due date"
                });
            } else if (this.start_time == null || this.start_time == "") {
                ev.$toasted.global.error({
                    message: "please select start time"
                });
            } else if (this.end_time == null || this.end_time == "") {
                ev.$toasted.global.error({
                    message: "please select end time"
                });
            } else {
                formData.append('file', this.file);
                formData.append('role', this.role);
                formData.append('uid', this.uid);
                formData.append('schedule_name', this.schedule_name);
                formData.append('description', this.description);
                formData.append('due_date', this.due_date);
                formData.append('start_time', this.start_time);
                formData.append('end_time', this.end_time);
                formData.append('stype', 'task');
                formData.append('assign_uid', this.assign_uid);
                

               /* axios({
                    method: 'POST',
                    url: this.$root.URL_ROOT + 'lms_api.php',
                    data: {
                        type: 'save_schedule',
                        role: this.role,
                        uid: this.uid,
                        schedule_name: this.schedule_name,
                        description: this.description,
                        due_date: this.due_date,
                        start_time: this.start_time,
                        end_time: this.end_time,
                        stype: 'task'

                    }
                })*/
                  axios.post(this.$root.URL_ROOT + 'lms_api.php?type=save_schedule_task_data', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then(function (response) {
                    var data = response.data;
                    if (data.status == "success") {
                        ev.$toasted.global.success({
                            message: data.msg
                        });
                        ev.$router.push("/task");
                    }else{
                        ev.$toasted.global.error({
                            message: data.msg
                        });
                    }
                })
            }
        },
        getAllUsers(){
            var ev= this;
              axios({
                    method: 'POST',
                    url: this.$root.URL_ROOT + 'lms_api.php',
                    data: {
                        type: 'getAssignUserList',
                        role: this.role,
                        uid: this.uid,
                       
                    }
                }).then(function (response) {
                    var data = response.data;
                    console.log(data);
                    ev.allUser=data;
                })
        }
    },
}
</script>

<style scoped>
.btn-top-table {
    display: inline-block;
    padding: 10px;
    width: 50%;
}

.table {
    width: 100%;

}

.table-dt {
    overflow: scroll;
}

.tbl-action {
    margin: 2px;
    border: unset;
}
.upload-btn{
    width: 60%;
    background-color: #8e7cb1;
    color: #ffff;
    font-size: 16px;
    margin-left: 15px;
    display: block;
}
.select-file-span{
    margin-left: 20px;
    margin-top: 10px;
}
</style>
