<template>
<div>
    <MobileHeader></MobileHeader>
    <div class="d-flex flex-row flex-column-fluid page">
        <div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">

            <Header></Header>

            <!--begin::Content-->
            <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
                <!--begin::Entry-->
                <div class="d-flex flex-column-fluid">
                    <!--begin::Container-->
                    <div class="container">
                        <!--begin::Dashboard-->

                        <div class="row">
                            <div class="col-xl-4">
                                <!--begin::Mixed Widget 2-->
                                <div class="card card-custom bg-gray-100 gutter-b card-stretch">
                                    <!--begin::Header-->
                                    <div class="card-header border-0 bg-primary py-5">
                                        <h3 class="card-title font-weight-bolder text-white">Earnings this Month</h3>

                                    </div>
                                    <!--end::Header-->
                                    <!--begin::Body-->
                                    <div class="card-body p-0 position-relative overflow-hidden">
                                        <!--begin::Chart-->
                                        <div id="kt_mixed_widget_2_chart" class="card-rounded-bottom bg-primary" style="height: 200px; min-height: 200px;">
                                            <div id="apexchartsb4vhaa9a" class="apexcharts-canvas apexchartsb4vhaa9a apexcharts-theme-light" style="width: 413px; height: 200px;"><svg id="SvgjsSvg1063" width="413" height="200" xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs" class="apexcharts-svg" xmlns:data="ApexChartsNS" transform="translate(0, 0)" style="background: transparent;">
                                                    <g id="SvgjsG1065" class="apexcharts-inner apexcharts-graphical" transform="translate(0, 0)">
                                                        <defs id="SvgjsDefs1064">
                                                            <clipPath id="gridRectMaskb4vhaa9a">
                                                                <rect id="SvgjsRect1068" width="420" height="203" x="-3.5" y="-1.5" rx="0" ry="0" opacity="1" stroke-width="0" stroke="none" stroke-dasharray="0" fill="#fff"></rect>
                                                            </clipPath>
                                                            <clipPath id="gridRectMarkerMaskb4vhaa9a">
                                                                <rect id="SvgjsRect1069" width="417" height="204" x="-2" y="-2" rx="0" ry="0" opacity="1" stroke-width="0" stroke="none" stroke-dasharray="0" fill="#fff"></rect>
                                                            </clipPath>
                                                            <filter id="SvgjsFilter1075" filterUnits="userSpaceOnUse" width="200%" height="200%" x="-50%" y="-50%">
                                                                <feFlood id="SvgjsFeFlood1076" flood-color="#287ed7" flood-opacity="0.5" result="SvgjsFeFlood1076Out" in="SourceGraphic"></feFlood>
                                                                <feComposite id="SvgjsFeComposite1077" in="SvgjsFeFlood1076Out" in2="SourceAlpha" operator="in" result="SvgjsFeComposite1077Out"></feComposite>
                                                                <feOffset id="SvgjsFeOffset1078" dx="0" dy="5" result="SvgjsFeOffset1078Out" in="SvgjsFeComposite1077Out"></feOffset>
                                                                <feGaussianBlur id="SvgjsFeGaussianBlur1079" stdDeviation="3 " result="SvgjsFeGaussianBlur1079Out" in="SvgjsFeOffset1078Out"></feGaussianBlur>
                                                                <feMerge id="SvgjsFeMerge1080" result="SvgjsFeMerge1080Out" in="SourceGraphic">
                                                                    <feMergeNode id="SvgjsFeMergeNode1081" in="SvgjsFeGaussianBlur1079Out"></feMergeNode>
                                                                    <feMergeNode id="SvgjsFeMergeNode1082" in="[object Arguments]"></feMergeNode>
                                                                </feMerge>
                                                                <feBlend id="SvgjsFeBlend1083" in="SourceGraphic" in2="SvgjsFeMerge1080Out" mode="normal" result="SvgjsFeBlend1083Out"></feBlend>
                                                            </filter>
                                                            <filter id="SvgjsFilter1085" filterUnits="userSpaceOnUse" width="200%" height="200%" x="-50%" y="-50%">
                                                                <feFlood id="SvgjsFeFlood1086" flood-color="#287ed7" flood-opacity="0.5" result="SvgjsFeFlood1086Out" in="SourceGraphic"></feFlood>
                                                                <feComposite id="SvgjsFeComposite1087" in="SvgjsFeFlood1086Out" in2="SourceAlpha" operator="in" result="SvgjsFeComposite1087Out"></feComposite>
                                                                <feOffset id="SvgjsFeOffset1088" dx="0" dy="5" result="SvgjsFeOffset1088Out" in="SvgjsFeComposite1087Out"></feOffset>
                                                                <feGaussianBlur id="SvgjsFeGaussianBlur1089" stdDeviation="3 " result="SvgjsFeGaussianBlur1089Out" in="SvgjsFeOffset1088Out"></feGaussianBlur>
                                                                <feMerge id="SvgjsFeMerge1090" result="SvgjsFeMerge1090Out" in="SourceGraphic">
                                                                    <feMergeNode id="SvgjsFeMergeNode1091" in="SvgjsFeGaussianBlur1089Out"></feMergeNode>
                                                                    <feMergeNode id="SvgjsFeMergeNode1092" in="[object Arguments]"></feMergeNode>
                                                                </feMerge>
                                                                <feBlend id="SvgjsFeBlend1093" in="SourceGraphic" in2="SvgjsFeMerge1090Out" mode="normal" result="SvgjsFeBlend1093Out"></feBlend>
                                                            </filter>
                                                        </defs>
                                                        <g id="SvgjsG1094" class="apexcharts-xaxis" transform="translate(0, 0)">
                                                            <g id="SvgjsG1095" class="apexcharts-xaxis-texts-g" transform="translate(0, -4)"></g>
                                                        </g>
                                                        <g id="SvgjsG1097" class="apexcharts-grid">
                                                            <g id="SvgjsG1098" class="apexcharts-gridlines-horizontal" style="display: none;">
                                                                <line id="SvgjsLine1100" x1="0" y1="0" x2="413" y2="0" stroke="#e0e0e0" stroke-dasharray="0" class="apexcharts-gridline"></line>
                                                                <line id="SvgjsLine1101" x1="0" y1="20" x2="413" y2="20" stroke="#e0e0e0" stroke-dasharray="0" class="apexcharts-gridline"></line>
                                                                <line id="SvgjsLine1102" x1="0" y1="40" x2="413" y2="40" stroke="#e0e0e0" stroke-dasharray="0" class="apexcharts-gridline"></line>
                                                                <line id="SvgjsLine1103" x1="0" y1="60" x2="413" y2="60" stroke="#e0e0e0" stroke-dasharray="0" class="apexcharts-gridline"></line>
                                                                <line id="SvgjsLine1104" x1="0" y1="80" x2="413" y2="80" stroke="#e0e0e0" stroke-dasharray="0" class="apexcharts-gridline"></line>
                                                                <line id="SvgjsLine1105" x1="0" y1="100" x2="413" y2="100" stroke="#e0e0e0" stroke-dasharray="0" class="apexcharts-gridline"></line>
                                                                <line id="SvgjsLine1106" x1="0" y1="120" x2="413" y2="120" stroke="#e0e0e0" stroke-dasharray="0" class="apexcharts-gridline"></line>
                                                                <line id="SvgjsLine1107" x1="0" y1="140" x2="413" y2="140" stroke="#e0e0e0" stroke-dasharray="0" class="apexcharts-gridline"></line>
                                                                <line id="SvgjsLine1108" x1="0" y1="160" x2="413" y2="160" stroke="#e0e0e0" stroke-dasharray="0" class="apexcharts-gridline"></line>
                                                                <line id="SvgjsLine1109" x1="0" y1="180" x2="413" y2="180" stroke="#e0e0e0" stroke-dasharray="0" class="apexcharts-gridline"></line>
                                                                <line id="SvgjsLine1110" x1="0" y1="200" x2="413" y2="200" stroke="#e0e0e0" stroke-dasharray="0" class="apexcharts-gridline"></line>
                                                            </g>
                                                            <g id="SvgjsG1099" class="apexcharts-gridlines-vertical" style="display: none;"></g>
                                                            <line id="SvgjsLine1112" x1="0" y1="200" x2="413" y2="200" stroke="transparent" stroke-dasharray="0"></line>
                                                            <line id="SvgjsLine1111" x1="0" y1="1" x2="0" y2="200" stroke="transparent" stroke-dasharray="0"></line>
                                                        </g>
                                                        <g id="SvgjsG1070" class="apexcharts-area-series apexcharts-plot-series">
                                                            <g id="SvgjsG1071" class="apexcharts-series" seriesName="NetxProfit" data:longestSeries="true" rel="1" data:realIndex="0">
                                                                <path id="SvgjsPath1074" d="M 0 200L 0 200C 48.18333333333333 200 89.48333333333332 200 137.66666666666666 200C 185.85 200 227.14999999999998 200 275.3333333333333 200C 323.51666666666665 200 364.81666666666666 200 413 200C 413 200 413 200 413 200M 413 200z" fill="transparent" fill-opacity="1" stroke-opacity="1" stroke-linecap="butt" stroke-width="0" stroke-dasharray="0" class="apexcharts-area" index="0" clip-path="url(#gridRectMaskb4vhaa9a)" filter="url(#SvgjsFilter1075)" pathTo="M 0 200L 0 200C 48.18333333333333 200 89.48333333333332 200 137.66666666666666 200C 185.85 200 227.14999999999998 200 275.3333333333333 200C 323.51666666666665 200 364.81666666666666 200 413 200C 413 200 413 200 413 200M 413 200z" pathFrom="M -1 200L -1 200L 137.66666666666666 200L 275.3333333333333 200L 413 200"></path>
                                                                <path id="SvgjsPath1084" d="M 0 200C 48.18333333333333 200 89.48333333333332 200 137.66666666666666 200C 185.85 200 227.14999999999998 200 275.3333333333333 200C 323.51666666666665 200 364.81666666666666 200 413 200" fill="none" fill-opacity="1" stroke="#287ed7" stroke-opacity="1" stroke-linecap="butt" stroke-width="3" stroke-dasharray="0" class="apexcharts-area" index="0" clip-path="url(#gridRectMaskb4vhaa9a)" filter="url(#SvgjsFilter1085)" pathTo="M 0 200C 48.18333333333333 200 89.48333333333332 200 137.66666666666666 200C 185.85 200 227.14999999999998 200 275.3333333333333 200C 323.51666666666665 200 364.81666666666666 200 413 200" pathFrom="M -1 200L -1 200L 137.66666666666666 200L 275.3333333333333 200L 413 200"></path>
                                                                <g id="SvgjsG1072" class="apexcharts-series-markers-wrap" data:realIndex="0">
                                                                    <g class="apexcharts-series-markers">
                                                                        <circle id="SvgjsCircle1118" r="0" cx="413" cy="0" class="apexcharts-marker wp6uyyn89 no-pointer-events" stroke="#287ed7" fill="#eee5ff" fill-opacity="1" stroke-width="3" stroke-opacity="0.9" default-marker-size="0"></circle>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                            <g id="SvgjsG1073" class="apexcharts-datalabels" data:realIndex="0"></g>
                                                        </g>
                                                        <line id="SvgjsLine1113" x1="0" y1="0" x2="413" y2="0" stroke="#b6b6b6" stroke-dasharray="0" stroke-width="1" class="apexcharts-ycrosshairs"></line>
                                                        <line id="SvgjsLine1114" x1="0" y1="0" x2="413" y2="0" stroke-dasharray="0" stroke-width="0" class="apexcharts-ycrosshairs-hidden"></line>
                                                        <g id="SvgjsG1115" class="apexcharts-yaxis-annotations"></g>
                                                        <g id="SvgjsG1116" class="apexcharts-xaxis-annotations"></g>
                                                        <g id="SvgjsG1117" class="apexcharts-point-annotations"></g>
                                                    </g>
                                                    <g id="SvgjsG1096" class="apexcharts-yaxis" rel="0" transform="translate(-18, 0)"></g>
                                                    <g id="SvgjsG1066" class="apexcharts-annotations"></g>
                                                </svg>
                                                <div class="apexcharts-legend" style="max-height: 100px;"></div>
                                                <div class="apexcharts-tooltip apexcharts-theme-light" style="left: 234.484px; top: 132px;">
                                                    <div class="apexcharts-tooltip-title" style="font-family: Poppins; font-size: 12px;">Yearly</div>
                                                    <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;"><span class="apexcharts-tooltip-marker" style="background-color: transparent; display: none;"></span>
                                                        <div class="apexcharts-tooltip-text" style="font-family: Poppins; font-size: 12px;">
                                                            <div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-label">Net Profit: </span><span class="apexcharts-tooltip-text-value">$0 thousands</span></div>
                                                            <div class="apexcharts-tooltip-z-group"><span class="apexcharts-tooltip-text-z-label"></span><span class="apexcharts-tooltip-text-z-value"></span></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="apexcharts-xaxistooltip apexcharts-xaxistooltip-bottom apexcharts-theme-light" style="left: 384.125px; top: 202px;">
                                                    <div class="apexcharts-xaxistooltip-text" style="font-family: Poppins; font-size: 12px; min-width: 39.8125px;">Yearly</div>
                                                </div>
                                                <div class="apexcharts-yaxistooltip apexcharts-yaxistooltip-0 apexcharts-yaxistooltip-left apexcharts-theme-light">
                                                    <div class="apexcharts-yaxistooltip-text"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <!--end::Chart-->
                                        <!--begin::Stats-->
                                        <div class="card-spacer mt-n25">
                                            <!--begin::Row-->
                                            <div class="row m-0">
                                                <div class="col bg-white px-6 py-8 rounded-xl mr-7 mb-7">
                                                    <span class="svg-icon svg-icon-3x svg-icon-info d-block my-2">
                                                        <!--begin::Svg Icon | path:assets/media/svg/icons/Media/Equalizer.svg-->
                                                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                <rect x="0" y="0" width="24" height="24"></rect>
                                                                <rect fill="#000000" opacity="0.3" x="13" y="4" width="3" height="16" rx="1.5"></rect>
                                                                <rect fill="#000000" x="8" y="9" width="3" height="11" rx="1.5"></rect>
                                                                <rect fill="#000000" x="18" y="11" width="3" height="9" rx="1.5"></rect>
                                                                <rect fill="#000000" x="3" y="13" width="3" height="7" rx="1.5"></rect>
                                                            </g>
                                                        </svg>
                                                        <!--end::Svg Icon-->
                                                    </span>
                                                    <a href="#" class="text-info font-weight-bold font-size-h6 tooltipp">Today Sales<span class="tooltiptext">0</span></a>
                                                </div>
                                                <div class="col bg-white px-6 py-8 rounded-xl mb-7">
                                                    <span class="svg-icon svg-icon-3x svg-icon-info d-block my-2">
                                                        <!--begin::Svg Icon | path:assets/media/svg/icons/Media/Equalizer.svg-->
                                                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                <rect x="0" y="0" width="24" height="24"></rect>
                                                                <rect fill="#000000" opacity="0.3" x="13" y="4" width="3" height="16" rx="1.5"></rect>
                                                                <rect fill="#000000" x="8" y="9" width="3" height="11" rx="1.5"></rect>
                                                                <rect fill="#000000" x="18" y="11" width="3" height="9" rx="1.5"></rect>
                                                                <rect fill="#000000" x="3" y="13" width="3" height="7" rx="1.5"></rect>
                                                            </g>
                                                        </svg>
                                                        <!--end::Svg Icon-->
                                                    </span>
                                                    <a href="#" class="text-info font-weight-bold font-size-h6 tooltipp">Weekly Sales<span class="tooltiptext">0</span></a>
                                                </div>
                                            </div>
                                            <!--end::Row-->
                                            <!--begin::Row-->
                                            <div class="row m-0">
                                                <div class="col bg-white px-6 py-8 rounded-xl mr-7">
                                                    <span class="svg-icon svg-icon-3x svg-icon-info d-block my-2">
                                                        <!--begin::Svg Icon | path:assets/media/svg/icons/Media/Equalizer.svg-->
                                                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                <rect x="0" y="0" width="24" height="24"></rect>
                                                                <rect fill="#000000" opacity="0.3" x="13" y="4" width="3" height="16" rx="1.5"></rect>
                                                                <rect fill="#000000" x="8" y="9" width="3" height="11" rx="1.5"></rect>
                                                                <rect fill="#000000" x="18" y="11" width="3" height="9" rx="1.5"></rect>
                                                                <rect fill="#000000" x="3" y="13" width="3" height="7" rx="1.5"></rect>
                                                            </g>
                                                        </svg>
                                                        <!--end::Svg Icon-->
                                                    </span>
                                                    <a href="#" class="text-info font-weight-bold font-size-h6 tooltipp">Monthly Sales<span class="tooltiptext">0</span></a>
                                                </div>
                                                <div class="col bg-white px-6 py-8 rounded-xl">
                                                    <span class="svg-icon svg-icon-3x svg-icon-success d-block my-2">
                                                        <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Urgent-mail.svg-->
                                                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                <rect x="0" y="0" width="24" height="24"></rect>
                                                                <path d="M12.7037037,14 L15.6666667,10 L13.4444444,10 L13.4444444,6 L9,12 L11.2222222,12 L11.2222222,14 L6,14 C5.44771525,14 5,13.5522847 5,13 L5,3 C5,2.44771525 5.44771525,2 6,2 L18,2 C18.5522847,2 19,2.44771525 19,3 L19,13 C19,13.5522847 18.5522847,14 18,14 L12.7037037,14 Z" fill="#000000" opacity="0.3"></path>
                                                                <path d="M9.80428954,10.9142091 L9,12 L11.2222222,12 L11.2222222,16 L15.6666667,10 L15.4615385,10 L20.2072547,6.57253826 C20.4311176,6.4108595 20.7436609,6.46126971 20.9053396,6.68513259 C20.9668779,6.77033951 21,6.87277228 21,6.97787787 L21,17 C21,18.1045695 20.1045695,19 19,19 L5,19 C3.8954305,19 3,18.1045695 3,17 L3,6.97787787 C3,6.70173549 3.22385763,6.47787787 3.5,6.47787787 C3.60510559,6.47787787 3.70753836,6.51099993 3.79274528,6.57253826 L9.80428954,10.9142091 Z" fill="#000000"></path>
                                                            </g>
                                                        </svg>
                                                        <!--end::Svg Icon-->
                                                    </span>
                                                    <a href="#" class="text-success font-weight-bold font-size-h6 mt-2 tooltipp">Total Sales<span class="tooltiptext">0</span></a>
                                                </div>
                                            </div>
                                            <!--end::Row-->
                                        </div>
                                        <!--end::Stats-->
                                        <div class="resize-triggers">
                                            <div class="expand-trigger">
                                                <div style="width: 414px; height: 453px;"></div>
                                            </div>
                                            <div class="contract-trigger"></div>
                                        </div>
                                    </div>
                                    <!--end::Body-->
                                </div>
                                <!--end::Mixed Widget 2-->
                            </div>
                            <div class="col-xl-4">
                                <!--begin::Mixed Widget 18-->
                                <div class="card card-custom gutter-b card-stretch s">
                                    <!--begin::Header-->
                                    <div class="card-header border-0 pt-5">
                                        <div class="card-title font-weight-bolder">
                                            <div class="card-label">Weekly Student Enrollments
                                                <!--<div class="font-size-sm text-muted mt-2">890,344 Sales</div>-->
                                            </div>
                                        </div>
                                    </div>
                                    <!--end::Header-->
                                    <!--begin::Body-->
                                    <div class="card-body">
                                        <!--begin::Chart-->
                                        <div id="kt_mixed_widget_18_chart" style="height: 250px"></div>
                                        <!--end::Chart-->
                                        <!--begin::Items-->
                                        <div class="mt-n12 position-relative zindex-0">
                                            <!--begin::Widget Item-->
                                            <div class="d-flex align-items-center mb-8">
                                                <!--begin::Symbol-->
                                                <div class="symbol symbol-circle symbol-40 symbol-light mr-3 flex-shrink-0">
                                                    <div class="symbol-label">
                                                        <span class="svg-icon svg-icon-lg svg-icon-gray-500">
                                                            <!--begin::Svg Icon | path:assets/media/svg/icons/Media/Equalizer.svg-->
                                                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                    <rect x="0" y="0" width="24" height="24"></rect>
                                                                    <rect fill="#000000" opacity="0.3" x="13" y="4" width="3" height="16" rx="1.5"></rect>
                                                                    <rect fill="#000000" x="8" y="9" width="3" height="11" rx="1.5"></rect>
                                                                    <rect fill="#000000" x="18" y="11" width="3" height="9" rx="1.5"></rect>
                                                                    <rect fill="#000000" x="3" y="13" width="3" height="7" rx="1.5"></rect>
                                                                </g>
                                                            </svg>
                                                            <!--end::Svg Icon-->
                                                        </span>
                                                    </div>
                                                </div>
                                                <!--end::Symbol-->
                                                <!--begin::Title-->
                                                <div>
                                                    <a href="#" class="font-size-h6 text-dark-75 text-hover-primary font-weight-bolder">Most Sold Courses</a>
                                                    <div class="font-size-sm text-muted font-weight-bold mt-1"> NO COURSE </div>
                                                </div>
                                                <!--end::Title-->
                                            </div>
                                            <!--end::Widget Item-->
                                            <!--begin::Widget Item-->
                                            <div class="d-flex align-items-center mb-8">
                                                <!--begin::Symbol-->
                                                <div class="symbol symbol-circle symbol-40 symbol-light mr-3 flex-shrink-0">
                                                    <div class="symbol-label">
                                                        <span class="svg-icon svg-icon-lg svg-icon-gray-500">
                                                            <!--begin::Svg Icon | path:assets/media/svg/icons/Shopping/Chart-pie.svg-->
                                                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                    <rect x="0" y="0" width="24" height="24"></rect>
                                                                    <path d="M4.00246329,12.2004927 L13,14 L13,4.06189375 C16.9463116,4.55399184 20,7.92038235 20,12 C20,16.418278 16.418278,20 12,20 C7.64874861,20 4.10886412,16.5261253 4.00246329,12.2004927 Z" fill="#000000" opacity="0.3"></path>
                                                                    <path d="M3.0603968,10.0120794 C3.54712466,6.05992157 6.91622084,3 11,3 L11,11.6 L3.0603968,10.0120794 Z" fill="#000000"></path>
                                                                </g>
                                                            </svg>
                                                            <!--end::Svg Icon-->
                                                        </span>
                                                    </div>
                                                </div>
                                                <!--end::Symbol-->
                                                <!--begin::Title-->
                                                <div>
                                                    <a href="#" class="font-size-h6 text-dark-75 text-hover-primary font-weight-bolder">Total Revenue</a>
                                                    <div class="font-size-sm text-muted font-weight-bold mt-1">0</div>
                                                </div>
                                                <!--end::Title-->
                                            </div>
                                            <!--end::Widget Item-->
                                        </div>
                                        <!--end::Items-->
                                    </div>
                                    <!--end::Body-->
                                </div>
                                <!--end::Mixed Widget 18-->
                            </div>
                            <div class="col-xl-4">
                                <!--begin::List Widget 7-->
                                <div class="card card-custom gutter-b card-stretch s">
                                    <!--begin::Header-->
                                    <div class="card-header border-0">
                                        <h3 class="card-title font-weight-bolder text-dark">Recent Enrollments</h3>
                                        <div class="card-toolbar">
                                        </div>
                                    </div>
                                    <!--end::Header-->
                                    <!--begin::Body-->
                                    <div class="card-body pt-0">
                                      <template v-for="(edata,key) in getEnrollData">
                                         <!--begin::Item-->
                                        <div :key="edata.id" v-if="key<4" class="d-flex align-items-center flex-wrap mb-10">
                                            <!--begin::Symbol-->
                                            <div class="symbol symbol-50 symbol-light mr-5">
                                                <span class="symbol-label" style="text-transform:capitalize;">
                                                    <span class="svg-icon svg-icon-2x svg-icon-success">
                                                        <!--begin::Svg Icon | path:/metronic/theme/html/demo7/dist/assets/media/svg/icons/Communication/Group.svg-->
                                                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                                                <path d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                                                                <path d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z" fill="#000000" fill-rule="nonzero"></path>
                                                            </g>
                                                        </svg>
                                                        <!--end::Svg Icon-->
                                                    </span>
                                                </span>
                                            </div>
                                            <!--end::Symbol-->
                                            <!--begin::Text-->
                                            <div class="d-flex flex-column flex-grow-1 mr-2">
                                                <a href="#" class="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1" style="text-transform:capitalize;">
                                                    {{edata.username}}</a>
                                                <span class="text-muted font-weight-bold">
                                                    {{edata.city}}, {{edata.state}}
                                                </span>
                                            </div>
                                            <!--end::Text-->

                                            <span class="label label-xl label-light label-inline my-lg-0 my-2 text-dark-50 font-weight-bolder"> $0
                                            </span>
                                        </div>
                                        <!--end::Item-->
                                       
                                      </template>
                                       

                                    </div>
                                    <!--end::Body-->

                                    <!--begin::Footer-->
                                    <div class="card-footer border-0">
                                        <router-link to="/Students" class="btn btn-light btn-xl" style="float:right;">View All
                                            <span class="symbol-label">
                                                <span class="svg-icon svg-icon-2x svg-icon-success">
                                                    <!--begin::Svg Icon | path:assets/media/svg/icons/Shopping/Cart3.svg-->
                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                            <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                                            <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)" x="11" y="5" width="2" height="14" rx="1"></rect>
                                                            <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fill-rule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)"></path>
                                                        </g>
                                                    </svg>
                                                    <!--end::Svg Icon-->
                                                </span>
                                            </span>
                                        </router-link>
                                    </div>
                                    <!--end::Footer-->
                                </div>
                                <!--end::List Widget 7-->
                            </div>
                        </div>

                        <div clas="row" style="display:flex;">
                            <div class="col-xl-6">
                                <!--begin::Base Table Widget 3-->
                                <div class="card card-custom gutter-b card-stretch" style="height:auto;">
                                    <!--begin::Header-->
                                    <div class="card-header border-0 pt-5">
                                        <h3 class="card-title align-items-start flex-column">
                                            <span class="card-label font-weight-bolder text-dark">Task</span>
                                            <!--<span class="text-muted mt-3 font-weight-bold font-size-sm">More than 400+ new members</span>-->
                                        </h3>
                                        <div class="card-toolbar">
                                            <router-link to="/addtask">
                                                <button type="button" class="btn btn-light-primary btn-md py-2 font-weight-bolder" data-toggle="modal" data-target="#staticBackdrop1Task">
                                                    Create Task
                                                </button>
                                            </router-link>
                                            <!-- Modal-->
                                            <div class="modal fade" id="staticBackdrop1Task" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                <div class="modal-dialog" role="document">
                                                    <div class="modal-content">
                                                        <div class="modal-header">
                                                            <h5 class="modal-title" id="exampleModalLabel">New Task</h5>
                                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                                <i aria-hidden="true" class="ki ki-close"></i>
                                                            </button>
                                                        </div>
                                                        <form action="https://levelup-learning.in/ImpactSchool/public/Admin/CreateTask" enctype="multipart/form-data" method="post">
                                                            <input type="hidden" name="_token" value="3VbwUFSkVyvkLlAvD4UfIfrBhq4s1AqX7HsYe07I">
                                                            <div class="modal-body">
                                                                <div class="form-group">
                                                                    <label class="form-control-label">Task Name:</label>
                                                                    <input type="text" class="form-control" name="task_name" placeholder="Enter task name">
                                                                </div>
                                                                <div class="form-group">
                                                                    <label>Description</label>
                                                                    <div class="md-editor" id="1622820093902">
                                                                        <div class="md-header btn-toolbar">
                                                                            <div class="btn-group"><button class="btn-default btn-sm btn" type="button" title="Bold" tabindex="-1" data-provider="bootstrap-markdown" data-handler="bootstrap-markdown-cmdBold" data-hotkey="Ctrl+B"><span class="fa fa-bold"></span> </button><button class="btn-default btn-sm btn" type="button" title="Italic" tabindex="-1" data-provider="bootstrap-markdown" data-handler="bootstrap-markdown-cmdItalic" data-hotkey="Ctrl+I"><span class="fa fa-italic"></span> </button><button class="btn-default btn-sm btn" type="button" title="Heading" tabindex="-1" data-provider="bootstrap-markdown" data-handler="bootstrap-markdown-cmdHeading" data-hotkey="Ctrl+H"><span class="fa fa-heading"></span> </button></div>
                                                                            <div class="btn-group"><button class="btn-default btn-sm btn" type="button" title="URL/Link" tabindex="-1" data-provider="bootstrap-markdown" data-handler="bootstrap-markdown-cmdUrl" data-hotkey="Ctrl+L"><span class="fa fa-link"></span> </button><button class="btn-default btn-sm btn" type="button" title="Image" tabindex="-1" data-provider="bootstrap-markdown" data-handler="bootstrap-markdown-cmdImage" data-hotkey="Ctrl+G"><span class="fa fa-image"></span> </button></div>
                                                                            <div class="btn-group"><button class="btn-default btn-sm btn" type="button" title="Unordered List" tabindex="-1" data-provider="bootstrap-markdown" data-handler="bootstrap-markdown-cmdList" data-hotkey="Ctrl+U"><span class="fa fa-list"></span> </button><button class="btn-default btn-sm btn" type="button" title="Ordered List" tabindex="-1" data-provider="bootstrap-markdown" data-handler="bootstrap-markdown-cmdListO" data-hotkey="Ctrl+O"><span class="fa fa-list-ol"></span> </button><button class="btn-default btn-sm btn" type="button" title="Code" tabindex="-1" data-provider="bootstrap-markdown" data-handler="bootstrap-markdown-cmdCode" data-hotkey="Ctrl+K"><span class="fa fa-code"></span> </button><button class="btn-default btn-sm btn" type="button" title="Quote" tabindex="-1" data-provider="bootstrap-markdown" data-handler="bootstrap-markdown-cmdQuote" data-hotkey="Ctrl+Q"><span class="fa fa-quote-left"></span> </button></div>
                                                                            <div class="btn-group"><button class="btn-sm btn btn-primary" type="button" title="Preview" tabindex="-1" data-provider="bootstrap-markdown" data-handler="bootstrap-markdown-cmdPreview" data-hotkey="Ctrl+P" data-toggle="button"><span class="fa fa-search"></span> Preview</button></div>
                                                                            <div class="md-controls"><a class="md-control md-control-fullscreen" href="#"><span class="fa fa-expand"></span></a></div>
                                                                        </div><textarea name="description" class="form-control md-input" data-provide="markdown" rows="10" style="resize: none;"></textarea>
                                                                        <div class="md-fullscreen-controls"><a href="#" class="exit-fullscreen" title="Exit fullscreen"><span class="fa fa-compress"></span></a></div>
                                                                    </div>
                                                                </div>
                                                                <div class="form-group">
                                                                    <label>End Time</label>
                                                                    <input class="form-control" id="kt_timepicker_1" placeholder="Select end time" name="end_time" type="text">
                                                                </div>
                                                            </div>
                                                            <div class="modal-footer">
                                                                <button type="button" class="btn btn-light-primary font-weight-bold" data-dismiss="modal">Close</button>
                                                                <button type="submit" class="btn btn-primary font-weight-bold">Add Task</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!--end::Header-->
                                    <!--begin::Body-->
                                    <div class="card-body pt-2 pb-0">
                                        <!--begin::Table-->
                                        <div class="table-responsive">
                                            <table class="table table-borderless table-vertical-center">
                                                <thead>
                                                    <tr>
                                                        <th class="p-0"></th>
                                                        <th class="p-0"></th>
                                                        
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <template v-for="(cdata,key) in getMyTask">
                                                      <tr v-if="key<4" :key="cdata.id">
                                                          <td class="pl-0 py-5">
                                                              <p class="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">{{cdata.name}}</p>
                                                          </td>
                                                          <td class="pl-0">
                                                              <a  class="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">{{cdata.due_date}}</a>
                                                          </td>
                                                         
                                                          
                                                      </tr>
                                                    </template>
                                                </tbody>
                                            </table>
                                        </div>
                                        <!--end::table-->
                                    </div>
                                    <!--begin::Body-->
                                </div>
                                <!--end::Base Table Widget 3-->
                            </div>
                            <div class="col-xl-6">
                                <!--begin::Base Table Widget 3-->

                                <div class="card card-custom gutter-b card-stretch" style="height:auto;">
                                    <!--begin::Header-->
                                    <div class="card-header border-0 pt-5">
                                        <h3 class="card-title align-items-start flex-column">
                                            <span class="card-label font-weight-bolder text-dark">ToDo</span>
                                            <!--<span class="text-muted mt-3 font-weight-bold font-size-sm">More than 400+ new members</span>-->
                                        </h3>
                                        <div class="card-toolbar">
                                            <router-link to="/addtodo">
                                                <button type="button" class="btn btn-light-primary btn-md py-2 font-weight-bolder" data-toggle="modal" data-target="#staticBackdrop1">
                                                    Create ToDo
                                                </button>
                                            </router-link>
                                            <!-- Modal-->
                                            <div class="modal fade" id="staticBackdrop1" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                <div class="modal-dialog" role="document">
                                                    <div class="modal-content">
                                                        <div class="modal-header">
                                                            <h5 class="modal-title" id="exampleModalLabel">New Todo</h5>
                                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                                <i aria-hidden="true" class="ki ki-close"></i>
                                                            </button>
                                                        </div>
                                                        <form action="https://levelup-learning.in/ImpactSchool/public/Admin/CreateToDo" enctype="multipart/form-data" method="post">
                                                            <input type="hidden" name="_token" value="3VbwUFSkVyvkLlAvD4UfIfrBhq4s1AqX7HsYe07I">
                                                            <div class="modal-body">
                                                                <div class="form-group">
                                                                    <label class="form-control-label">ToDo Title:</label>
                                                                    <input type="text" class="form-control" name="task_title" placeholder="Enter todo title">
                                                                </div>
                                                                <div class="form-group">
                                                                    <label>Task</label>
                                                                    <div class="md-editor" id="1622820093908">
                                                                        <div class="md-header btn-toolbar">
                                                                            <div class="btn-group"><button class="btn-default btn-sm btn" type="button" title="Bold" tabindex="-1" data-provider="bootstrap-markdown" data-handler="bootstrap-markdown-cmdBold" data-hotkey="Ctrl+B"><span class="fa fa-bold"></span> </button><button class="btn-default btn-sm btn" type="button" title="Italic" tabindex="-1" data-provider="bootstrap-markdown" data-handler="bootstrap-markdown-cmdItalic" data-hotkey="Ctrl+I"><span class="fa fa-italic"></span> </button><button class="btn-default btn-sm btn" type="button" title="Heading" tabindex="-1" data-provider="bootstrap-markdown" data-handler="bootstrap-markdown-cmdHeading" data-hotkey="Ctrl+H"><span class="fa fa-heading"></span> </button></div>
                                                                            <div class="btn-group"><button class="btn-default btn-sm btn" type="button" title="URL/Link" tabindex="-1" data-provider="bootstrap-markdown" data-handler="bootstrap-markdown-cmdUrl" data-hotkey="Ctrl+L"><span class="fa fa-link"></span> </button><button class="btn-default btn-sm btn" type="button" title="Image" tabindex="-1" data-provider="bootstrap-markdown" data-handler="bootstrap-markdown-cmdImage" data-hotkey="Ctrl+G"><span class="fa fa-image"></span> </button></div>
                                                                            <div class="btn-group"><button class="btn-default btn-sm btn" type="button" title="Unordered List" tabindex="-1" data-provider="bootstrap-markdown" data-handler="bootstrap-markdown-cmdList" data-hotkey="Ctrl+U"><span class="fa fa-list"></span> </button><button class="btn-default btn-sm btn" type="button" title="Ordered List" tabindex="-1" data-provider="bootstrap-markdown" data-handler="bootstrap-markdown-cmdListO" data-hotkey="Ctrl+O"><span class="fa fa-list-ol"></span> </button><button class="btn-default btn-sm btn" type="button" title="Code" tabindex="-1" data-provider="bootstrap-markdown" data-handler="bootstrap-markdown-cmdCode" data-hotkey="Ctrl+K"><span class="fa fa-code"></span> </button><button class="btn-default btn-sm btn" type="button" title="Quote" tabindex="-1" data-provider="bootstrap-markdown" data-handler="bootstrap-markdown-cmdQuote" data-hotkey="Ctrl+Q"><span class="fa fa-quote-left"></span> </button></div>
                                                                            <div class="btn-group"><button class="btn-sm btn btn-primary" type="button" title="Preview" tabindex="-1" data-provider="bootstrap-markdown" data-handler="bootstrap-markdown-cmdPreview" data-hotkey="Ctrl+P" data-toggle="button"><span class="fa fa-search"></span> Preview</button></div>
                                                                            <div class="md-controls"><a class="md-control md-control-fullscreen" href="#"><span class="fa fa-expand"></span></a></div>
                                                                        </div><textarea name="task" class="form-control md-input" data-provide="markdown" rows="10" style="resize: none;"></textarea>
                                                                        <div class="md-fullscreen-controls"><a href="#" class="exit-fullscreen" title="Exit fullscreen"><span class="fa fa-compress"></span></a></div>
                                                                    </div>
                                                                </div>
                                                                <div class="form-group">
                                                                    <label>End Time</label>
                                                                    <input class="form-control" id="kt_timepicker_1" placeholder="Select end time" name="end_time" type="text">
                                                                </div>
                                                            </div>
                                                            <div class="modal-footer">
                                                                <button type="button" class="btn btn-light-primary font-weight-bold" data-dismiss="modal">Close</button>
                                                                <button type="submit" class="btn btn-primary font-weight-bold">Add ToDo</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!--end::Header-->
                                    <!--begin::Body-->
                                    <div class="card-body pt-2 pb-0">
                                        <!--begin::Table-->
                                        <div class="table-responsive">
                                            <table class="table table-borderless table-vertical-center">
                                                <thead>
                                                    <tr>
                                                        <th class="p-0" ></th>
                                                        <th class="p-0" ></th>
                                                        
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <template v-for="(cdata,key) in getMyTodo">
                                                      <tr v-if="key<4" :key="cdata.id">
                                                          <td class="pl-0 py-5">
                                                              <p class="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">{{cdata.name}}</p>
                                                          </td>
                                                          <td class="pl-0">
                                                              <a  class="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">{{cdata.due_date}}</a>
                                                          </td>
                                                         
                                                          
                                                      </tr>
                                                    </template>
                                                </tbody>
                                            </table>
                                        </div>
                                        <!--end::table-->
                                    </div>
                                    <!--begin::Body-->
                                </div>

                            </div>
                        </div>

                        <div clas="row" style="display:flex;">
                            <div class="col-xl-6">
                                <!--begin::Base Table Widget 3-->
                                <div class="card card-custom gutter-b card-stretch" style="height:auto;">
                                    <!--begin::Header-->
                                    <div class="card-header border-0 pt-5">
                                        <h3 class="card-title align-items-start flex-column">
                                            <span class="card-label font-weight-bolder text-dark">Course</span>
                                            <!--<span class="text-muted mt-3 font-weight-bold font-size-sm">More than 400+ new members</span>-->
                                        </h3>
                                        <div class="card-toolbar">
                                            <router-link to="/courses">
                                                <button type="button" class="btn btn-light-primary btn-md py-2 font-weight-bolder">Create Course</button>
                                            </router-link>
                                            
                                        </div>
                                    </div>
                                    <!--end::Header-->
                                    <!--begin::Body-->
                                    <div class="card-body pt-2 pb-0">
                                        <!--begin::Table-->
                                        <div class="table-responsive">
                                            <table class="table table-borderless table-vertical-center">
                                                <thead>
                                                    <tr>
                                                        <th class="p-0" style="width: 50px"></th>
                                                        <th class="p-0" style="min-width: 150px"></th>
                                                        <th class="p-0" style="min-width: 140px"></th>
                                                       
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                  <template v-for="(cdata,key) in getCourse">
                                                      <tr v-if="key<4" :key="cdata.id">
                                                          <td class="pl-0 py-5">
                                                              <div class="symbol symbol-45 symbol-light-success">
                                                                  <span class="symbol-label">
                                                                      <img :src="$root.URL_IMAGES+'Course/'+cdata.course_image" class="w-100 h-50 align-self-center">
                                                                  </span>
                                                              </div>
                                                          </td>
                                                          <td class="pl-0">
                                                              <a  class="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">{{cdata.course_name}}</a>
                                                          </td>
                                                          <td class="text-right">
                                                              <span class="text-muted font-weight-bold">Free</span>
                                                          </td>
                                                          
                                                      </tr>
                                                    </template>

                                                    <tr>
                                                        <td class="text-right" colspan="4">
                                                            <router-link to="/courses" class="btn btn-light btn-xl">View All
                                                                <span class="symbol-label">
                                                                    <span class="svg-icon svg-icon-2x svg-icon-success">
                                                                        <!--begin::Svg Icon | path:assets/media/svg/icons/Shopping/Cart3.svg-->
                                                                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                                <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                                                                <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)" x="11" y="5" width="2" height="14" rx="1"></rect>
                                                                                <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fill-rule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)"></path>
                                                                            </g>
                                                                        </svg>
                                                                        <!--end::Svg Icon-->
                                                                    </span>
                                                                </span>
                                                            </router-link>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <!--end::table-->
                                    </div>
                                    <!--begin::Body-->
                                </div>
                                <!--end::Base Table Widget 3-->
                            </div>
                            <div class="col-xl-6">
                                <!--begin::Base Table Widget 3-->
                                <!--end::Base Table Widget 3-->
                            </div>
                        </div>

                        <!--end::Dashboard-->
                    </div>
                    <!--end::Container-->
                </div>
                <!--end::Entry-->
            </div>
            <!--end::Content-->

            <Footer></Footer>

        </div>
    </div>

</div>
</template>

<script>
import axios from 'axios'
import MobileHeader from '../../components/MobileHeader'
import Header from '../../components/Header'
import Footer from '../../components/Footer'

export default {
    name: 'Home',
    components: {
        Header,
        Footer,
        MobileHeader,

    },
    data() {
        return {
          uid: localStorage.getItem('uid'),
          role: localStorage.getItem('role'),
          getEnrollData: [],
          getCourse:[],
          getMyTodo:[],
          getMyTask:[],
        }
    },
    mounted() {
        window.scrollTo(0, 0)
        this.getEnroll();
        this.getLetestCourse();
        this.getMyTodoData();
        this.getMyTaskData();
    },
    methods: {
        getEnroll(){
             var ev = this;
            axios({
                method: "POST",
                url: this.$root.URL_ROOT + "lms_api.php",
                data: {
                    type: "getLetestEnroll",
                    
                },
            }).then(function (response) {
              var data=response.data;
              ev.getEnrollData=data;
              //console.log(data);
            })
        },
        getLetestCourse(){
             var ev = this;
            axios({
                method: "POST",
                url: this.$root.URL_ROOT + "lms_api.php",
                data: {
                    type: "getLetestCourse",
                    
                },
            }).then(function (response) {
              var data=response.data;
              ev.getCourse=data;
              //console.log(data);
            })
        },
        getMyTodoData(){
             var ev = this;
            axios({
                method: "POST",
                url: this.$root.URL_ROOT + "lms_api.php",
                data: {
                    type: "getSchedule",
                    uid:this.uid,
                    stype:'todo'
                    
                },
            }).then(function (response) {
              var data=response.data;
              ev.getMyTodo=data;
              //console.log(data);
            })
        },
        getMyTaskData(){
             var ev = this;
            axios({
                method: "POST",
                url: this.$root.URL_ROOT + "lms_api.php",
                data: {
                    type: "getSchedule",
                    uid:this.uid,
                    stype:'task'
                    
                },
            }).then(function (response) {
              var data=response.data;
              ev.getMyTask=data;
              //console.log(data);
            })
        },



    }
}
</script>
