<template>
<div name="student">

    <MobileHeader></MobileHeader>
    <div class="d-flex flex-row flex-column-fluid page">
        <div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">

            <Header></Header>

            <!--begin::Content-->
            <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
                <!--begin::Entry-->
                <div class="d-flex flex-column-fluid">
                    <!--begin::Container-->
                    <!--begin::Dashboard-->

                    <div class="container">
                        <div class="card card-custom" style="width:-webkit-fill-available;">
                            <div class="card-body p-0">
                                <div id="loading"></div>
                                <!--begin: Wizard-->
                                <div class="wizard wizard-2" id="kt_wizard" data-wizard-state="first" data-wizard-clickable="true">
                                    <!--begin: Wizard Nav-->
                                    <div class="wizard-nav border-right py-8 px-8 px-lg-10">
                                        <div class="card course_card mb-2">
                                            <div class="cards mt-3">
                                                <h3 class="ml-3 corse_nm">{{course_name_show}}</h3>
                                            </div>
                                        </div>
                                        <template v-for="(items,index) in allChapter">
                                            <div :key="index" class="wizard-steps">
                                                <div class="accordion accordion-toggle-arrow" id="accordionExample1">
                                                    <!--begin::Wizard Step 1 Nav-->

                                                    <div class="card">
                                                        <div class="card-header">
                                                            <div class="card-title" data-toggle="collapse" data-target="#collapseOne11" @click="getList2(items.chapter_id);open_drop(items.chapter_id);">
                                                                {{items.chapter_name}}
                                                            </div>
                                                        </div>
                                                        <div id="collapseOne11" :class="open_val==items.chapter_id?'collapse show':'collapse'" data-parent="#accordionExample1">
                                                            <div class="card-body">
                                                                <template v-for="(ldata,index) in list2">
                                                                    <div :key="ldata.id" class="wizard-step mb-2" @click="getLessonById(ldata.id);getDatas(index+1)" data-wizard-type="step" data-wizard-state="current">
                                                                        <div class="wizard-label">

                                                                            <div class="row">
                                                                                <div class="col-4">
                                                                                    <img src="../../assets/icons/text_icon.svg" v-if="ldata.type=='text'" class="imc">
                                                                                    <img src="../../assets/icons/pdf_icon.svg" v-if="ldata.type=='pdf'" class="imc">
                                                                                    <img src="../../assets/icons/video_icon.svg" v-if="ldata.type=='video'" class="imc">
                                                                                    <img src="../../assets/icons/audio_icon.svg" v-if="ldata.type=='audio'" class="imc">

                                                                                </div>
                                                                                <div class="col-8">
                                                                                    <h3 class="wizard-title">{{ldata.title}}</h3>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </template>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                    <!--end: Wizard Nav-->
                                    <!--begin: Wizard Body-->
                                    <div class="wizard-body py-8 px-8 px-lg-10">
                                        <!--begin: Wizard Form-->
                                        <div class="row">
                                            <!----offset-xxl-2-->
                                            <div class=" col-xxl-12">
                                                <div class="main-content container" v-if="noData">
                                                    <div class="lesson-title">
                                                        <h2 class="title-lesson">{{newAry.title}}</h2>
                                                    </div>
                                                    <div class="view-data">
                                                        <div v-if="newAry.type=='text'" v-html="newAry.content"></div>
                                                        <div v-if="newAry.type=='pdf'">
                                                            <iframe class="pdf-view" :src="$root.URL_IMAGES+'pdf/'+newAry.pdf" type="application/pdf" data="[ImageURLHere]#zoom=65%&scrollbar=0&toolbar=0&navpanes=0"></iframe>
                                                        </div>

                                                        <div v-if="newAry.type=='audio'">

                                                            <av-circle :audio-src="$root.URL_IMAGES+'audio/'+newAry.audio" :symmetric="true" :outline-width="0" :progress-width="5" :outline-meter-space="5" :playtime="true" playtime-font="18px Monaco">
                                                            </av-circle>

                                                        </div>

                                                        <div v-if="newAry.type=='video'">
                                                            <iframe id="iframe" v-if="newAry.video_type=='local'" :src="'https://player.vimeo.com/video/'+newAry.video+'?badge=0&amp;autopause=0&amp;player_id=0&amp;#toolbar=0'" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="width:100%;height:400px;" :title="newAry.title"></iframe>
                                                            <iframe id="iframe" v-if="newAry.video_type=='loom'" :src="newAry.video+'#toolbar=0'" frameborder="0" allow="autoplay; fullscreen;" allowfullscreen style="width:100%;height:400px;" :title="newAry.title"></iframe>
                                                        </div>
                                                    </div>
                                                    <div class="btn-section" v-if="role==2">
                                                        <div class="row">
                                                            <div class="col-6">
                                                                <button v-if="show_btn_pre==true" @click="getDatas(current-1)" class="btn btn-light-primary font-weight-bolder text-uppercase px-9 py-4 previous">
                                                                    Previous
                                                                </button>
                                                            </div>
                                                            <div class="col-6">
                                                                <button class="btn btn-info complete-btn" v-if="show_btn_next==true" @click="save_complete(newAry.id,newAry.chapter_id);getDatas(current+1);">
                                                                    Complete or Next
                                                                </button>

                                                                <button class="btn btn-info complete-btn" v-if="show_last==true" @click="save_complete(newAry.id,newAry.chapter_id)">
                                                                    Complete
                                                                </button>

                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div class="main-content container" v-else>
                                                    <div class="center-msg">
                                                        <h2>Please Select Lesson You Not Selected Any Lesson </h2>
                                                    </div>
                                                </div>
                                            </div>
                                            <!--end: Wizard-->
                                        </div>
                                        <!--end: Wizard Form-->
                                    </div>
                                    <!--end: Wizard Body-->
                                </div>
                                <!--end: Wizard-->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
</template>

<script>
import axios from 'axios'
import MobileHeader from '../../components/MobileHeader'
import Header from '../../components/Header'
import Footer from '../../components/Footer'

export default {
    name: 'Task',
    components: {
        Header,
        Footer,
        MobileHeader,

    },
    data() {
        return {
            uid: localStorage.getItem("uid"),
            role: localStorage.getItem("role"),
            chapter: [],
            course: [],
            lesson: [],
            total: 0,
            current: 1,
            show_btn_next: false,
            show_btn_pre: false,
            mainData: [],
            //newAry: [],
            course_details: [],
            show_last: false,
            show_drop: false,

            course_data: [],
            course_name_show: null,
            allChapter: [],
            newAry: [],
            totalCount: null,
            list2: [],
            open_val: null,
            noData: false,
           

        }
    },
    mounted() {
        if (this.uid == null || this.uid == "") {
            this.$router.push("/");
        }
        //this.getMyCourse();
        this.getAllChapter();
        this.getLesson();
        

    },
    watch: {
        newAry: function (vl) {
            this.newAry = vl;
        }
    },
    methods: {
        getAllChapter() {
            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'lms_api.php',
                data: {
                    type: 'getAllChapter',
                    cid: this.$route.params.id,

                }
            }).then(function (response) {
                var data = response.data;
                console.log(data);

                ev.course_data = data.course;
                ev.course_name_show = data.course.course_name;
                //console.log(ev.course_data)

                ev.allChapter = data.chapter;
                //ev.list = data.chapter;

            })

        },
        getLesson() {
            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'lms_api.php',
                data: {
                    type: 'getLesson',
                    cid: this.$route.params.id,
                }
            }).then(function (response) {
                //console.log(response.data)
                var data = response.data;
                ev.course_data = data.data;
                // ev.newAry = data.data;
                ev.totalCount = data.count;
                // console.log(ev.newAry)
                if (ev.totalCount > 0) {
                    //var ids = ev.course_data[0].id
                    // ev.getLessonById(ids, 0);
                }
                //ev.changePage();

            })

        },
        getList2(ids) {

            var ev = this;
            ev.list2 = null;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'lms_api.php',
                data: {
                    type: 'getList2',
                    chapter_id: ids,
                }
            }).then(function (response) {
                var data = response.data;
                //console.log(data);
                ev.list2 = data
            })
        },
        
        open_drop(ids) {
            if (this.show_drop == false) {
                this.show_drop = true;
            } else {
                this.show_drop = false;
            }
            if (this.open_val == ids) {
                this.open_val = null;
            } else {
                this.open_val = ids;
            }
        },
        getMyCourse() {
            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'lms_api.php',
                data: {
                    type: 'getMyCourse',
                    sid: this.$route.params.id,
                    uid: this.uid

                }
            }).then(function (response) {
                //console.log(response.data);
                var data = response.data;
                //ev.course_data = data;
                ev.chapter = data.chapter;
                ev.lesson = data.lesson;
                ev.course = data.course;
                ev.total = ev.lesson.length;
                if (ev.total > 0) {
                    if (ev.total == 1) {
                        ev.show_btn_next = false;
                        ev.show_btn_pre = false;
                        ev.mainData = data.lesson;
                        ev.current = 1;
                        ev.getDatas(1);
                    } else if (ev.total > 1) {
                        ev.show_btn_next = true;
                        ev.mainData = data.lesson;
                        ev.current = 1;
                        ev.getDatas(1);
                    }
                } else {
                    ev.show_btn_next = false;
                    ev.show_btn_pre = false;
                    ev.current = 0;

                }
            })
        },
        getLessonById(ids) {
            var ev = this;
            ev.noData = false;
            ev.newAry = [];
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'lms_api.php',
                data: {
                    type: 'getLessonById',
                    lid: ids
                }
            }).then(function (response) {
                //console.log(response.data);
                var data = response.data;
                ev.newAry = data;
                ev.noData = true;
            })
        },
        getDatas(order) {
            //console.log(order);
            if (this.total > order) {
                this.show_btn_next = true;
                if (order > 1) {
                    this.show_btn_pre = true;
                } else if (order == 1) {
                    this.show_btn_pre = false;
                }

            } else if (order == 1 && this.total > order) {
                //console.log("success");
                this.show_btn_pre = false;
                this.show_btn_next = true;
                this.show_last = false;
            } else if (this.total == 1) {
                this.show_btn_pre = false;
                this.show_btn_next = false;
                this.show_last = true;
            } else if (this.total == order) {
                this.show_btn_pre = true;
                this.show_btn_next = false;
                this.show_last = true;
            } else {
                this.show_btn_pre = false;
                this.show_btn_next = false;
                this.show_last = false;
            }
            this.newAry = this.mainData[(order - 1)];
            this.current = order;
            //console.log("test");
            //console.log(this.mainData);
            //console.log(this.newAry)
        },
        save_complete(ids, cid) {
            var ev = this;
            //console.log(this.current)
            //console.log(this.total)
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'lms_api.php',
                data: {
                    type: 'save_complete',
                    lid: ids,
                    uid: this.uid,
                    chapter_id: cid
                }
            }).then(function (response) {
                //console.log(response.data);
                var data = response.data;
                if (ev.total == ev.current) {
                    ev.$toasted.global.success({
                        message: 'successfully completed this course'
                    });
                }
                //console.log(ev.total);
            })
        },

    },
}
</script>

<style scoped>
@media only screen and (max-width: 600px) {
    .no-record {
        font-size: 16px !important;
    }
}
.course_card{
    background-color: #3798fb;
    color: #fff;
    text-align: center;
}
.corse_nm{
    text-transform: capitalize;
}
.center-msg {
    text-align: center;
    padding: 50px 10px 10px 10px;
}

.btn-section {
    margin-top: 20px;
    padding: 20px;
    border-top: 2px solid #d9d9da;
}

.complete-btn {
    background-color: #3898fb;
    border: unset;
    float: right;
}

.view-data {
    padding: 25px;
    box-shadow: 0px 0px 40px 10px #ccc;
    border-radius: 10px;

}

.lesson-title {
    margin-bottom: 20px;
    box-shadow: 0px 8px 21px 1px #c5c5c5;
    padding: 10px;
    border-radius: 10px;
    color: #3898fb;
    font-weight: bold;

}

.title-lesson {
    margin-left: 10px;
}

.pdf-view {
    width: 100%;
    height: 600px;
}

.lesson-name {
    text-align: center;
    margin-bottom: 20px;
}

.imc {
    width: 30px;
}

.mrg-top-10 {
    margin-top: 10px;
}

.add-btn-s {
    background-color: #3798fb;
    border: none;
    margin: 1%;
}

.btn-top-table {
    display: inline-block;
    padding: 10px;
    width: 50%;
}

.table {
    width: 100%;

}

.table-dt {
    overflow: scroll;
}

.tbl-action {
    margin: 2px;
    border: unset;
}
</style>
