<template>
<div name="student">

    <MobileHeader></MobileHeader>
    <div class="d-flex flex-row flex-column-fluid page">
        <div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">

            <Header></Header>

            <!--begin::Content-->
            <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
                <!--begin::Entry-->
                <div class="d-flex flex-column-fluid">
                    <!--begin::Container-->
                    <!--begin::Dashboard-->

                    <div class="container">

                        <div class="card card-custom mt-5">
                            <!--begin::Header-->
                            <div class="card-header flex-wrap border-0 pt-6 pb-0">
                                <div class="card-title">
                                    <h3 class="card-label">Notification's
                                        <span class="d-block text-muted pt-2 font-size-sm">Notification's</span></h3>
                                </div>
                                <div class="card-toolbar">
                                    <!--begin::Search Form-->
                                    
                                    <!--end::Search Form-->
                                </div>
                            </div>
                            <!--end::Header-->
                            <div class="card-body">
                                <!--begin: Datatable-->
                                <div id="kt_datatable_21_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <div class="table table-separate table-head-custom table-checkable dataTable no-footer dtr-inline" id="kt_datatable_21" role="grid" aria-describedby="kt_datatable_21_info" style="width: 1232px;">
                                               
                                               <h2 class="sorting"  style="width: 759px;" >Notification</h2>
                                                   <template v-for="ndata in notification">
                                                        <div class="odd" :key="ndata.id">
                                                            <div class="row">
                                                                <div class="col-2">
                                                                    <img :src="$root.URL_IMAGES+'User/'+ndata.avatar" style="width: 80px;border-radius: 50%;" >
                                                                </div>
                                                                <div class="col-8">
                                                                    <h3>{{ndata.username}}</h3>
                                                                    <p class="ht">{{ndata.msg}}</p>
                                                                </div>
                                                                
                                                            </div>
                                                        </div>
                                                    </template>
                                               
                                            </div>
                                            <pagination v-model="page" :records="totalCount" :per-page="perPage" />
                                        </div>
                                    </div>
                                    
                                </div>
                                <!--end: Datatable-->
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
</template>

<script>
import axios from 'axios'
import MobileHeader from '../../components/MobileHeader'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import Pagination from 'vue-pagination-2';

export default {
    name: 'Todo',
    components: {
        Header,
        Footer,
        MobileHeader,
        Pagination,
    },
    data() {
        return {
            uid: localStorage.getItem("uid"),
            role: localStorage.getItem("role"),
            allData: [],
            notification: [],
            open_edit_box: false,
            title: null,
            vid: null,
             page: 1,
            totalCount: 0,
            newAry: [],
            perPage: 15,
           
        }
    },
    mounted() {
        if (this.uid == null || this.uid == "") {
            this.$router.push("/");
        }
        this.getNotification();

    },
    watch:{
         page: function (vl) {
            this.changePage();
        },
    },
    methods: {
        getNotification() {
            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'lms_api.php',
                data: {
                    type: 'getNotification',
                    uid: this.uid,
                }
            }).then(function (response) {
                var data = response.data;
                ev.notification = data.data;
                console.log(data);
                 ev.newAry = data.data;
                ev.totalCount = data.count;
                ev.changePage();
            })
        },
        changePage() {
            this.notification = [];
            var preCount = (this.perPage * this.page);

            var temp = this.perPage;
            var newPP = (preCount) - temp;
            //console.log(newPP);
            for (var i = 0; i < temp; i++) {
                if (this.newAry[newPP]) {
                    this.notification[i] = this.newAry[newPP];
                    newPP++;
                }

            }
        },
        
        
    },
}
</script>

<style scoped>
.odd{
    background-color: #f2f2f7;
    margin-bottom: 10px;
    padding: 10px;
}
.ht{
    padding-left: 10px;
}
.add-btn-s {
    background-color: #3798fb;
    border: none;
    margin: 1%;
}

.btn-top-table {
    display: inline-block;
    padding: 10px;
    width: 50%;
}

.table {
    width: 100%;

}

.table-dt {
    overflow: scroll;
}

.tbl-action {
    margin: 2px;
    border: unset;
}
</style>
