<template>
<div id="kt_body" class="header-fixed header-mobile-fixed header-bottom-enabled subheader-enabled page-loading">

    <!--begin::Main-->
    <div class="d-flex flex-column flex-root">
        <!--begin::Login-->
        <div class="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white login-div-height" id="kt_login">
            <!--begin::Aside assets/media/bg/bg-4.jpg   -->
            <div class="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10 login-bg">
                <!--begin: Aside Container-->
                <div class="d-flex flex-row-fluid flex-column justify-content-between">
                    <!--begin: Aside header-->
                    <a href="#" class="flex-column-auto mt-5 pb-lg-0 pb-10">
                        <!--<img src="../assets/media/logos/ip_logo.png" class="max-h-70px" alt="" />-->
                        <span class="logo-heading">IMPACT SCHOOL</span>
                    </a>
                    <!--end: Aside header-->
                    <!--begin: Aside content-->
                    <div class="flex-column-fluid d-flex flex-column justify-content-center">
                        <h3 class="font-size-h1 mb-5 text-white">Welcome to IMPACT SCHOOL!</h3>
                        <p class="font-weight-lighter text-white opacity-80">The ultimate Bootstrap, Angular 8, React &amp; VueJS admin theme framework for next generation web apps.</p>
                    </div>
                    <!--end: Aside content-->
                    <!--begin: Aside footer for desktop-->
                    <div class="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
                        <div class="opacity-70 font-weight-bold text-white">© 2021 Metronic</div>
                        <div class="d-flex hide">
                            <a href="#" class="text-white">Privacy</a>
                            <a href="#" class="text-white ml-10">Legal</a>
                            <a href="#" class="text-white ml-10">Contact</a>
                        </div>
                    </div>
                    <!--end: Aside footer for desktop-->
                </div>
                <!--end: Aside Container-->
            </div>
            <!--begin::Aside-->
            <!--begin::Content-->
            <div class="d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden">
                <!--begin::Content header-->
                <div class=" hide position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
                    <span class="font-weight-bold text-dark-50">Dont have an account yet?</span>
                    <a href="javascript:;" class="font-weight-bold ml-2" id="kt_login_signup">Sign Up!</a>
                </div>
                <!--end::Content header-->
                <!--begin::Content body-->
                <div class="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
                    <!--begin::Signin-->
                    <div class="login-form login-signin">
                        <div class="text-center mb-10 mb-lg-20">
                            <h3 class="font-size-h1">Sign In Super Admin</h3>
                            <p class="text-muted font-weight-bold">Enter your username and password</p>
                        </div>
                        <!--begin::Form-->
                        <div class="form-group">
                            <input class="form-control form-control-solid h-auto py-5 px-6" v-model="username" type="text" placeholder="Username" name="username" autocomplete="off" />
                        </div>
                        <div class="form-group">
                            <input class="form-control form-control-solid h-auto py-5 px-6" v-model="password" type="password" placeholder="Password" name="password" autocomplete="off" />
                        </div>
                        <!--begin::Action-->
                        <div class="form-group d-flex flex-wrap justify-content-between align-items-center">
                            <a href="javascript:;" class="text-dark-50 text-hover-primary my-3 mr-2" id="kt_login_forgot">Forgot Password ?</a>
                            <button type="button" id="kt_login_signin_submit" @click="login" class="btn btn-primary font-weight-bold px-9 py-4 my-3">Sign In</button>
                        </div>
                        <!--end::Action-->

                        <!--end::Form-->
                    </div>
                    <!--end::Signin-->
                    <!--begin::Signup-->
                    <div class="login-form login-signup">
                        <div class="text-center mb-10 mb-lg-20">
                            <h3 class="font-size-h1">Sign Up</h3>
                            <p class="text-muted font-weight-bold">Enter your details to create your account</p>
                        </div>
                        <!--begin::Form-->
                        <form class="form" novalidate="novalidate" id="kt_login_signup_form">
                            <div class="form-group">
                                <input class="form-control form-control-solid h-auto py-5 px-6" type="text" placeholder="Fullname" name="fullname" autocomplete="off" />
                            </div>
                            <div class="form-group">
                                <input class="form-control form-control-solid h-auto py-5 px-6" type="email" placeholder="Email" name="email" autocomplete="off" />
                            </div>
                            <div class="form-group">
                                <input class="form-control form-control-solid h-auto py-5 px-6" type="password" placeholder="Password" name="password" autocomplete="off" />
                            </div>
                            <div class="form-group">
                                <input class="form-control form-control-solid h-auto py-5 px-6" type="password" placeholder="Confirm password" name="cpassword" autocomplete="off" />
                            </div>
                            <div class="form-group">
                                <label class="checkbox mb-0">
                                    <input type="checkbox" name="agree" />
                                    <span></span>I Agree the
                                    <a href="#">terms and conditions</a></label>
                            </div>
                            <div class="form-group d-flex flex-wrap flex-center">
                                <button type="button" id="kt_login_signup_submit" class="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4">Submit</button>
                                <button type="button" id="kt_login_signup_cancel" class="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4">Cancel</button>
                            </div>
                        </form>
                        <!--end::Form-->
                    </div>
                    <!--end::Signup-->
                    <!--begin::Forgot-->
                    <div class="login-form login-forgot">
                        <div class="text-center mb-10 mb-lg-20">
                            <h3 class="font-size-h1">Forgotten Password ?</h3>
                            <p class="text-muted font-weight-bold">Enter your email to reset your password</p>
                        </div>
                        <!--begin::Form-->
                        <form class="form" novalidate="novalidate" id="kt_login_forgot_form">
                            <div class="form-group">
                                <input class="form-control form-control-solid h-auto py-5 px-6" type="email" placeholder="Email" name="email" autocomplete="off" />
                            </div>
                            <div class="form-group d-flex flex-wrap flex-center">
                                <button type="button" id="kt_login_forgot_submit" class="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4">Submit</button>
                                <button type="button" id="kt_login_forgot_cancel" class="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4">Cancel</button>
                            </div>
                        </form>
                        <!--end::Form-->
                    </div>
                    <!--end::Forgot-->
                </div>
                <!--end::Content body-->
                <!--begin::Content footer for mobile-->
                <div class="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
                    <div class="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">© 2021 Metronic</div>
                    <div class="d-flex order-1 order-sm-2 my-2 hide">
                        <a href="#" class="text-dark-75 text-hover-primary">Privacy</a>
                        <a href="#" class="text-dark-75 text-hover-primary ml-4">Legal</a>
                        <a href="#" class="text-dark-75 text-hover-primary ml-4">Contact</a>
                    </div>
                </div>
                <!--end::Content footer for mobile-->
            </div>
            <!--end::Content-->
        </div>
        <!--end::Login-->
    </div>
    <!--end::Main-->

</div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'AdminLogin',
    components: {

    },
    data() {
        return {
            username: null,
            password: null,
            uid: localStorage.getItem("uid"),
            role: localStorage.getItem("role"),
        }
    },
    mounted() {
        //console.log(this.uid)
        if (this.uid != null || this.uid != "") {
            if (this.role == 10) {
                this.$router.push('/dashboard');
            }
        }
    },
    methods: {
        login() {
            var ev = this;
            if (this.username == "" || this.username == null) {
                this.$toasted.global.error({
                    message: 'please enter username'
                });
            } else if (this.password == "" || this.password == null) {
                this.$toasted.global.error({
                    message: 'please enter password'
                });
            } else {
                axios({
                    method: 'POST',
                    url: this.$root.URL_ROOT + 'lms_api.php',
                    data: {
                        type: 'adminlogin',
                        username: this.username,
                        password: this.password,
                    }
                }).then(function (response) {
                    //console.log(response.data);
                    var data = response.data;
                    ev.userdata = data.session_data;
                    if (data.status == 'success') {
                        ev.$toasted.global.success({
                            message: data.msg
                        });
                        localStorage.setItem('username', ev.userdata.username);
                        localStorage.setItem('uid', ev.userdata.uid);
                        localStorage.setItem('mobile', ev.userdata.mobile);
                         localStorage.setItem('role', ev.userdata.role);
                        //localStorage.setItem('usertype', ev.userdata.usertype);
                        ev.$router.push('/admindashboard');
                        //console.log(localStorage.getItem("uid"));
                    } else {
                        ev.$toasted.global.error({
                            message: data.msg
                        });
                    }

                })
            }

        }
    }
}
</script>
