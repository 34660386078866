<template>
<div name="student">

    <MobileHeader></MobileHeader>
    <div class="d-flex flex-row flex-column-fluid page">
        <div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">

            <Header></Header>

            <!--begin::Content-->
            <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
                <!--begin::Entry-->
                <div class="d-flex flex-column-fluid">
                    <!--begin::Container-->
                    <!--begin::Dashboard-->

                    <div class="container">
                        <div class="card card-custom mt-5">
                            <div class="card-body">
                                <!--begin::Search Form-->
                                <div class="mb-7">
                                    <div class="row align-items-center">
                                        <div class="col-lg-10 col-xl-10">
                                            <div class="row align-items-center">

                                                <div class="col-md-8 my-2 my-md-0">
                                                    <div class="align-items-center form-group">
                                                        <label class="mr-3 mb-0 d-none d-md-block">KPI</label>
                                                        <div class="">
                                                            <select class="form-control" v-model="kpi">
                                                                <option value="">Select KPI</option>
                                                                <template v-for="adata in allData">
                                                                    <option :key="adata.id" :value="adata.id">{{adata.kpi_name}}</option>
                                                                </template>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-4 my-2 my-md-0">
                                                    <div class="form-group">
                                                        <label>Expiry Date</label>
                                                        <div class="input-group date">
                                                            <input type="date" class="form-control" v-model="exdate">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-2 col-xl-2 mt-5 mt-lg-0">
                                            <input type="submit" name="assign" value="Assign" class="btn btn-light-primary px-6 font-weight-bold" @click="assign_kpi">
                                        </div>
                                    </div>
                                </div>
                                <!--end: Search Form-->
                                <!--begin: Datatable-->
                                <div class="checkbox-inline" style="margin-bottom:18px;">
                                    <label class="checkbox checkbox-rounded">
                                        <input type="checkbox" class="checkbox2" name="employee" id="select_all" @click="selectAll" v-model="allSelected">
                                        <span></span> Select All
                                    </label>
                                </div>
                                <div id="example_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">

                                    <div class="row">
                                        <template v-for="sdata in studentData">
                                            <div class="col-3" :key="sdata.id">
                                                <div class="checkbox-inline">
                                                    <label class="checkbox checkbox-rounded">
                                                        <input type="checkbox" class="checkbox2" v-model="userIds" @click="select" :value="sdata.id">
                                                        <span></span>
                                                    </label>
                                                    <h3>{{sdata.username}}</h3>
                                                </div>
                                            </div>
                                        </template>
                                    </div>

                                </div>
                                <!--end: Datatable-->

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
</template>

<script>
import axios from 'axios'
import MobileHeader from '../../components/MobileHeader'
import Header from '../../components/Header'
import Footer from '../../components/Footer'

export default {
    name: 'Sops',
    components: {
        Header,
        Footer,
        MobileHeader,

    },
    data() {
        return {
            uid: localStorage.getItem("uid"),
            role: localStorage.getItem("role"),
            allData: [],
            studentData: [],
            selected: [],
            allSelected: false,
            userIds: [],
            sops: "",
            exdate: null,
            kpi: "",
        }
    },
    mounted() {
        if (this.uid == null || this.uid == "") {
            this.$router.push("/");
        }
        this.getAllKpi();
        this.getAllStudent();
    },
    methods: {
        selectAll: function () {
            var user = [];
            this.userIds = [];

            for (user in this.studentData) {
                this.userIds.push(this.studentData[user].id.toString());
            }
            //console.log(this.userIds);
        },
        select: function () {
            this.allSelected = false;
        },
        getAllKpi() {
            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'lms_api.php',
                data: {
                    type: 'getKpisData',
                }
            }).then(function (response) {
                var data = response.data;
                ev.allData = data;
            })
        },
        getAllStudent() {
            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'lms_api.php',
                data: {
                    type: 'allStudentSops',
                }
            }).then(function (response) {
                var data = response.data;
                ev.studentData = data;

            })
        },
        assign_kpi() {
            var ev = this;

            if (this.kpi == "" || this.kpi == null) {
                this.$toasted.global.error({
                    message: "Please select KPI"
                });
            } else if (this.exdate == "" || this.exdate == null) {
                this.$toasted.global.error({
                    message: "Please select expiry"
                });
            } else if (this.userIds.length < 0 || this.userIds.length == 0) {
                this.$toasted.global.error({
                    message: "Please select user"
                })
            } else {

                axios({
                    method: 'POST',
                    url: this.$root.URL_ROOT + 'lms_api.php',
                    data: {
                        type: 'assign_kpi',
                        uid:this.uid,
                        sops: this.sops,
                        exdate: this.exdate,
                        userIds: this.userIds,
                    }
                }).then(function (response) {
                    var data = response.data;
                    //console.log(data);
                    if(data.status=="success"){
                        ev.$toasted.global.success({
                            message: data.msg
                        });
                        ev.$router.push('/viewassignsops');
                    }else{
                        ev.$toasted.global.error({
                            message: data.msg
                        });
                    }
                })
            }
        },

    },
}
</script>

<style scoped>
.pull-right {
    float: right;
}

.mouse-point {
    cursor: pointer;
}

.add-btn-s {
    background-color: #3798fb;
    border: none;
    margin: 1%;
}

.btn-top-table {
    display: inline-block;
    padding: 10px;
    width: 50%;
}

.table {
    width: 100%;

}

.table-dt {
    overflow: scroll;
}

.tbl-action {
    margin: 2px;
    border: unset;
}
</style>
