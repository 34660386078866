<template>
<div name="student">

    <MobileHeader></MobileHeader>
    <div class="d-flex flex-row flex-column-fluid page">
        <div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">

            <Header></Header>

            <!--begin::Content-->
            <div class="content d-flex flex-column flex-column-fluid" id="kt_content">

                <!--begin::Entry-->
                <div class="d-flex flex-column-fluid">
                    <!--begin::Container-->
                    <!--begin::Dashboard-->

                    <div class="container">
                        <div class="card card-custom mt-5">
                            <!--begin::Header-->
                            <div class="card-header flex-wrap border-0 pt-6 pb-0">
                                <div class="card-title">
                                    <h3 class="card-label">View Enrolled Course's
                                        <span class="d-block text-muted pt-2 font-size-sm">View Enrolled Course's</span></h3>
                                </div>
                                <div class="card-toolbar">
                                    <!--   <div class="input-icon">-->
                                    <!--	<input type="text" class="form-control" placeholder="Search..." id="kt_datatable_search_query" />-->
                                    <!--	<span>-->
                                    <!--		<i class="flaticon2-search-1 text-muted"></i>-->
                                    <!--	</span>-->
                                    <!--</div>-->
                                    
                                </div>
                            </div>
                            <!--end::Header-->
                            <div class="card-body">
                                <!--begin: Datatable-->
                                <div class="modal fade" id="staticBackdrop11" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div class="modal-dialog" role="document">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">Update Expiry Date</h5>
                                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                    <i aria-hidden="true" class="ki ki-close"></i>
                                                </button>
                                            </div>
                                            <form action="https://levelup-learning.in/ImpactSchool/public/SuperAdmin/UpdExDCourse/1" enctype="multipart/form-data" method="post"></form>
                                            <input type="hidden" name="_token" value="ySn4M9llM4m23Uv6gFMEmDeABaAIWogc503cOI21">
                                            <div class="modal-body">
                                                <div class="form-group">
                                                    <label>Expiry Date</label>
                                                    <div class="input-group date">
                                                        <input type="text" name="expiry_date" class="form-control" id="kt_datepicker_1" placeholder="Select date">
                                                        <div class="input-group-append">
                                                            <span class="input-group-text">
                                                                <i class="la la-calendar-check-o"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="modal-footer">
                                                <button type="button" class="btn btn-light-primary font-weight-bold" data-dismiss="modal">Close</button>
                                                <button type="submit" class="btn btn-primary font-weight-bold">Update</button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div id="example_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                                    
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <table id="example" class="table dataTable no-footer" style="width: 100%;" role="grid" aria-describedby="example_info">
                                                <thead>
                                                    <tr role="row">
                                                        <th  class="sorting sorting_asc" rowspan="1" colspan="1" aria-sort="ascending"  style="width: 120px;">Name</th>
                                                        <th  class="sorting"  rowspan="1" colspan="1" style="width: 136px;">Course</th>
                                                        <!---<th  class="sorting"  rowspan="1" colspan="1" style="width: 136px;">Chapter</th>-->
                                                        <th  class="sorting"  rowspan="1" colspan="1" style="width: 289px;">Enrollment Status</th>
                                                        <th  class="sorting"  rowspan="1" colspan="1" style="width: 216px;">Assigned On</th>
                                                        <th  class="sorting"  rowspan="1" colspan="1" style="width: 188px;">Expired On</th>
                                                       
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    <template v-for="ndata in newAry" >
                                                        <tr class="odd" :key="ndata.id">
                                                            <td class="sorting_1">{{ndata.student_name}}</td>
                                                            <td>{{ndata.course_name}}</td>
                                                            <!---<td>{{ndata.chapter_name}}</td>--->
                                                            <td>
                                                                <p v-if="ndata.status==10" class="label label-danger label-pill label-inline mr-2" style="width:auto !important;">
                                                                     Expire
                                                                </p>
                                                                <p v-if="ndata.status==5" class="label label-danger yellow-bg label-pill label-inline mr-2" style="width:auto !important;">
                                                                     Going to expire
                                                                </p>
                                                                <p v-if="ndata.status==1" class="label label-danger green-bg label-pill label-inline mr-2" style="width:auto !important;">
                                                                     Active
                                                                </p>
                                                            </td>
                                                            <td>{{ndata.start_date}}</td>
                                                            <td>{{ndata.exdate}}</td>
                                                            
                                                        </tr>
                                                    </template>


                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    

                                </div>
                                <!--end: Datatable-->
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>

</div>
</template>

<script>
import axios from 'axios'
import MobileHeader from '../../components/MobileHeader'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import Pagination from 'vue-pagination-2';


export default {
    name: 'Student',
    components: {
        Header,
        Footer,
        MobileHeader,
        Pagination,
       

    },
    data() {
        return {
            uid: localStorage.getItem("uid"),
            role: localStorage.getItem("role"),
            pop_val: false,
            edit_pop_val: false,
            course_name: null,
            edit_course_name: null,
            edit_cid: null,
            course_data: [],
            page: 1,
            totalCount: 0,
            newAry: [],
            perPage: 5,
        }
    },
    mounted() {
        if (this.uid == null || this.uid == "") {
            this.$router.push("/");
        }
        //this.allCourse();
        this.allCourse_new();
    },
    watch: {
        page: function (vl) {
            this.changePage();
        }
    },
    methods: {
        changePage() {
            this.course_data = [];
            var preCount = (this.perPage * this.page);

            var temp = this.perPage;
            var newPP = (preCount) - temp;
            //console.log(newPP);
            for (var i = 0; i < temp; i++) {
                if (this.newAry[newPP]) {
                    this.course_data[i] = this.newAry[newPP];
                    newPP++;
                }

            }
        },
        
        allCourse() {
            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'lms_api.php',
                data: {
                    type: 'allEnrollData',
                    uid: this.uid,
                    role: this.role,
                }
            }).then(function (response) {
                //console.log(response.data)
                var data = response.data;
                ev.course_data = data.data;
                ev.newAry = data.data;
                ev.totalCount = data.count;
                ev.changePage();

            })
        },
        
        allCourse_new() {
            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'lms_api.php',
                data: {
                    type: 'enrollment_course_by_course',
                    cid:this.$route.params.id,
                }
            }).then(function (response) {
                console.log("new");
                console.log(response.data)
                var data = response.data;
                ev.course_data = data.data;
                ev.newAry = data.data;
                ev.totalCount = data.count;
                ev.changePage();


            })
        },
    },
}
</script>

<style scoped>
@media only screen and (max-width: 600px) {

    .main-popup-overlay {
        padding-top: 30% !important;
        padding-left: 5% !important;
        padding-right: 5% !important;
    }
}

.btn-popup-cancel {
    float: right;
    width: 120px;
}

.main-popup {
    position: fixed;
    width: 100%;
    padding: 20%;
}

.popup-header {
    text-align: center;
}

.btn-div-popup {
    padding-top: 5%;

}

.btn-popup-save {
    float: left;
    width: 120px;

}

.content-start-popup {

    background: #ffffff;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 10%;
    padding-bottom: 10%;
    border-radius: 15px;
}

.main-popup-overlay {
    position: fixed;
    width: 100%;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: #0404046e;
    padding-top: 20%;
    padding-right: 30%;
    padding-left: 30%;
    z-index: 999999999;
}

.btn-top-table {
    display: inline-block;
    padding: 10px;
    width: 50%;
}

.table {
    width: 100%;

}

.table-dt {
    overflow: scroll;
}

.tbl-action {
    margin: 2px;
    border: unset;
}
</style>
