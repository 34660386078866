<template>
<div name="student">

    <MobileHeader></MobileHeader>
    <div class="d-flex flex-row flex-column-fluid page">
        <div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">

            <Header></Header>

            <!--begin::Content-->
            <div class="content d-flex flex-column flex-column-fluid" id="kt_content">

                <!--begin::Entry-->
                <div class="d-flex flex-column-fluid">
                    <!--begin::Container-->
                    <!--begin::Dashboard-->

                    <div class="container">
                        <div class="card card-custom mt-5">
                            <div class="card-body">
                                <div class="heading-area">
                                    <h1 class="course-name">{{course_details.course_name}}</h1>
                                </div>
                                <div class="subheading">
                                    <h3>Total Lesson : {{total}}</h3>
                                </div>
                                <div class="lesson-areas">
                                    <div class="back-next-btn">
                                        <img class="pull-left btn-icon" v-if="show_btn_pre==true" @click="getDatas(current-1)" src="../../assets/icons/previous.svg">
                                        <img class="pull-right btn-icon" v-if="show_btn_next==true" @click="getDatas(current+1)" src="../../assets/icons/next.svg">
                                    </div>

                                    <div class="content-data">
                                        <div class="lesson-name">
                                            <h1>Lesson : {{current}}</h1>
                                            
                                        </div>

                                        <div class="main-content container">
                                            <div class="lesson-title">
                                                <h2 class="title-lesson">{{newAry.title}}</h2>
                                            </div>

                                            <div v-if="newAry.type=='text'" v-html="newAry.content"></div>
                                            <div v-if="newAry.type=='pdf'">
                                                <iframe class="pdf-view" :src="$root.URL_IMAGES+'pdf/'+newAry.pdf" type="application/pdf" data="[ImageURLHere]#zoom=65%&scrollbar=0&toolbar=0&navpanes=0"></iframe>
                                            </div>

                                            <div v-if="newAry.type=='audio'">

                                                <av-circle :audio-src="$root.URL_IMAGES+'audio/'+newAry.audio" :symmetric="true" :outline-width="0" :progress-width="5" :outline-meter-space="5" :playtime="true" playtime-font="18px Monaco">
                                                </av-circle>

                                            </div>

                                            <div v-if="newAry.type=='video'">
                                                <iframe v-if="newAry.video_type=='local'" :src="'https://player.vimeo.com/video/'+newAry.video+'?badge=0&amp;autopause=0&amp;player_id=0&amp;'" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="width:100%;height:400px;" :title="newAry.title"></iframe>
                                                <iframe v-if="newAry.video_type=='loom'" :src="newAry.video" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="width:100%;height:400px;" :title="newAry.title"></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>

</div>
</template>

<script>
import axios from 'axios'
import MobileHeader from '../../components/MobileHeader'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import AudioVisual from 'vue-audio-visual'
import Vue from 'vue'
Vue.use(AudioVisual)

export default {
    name: 'PreviewCourse',
    components: {
        Header,
        Footer,
        MobileHeader,

    },
    data() {
        return {
            uid: localStorage.getItem("uid"),
            role: localStorage.getItem("role"),
            total: 0,
            current: 1,
            show_btn_next: false,
            show_btn_pre: false,
            mainData: [],
            newAry: [],
            course_details: [],
        }
    },
    mounted() {
        if (this.uid == null || this.uid == "") {
            this.$router.push("/");
        }
        // this.getLesson();
        this.getAllLesson();
    },
    watch: {

    },
    methods: {

        save_course() {
            var ev = this;

            if (this.course_name == null || this.course_name == "") {
                ev.$toasted.global.error({
                    message: "please enter course name"
                });
            } else {
                axios({
                    method: 'POST',
                    url: this.$root.URL_ROOT + 'lms_api.php',
                    data: {
                        type: 'course_save',
                        course_name: this.course_name,
                        uid: this.uid,
                        role: this.role,
                    }
                }).then(function (response) {
                    //console.log(response.data);
                    var data = response.data;
                    if (data.status == "success") {
                        ev.$toasted.global.success({
                            message: data.msg
                        });
                        ev.course_name = null;
                        ev.pop_val = false;
                        ev.allCourse();
                    } else {
                        ev.$toasted.global.error({
                            message: data.msg
                        });
                    }

                })
            }
        },
        getLesson() {
            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'lms_api.php',
                data: {
                    type: 'getLesson',
                    cid: this.$route.params.cid,
                }
            }).then(function (response) {
                console.log(response.data)
                var data = response.data;
                ev.course_data = data.data;
                //ev.changePage();
                ev.total = data.count;
                ev.current = 1;
                if (ev.total > 1) {
                    ev.show_btn = true;
                } else {
                    ev.show_btn = false;
                }

            })

        },
        getAllLesson() {
            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'lms_api.php',
                data: {
                    type: 'getPreviewLession',
                    cid: this.$route.params.cid,
                }
            }).then(function (response) {
                console.log(response.data)
                var data = response.data;
                ev.total = data.total;
                ev.course_details = data.course_details
                if (ev.total > 0) {
                    if (ev.total == 1) {
                        ev.show_btn_next = false;
                        ev.show_btn_pre = false;
                        ev.mainData = data.data;
                        ev.current = 1;
                        ev.getDatas(1);
                    } else if (ev.total > 1) {
                        ev.show_btn_next = true;
                        ev.mainData = data.data;
                        ev.current = 1;
                        ev.getDatas(1);
                    }
                } else {
                    ev.show_btn_next = false;
                    ev.show_btn_pre = false;
                    ev.current = 0;

                }

            })

        },
        getDatas(order) {
            //console.log(order);
            if (this.total > order) {
                this.show_btn_next = true;
                if (order > 1) {
                    this.show_btn_pre = true;
                } else if (order == 1) {
                    this.show_btn_pre = false;
                }

            } else if (order == 1 && this.total > order) {
                //console.log("success");
                this.show_btn_pre = false;
                this.show_btn_next = true;
            } else if(this.total==1){
                this.show_btn_pre = false;
                this.show_btn_next = false;
            } else if(this.total==order){
                this.show_btn_pre = true;
                this.show_btn_next = false;
            } else {
                this.show_btn_pre = false;
                this.show_btn_next = false;
            }
            this.newAry = this.mainData[(order - 1)];
            this.current = order;
            //console.log("test");
            //console.log(this.mainData);
            //console.log(this.newAry)
        },

    },
}
</script>

<style scoped>
@media only screen and (max-width: 600px) {
    .no-record {
        font-size: 16px !important;
    }
}

.title-lesson {
    margin-bottom: 20px;
    margin-left: 10px;
}

.pdf-view {
    width: 100%;
    height: 600px;
}

.lesson-name {
    text-align: center;
    margin-bottom: 20px;
}

.back-next-btn {
    width: 100%;
    height: 60px;
}

.pull-right {
    float: right;
}

.no-record {
    text-align: center;
    width: 100%;
    font-size: 22px;
    padding: 5%;
    font-weight: bold;
}

.heading-area {
    text-align: center;

}

.btn-icon {
    width: 50px;
    height: 50px;
    cursor: pointer;

}
</style>
