<template>
    <div>
     <MobileHeader></MobileHeader>
      <div class="d-flex flex-row flex-column-fluid page">
          <div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">

            <Header></Header>
             
              <!--begin::Content-->
              <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
                <!--begin::Entry-->
                <div class="d-flex flex-column-fluid">
                  <!--begin::Container-->
                  <div class="container">
                    <!--begin::Dashboard-->

                        <!--begin::Row 1-->
                        <div class="row">
                          <div class="col-xl-4">
                            <!--begin::Mixed Widget 2-->
                            <div class="card card-custom bg-gray-100 gutter-b card-stretch">
                              <!--begin::Header-->
                              <div class="card-header border-0 bg-primary py-5">
                                <h3 class="card-title font-weight-bolder text-white">Sales Stat</h3>
                                <div class="card-toolbar">
                                  <div class="dropdown dropdown-inline">
                                    <a href="#" class="btn btn-transparent-white btn-sm font-weight-bolder dropdown-toggle px-5" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Export</a>
                                    <div class="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                                      <!--begin::Navigation-->
                                      <ul class="navi navi-hover">
                                        <li class="navi-header pb-1">
                                          <span class="text-primary text-uppercase font-weight-bold font-size-sm">Add new:</span>
                                        </li>
                                        <li class="navi-item">
                                          <a href="#" class="navi-link">
                                            <span class="navi-icon">
                                              <i class="flaticon2-shopping-cart-1"></i>
                                            </span>
                                            <span class="navi-text">Order</span>
                                          </a>
                                        </li>
                                        <li class="navi-item">
                                          <a href="#" class="navi-link">
                                            <span class="navi-icon">
                                              <i class="flaticon2-calendar-8"></i>
                                            </span>
                                            <span class="navi-text">Event</span>
                                          </a>
                                        </li>
                                        <li class="navi-item">
                                          <a href="#" class="navi-link">
                                            <span class="navi-icon">
                                              <i class="flaticon2-graph-1"></i>
                                            </span>
                                            <span class="navi-text">Report</span>
                                          </a>
                                        </li>
                                        <li class="navi-item">
                                          <a href="#" class="navi-link">
                                            <span class="navi-icon">
                                              <i class="flaticon2-rocket-1"></i>
                                            </span>
                                            <span class="navi-text">Post</span>
                                          </a>
                                        </li>
                                        <li class="navi-item">
                                          <a href="#" class="navi-link">
                                            <span class="navi-icon">
                                              <i class="flaticon2-writing"></i>
                                            </span>
                                            <span class="navi-text">File</span>
                                          </a>
                                        </li>
                                      </ul>
                                      <!--end::Navigation-->
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <!--end::Header-->
                              <!--begin::Body-->
                              <div class="card-body p-0 position-relative overflow-hidden">
                                <!--begin::Chart-->
                                <div id="kt_mixed_widget_2_chart" class="card-rounded-bottom bg-primary" style="height: 200px"></div>
                                <!--end::Chart-->
                                <!--begin::Stats-->
                                <div class="card-spacer mt-n25">
                                  <!--begin::Row-->
                                  <div class="row m-0">
                                    <div class="col bg-white px-6 py-8 rounded-xl mr-7 mb-7">
                                      <span class="svg-icon svg-icon-3x svg-icon-info d-block my-2">
                                        <!--begin::Svg Icon | path:assets/media/svg/icons/Media/Equalizer.svg-->
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                            <rect x="0" y="0" width="24" height="24" />
                                            <rect fill="#000000" opacity="0.3" x="13" y="4" width="3" height="16" rx="1.5" />
                                            <rect fill="#000000" x="8" y="9" width="3" height="11" rx="1.5" />
                                            <rect fill="#000000" x="18" y="11" width="3" height="9" rx="1.5" />
                                            <rect fill="#000000" x="3" y="13" width="3" height="7" rx="1.5" />
                                          </g>
                                        </svg>
                                        <!--end::Svg Icon-->
                                      </span>
                                      <a href="#" class="text-info font-weight-bold font-size-h6">Weekly Sales</a>
                                    </div>
                                    <div class="col bg-white px-6 py-8 rounded-xl mb-7">
                                      <span class="svg-icon svg-icon-3x svg-icon-warning d-block my-2">
                                        <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Add-user.svg-->
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                            <polygon points="0 0 24 0 24 24 0 24" />
                                            <path d="M18,8 L16,8 C15.4477153,8 15,7.55228475 15,7 C15,6.44771525 15.4477153,6 16,6 L18,6 L18,4 C18,3.44771525 18.4477153,3 19,3 C19.5522847,3 20,3.44771525 20,4 L20,6 L22,6 C22.5522847,6 23,6.44771525 23,7 C23,7.55228475 22.5522847,8 22,8 L20,8 L20,10 C20,10.5522847 19.5522847,11 19,11 C18.4477153,11 18,10.5522847 18,10 L18,8 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" />
                                            <path d="M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z" fill="#000000" fill-rule="nonzero" />
                                          </g>
                                        </svg>
                                        <!--end::Svg Icon-->
                                      </span>
                                      <a href="#" class="text-warning font-weight-bold font-size-h6 mt-2">New Users</a>
                                    </div>
                                  </div>
                                  <!--end::Row-->
                                  <!--begin::Row-->
                                  <div class="row m-0">
                                    <div class="col bg-white px-6 py-8 rounded-xl mr-7">
                                      <span class="svg-icon svg-icon-3x svg-icon-danger d-block my-2">
                                        <!--begin::Svg Icon | path:assets/media/svg/icons/Design/Layers.svg-->
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                            <polygon points="0 0 24 0 24 24 0 24" />
                                            <path d="M12.9336061,16.072447 L19.36,10.9564761 L19.5181585,10.8312381 C20.1676248,10.3169571 20.2772143,9.3735535 19.7629333,8.72408713 C19.6917232,8.63415859 19.6104327,8.55269514 19.5206557,8.48129411 L12.9336854,3.24257445 C12.3871201,2.80788259 11.6128799,2.80788259 11.0663146,3.24257445 L4.47482784,8.48488609 C3.82645598,9.00054628 3.71887192,9.94418071 4.23453211,10.5925526 C4.30500305,10.6811601 4.38527899,10.7615046 4.47382636,10.8320511 L4.63,10.9564761 L11.0659024,16.0730648 C11.6126744,16.5077525 12.3871218,16.5074963 12.9336061,16.072447 Z" fill="#000000" fill-rule="nonzero" />
                                            <path d="M11.0563554,18.6706981 L5.33593024,14.122919 C4.94553994,13.8125559 4.37746707,13.8774308 4.06710397,14.2678211 C4.06471678,14.2708238 4.06234874,14.2738418 4.06,14.2768747 L4.06,14.2768747 C3.75257288,14.6738539 3.82516916,15.244888 4.22214834,15.5523151 C4.22358765,15.5534297 4.2250303,15.55454 4.22647627,15.555646 L11.0872776,20.8031356 C11.6250734,21.2144692 12.371757,21.2145375 12.909628,20.8033023 L19.7677785,15.559828 C20.1693192,15.2528257 20.2459576,14.6784381 19.9389553,14.2768974 C19.9376429,14.2751809 19.9363245,14.2734691 19.935,14.2717619 L19.935,14.2717619 C19.6266937,13.8743807 19.0546209,13.8021712 18.6572397,14.1104775 C18.654352,14.112718 18.6514778,14.1149757 18.6486172,14.1172508 L12.9235044,18.6705218 C12.377022,19.1051477 11.6029199,19.1052208 11.0563554,18.6706981 Z" fill="#000000" opacity="0.3" />
                                          </g>
                                        </svg>
                                        <!--end::Svg Icon-->
                                      </span>
                                      <a href="#" class="text-danger font-weight-bold font-size-h6 mt-2">Item Orders</a>
                                    </div>
                                    <div class="col bg-white px-6 py-8 rounded-xl">
                                      <span class="svg-icon svg-icon-3x svg-icon-success d-block my-2">
                                        <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Urgent-mail.svg-->
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                            <rect x="0" y="0" width="24" height="24" />
                                            <path d="M12.7037037,14 L15.6666667,10 L13.4444444,10 L13.4444444,6 L9,12 L11.2222222,12 L11.2222222,14 L6,14 C5.44771525,14 5,13.5522847 5,13 L5,3 C5,2.44771525 5.44771525,2 6,2 L18,2 C18.5522847,2 19,2.44771525 19,3 L19,13 C19,13.5522847 18.5522847,14 18,14 L12.7037037,14 Z" fill="#000000" opacity="0.3" />
                                            <path d="M9.80428954,10.9142091 L9,12 L11.2222222,12 L11.2222222,16 L15.6666667,10 L15.4615385,10 L20.2072547,6.57253826 C20.4311176,6.4108595 20.7436609,6.46126971 20.9053396,6.68513259 C20.9668779,6.77033951 21,6.87277228 21,6.97787787 L21,17 C21,18.1045695 20.1045695,19 19,19 L5,19 C3.8954305,19 3,18.1045695 3,17 L3,6.97787787 C3,6.70173549 3.22385763,6.47787787 3.5,6.47787787 C3.60510559,6.47787787 3.70753836,6.51099993 3.79274528,6.57253826 L9.80428954,10.9142091 Z" fill="#000000" />
                                          </g>
                                        </svg>
                                        <!--end::Svg Icon-->
                                      </span>
                                      <a href="#" class="text-success font-weight-bold font-size-h6 mt-2">Bug Reports</a>
                                    </div>
                                  </div>
                                  <!--end::Row-->
                                </div>
                                <!--end::Stats-->
                              </div>
                              <!--end::Body-->
                            </div>
                            <!--end::Mixed Widget 2-->
                          </div>
                          <div class="col-xl-4">
                            <!--begin::Mixed Widget 18-->
                            <div class="card card-custom gutter-b card-stretch">
                              <!--begin::Header-->
                              <div class="card-header border-0 pt-5">
                                <div class="card-title font-weight-bolder">
                                  <div class="card-label">Weekly Sales Stats
                                  <div class="font-size-sm text-muted mt-2">890,344 Sales</div></div>
                                </div>
                                <div class="card-toolbar">
                                  <div class="dropdown dropdown-inline">
                                    <a href="#" class="btn btn-clean btn-sm btn-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                      <i class="ki ki-bold-more-hor"></i>
                                    </a>
                                    <div class="dropdown-menu dropdown-menu-md dropdown-menu-right">
                                      <!--begin::Navigation-->
                                      <ul class="navi navi-hover">
                                        <li class="navi-header font-weight-bold py-4">
                                          <span class="font-size-lg">Choose Label:</span>
                                          <i class="flaticon2-information icon-md text-muted" data-toggle="tooltip" data-placement="right" title="Click to learn more..."></i>
                                        </li>
                                        <li class="navi-separator mb-3 opacity-70"></li>
                                        <li class="navi-item">
                                          <a href="#" class="navi-link">
                                            <span class="navi-text">
                                              <span class="label label-xl label-inline label-light-success">Customer</span>
                                            </span>
                                          </a>
                                        </li>
                                        <li class="navi-item">
                                          <a href="#" class="navi-link">
                                            <span class="navi-text">
                                              <span class="label label-xl label-inline label-light-danger">Partner</span>
                                            </span>
                                          </a>
                                        </li>
                                        <li class="navi-item">
                                          <a href="#" class="navi-link">
                                            <span class="navi-text">
                                              <span class="label label-xl label-inline label-light-warning">Suplier</span>
                                            </span>
                                          </a>
                                        </li>
                                        <li class="navi-item">
                                          <a href="#" class="navi-link">
                                            <span class="navi-text">
                                              <span class="label label-xl label-inline label-light-primary">Member</span>
                                            </span>
                                          </a>
                                        </li>
                                        <li class="navi-item">
                                          <a href="#" class="navi-link">
                                            <span class="navi-text">
                                              <span class="label label-xl label-inline label-light-dark">Staff</span>
                                            </span>
                                          </a>
                                        </li>
                                        <li class="navi-separator mt-3 opacity-70"></li>
                                        <li class="navi-footer py-4">
                                          <a class="btn btn-clean font-weight-bold btn-sm" href="#">
                                          <i class="ki ki-plus icon-sm"></i>Add new</a>
                                        </li>
                                      </ul>
                                      <!--end::Navigation-->
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <!--end::Header-->
                              <!--begin::Body-->
                              <div class="card-body">
                                <!--begin::Chart-->
                                <div id="kt_mixed_widget_18_chart" style="height: 250px"></div>
                                <!--end::Chart-->
                                <!--begin::Items-->
                                <div class="mt-n12 position-relative zindex-0">
                                  <!--begin::Widget Item-->
                                  <div class="d-flex align-items-center mb-8">
                                    <!--begin::Symbol-->
                                    <div class="symbol symbol-circle symbol-40 symbol-light mr-3 flex-shrink-0">
                                      <div class="symbol-label">
                                        <span class="svg-icon svg-icon-lg svg-icon-gray-500">
                                          <!--begin::Svg Icon | path:assets/media/svg/icons/Media/Equalizer.svg-->
                                          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                              <rect x="0" y="0" width="24" height="24" />
                                              <rect fill="#000000" opacity="0.3" x="13" y="4" width="3" height="16" rx="1.5" />
                                              <rect fill="#000000" x="8" y="9" width="3" height="11" rx="1.5" />
                                              <rect fill="#000000" x="18" y="11" width="3" height="9" rx="1.5" />
                                              <rect fill="#000000" x="3" y="13" width="3" height="7" rx="1.5" />
                                            </g>
                                          </svg>
                                          <!--end::Svg Icon-->
                                        </span>
                                      </div>
                                    </div>
                                    <!--end::Symbol-->
                                    <!--begin::Title-->
                                    <div>
                                      <a href="#" class="font-size-h6 text-dark-75 text-hover-primary font-weight-bolder">Most Sales</a>
                                      <div class="font-size-sm text-muted font-weight-bold mt-1">Authors with the best sales</div>
                                    </div>
                                    <!--end::Title-->
                                  </div>
                                  <!--end::Widget Item-->
                                  <!--begin::Widget Item-->
                                  <div class="d-flex align-items-center mb-8">
                                    <!--begin::Symbol-->
                                    <div class="symbol symbol-circle symbol-40 symbol-light mr-3 flex-shrink-0">
                                      <div class="symbol-label">
                                        <span class="svg-icon svg-icon-lg svg-icon-gray-500">
                                          <!--begin::Svg Icon | path:assets/media/svg/icons/Shopping/Chart-pie.svg-->
                                          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                              <rect x="0" y="0" width="24" height="24" />
                                              <path d="M4.00246329,12.2004927 L13,14 L13,4.06189375 C16.9463116,4.55399184 20,7.92038235 20,12 C20,16.418278 16.418278,20 12,20 C7.64874861,20 4.10886412,16.5261253 4.00246329,12.2004927 Z" fill="#000000" opacity="0.3" />
                                              <path d="M3.0603968,10.0120794 C3.54712466,6.05992157 6.91622084,3 11,3 L11,11.6 L3.0603968,10.0120794 Z" fill="#000000" />
                                            </g>
                                          </svg>
                                          <!--end::Svg Icon-->
                                        </span>
                                      </div>
                                    </div>
                                    <!--end::Symbol-->
                                    <!--begin::Title-->
                                    <div>
                                      <a href="#" class="font-size-h6 text-dark-75 text-hover-primary font-weight-bolder">Total Sales Lead</a>
                                      <div class="font-size-sm text-muted font-weight-bold mt-1">40% increased on week-to-week reports</div>
                                    </div>
                                    <!--end::Title-->
                                  </div>
                                  <!--end::Widget Item-->
                                  <!--begin::Widget Item-->
                                  <div class="d-flex align-items-center">
                                    <!--begin::Symbol-->
                                    <div class="symbol symbol-circle symbol-40 symbol-light mr-3 flex-shrink-0">
                                      <div class="symbol-label">
                                        <span class="svg-icon svg-icon-lg svg-icon-gray-500">
                                          <!--begin::Svg Icon | path:assets/media/svg/icons/Design/Layers.svg-->
                                          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                              <polygon points="0 0 24 0 24 24 0 24" />
                                              <path d="M12.9336061,16.072447 L19.36,10.9564761 L19.5181585,10.8312381 C20.1676248,10.3169571 20.2772143,9.3735535 19.7629333,8.72408713 C19.6917232,8.63415859 19.6104327,8.55269514 19.5206557,8.48129411 L12.9336854,3.24257445 C12.3871201,2.80788259 11.6128799,2.80788259 11.0663146,3.24257445 L4.47482784,8.48488609 C3.82645598,9.00054628 3.71887192,9.94418071 4.23453211,10.5925526 C4.30500305,10.6811601 4.38527899,10.7615046 4.47382636,10.8320511 L4.63,10.9564761 L11.0659024,16.0730648 C11.6126744,16.5077525 12.3871218,16.5074963 12.9336061,16.072447 Z" fill="#000000" fill-rule="nonzero" />
                                              <path d="M11.0563554,18.6706981 L5.33593024,14.122919 C4.94553994,13.8125559 4.37746707,13.8774308 4.06710397,14.2678211 C4.06471678,14.2708238 4.06234874,14.2738418 4.06,14.2768747 L4.06,14.2768747 C3.75257288,14.6738539 3.82516916,15.244888 4.22214834,15.5523151 C4.22358765,15.5534297 4.2250303,15.55454 4.22647627,15.555646 L11.0872776,20.8031356 C11.6250734,21.2144692 12.371757,21.2145375 12.909628,20.8033023 L19.7677785,15.559828 C20.1693192,15.2528257 20.2459576,14.6784381 19.9389553,14.2768974 C19.9376429,14.2751809 19.9363245,14.2734691 19.935,14.2717619 L19.935,14.2717619 C19.6266937,13.8743807 19.0546209,13.8021712 18.6572397,14.1104775 C18.654352,14.112718 18.6514778,14.1149757 18.6486172,14.1172508 L12.9235044,18.6705218 C12.377022,19.1051477 11.6029199,19.1052208 11.0563554,18.6706981 Z" fill="#000000" opacity="0.3" />
                                            </g>
                                          </svg>
                                          <!--end::Svg Icon-->
                                        </span>
                                      </div>
                                    </div>
                                    <!--end::Symbol-->
                                    <!--begin::Title-->
                                    <div>
                                      <a href="#" class="font-size-h6 text-dark-75 text-hover-primary font-weight-bolder">Average Bestseller</a>
                                      <div class="font-size-sm text-muted font-weight-bold mt-1">Pitstop Email Marketing</div>
                                    </div>
                                    <!--end::Title-->
                                  </div>
                                  <!--end::Widget Item-->
                                </div>
                                <!--end::Items-->
                              </div>
                              <!--end::Body-->
                            </div>
                            <!--end::Mixed Widget 18-->
                          </div>
                          <div class="col-xl-4">
                            <!--begin::List Widget 7-->
                            <div class="card card-custom gutter-b card-stretch">
                              <!--begin::Header-->
                              <div class="card-header border-0">
                                <h3 class="card-title font-weight-bolder text-dark">Trends</h3>
                                <div class="card-toolbar">
                                  <div class="dropdown dropdown-inline">
                                    <a href="#" class="btn btn-clean btn-hover-light-primary btn-sm btn-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                      <i class="ki ki-bold-more-ver"></i>
                                    </a>
                                    <div class="dropdown-menu dropdown-menu-md dropdown-menu-right">
                                      <!--begin::Naviigation-->
                                      <ul class="navi">
                                        <li class="navi-header font-weight-bold py-5">
                                          <span class="font-size-lg">Add New:</span>
                                          <i class="flaticon2-information icon-md text-muted" data-toggle="tooltip" data-placement="right" title="Click to learn more..."></i>
                                        </li>
                                        <li class="navi-separator mb-3 opacity-70"></li>
                                        <li class="navi-item">
                                          <a href="#" class="navi-link">
                                            <span class="navi-icon">
                                              <i class="flaticon2-shopping-cart-1"></i>
                                            </span>
                                            <span class="navi-text">Order</span>
                                          </a>
                                        </li>
                                        <li class="navi-item">
                                          <a href="#" class="navi-link">
                                            <span class="navi-icon">
                                              <i class="navi-icon flaticon2-calendar-8"></i>
                                            </span>
                                            <span class="navi-text">Members</span>
                                            <span class="navi-label">
                                              <span class="label label-light-danger label-rounded font-weight-bold">3</span>
                                            </span>
                                          </a>
                                        </li>
                                        <li class="navi-item">
                                          <a href="#" class="navi-link">
                                            <span class="navi-icon">
                                              <i class="navi-icon flaticon2-telegram-logo"></i>
                                            </span>
                                            <span class="navi-text">Project</span>
                                          </a>
                                        </li>
                                        <li class="navi-item">
                                          <a href="#" class="navi-link">
                                            <span class="navi-icon">
                                              <i class="navi-icon flaticon2-new-email"></i>
                                            </span>
                                            <span class="navi-text">Record</span>
                                            <span class="navi-label">
                                              <span class="label label-light-success label-rounded font-weight-bold">5</span>
                                            </span>
                                          </a>
                                        </li>
                                        <li class="navi-separator mt-3 opacity-70"></li>
                                        <li class="navi-footer pt-5 pb-4">
                                          <a class="btn btn-light-primary font-weight-bolder btn-sm" href="#">More options</a>
                                          <a class="btn btn-clean font-weight-bold btn-sm d-none" href="#" data-toggle="tooltip" data-placement="right" title="Click to learn more...">Learn more</a>
                                        </li>
                                      </ul>
                                      <!--end::Naviigation-->
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <!--end::Header-->
                              <!--begin::Body-->
                              <div class="card-body pt-0">
                                <!--begin::Item-->
                                <div class="d-flex align-items-center flex-wrap mb-8">
                                  <!--begin::Symbol-->
                                  <div class="symbol symbol-50 symbol-light mr-5">
                                    <span class="symbol-label">
                                      <img src="../assets/media/svg/misc/006-plurk.svg" class="h-50 align-self-center" alt="" />
                                    </span>
                                  </div>
                                  <!--end::Symbol-->
                                  <!--begin::Text-->
                                  <div class="d-flex flex-column flex-grow-1 mr-2">
                                    <a href="#" class="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1">Top Authors</a>
                                    <span class="text-muted font-weight-bold">Mark, Rowling, Esther</span>
                                  </div>
                                  <!--end::Text-->
                                  <span class="label label-xl label-light label-inline my-lg-0 my-2 text-dark-50 font-weight-bolder">+82$</span>
                                </div>
                                <!--end::Item-->
                                <!--begin::Item-->
                                <div class="d-flex align-items-center flex-wrap mb-8">
                                  <!--begin::Symbol-->
                                  <div class="symbol symbol-50 symbol-light mr-5">
                                    <span class="symbol-label">
                                      <img src="../assets/media/svg/misc/015-telegram.svg" class="h-50 align-self-center" alt="" />
                                    </span>
                                  </div>
                                  <!--end::Symbol-->
                                  <!--begin::Text-->
                                  <div class="d-flex flex-column flex-grow-1 mr-2 my-2">
                                    <a href="#" class="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1">Popular Authors</a>
                                    <span class="text-muted font-weight-bold">Randy, Steve, Mike</span>
                                  </div>
                                  <!--end::Text-->
                                  <span class="label label-xl label-light label-inline my-lg-0 my-2 text-dark-50 font-weight-bolder">+280$</span>
                                </div>
                                <!--end::Item-->
                                <!--begin::Item-->
                                <div class="d-flex align-items-center flex-wrap mb-8">
                                  <!--begin::Symbol-->
                                  <div class="symbol symbol-50 symbol-light mr-5">
                                    <span class="symbol-label">
                                      <img src="../assets/media/svg/misc/003-puzzle.svg" class="h-50 align-self-center" alt="" />
                                    </span>
                                  </div>
                                  <!--end::Symbol-->
                                  <!--begin::Text-->
                                  <div class="d-flex flex-column flex-grow-1 mr-2 my-2">
                                    <a href="#" class="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1">New Users</a>
                                    <span class="text-muted font-weight-bold">John, Pat, Jimmy</span>
                                  </div>
                                  <!--end::Text-->
                                  <span class="label label-xl label-light label-inline my-lg-0 my-2 text-dark-50 font-weight-bolder">+4500$</span>
                                </div>
                                <!--end::Item-->
                                <!--begin::Item-->
                                <div class="d-flex align-items-center flex-wrap mb-8">
                                  <!--begin::Symbol-->
                                  <div class="symbol symbol-50 symbol-light mr-5">
                                    <span class="symbol-label">
                                      <img src="../assets/media/svg/misc/005-bebo.svg" class="h-50 align-self-center" alt="" />
                                    </span>
                                  </div>
                                  <!--end::Symbol-->
                                  <!--begin::Text-->
                                  <div class="d-flex flex-column flex-grow-1 mr-2 my-2">
                                    <a href="#" class="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1">Active Customers</a>
                                    <span class="text-muted font-weight-bold">Sandra, Tim, Louis</span>
                                  </div>
                                  <!--end::Text-->
                                  <span class="label label-xl label-light label-inline my-lg-0 my-2 text-dark-50 font-weight-bolder">+4500$</span>
                                </div>
                                <!--end::Item-->
                                <!--begin::Item-->
                                <div class="d-flex align-items-center flex-wrap">
                                  <!--begin::Symbol-->
                                  <div class="symbol symbol-50 symbol-light mr-5">
                                    <span class="symbol-label">
                                      <img src="../assets/media/svg/misc/014-kickstarter.svg" class="h-50 align-self-center" alt="" />
                                    </span>
                                  </div>
                                  <!--end::Symbol-->
                                  <!--begin::Text-->
                                  <div class="d-flex flex-column flex-grow-1 mr-2 my-2">
                                    <a href="#" class="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1">Bestseller Theme</a>
                                    <span class="text-muted font-weight-bold">Disco, Retro, Sports</span>
                                  </div>
                                  <!--end::Text-->
                                  <span class="label label-xl label-light label-inline my-lg-0 my-2 text-dark-50 font-weight-bolder">+4500$</span>
                                </div>
                                <!--end::Item-->
                              </div>
                              <!--end::Body-->
                            </div>
                            <!--end::List Widget 7-->
                          </div>
                        </div>
                        <!--end::Row 1-->

                        <!--begin::Row 2-->
                        <div class="row">
                          <div class="col-xl-4">
                            <!--begin::Stats Widget 1-->
                            <div class="card card-custom bgi-no-repeat gutter-b card-stretch" style="background-position: right top; background-size: 30% auto; background-image: url(assets/media/svg/shapes/abstract-4.svg)">
                              <!--begin::Body-->
                              <div class="card-body">
                                <a href="#" class="card-title font-weight-bold text-muted text-hover-primary font-size-h5">Meeting Schedule</a>
                                <div class="font-weight-bold text-success mt-9 mb-5">3:30PM - 4:20PM</div>
                                <p class="text-dark-75 font-weight-bolder font-size-h5 m-0">Craft a headline that is informative
                                <br />and will capture readers</p>
                              </div>
                              <!--end::Body-->
                            </div>
                            <!--end::Stats Widget 1-->
                          </div>
                          <div class="col-xl-4">
                            <!--begin::Stats Widget 2-->
                            <div class="card card-custom bgi-no-repeat gutter-b card-stretch" style="background-position: right top; background-size: 30% auto; background-image: url(assets/media/svg/shapes/abstract-2.svg)">
                              <!--begin::Body-->
                              <div class="card-body">
                                <a href="#" class="card-title font-weight-bold text-muted text-hover-primary font-size-h5">Announcement</a>
                                <div class="font-weight-bold text-success mt-9 mb-5">03 May 2020</div>
                                <p class="text-dark-75 font-weight-bolder font-size-h5 m-0">Great blog posts don’t just happen
                                <br />Even the best bloggers need it</p>
                              </div>
                              <!--end::Body-->
                            </div>
                            <!--end::Stats Widget 2-->
                          </div>
                          <div class="col-xl-4">
                            <!--begin::Stats Widget 3-->
                            <div class="card card-custom bgi-no-repeat gutter-b card-stretch" style="background-position: right top; background-size: 30% auto; background-image: url(assets/media/svg/shapes/abstract-1.svg)">
                              <!--begin::body-->
                              <div class="card-body">
                                <a href="#" class="card-title font-weight-bold text-muted text-hover-primary font-size-h5">New Release</a>
                                <div class="font-weight-bold text-success mt-9 mb-5">ReactJS</div>
                                <p class="text-dark-75 font-weight-bolder font-size-h5 m-0">AirWays - A Front-end solution for
                                <br />airlines build with ReactJS</p>
                              </div>
                              <!--end::Body-->
                            </div>
                            <!--end::Stats Widget 3-->
                          </div>
                        </div>
                        <!--end::Row 2-->

                        <!---begin::Row 3---->
                        <div clas="row">
                            <div class="col-xl-6">
                            <!--begin::Base Table Widget 3-->
                            <div class="card card-custom gutter-b card-stretch" style="height:auto;">
                              <!--begin::Header-->
                              <div class="card-header border-0 pt-5">
                                <h3 class="card-title align-items-start flex-column">
                                  <span class="card-label font-weight-bolder text-dark">Course</span>
                                  <!--<span class="text-muted mt-3 font-weight-bold font-size-sm">More than 400+ new members</span>-->
                                </h3>
                                <div class="card-toolbar">
                                    <a href="https://levelup-learning.in/impactSchool/public/SuperAdmin/AddCourse">
                                  <button type="button" class="btn btn-light-primary btn-md py-2 font-weight-bolder">Create Course</button>
                                  </a>
                                  <!--<button type="button" class="btn btn-light-primary btn-md py-2 font-weight-bolder" data-toggle="modal" data-target="#staticBackdrop">Create Course</button> -->
                                  <!-- Modal-->
                                <div class="modal fade" id="staticBackdrop" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                  <div class="modal-dialog" role="document">
                                    <div class="modal-content">
                                      <div class="modal-header">
                                        <h5 class="modal-title" id="exampleModalLabel">New Course Name</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                          <i aria-hidden="true" class="ki ki-close"></i>
                                        </button>
                                      </div>
                                      <form action="https://levelup-learning.in/impactSchool/public/SuperAdmin/AddCourse" enctype="multipart/form-data" method="post">
                                          <input type="hidden" name="_token" value="wOvWgLKY6KIfa0v3oPUpdW7Ijk6RzDTTu9aHAEbb">
                                      <div class="modal-body">
                                        <div class="form-group">
                                            <label class="form-control-label">Course Name:</label>
                                          <input type="text" class="form-control" name="course_name" placeholder="Enter course name">
                                        </div>   
                                        <div class="form-group">
                                            <label class="form-control-label">Image:</label>
                                            <input type="file" name="course_image" class="custom-file-input">
                                                    <label class="custom-file-label" for="customFile" style="margin: 26% 23px;">Choose file</label>
                                        </div> 
                                      </div>
                                      <div class="modal-footer">
                                        <button type="button" class="btn btn-light-primary font-weight-bold" data-dismiss="modal">Close</button>
                                        <button type="submit" class="btn btn-primary font-weight-bold">Add Course</button>
                                      </div>
                                      </form>
                                    </div>
                                  </div>
                                </div> 
                                </div>
                              </div>
                              <!--end::Header-->
                              <!--begin::Body-->
                              <div class="card-body pt-2 pb-0">
                                <!--begin::Table-->
                                <div class="table-responsive">
                                  <table class="table table-borderless table-vertical-center">
                                    <thead>
                                      <tr>
                                        <th class="p-0" style="width: 50px"></th>
                                        <th class="p-0" style="min-width: 150px"></th>
                                        <th class="p-0" style="min-width: 140px"></th>
                                        <th class="p-0" style="min-width: 100px"></th>
                                        <th class="p-0" style="min-width: 40px"></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                                                
                                      <tr>
                                        <td class="pl-0 py-5">
                                          <div class="symbol symbol-45 symbol-light-success">
                                                                          <span class="symbol-label">
                                              <span class="svg-icon svg-icon-2x svg-icon-success">
                                                <!--begin::Svg Icon | path:assets/media/svg/icons/Shopping/Cart3.svg-->
                                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                    <rect x="0" y="0" width="24" height="24"></rect>
                                                    <path d="M12,4.56204994 L7.76822128,9.6401844 C7.4146572,10.0644613 6.7840925,10.1217854 6.3598156,9.76822128 C5.9355387,9.4146572 5.87821464,8.7840925 6.23177872,8.3598156 L11.2317787,2.3598156 C11.6315738,1.88006147 12.3684262,1.88006147 12.7682213,2.3598156 L17.7682213,8.3598156 C18.1217854,8.7840925 18.0644613,9.4146572 17.6401844,9.76822128 C17.2159075,10.1217854 16.5853428,10.0644613 16.2317787,9.6401844 L12,4.56204994 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                                                    <path d="M3.5,9 L20.5,9 C21.0522847,9 21.5,9.44771525 21.5,10 C21.5,10.132026 21.4738562,10.2627452 21.4230769,10.3846154 L17.7692308,19.1538462 C17.3034221,20.271787 16.2111026,21 15,21 L9,21 C7.78889745,21 6.6965779,20.271787 6.23076923,19.1538462 L2.57692308,10.3846154 C2.36450587,9.87481408 2.60558331,9.28934029 3.11538462,9.07692308 C3.23725479,9.02614384 3.36797398,9 3.5,9 Z M12,17 C13.1045695,17 14,16.1045695 14,15 C14,13.8954305 13.1045695,13 12,13 C10.8954305,13 10,13.8954305 10,15 C10,16.1045695 10.8954305,17 12,17 Z" fill="#000000"></path>
                                                  </g>
                                                </svg>
                                                <!--end::Svg Icon-->
                                              </span>
                                            </span>
                                                                      </div>
                                        </td>
                                        <td class="pl-0">
                                          <a href="https://levelup-learning.in/impactSchool/public/SuperAdmin/ViewLesson/1" class="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">Reading</a>
                                        </td> 
                                        <td class="text-right">
                                                                        <span class="text-muted font-weight-bold">Free</span>
                                                                  </td>
                                        <td class="text-right pr-0">
                                        <a href="https://levelup-learning.in/impactSchool/public/SuperAdmin/ViewLesson/1" class="btn btn-icon btn-light btn-sm">
                                          <span class="svg-icon svg-icon-md svg-icon-success">
                                            <!--begin::Svg Icon | path:assets/media/svg/icons/Navigation/Arrow-right.svg-->
                                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                                <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)" x="11" y="5" width="2" height="14" rx="1"></rect>
                                                <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fill-rule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)"></path>
                                              </g>
                                            </svg>
                                            <!--end::Svg Icon-->
                                          </span>
                                        </a>
                                      </td>
                                      </tr>
                                      
                                                              
                                      <tr>
                                        <td class="pl-0 py-5">
                                          <div class="symbol symbol-45 symbol-light-success">
                                                                              <span class="symbol-label">
                                                  <img src="https://levelup-learning.in/impactSchool/public/course_images/12_new.png" class="w-100 h-50 align-self-center">
                                              </span>
                                                                        </div>
                                        </td>
                                        <td class="pl-0">
                                          <a href="https://levelup-learning.in/impactSchool/public/SuperAdmin/ViewLesson/2" class="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">Personal Productivity course</a>
                                        </td> 
                                        <td class="text-right">
                                                                        <span class="text-muted font-weight-bold">Free</span>
                                                                  </td>
                                        <td class="text-right pr-0">
                                        <a href="https://levelup-learning.in/impactSchool/public/SuperAdmin/ViewLesson/2" class="btn btn-icon btn-light btn-sm">
                                          <span class="svg-icon svg-icon-md svg-icon-success">
                                            <!--begin::Svg Icon | path:assets/media/svg/icons/Navigation/Arrow-right.svg-->
                                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                                <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)" x="11" y="5" width="2" height="14" rx="1"></rect>
                                                <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fill-rule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)"></path>
                                              </g>
                                            </svg>
                                            <!--end::Svg Icon-->
                                          </span>
                                        </a>
                                      </td>
                                      </tr>
                                      
                                                              
                                      <tr>
                                        <td class="pl-0 py-5">
                                          <div class="symbol symbol-45 symbol-light-success">
                                                                          <span class="symbol-label">
                                              <span class="svg-icon svg-icon-2x svg-icon-success">
                                                <!--begin::Svg Icon | path:assets/media/svg/icons/Shopping/Cart3.svg-->
                                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                    <rect x="0" y="0" width="24" height="24"></rect>
                                                    <path d="M12,4.56204994 L7.76822128,9.6401844 C7.4146572,10.0644613 6.7840925,10.1217854 6.3598156,9.76822128 C5.9355387,9.4146572 5.87821464,8.7840925 6.23177872,8.3598156 L11.2317787,2.3598156 C11.6315738,1.88006147 12.3684262,1.88006147 12.7682213,2.3598156 L17.7682213,8.3598156 C18.1217854,8.7840925 18.0644613,9.4146572 17.6401844,9.76822128 C17.2159075,10.1217854 16.5853428,10.0644613 16.2317787,9.6401844 L12,4.56204994 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                                                    <path d="M3.5,9 L20.5,9 C21.0522847,9 21.5,9.44771525 21.5,10 C21.5,10.132026 21.4738562,10.2627452 21.4230769,10.3846154 L17.7692308,19.1538462 C17.3034221,20.271787 16.2111026,21 15,21 L9,21 C7.78889745,21 6.6965779,20.271787 6.23076923,19.1538462 L2.57692308,10.3846154 C2.36450587,9.87481408 2.60558331,9.28934029 3.11538462,9.07692308 C3.23725479,9.02614384 3.36797398,9 3.5,9 Z M12,17 C13.1045695,17 14,16.1045695 14,15 C14,13.8954305 13.1045695,13 12,13 C10.8954305,13 10,13.8954305 10,15 C10,16.1045695 10.8954305,17 12,17 Z" fill="#000000"></path>
                                                  </g>
                                                </svg>
                                                <!--end::Svg Icon-->
                                              </span>
                                            </span>
                                                                      </div>
                                        </td>
                                        <td class="pl-0">
                                          <a href="https://levelup-learning.in/impactSchool/public/SuperAdmin/ViewLesson/3" class="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">test3</a>
                                        </td> 
                                        <td class="text-right">
                                                                        <span class="text-muted font-weight-bold">Free</span>
                                                                  </td>
                                        <td class="text-right pr-0">
                                        <a href="https://levelup-learning.in/impactSchool/public/SuperAdmin/ViewLesson/3" class="btn btn-icon btn-light btn-sm">
                                          <span class="svg-icon svg-icon-md svg-icon-success">
                                            <!--begin::Svg Icon | path:assets/media/svg/icons/Navigation/Arrow-right.svg-->
                                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                                <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)" x="11" y="5" width="2" height="14" rx="1"></rect>
                                                <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fill-rule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)"></path>
                                              </g>
                                            </svg>
                                            <!--end::Svg Icon-->
                                          </span>
                                        </a>
                                      </td>
                                      </tr>
                                      
                                                            
                                      <tr>
                                          <td class="text-right" colspan="4"> 
                                          <a href="https://levelup-learning.in/impactSchool/public/SuperAdmin/ViewCourse" class="btn btn-light btn-xl">View All
                                              <span class="symbol-label">
                                        <span class="svg-icon svg-icon-2x svg-icon-success">
                                          <!--begin::Svg Icon | path:assets/media/svg/icons/Shopping/Cart3.svg-->
                                          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                                <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)" x="11" y="5" width="2" height="14" rx="1"></rect>
                                                <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fill-rule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)"></path>
                                              </g>
                                            </svg>
                                          <!--end::Svg Icon-->
                                        </span>
                                        </span>
                                        </a>
                                        </td>
                                      </tr>
                                                          </tbody>
                                  </table>
                                </div>
                                <!--end::table-->
                              </div>
                              <!--begin::Body-->
                            </div>
                            <!--end::Base Table Widget 3-->
                            </div>
                            <div class="col-xl-6"></div>
                        </div>
                        <!----end :: Row3 -->

                    <!--end::Dashboard-->
                  </div>
                  <!--end::Container-->
                </div>
                <!--end::Entry-->
              </div>
					<!--end::Content-->

             <Footer></Footer>

           

          </div>
      </div>

     
        
    </div>
</template>


<script>

import axios from 'axios'
import MobileHeader from '../components/MobileHeader'
import Header from '../components/Header'
import Footer from '../components/Footer'

export default {
  name: 'Home',
  components: {
    Header,
    Footer,
    MobileHeader,
   
  },
  data () {
    return {
      cartData: [],
      uid: localStorage.getItem('uid'),
      ip_address: JSON.parse(localStorage.getItem('ip_address')),
      cartcount: 0,
      total: 0,
      btn_chk: false,

      pincode: '',
      tm: ''

    }
  },
  mounted () {
    /// /console.log(this.$gmapOptions.load.key)
   // this.ip_address = JSON.parse(localStorage.getItem('ip_address'))
    //this.getData()
    window.scrollTo(0, 0)
    
  },
  methods: {
    show_warn () {
      this.$toasted.global.error({ message: 'Enter valid Pincode' })
    },
    update_cart_pre (cid, pid, pname, qty, price, mode) {
      var t = this.update_cart(cid, pid, pname, qty, price, mode)
      /// /console.log(t)
      setTimeout(() => {
        if (t != null) {
          this.getData()
        }
      }, 1000)
    },
    change_pin () {
      var ev = this
      var pin = this.pincode
      var len = pin.length
      if (len > 5 && len < 7) {
        axios({ method: 'POST', url: this.$root.URL_ROOT + 'api.php', data: { type: 'check_pin', pincode: pin } }).then(function (response) {
          var data = response.data
          if (data.status == 'success') {
            ev.tm = data.data.time
            ev.btn_chk = true
          } else {
            ev.tm = 'is currently not available'
            ev.btn_chk = false
          }
          //console.log('pin')
          //console.log(data)
        })
      }
    },
    chng_pin () {
      this.pincode = ''
      this.tm = ''
    },
    delete_cart_pre (cid) {
      var t = this.delete_cart(cid)

      setTimeout(() => {
        this.getData()
      }, 1000)
    },

    go_order () {
      this.$router.push('placeorder')
    },

    getData () {
      var self = this
      axios({
        method: 'POST',
        url: this.$root.URL_ROOT + 'api.php',
        data: {
          type: 'mycartdata',
          uid: this.uid,
          ip_address: this.ip_address
        }
      }).then(function (response) {
        var data = response.data
        self.cartData = data.mycartdata
        self.cartcount = data.cart_count
        self.total = data.total
        //console.log("mycartdata");
        //console.log(response)
        //console.log("dededeed");
      })
    }

  }
}
</script>