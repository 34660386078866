<template>
<div name="student">

    <MobileHeader></MobileHeader>
    <div class="d-flex flex-row flex-column-fluid page">
        <div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">

            <Header></Header>

            <!--begin::Content-->
            <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
                <!--begin::Entry-->
                <div class="d-flex flex-column-fluid">
                    <!--begin::Container-->
                    <!--begin::Dashboard-->

                    <div class="container">
                        <div class="flex-row pt-5 pb-5" id="sop">
                            
                            <!--begin::Row-->
                            <router-link v-if="role!=5" to="/addsops" class="btn btn-info add-btn-s">Add SOP's</router-link>

                            <router-link to="/assignsops" v-if="role!=5" class="btn btn-info add-btn-s pull-right">Assign SOP's</router-link>
                            <router-link to="/viewassignsops" :class="role!=5?'btn btn-info add-btn-s pull-right':'btn btn-info add-btn-s'">All Assign SOP's</router-link>
                            <div class="card card-body gutter-b ">
                                
                                <!--begin: Datatable-->
                                <div id="example_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <table id="example" class="table dataTable no-footer" style="width: 100%;" role="grid" aria-describedby="example_info">
                                                <thead>
                                                    <tr role="row">
                                                        <th class="sorting sorting_asc" rowspan="1" colspan="1" >Name</th>
                                                        <th class="sorting"  rowspan="1" colspan="1" >Type</th>
                                                        <th class="sorting"  rowspan="1" colspan="1" >Assign</th>
                                                        <th class="sorting"  rowspan="1" colspan="1" >Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <template v-for="sdata in allData">
                                                        <tr class="odd" :key="sdata.id">
                                                            <td class="sorting_1">{{sdata.sops_name}}</td>
                                                            <td><span class="label label-inline label-light-danger font-weight-bold">{{sdata.file_type}}</span></td>
                                                            <td>{{sdata.assign}}</td>
                                                            <td>
                                                                <router-link :to="'/viewsops/'+sdata.id"><span class="label label-inline label-light-danger font-weight-bold ml-5">View</span></router-link>
                                                                <router-link v-if="role!=5" :to="'/editsops/'+sdata.id"><span class="edit-btn-clr label label-inline label-light-danger font-weight-bold ml-5">Edit</span></router-link>
                                                                <a v-if="role!=5" @click="delete_sops(sdata.id)" class="mouse-point"><span class="delete-btn-clr label label-inline label-light-danger font-weight-bold ml-5">Delete</span></a>
                                                            </td>
                                                        </tr>
                                                    </template>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                </div>
                                <!--end: Datatable-->
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
</template>

<script>
import axios from 'axios'
import MobileHeader from '../../components/MobileHeader'
import Header from '../../components/Header'
import Footer from '../../components/Footer'

export default {
    name: 'Sops',
    components: {
        Header,
        Footer,
        MobileHeader,

    },
    data() {
        return {
            uid: localStorage.getItem("uid"),
            role: localStorage.getItem("role"),
            allData: [],
        }
    },
    mounted() {
        if (this.uid == null || this.uid == "") {
            this.$router.push("/");
        }
        this.getSchedule();
    },
    methods: {
        getSchedule() {
            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'lms_api.php',
                data: {
                    type: 'getSops',
                    role: this.role,
                    uid: this.uid,
                    stype: 'sops',
                }
            }).then(function (response) {
                var data = response.data;
                ev.allData = data.data;
                //console.log(data);
            })
        },
        delete_sops(ids) {
            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'lms_api.php',
                data: {
                    type: 'delete_sops',
                    sid: ids,
                }
            }).then(function (response) {
                var data = response.data;
                if (data.status == 'success') {
                    ev.$toasted.global.success({
                        message: data.msg
                    });
                    ev.getSchedule();
                } else {
                    ev.$toasted.global.error({
                        message: data.msg
                    });
                }
            })
        }
    },
}
</script>

<style scoped>
.pull-right{
    float:right;
}
.mouse-point {
    cursor: pointer;
}

.add-btn-s {
    background-color: #3798fb;
    border: none;
    margin: 1%;
}

.btn-top-table {
    display: inline-block;
    padding: 10px;
    width: 50%;
}

.table {
    width: 100%;

}

.table-dt {
    overflow: scroll;
}

.tbl-action {
    margin: 2px;
    border: unset;
}
</style>
