<template>
<div name="student">

    <MobileHeader></MobileHeader>
    <div class="d-flex flex-row flex-column-fluid page">
        <div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">

            <Header></Header>

            <!--begin::Content-->
            <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
                <!--begin::Entry-->
                <div class="d-flex flex-column-fluid">
                    <!--begin::Container-->
                    <!--begin::Dashboard-->

                    <div class="container">
                        <div class="card card-custom" style="width:-webkit-fill-available;">
                            <div class="card-body p-0">
                                <div id="loading"></div>
                                <!--begin: Wizard-->
                                <div class="wizard wizard-2 row" id="kt_wizard" data-wizard-state="first" data-wizard-clickable="true">
                                    <!--begin: Wizard Nav-->
                                    <div class="col-md-4 wizard-nav border-right py-8 px-8 px-lg-10">
                                        <div class="wizard-steps">
                                            <div class="accordion accordion-toggle-arrow" id="accordionExample1">
                                                <!--begin::Wizard Step 1 Nav-->
                                                <div class="card">
                                                    <div class="card-header">
                                                        <div class="card-title" data-toggle="collapse" data-target="#collapseOne11" aria-expanded="true">lesson 1
                                                        </div>
                                                    </div>
                                                    <div id="collapseOne11" class="collapse show" data-parent="#accordionExample1" style="">
                                                        <div class="card-body">

                                                            <div class="wizard-step mb-2" data-wizard-type="step" data-wizard-state="current">
                                                                <div class="wizard-label">
                                                                    <h3 class="wizard-title">Test Module</h3>
                                                                    <span class="label label-inline label-primary font-weight-bold"><i class="la la-file-text-o text-white text-capitalize"></i>
                                                                        <p class="ml-2" style="margin-top:13px;">content</p>
                                                                    </span>
                                                                </div>
                                                            </div>

                                                            <!--begin::Wizard Step 1 Nav-->
                                                            <div class="wizard-step mb-2" data-wizard-type="step" data-wizard-state="pending">
                                                                <div class="wizard-label">
                                                                    <h3 class="wizard-title">test</h3>
                                                                    <span class="label label-inline label-primary font-weight-bold">
                                                                        <i class="la la-video text-white text-capitalize"></i>
                                                                        <p class="ml-2" style="margin-top:13px;">video</p>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <!--end::Wizard Step 1 Nav-->

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!--end: Wizard Nav-->
                                    <!--begin: Wizard Body-->
                                    <div class=" col-md-8 wizard-body py-8 px-8 px-lg-10">
                                        <!--begin: Wizard Form-->
                                        
                                        <!--end: Wizard Form-->
                                    </div>
                                    <!--end: Wizard Body-->
                                </div>
                                <!--end: Wizard-->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
</template>

<script>
import axios from 'axios'
import MobileHeader from '../../components/MobileHeader'
import Header from '../../components/Header'
import Footer from '../../components/Footer'

export default {
    name: 'Todo',
    components: {
        Header,
        Footer,
        MobileHeader,

    },
    data() {
        return {
            uid: localStorage.getItem("uid"),
            role: localStorage.getItem("role"),
            allData: [],
        }
    },
    mounted() {
        if (this.uid == null || this.uid == "") {
            this.$router.push("/");
        }

    },
    methods: {
        getAllLesson() {
            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'lms_api.php',
                data: {
                    type: 'getPreviewLession',
                    cid: this.$route.params.cid,
                }
            }).then(function (response) {
                //console.log(response.data)
                var data = response.data;
                ev.total = data.total;
                ev.course_details = data.course_details
                if (ev.total > 0) {
                    if (ev.total == 1) {
                        ev.show_btn_next = false;
                        ev.show_btn_pre = false;
                        ev.mainData = data.data;
                        ev.current = 1;
                        ev.getDatas(1);
                    } else if (ev.total > 1) {
                        ev.show_btn_next = true;
                        ev.mainData = data.data;
                        ev.current = 1;
                        ev.getDatas(1);
                    }
                } else {
                    ev.show_btn_next = false;
                    ev.show_btn_pre = false;
                    ev.current = 0;

                }

            })

        },
    },
}
</script>

<style scoped>
.add-btn-s {
    background-color: #3798fb;
    border: none;
    margin: 1%;
}

.btn-top-table {
    display: inline-block;
    padding: 10px;
    width: 50%;
}

.table {
    width: 100%;

}

.table-dt {
    overflow: scroll;
}

.tbl-action {
    margin: 2px;
    border: unset;
}
</style>
