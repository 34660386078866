<template>
<div name="addstudent">

    <MobileHeader></MobileHeader>
    <div class="d-flex flex-row flex-column-fluid page">
        <div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">

            <Header></Header>

            <!--begin::Content-->
            <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
                <!--begin::Entry-->
                <div class="d-flex flex-column-fluid">
                    <!--begin::Container-->
                    <!--begin::Dashboard-->

                    <div class="container">

                        <div class="card card-custom">
                            <div class="card-body p-0">
                                <!--begin: Wizard-->
                                <div class="wizard wizard-3" id="kt_wizard_v3" data-wizard-state="step-first" data-wizard-clickable="true">
                                    <!--begin: Wizard Nav-->
                                    <div class="wizard-nav">
                                        <div class="wizard-steps px-8 py-8 px-lg-15 py-lg-3">
                                            <!--begin::Wizard Step 1 Nav-->
                                            <div class="wizard-step" data-wizard-type="step" :data-wizard-state="box_pos==1?'current':''">
                                                <div class="wizard-label">
                                                    <h3 class="wizard-title">
                                                        <span>1.</span>Teacher Profile</h3>
                                                    <p>Teacher's Personal Information</p>
                                                    <div class="wizard-bar"></div>
                                                </div>
                                            </div>
                                            <!--end::Wizard Step 1 Nav-->
                                            <!--begin::Wizard Step 2 Nav-->
                                            <div class="wizard-step" data-wizard-type="step" :data-wizard-state="box_pos==2?'current':''">
                                                <div class="wizard-label">
                                                    <h3 class="wizard-title">
                                                        <span>2.</span>Address</h3>
                                                    <p>Teacher's Address</p>
                                                    <div class="wizard-bar"></div>
                                                </div>
                                            </div>
                                            <!--end::Wizard Step 2 Nav-->

                                        </div>
                                    </div>
                                    <!--end: Wizard Nav-->
                                    <!--begin: Wizard Body-->
                                    <div class="row justify-content-center py-10 px-8 py-lg-12 px-lg-10">
                                        <div class="col-xl-12 col-xxl-7">
                                            <!--begin: Wizard Form-->
                                            <!--begin: Wizard Step 1-->
                                            <div class="pb-5" data-wizard-type="step-content" :data-wizard-state="box_pos==1?'current':''">
                                                <h4 class="mb-10 font-weight-bold text-dark">Teacher's Profile Details:</h4>
                                                <!--begin::Input-->
                                                <div class="form-group">
                                                    <label>Full Name</label>
                                                    <input type="text" class="form-control" v-model="fname" name="fullname" placeholder="Full Name" />
                                                    <span class="form-text text-muted">Please enter your Full Name.</span>
                                                </div>
                                                <!--end::Input-->
                                                <!--begin::Input-->
                                                <div class="form-group">
                                                    <label>Contact Phone</label>
                                                    <input type="number" class="form-control" v-model="mobile" name="contact" placeholder="Contact Phone" />
                                                    <span class="form-text text-muted">Please enter your Contact.</span>
                                                </div>
                                                <!--end::Input-->
                                                <div class="row">
                                                    <div class="col-xl-6">
                                                        <!--begin::Input-->
                                                        <div class="form-group">
                                                            <label>Email Address</label>
                                                            <input type="email" class="form-control" v-model="email" name="email" placeholder="Email Address" />
                                                            <span class="form-text text-muted">Please enter your Email Address.</span>
                                                        </div>
                                                        <!--end::Input-->
                                                    </div>
                                                    <div class="col-xl-6">
                                                        <!--begin::Input-->
                                                        <div class="form-group">
                                                            <label>Password</label>
                                                            <input type="password" class="form-control" v-model="password" name="password" placeholder="Password" />
                                                            <span class="form-text text-muted">Please enter your Password.</span>
                                                        </div>
                                                        <!--end::Input-->
                                                    </div>
                                                </div>

                                                <button type="button" class="btn btn-primary font-weight-bolder text-uppercase px-9 py-4" data-wizard-type="action-next" @click="save_student(1)">Next</button>

                                            </div>
                                            <!--end: Wizard Step 1-->
                                            <!--begin: Wizard Step 2-->
                                            <div class="pb-5" data-wizard-type="step-content" :data-wizard-state="box_pos==2?'current':''">
                                                <h4 class="mb-10 font-weight-bold text-dark">Setup Your Address</h4>
                                                <!--begin::Input-->
                                                <div class="form-group">
                                                    <label>Address Line 1</label>
                                                    <input type="text" class="form-control" name="package" v-model="add1" placeholder="Address Line 1" />
                                                    <span class="form-text text-muted">Please enter address line 1.</span>
                                                </div>
                                                <!--end::Input-->
                                                <!--begin::Input-->
                                                <div class="form-group">
                                                    <label>Address Line 2</label>
                                                    <input type="text" class="form-control" v-model="add2" name="weight" placeholder="Address Line 2" />
                                                    <span class="form-text text-muted">Please enter address line 2.</span>
                                                </div>
                                                <!--end::Input-->
                                                <div class="form-text"></div>
                                                <div class="row">
                                                    <div class="col-xl-6">
                                                        <!--begin::Input-->
                                                        <div class="form-group">
                                                            <label>Postcode</label>
                                                            <input type="text" class="form-control" v-model="pincode" name="width" placeholder="Postcode" />
                                                            <span class="form-text text-muted">Please enter postcode.</span>
                                                        </div>
                                                        <!--end::Input-->
                                                    </div>
                                                    <div class="col-xl-6">
                                                        <!--begin::Input-->
                                                        <div class="form-group">
                                                            <label>City</label>
                                                            <input type="text" class="form-control" v-model="city" name="height" placeholder="City" />
                                                            <span class="form-text text-muted">Please enter your city.</span>
                                                        </div>
                                                        <!--end::Input-->
                                                    </div>
                                                    <div class="col-xl-6">
                                                        <!--begin::Input-->
                                                        <div class="form-group">
                                                            <label>State</label>
                                                            <input type="text" class="form-control" v-model="state" name="packagelength" placeholder="State" />
                                                            <span class="form-text text-muted">Please enter your state.</span>
                                                        </div>
                                                        <!--end::Input-->
                                                    </div>

                                                    <div class="col-xl-6">
                                                        <!--begin::Input-->
                                                        <div class="form-group">
                                                            <label>Country</label>
                                                            <input type="text" class="form-control" v-model="country" name="packagelength" placeholder="Country" />
                                                            <span class="form-text text-muted">Please enter your country.</span>
                                                        </div>
                                                        <!--end::Input-->
                                                    </div>
                                                </div>

                                                <button type="button" class="btn btn-primary font-weight-bolder text-uppercase px-9 py-4" data-wizard-type="action-next" @click="save_student(2)">Next</button>

                                            </div>
                                            <!--end: Wizard Step 2-->

                                            <!--begin: Wizard Actions-->
                                            <div class="d-flex justify-content-between border-top mt-5 pt-10 hide">
                                                <div class="mr-2">
                                                    <button type="button" class="btn btn-light-primary font-weight-bolder text-uppercase px-9 py-4" data-wizard-type="action-prev">Previous</button>
                                                </div>
                                                <div>
                                                    <button type="button" class="btn btn-success font-weight-bolder text-uppercase px-9 py-4" data-wizard-type="action-submit">Submit</button>
                                                    <button type="button" class="btn btn-primary font-weight-bolder text-uppercase px-9 py-4" data-wizard-type="action-next" @click="box_pos=3">Next</button>
                                                    <button type="button" class="btn btn-primary font-weight-bolder text-uppercase px-9 py-4" data-wizard-type="action-next" @click="getTest()">Sub</button>
                                                </div>
                                            </div>
                                            <!--end: Wizard Actions-->

                                            <!--end: Wizard Form-->
                                        </div>
                                    </div>
                                    <!--end: Wizard Body-->
                                </div>
                                <!--end: Wizard-->
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
</template>

<script>
import axios from 'axios'
import MobileHeader from '../../components/MobileHeader'
import Header from '../../components/Header'
import Footer from '../../components/Footer'

export default {
    name: 'AddStudent',
    components: {
        Header,
        Footer,
        MobileHeader,

    },
    data() {
        return {
            uid: localStorage.getItem("uid"),
            role: localStorage.getItem("role"),
            course_data: [],
            box_pos: 1,
            fname: null,
            email: null,
            mobile: null,
            password: null,
            add1: null,
            add2: null,
            pincode: null,
            city: null,
            state: null,
            country: null,
            course: null,
            exdate: null,

        }
    },
    mounted() {
        this.getCourse();
        this.getTest();
    },
    watch: {

    },
    methods: {
        save_student(ids) {
            var ev = this;
            if (ids == 1) {
                if (ev.fname == null || ev.fname == "") {
                    ev.$toasted.global.error({
                        message: "please enter full name"
                    });
                } else if (ev.mobile == null || ev.mobile == "") {
                    ev.$toasted.global.error({
                        message: "please enter contact no. "
                    });
                } else if (ev.email == null || ev.email == "") {
                    ev.$toasted.global.error({
                        message: "please enter email  "
                    });
                } else if (ev.password == null || ev.password == "") {
                    ev.$toasted.global.error({
                        message: "please enter password "
                    });
                } else {
                    ev.box_pos = 2;
                }

            } else if (ids == 2) {
                if (ev.add1 == null || ev.add1 == "") {
                    ev.$toasted.global.error({
                        message: "please enter address line 1"
                    });
                } else if (ev.add2 == null || ev.add2 == "") {
                    ev.$toasted.global.error({
                        message: "please enter address line 2"
                    });
                } else if (ev.pincode == null || ev.pincode == "") {
                    ev.$toasted.global.error({
                        message: "please enter postcode"
                    });
                } else if (ev.city == null || ev.city == "") {
                    ev.$toasted.global.error({
                        message: "please enter city"
                    });
                } else if (ev.state == null || ev.state == "") {
                    ev.$toasted.global.error({
                        message: "please enter state"
                    });
                } else if (ev.country == null || ev.country == "") {
                    ev.$toasted.global.error({
                        message: "please enter country"
                    });
                } else {

                    axios({
                        method: 'POST',
                        url: ev.$root.URL_ROOT + 'lms_api.php',
                        data: {
                            type: 'save_teacher',
                            fname: ev.fname,
                            mobile: ev.mobile,
                            email: ev.email,
                            password: ev.password,
                            add1: ev.add1,
                            add2: ev.add2,
                            pincode: ev.pincode,
                            city: ev.city,
                            state: ev.state,
                            country: ev.country,
                        }
                    }).then(function (response) {
                        //console.log(response.data);
                        var data = response.data;
                        if (data.status == "success") {
                            ev.$toasted.global.success({
                                message: "Teacher added successfully"
                            });
                            ev.$router.push('/teacherview');
                        } else {
                            ev.$toasted.global.error({
                                message: data.msg
                            });
                        }
                    })
                }
            }
        },
        getCourse() {
            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'lms_api.php',
                data: {
                    type: 'getAllCourseDetails',
                }
            }).then(function (response) {
                //console.log(response.data);
                var data = response.data;
                ev.course_data = data;
            })
        },
        getTest() {
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'lms_api.php',
                data: {
                    type: 'test_code',
                    exdate: this.exdate
                }
            }).then(function (response) {
                //console.log(response.data);
            })
        }
    }

}
</script>

<style scoped>
.btn-top-table {
    display: inline-block;
    padding: 10px;
    width: 50%;
}

.table {
    width: 100%;
}

.table-dt {
    overflow: scroll;
}

.tbl-action {
    margin: 2px;
    border: unset;
}
</style>
