<template>
    <div name="student">

         <MobileHeader></MobileHeader>
      <div class="d-flex flex-row flex-column-fluid page">
          <div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">

            <Header></Header>
             
              <!--begin::Content-->
              <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
                <!--begin::Entry-->
                <div class="d-flex flex-column-fluid">
                  <!--begin::Container-->
                    <!--begin::Dashboard-->

                        <div class="container">
                            <div class="card card-custom mt-5">
                                <div class="card-body">

                                <div class="mb-7">
                                    <div class="row align-items-center">
                                        <div class="col-lg-7 col-xl-7">
                                        <div class="row align-items-center">
                                            <div class="col-md-6 my-2 my-md-0">
                                            <div class="input-icon">
                                                <input type="text" class="form-control" placeholder="Search..." id="kt_datatable_search_query">
                                                <span>
                                                <i class="flaticon2-search-1 text-muted"></i>
                                                </span>
                                            </div>
                                            </div>
                                        </div>
                                        </div>
                                        <div class="col-lg-5 col-xl-5 mt-5 mt-lg-0">
                                        
                                        <div class="btn-top-table">
                                            <router-link to="/addstudent">
                                            <button type="button" class="btn btn-primary font-weight-bolder ">
                                            <i class="fa fa-plus-circle" aria-hidden="true"></i>
                                                Add Student
                                            </button>
                                            </router-link>
                                        </div>

                                        </div>
                                    </div>
                                            </div>
                                
                                <div class="table-dts">
                                    <table class="table table-hover">
                                    <thead>
                                        <tr>
                                        
                                        <th scope="col">Student Name</th>
                                        <th scope="col">Email</th>
                                        <th scope="col">Total Spent</th>
                                        <th scope="col">Enrollments</th>
                                        
                                        <th scope="col">Action</th>
                                        
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <template v-for="item in student_data">
                                        <tr :key="item.id">
                                            
                                            <td>{{item.username}}</td>
                                            <td>{{item.email}}</td>
                                            <td>{{item.total_spent}}</td>
                                            <td>{{item.enrollments}}</td>

                                            <td>
                                            
                                            <router-link :to="'/editstudent/'+item.id" class="edit-btn-clr label tbl-action label-inline label-light-danger font-weight-bold" >
                                                <span >Edit</span>
                                            </router-link>
                                            <button class="delete-btn-clr label tbl-action label-inline label-light-danger font-weight-bold" @click="delete_student(item.id)" >
                                                <span >Delete</span>
                                            </button>
                                            </td>  
                                
                                            
                                        </tr>
                                        </template>
                                        
                                    </tbody>
                                    </table>
                                </div>  


                                </div>
                            </div>
                        </div>    
                </div>
              </div> 
          </div>
      </div>                 
        
    </div>
</template>

<script>

import axios from 'axios'
import MobileHeader from '../../components/MobileHeader'
import Header from '../../components/Header'
import Footer from '../../components/Footer'


export default {
    name: 'Student',
    components: {
        Header,
        Footer,
        MobileHeader,
    
    },
    data() {
        return {
            student_data: [],
            page: 1,
            totalCount: 0,
            newAry: [],
            perPage: 5,

        }       
    },
     mounted() {

         this.getStudentData();
     },
     methods: {
         changePage() {
            this.course_data = [];
            var preCount = (this.perPage * this.page);

            var temp = this.perPage;
            var newPP = (preCount) - temp;
            //console.log(newPP);
            for (var i = 0; i < temp; i++) {
                if (this.newAry[newPP]) {
                    this.course_data[i] = this.newAry[newPP];
                    newPP++;
                }

            }
        },
         getStudentData(){
              var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'lms_api.php',
                data: {
                    type: 'getStudentData',
                    
                }
            }).then(function (response) {
                //console.log(response.data);
                var data = response.data;
                ev.student_data = data.data;
                ev.newAry = data.data;
                ev.totalCount = data.count;
                ev.changePage();
            })
         },
         delete_student(ids){
              var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'lms_api.php',
                data: {
                    type: 'delete_student',
                    uid: ids
                    
                }
            }).then(function (response) {
                var data=response.data;
                //console.log(data);
                if(data.status=="success"){
                    ev.$toasted.global.success({
                         message: data.msg
                    });
                }else{
                    ev.$toasted.global.error({
                        message: data.msg
                    });
                }
                ev.getStudentData();
            })
         }
     }
}

</script>
<style scoped>
  .btn-top-table{
    display: inline-block;
    padding:10px;
    width: 50%;
  }
  .table{
    width:100%;
   
  }
  .table-dt{
    overflow: scroll;
  }
  .tbl-action{
    margin: 2px;
    border:unset;
  }
</style>