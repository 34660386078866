<template>
<div name="student">

    <MobileHeader></MobileHeader>
    <div class="d-flex flex-row flex-column-fluid page">
        <div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">

            <Header></Header>

            <!--begin::Content-->
            <div class="content d-flex flex-column flex-column-fluid" id="kt_content">

                <!--begin::Entry-->
                <div class="d-flex flex-column-fluid">
                    <!--begin::Container-->
                    <!--begin::Dashboard-->

                    <div class="container">
                        <div class="row">
                            <div class="col-md-4">
                                <div class="card card-custom mt-5">
                                    <div class="card-body">
                                        <div class="navi navi-bold navi-hover navi-active navi-link-rounded">
                                           
                                            <div class="navi-item mb-2">
                                                <a :class="open_form=='text'?'navi-link py-4 active':'navi-link py-4'" @click="open_form='text'">
                                                    <span class="navi-icon mr-2">
                                                        <img src="../../assets/icons/text_icon.svg" class="icon-svg">
                                                    </span>
                                                    <span class="navi-text font-size-lg">Text</span>
                                                </a>
                                            </div>
                                            
                                            <div class="navi-item mb-2">
                                                <a :class="open_form=='pdf'?'navi-link py-4 active':'navi-link py-4'" @click="open_form='pdf'">
                                                    <span class="navi-icon mr-2">
                                                        <img src="../../assets/icons/pdf_icon.svg" class="icon-svg">
                                                    </span>
                                                    <span class="navi-text font-size-lg">PDF</span>
                                                </a>
                                            </div>

                                            <div class="navi-item mb-2">
                                                <a :class="open_form=='video'?'navi-link py-4 active':'navi-link py-4'" @click="open_form='video'">
                                                    <span class="navi-icon mr-2">
                                                        <img src="../../assets/icons/video_icon.svg" class="icon-svg">
                                                    </span>
                                                    <span class="navi-text font-size-lg"> Video</span>
                                                </a>
                                            </div>

                                            <div class="navi-item mb-2">
                                                <a :class="open_form=='recording'?'navi-link py-4 active':'navi-link py-4'" @click="open_form='recording'">
                                                    <span class="navi-icon mr-2">
                                                        <img src="../../assets/icons/recording_icon.svg" class="icon-svg">
                                                    </span>
                                                    <span class="navi-text font-size-lg">Video Recording</span>
                                                </a>
                                            </div>

                                            <div class="navi-item mb-2">
                                                <a :class="open_form=='audio'?'navi-link py-4 active':'navi-link py-4'" @click="open_form='audio'">
                                                    <span class="navi-icon mr-2">
                                                        <img src="../../assets/icons/audio_icon.svg" class="icon-svg">
                                                    </span>
                                                    <span class="navi-text font-size-lg">Audio</span>
                                                </a>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="col-md-8">
                                <div class="card card-custom mt-5">
                                    <div class="card-body">

                                        <div class="container" v-if="open_form=='text'">

                                            <div class="card-body">

                                                <div class="form-group">
                                                    <label>Title <span class="text-danger">*</span></label>
                                                    <input type="text" class="form-control" v-model="title" placeholder="Enter title" />
                                                </div>

                                                <div class="form-group">
                                                    <label>Content <span class="text-danger">*</span></label>
                                                    <vue-editor v-model="content"></vue-editor>
                                                </div>

                                            </div>
                                            <div class="card-footer">
                                                <button type="reset" class="btn btn-primary mr-2" @click="text_lesson_save">Submit</button>
                                                <button type="reset" class="btn btn-secondary" @click="setRout">Cancel</button>
                                            </div>

                                        </div>

                                        <div class="container" v-if="open_form=='pdf'">

                                            <div class="card-body">
                                                <div class="form-group">
                                                    <label>Title <span class="text-danger">*</span></label>
                                                    <input type="text" class="form-control" v-model="title" placeholder="Enter title" />
                                                </div>

                                                <div class="form-group">
                                                    <label>Upload PDF <span class="text-danger">*</span></label>
                                                    <div class="upload-area" @click="$refs.file.click()">
                                                        <img class="icon-upload" src="../../assets/icons/upload_icon.svg">
                                                        <br>
                                                        <span class='select-file-span'>{{select_file_name}}</span>
                                                    </div>
                                                </div>
                                                <input class="hide" type="file" id="file" ref="file" @change="upload_file" accept="application/pdf, application/vnd.ms-excel" />

                                            </div>
                                            <div class="card-footer">
                                                <button type="reset" class="btn btn-primary mr-2" @click='pdf_lesson_save'>Submit</button>
                                                <button type="reset" class="btn btn-secondary" @click="setRout" >Cancel</button>
                                            </div>

                                        </div>

                                        <div class="container" v-if="open_form=='video'">

                                            <div class="card-body">
                                                <div class="form-group">
                                                    <label>Title <span class="text-danger">*</span></label>
                                                    <input type="text" class="form-control" v-model="title" placeholder="Enter title" />
                                                </div>

                                                <div class="form-group">
                                                    <label>Upload Video <span class="text-danger">*</span></label>
                                                    <select class="form-control" v-model="vid">
                                                        <option value="">Select Video</option>
                                                        <template v-for="vdata in videoList">
                                                            <option :key="vdata.id" :value="vdata.id">{{vdata.title}}</option>
                                                        </template>

                                                    </select>
                                                </div>
                                            </div>

                                            <div class="card-footer">
                                                <button type="reset" class="btn btn-primary mr-2" @click="video_lesson_save">Submit</button>
                                                <button type="reset" class="hide btn btn-secondary" @click="setRout" >Cancel</button>

                                            </div>

                                        </div>

                                        <div class="container" v-if="open_form=='recording'">

                                            <div class="card-body">

                                            </div>

                                        </div>

                                        <div class="container" v-if="open_form=='audio'">

                                            <div class="card-body">

                                                <div class="form-group">
                                                    <label>Title <span class="text-danger">*</span></label>
                                                    <input type="text" class="form-control" v-model="title" placeholder="Enter title" />
                                                </div>

                                                <div class="form-group">
                                                    <label>Upload Audio <span class="text-danger">*</span></label>
                                                    <div class="upload-area" @click="$refs.audio_r.click()">
                                                        <img class="icon-upload" src="../../assets/icons/upload_icon.svg">
                                                        <br>
                                                        <span class='select-file-span'>{{select_audio_file}}</span>
                                                    </div>
                                                </div>
                                                <input class="hide" type="file" id="file" ref="audio_r" @change="audio_upload_file" accept="audio/*" />

                                            </div>
                                            <div class="card-footer">
                                                <button type="reset" class="btn btn-primary mr-2" @click="audio_lesson_save">Submit</button>
                                                <button type="reset" class="btn btn-secondary" @click="setRout" >Cancel</button>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

        </div>
    </div>

</div>
</template>

<script>
import axios from 'axios'
import MobileHeader from '../../components/MobileHeader'
import Header from '../../components/Header'
import Footer from '../../components/Footer'

import {
    VueEditor
} from "vue2-editor";

export default {
    name: 'ViewLesson',
    components: {
        Header,
        Footer,
        MobileHeader,
        VueEditor

    },
    data() {
        return {
            uid: localStorage.getItem("uid"),
            role: localStorage.getItem("role"),
            content: null,
            title: null,
            cid: this.$route.params.id,
            open_form: 'text',
            file: "",
            select_file_name: null,
            audio_file: "",
            select_audio_file: null,
            video_file: "",
            select_video_file: null,
            urr: null,
            percentage: 0,
            upload_process: false,
            videoList: [],
            vid: "",

        }
    },
    mounted() {
        if (this.uid == null || this.uid == "") {
            this.$router.push("/");
        }
        this.allVideo();
    },
    watch: {

    },
    methods: {
        setRout(){
             this.$router.push("/viewlesson/"+this.cid);
        },
        allCourse() {
            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'lms_api.php',
                data: {
                    type: 'allCourse',
                }
            }).then(function (response) {
                //console.log(response.data)
                var data = response.data;
                ev.course_data = data.data;
                ev.newAry = data.data;
                ev.totalCount = data.count;
                ev.changePage();

            })
        },
        allVideo() {
            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'lms_api.php',
                data: {
                    type: 'videolibrary',
                }
            }).then(function (response) {
                var data = response.data;
                ev.videoList = data;

            })
        },
        text_lesson_save() {
            var ev = this;
            if (this.title == null || this.title == "") {
                ev.$toasted.global.error({
                    message: "please enter title"
                });
            } else if (this.content == null || this.content == "") {
                ev.$toasted.global.error({
                    message: "please enter content"
                });
            } else {

                axios({
                    method: 'POST',
                    url: this.$root.URL_ROOT + 'lms_api.php',
                    data: {
                        type: 'text_lesson_save',
                        cid: this.cid,
                        title: this.title,
                        content: this.content,
                        ctype: 'text',
                        uid: this.uid,
                    }
                }).then(function (response) {
                    //console.log(response.data);
                    var data=response.data
                    if (data.status == 'success') {
                        ev.$toasted.global.success({
                            message: data.msg
                        });
                        ev.$router.push("/viewlesson/"+ev.$route.params.id);
                    } else {
                        ev.$toasted.global.error({
                            message: data.msg
                        });
                    }
                })
            }
        },
        upload_file() {
            var ev = this;
            this.file = this.$refs.file.files[0];

            let formData = new FormData();
            this.select_file_name = this.file.name;

        },
        pdf_lesson_save: function () {
            var ev = this;
            this.file = this.$refs.file.files[0];

            let formData = new FormData();

            formData.append('file', this.file);
            formData.append('title', this.title);
            formData.append('cid', this.cid);
            formData.append('uid', this.uid);
            if (this.title == null || this.title == "") {
                ev.$toasted.global.error({
                    message: "please enter title"
                });
            } else if (this.file == undefined) {
                ev.$toasted.global.error({
                    message: "please upload pdf file"
                });
            } else {
                this.select_file_name = this.file.name;

                axios.post(this.$root.URL_ROOT + 'lms_api.php?type=upload_pdf', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(function (response) {
                        var data = response.data;
                        if (data.status == "success") {
                            ev.$toasted.global.success({
                                message: data.msg
                            });
                            //ev.$router.push("/viewlesson/" + ev.cid);
                            ev.$router.push("/viewlesson/"+ev.$route.params.cid);
                        } else {
                            ev.$toasted.global.error({
                                message: data.msg
                            });
                        }
                    })

            }

        },
        audio_upload_file() {
            var ev = this;
            this.audio_file = this.$refs.audio_r.files[0];

            let formData = new FormData();
            this.select_audio_file = this.audio_file.name;

        },
        audio_lesson_save: function () {
            var ev = this;
            this.audio_file = this.$refs.audio_r.files[0];

            let formData = new FormData();

            formData.append('file', this.audio_file);
            formData.append('title', this.title);
            formData.append('cid', this.cid);
            formData.append('uid', this.uid);
            if (this.title == null || this.title == "") {
                ev.$toasted.global.error({
                    message: "please enter title"
                });
            } else if (this.file == undefined) {
                ev.$toasted.global.error({
                    message: "please upload audio file"
                });
            } else {
                //this.select_audio_file = this.file.name;

                axios.post(this.$root.URL_ROOT + 'lms_api.php?type=upload_audio', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(function (response) {
                        var data = response.data;
                        if (data.status == "success") {
                            ev.$toasted.global.success({
                                message: data.msg
                            });
                            //ev.$router.push("/viewlesson/" + ev.cid);
                            ev.$router.push("/viewlesson/"+ev.$route.params.cid);
                        } else {
                            ev.$toasted.global.error({
                                message: data.msg
                            });
                        }
                    })

            }

        },
        video_upload_file() {
            var ev = this;
            this.video_file = this.$refs.video_r.files[0];

            let formData = new FormData();
            this.select_video_file = this.video_file.name;

        },
        video_lesson_save_old: function () {
            var ev = this;
            this.video_file = this.$refs.video_r.files[0];

            let formData = new FormData();

            formData.append('file', this.video_file);
            formData.append('title', this.title);
            formData.append('cid', this.cid);
            if (this.title == null || this.title == "") {
                ev.$toasted.global.error({
                    message: "please enter title"
                });
            } else if (this.file == undefined) {
                ev.$toasted.global.error({
                    message: "please upload video file"
                });
            } else {
                //this.select_audio_file = this.file.name;

                axios.post(this.$root.URL_ROOT + 'lms_api.php?type=upload_video', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(function (response) {
                        var data = response.data;
                        if (data.status == "success") {
                            ev.$toasted.global.success({
                                message: data.msg
                            });
                            ev.$router.push("/viewlesson/" + ev.cid);

                        } else {
                            ev.$toasted.global.error({
                                message: data.msg
                            });
                        }
                    })

            }

        },
        video_lesson_save: function () {
            var ev = this;
            if (this.title == null || this.title == "") {
                ev.$toasted.global.error({
                    message: "please enter title"
                });
            } else if (this.file == undefined) {
                ev.$toasted.global.error({
                    message: "please upload video file"
                });
            } else {
                axios({
                    method: 'POST',
                    url: this.$root.URL_ROOT + 'lms_api.php',
                    data: {
                        type: 'upload_video',
                        cid: this.cid,
                        title: this.title,
                        vid: this.vid,
                        uid: this.uid,

                    }
                }).then(function (response) {
                    var data = response.data;
                    //console.log(data)
                    if (data.status == 'success') {
                        ev.$toasted.global.success({
                            message: data.msg
                        });
                        ev.$router.push("/viewlesson/"+ev.$route.params.cid);
                    } else {
                        ev.$toasted.global.error({
                            message: data.msg
                        });
                    }
                })

            }

        },
    },
}
</script>

<style scoped>
.process {
    font-size: 22px;
    font-weight: bold;
    color: #3698fb;
}

.select-file-span {
    color: #3698fb;
    margin-top: 20px;
    font-size: 16px;
}

.icon-upload {
    width: 40px;
    margin-bottom: 15px;
}

.upload-area {
    padding: 5%;
    text-align: center;
    border: 1px solid #3898fb;
    border-radius: 10px;
}

.icon-svg {
    height: 25px;
}

.btn-top-table {
    display: inline-block;
    padding: 10px;
    width: 50%;
}

.table {
    width: 100%;

}

.table-dt {
    overflow: scroll;
}

.tbl-action {
    margin: 2px;
    border: unset;
}
</style>
