<template>
<div name="student">

    <MobileHeader></MobileHeader>
    <div class="d-flex flex-row flex-column-fluid page">
        <div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">

            <Header></Header>

            <!--begin::Content-->
            <div class="content d-flex flex-column flex-column-fluid" id="kt_content">

                <!--begin::Entry-->
                <div class="d-flex flex-column-fluid">
                    <!--begin::Container-->
                    <!--begin::Dashboard-->

                    <div class="container">
                        <div class="row">
                            
                            <div class="col-md-12">
                                <div class="card card-custom mt-5">
                                    <div class="card-body">

                                        

                                        <div class="container">

                                            <div class="card-body">
                                                <div class="form-group">
                                                    <label>Title <span class="text-danger">*</span></label>
                                                    <input type="text" class="form-control" v-model="title" placeholder="Enter title" />
                                                </div>

                                                <div class="form-group">
                                                    <label>Upload Video <span class="text-danger">*</span></label>
                                                    <div class="upload-area" @click="$refs.video_r.click()">
                                                        <img class="icon-upload" src="../../assets/icons/upload_icon.svg">
                                                        <br>
                                                        <span class='select-file-span'>{{select_video_file}}</span>
                                                    </div>
                                                </div>
                                                
                                                <input class="hide" type="file" id="file" ref="video_r" @change="video_upload_file" accept="video/*"  />
                                            </div>

                                            <div class="card-footer">
                                                <button type="reset" class="btn btn-primary mr-2" @click="video_lesson_save">Submit</button>
                                                <button type="reset" class="hide btn btn-secondary">Cancel</button>

                                               
                                            </div>

                                             <progress style="width:100%;" id="file" v-if="upload_process" :value="percentage" max="100"> {{percentage}}}% </progress>
                                             <span class="process" v-if="upload_process">{{percentage}} %</span>
                                        </div>

                                        

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

        </div>
    </div>

</div>
</template>

<script>
import axios from 'axios'
import MobileHeader from '../../components/MobileHeader'
import Header from '../../components/Header'
import Footer from '../../components/Footer'

import {
    VueEditor
} from "vue2-editor";

export default {
    name: 'AddLibrary',
    components: {
        Header,
        Footer,
        MobileHeader,
        VueEditor

    },
    data() {
        return {
            uid: localStorage.getItem("uid"),
            role: localStorage.getItem("role"),
            file: "",
            select_file_name: null,
            video_file: "",
            select_video_file: null,
            urr: null,
            percentage:0,
            upload_process:false,
            title: null,

        }
    },
    mounted() {
        if (this.uid == null || this.uid == "") {
            this.$router.push("/");
        }

    },
    watch: {

    },
    methods: {
       
        upload_file() {
            var ev = this;
            this.file = this.$refs.file.files[0];

            let formData = new FormData();
            this.select_file_name = this.file.name;

        },
        
        video_upload_file() {
            var ev = this;
            this.video_file = this.$refs.video_r.files[0];

            let formData = new FormData();
            this.select_video_file = this.video_file.name;
            console.log(this.$refs.video_r);

        },
        video_lesson_save: function () {
            var ev = this;
            this.video_file = this.$refs.video_r.files[0];

            let formData = new FormData();

            if (this.title == null || this.title == "") {
                ev.$toasted.global.error({
                    message: "please enter title"
                });
            } else if (this.file == undefined) {
                ev.$toasted.global.error({
                    message: "please upload video file"
                });
            } else {
                ev.upload_process=true;
                //this.select_audio_file = this.file.name;
                let file_name = this.$refs.video_r.files[0];
                this.$root.client.upload(
                    file_name, {
                        'name': ev.title,
                        'description': ''
                    },
                    function (uri) {
                        //console.log('Your video URI is: ' + uri);
                        ev.urr = uri;
                        //ev.fn2(uri);
                        formData.append('title', ev.title);
                        formData.append('video', ev.urr);
                        formData.append('uid', ev.uid);
                      
                        axios.post(ev.$root.URL_ROOT + 'lms_api.php?type=add_video', formData, {
                                headers: {
                                    'Content-Type': 'multipart/form-data'
                                }
                            })
                            .then(function (response) {
                                var data = response.data;
                                if (data.status == "success") {
                                    ev.$toasted.global.success({
                                        message: data.msg
                                    });
                                    ev.$router.push("/library");
    
                                } else {
                                    ev.$toasted.global.error({
                                        message: data.msg
                                    });
                                    ev.upload_process=false;
                                    ev.percentage=0;
                                }
                            })
                        
                    },
                    function (bytes_uploaded, bytes_total) {

                         ev.percentage = (bytes_uploaded / bytes_total * 100).toFixed(2)
                        console.log(bytes_uploaded, bytes_total, ev.percentage + '%')
                        
                    },
                    function (error) {
                         ev.$toasted.global.error({
                                        message: error
                                    });
                        //console.log('Failed because: ' + error)
                    }
                )

            }

        },
    },
}
</script>

<style scoped>
.upload-area{
    cursor: pointer;
}
.process{
    font-size: 22px;
    font-weight: bold;
    color:#3698fb;
}
.select-file-span {
    color: #3698fb;
    margin-top: 20px;
    font-size: 16px;
}

.icon-upload {
    width: 40px;
    margin-bottom: 15px;
}

.upload-area {
    padding: 5%;
    text-align: center;
    border: 1px solid #3898fb;
    border-radius: 10px;
}

.icon-svg {
    height: 25px;
}

.btn-top-table {
    display: inline-block;
    padding: 10px;
    width: 50%;
}

.table {
    width: 100%;

}

.table-dt {
    overflow: scroll;
}

.tbl-action {
    margin: 2px;
    border: unset;
}
</style>
