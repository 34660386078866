<template>
<div id="app">
   
    <router-view />
   <!--
    <div class="Loader__div">
        <div class="inner__Loader__div">
            <div class="loadingio-spinner-wedges-ib02bbdgo2">
                <div class="ldio-poo4y6sv1h">
                    <div>
                        <div>
                            <div></div>
                        </div>
                        <div>
                            <div></div>
                        </div>
                        <div>
                            <div></div>
                        </div>
                        <div>
                            <div></div>
                        </div>
                   </div>
                </div>
            </div>
        </div>
    </div>
    ---->

</div>
</template>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@300&family=Poppins:wght@500&display=swap');

/*@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');*/
body {
    font-family: 'Poppins', sans-serif;
}

.pull-right {
    float: right;
}


@keyframes ldio-poo4y6sv1h {
    0% { transform: rotate(0deg) }
    100% { transform: rotate(360deg) }
  }
  .ldio-poo4y6sv1h > div > div {
    transform-origin: 100px 100px;
    animation: ldio-poo4y6sv1h 3.0303030303030303s linear infinite;
    opacity: 0.8
  }
  .ldio-poo4y6sv1h > div > div > div {
    position: absolute;
    left: 30px;
    top: 30px;
    width: 70px;
    height: 70px;
    border-radius: 70px 0 0 0;
    transform-origin: 100px 100px
  }.ldio-poo4y6sv1h > div div:nth-child(1) {
    animation-duration: 0.7575757575757576s
  }
  .ldio-poo4y6sv1h > div div:nth-child(1) > div {
    background: #E15B64;
    transform: rotate(0deg);
  }.ldio-poo4y6sv1h > div div:nth-child(2) {
    animation-duration: 1.0101010101010102s
  }
  .ldio-poo4y6sv1h > div div:nth-child(2) > div {
    background: #F47E60;
    transform: rotate(0deg);
  }.ldio-poo4y6sv1h > div div:nth-child(3) {
    animation-duration: 1.5151515151515151s
  }
  .ldio-poo4y6sv1h > div div:nth-child(3) > div {
    background: #F8B26A;
    transform: rotate(0deg);
  }.ldio-poo4y6sv1h > div div:nth-child(4) {
    animation-duration: 3.0303030303030303s
  }
  .ldio-poo4y6sv1h > div div:nth-child(4) > div {
    background: #ABBD81;
    transform: rotate(0deg);
  }
  .loadingio-spinner-wedges-ib02bbdgo2 {
    width: 200px;
    height: 200px;
    display: inline-block;
    overflow: hidden;
    background: transparent;
  }
  .ldio-poo4y6sv1h {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
  }
  .ldio-poo4y6sv1h div { box-sizing: content-box; }
  .Loader__div{
      position: absolute;
      top: 0;left: 0;
      z-index: 111111;
      background:rgba(0, 0, 0, 0.5);
      width:100%;height: 100vh;
      display:grid;
      align-items:center;
  }
  .inner__Loader__div{
      text-align: center;
  }



</style>

<script>
//import { MyMixin } from './assets/mixin/common'
import axios from 'axios'

import '@/assets/css/pages/login/classic/login-1.css';
import '@/assets/plugins/custom/fullcalendar/fullcalendar.bundle.css';
import '@/assets/plugins/global/plugins.bundle.css';
import '@/assets/plugins/custom/prismjs/prismjs.bundle.css';
import '@/assets/css/style.bundle.css';
import '@/assets/css/pages/wizard/wizard-3.css';
import '@/assets/css/pages/wizard/wizard-2.css';

//import '@/assets/js/record.js';

//

//require('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700');

export default {
    // mixins: [MyMixin],
    components: {

    },
    data() {
        return {
            chknav: this.$route.name,
            ip_address: JSON.parse(localStorage.getItem('ip_address')),
            uid: localStorage.getItem("uid"),
            role: localStorage.getItem("role"),
            client_id: '22c039caab94b46eb86fc0c0fc1b56ff09d69399',
            client_secret: 'bUM1dWdx+TfemjEVctAKqszthGvQipDOPRerwbVqiOu9hljdJKhsfI5sX+MMDsgHzvvdmJol9wzouzvKkMlG8jBv3eq/T4ehlpilxE68elsw5D3U5xm+frmcA4xIDGk0',
            access_token: 'b10bf31ef34983f1d8e9cdf248eded5a',
           
        }
    },
    mounted() {

        if (this.uid == null || this.uid == "") {
            if (this.chknav == "AdminLogin") {
                // this.$router.push("/adminlogin");
            } else {
                //this.$router.push("/");
            }
        }
              
       
        axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'lms_api.php',
                data: {
                    type: 'change_enroll_status',
                }
            }).then(function (response) {
                var data = response.data;
                //console.log("dsadasdsaddsad");
                //console.log(data);
               
            })

    },
    watch: {
        chknav: function (val) {
            this.chknav = val
        }
    },
    methods: {
        async getConfig() {
            const response = await axios.get(this.$root.URL_ROOT + 'api.php?type=configdata')
            this.$store.commit('getConfig', response.data)
            //console.log(this.$store.state.config)
        },
        myIpData() {
            var ip_address = JSON.parse(localStorage.getItem('ip_address'))

            if (ip_address == null) {
                var min = 10000000000
                var max = 99999999999
                var num = Math.floor(Math.random() * (max - min + 1)) + min
                localStorage.setItem('ip_address', JSON.stringify(num))

            } else {

            }
        }

    }
}
</script>
