<template>
<div name="student">

    <MobileHeader></MobileHeader>
    <div class="d-flex flex-row flex-column-fluid page">
        <div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">

            <Header></Header>

            <!--begin::Content-->
            <div class="content d-flex flex-column flex-column-fluid" id="kt_content">

                <!--begin::Entry-->
                <div class="d-flex flex-column-fluid">
                    <!--begin::Container-->
                    <!--begin::Dashboard-->

                    <div class="container">
                        <div class="card card-custom mt-5 mb-15">
                            <div class="card-header mt-5">
                                <!--begin::Search Form-->
                                <div class="card-title" style="width:80%;">
                                    <div class="form-group" style="width:100%;">
                                        <label>User select</label><br>
                                        <select class="form-control" v-model="user_id">
                                            <option value="Select User">Select User</option>
                                            <template v-for="cdata in student_data">
                                                <option :key="cdata.id" :value="cdata.id">{{cdata.username}}</option>
                                            </template>
                                        </select>
                                    </div>
                                    <div class="form-group ml-5" style="width:100%;">
                                        <label>Role select</label><br>
                                        <select class="form-control" v-model="role_data">
                                            <option value="">Select Role</option>
                                            <option value="Site Admin">Site Admin</option>
                                            <option value="Course Admin">Course Admin</option>
                                        </select>
                                    </div>
                                    <div class="form-group ml-5">
                                        <label>Permissions:</label>
                                        <div class="checkbox-list">
                                            <label class="checkbox">
                                                <input type="checkbox" v-model="edit_check" name="permision[]" value="Edit">
                                                <span></span>Edit
                                            </label>
                                            <label class="checkbox">
                                                <input type="checkbox" v-model="view_check" name="permision[]" value="View">
                                                <span></span>View
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-toolbar">
                                    <input type="submit" @click="save_role" name="enroll" value="Assign" class="btn btn-lg btn-light-primary px-6 font-weight-bold">
                                </div>
                            </div>
                            <div class="card-header" id="1">
                                <div class="d-flex align-items-center bg-light-info rounded p-5 mt-5 mb-5">
                                    <!--begin::Title-->
                                    <div class="d-flex flex-column flex-grow-1 mr-2">
                                        <ul>
                                            <li>Can access everything in your site</li>
                                            <li>Can assign user roles</li>
                                            <li>Can NOT transfer account ownership</li>
                                            <li>Can NOT set up or edit payment integrations</li>
                                        </ul>
                                        <p>Note: The Course admin or Group analyst roles cannot be assigned if this user is a Site admin.4/10 Site admins have been assigned. Please upgrade your plan to have more Site admin</p>
                                    </div>
                                    <!--end::Title-->
                                </div>
                            </div>
                            <div class="card-header" id="2" style="display:none">
                                <div class="d-flex align-items-center bg-light-info rounded p-5 mt-5 mb-5">
                                    <!--begin::Title-->
                                    <div class="d-flex flex-column flex-grow-1 mr-2">
                                        <ul>
                                            <li>Can access everything in your site</li>
                                            <li>Can NOT set up or edit payment integrations</li>
                                        </ul>
                                        <p>Note: The Course admin or Group analyst roles cannot be assigned if this user is a Site admin.4/10 Site admins have been assigned. Please upgrade your plan to have more Site admin</p>
                                    </div>
                                    <!--end::Title-->
                                </div>
                            </div>
                            <div class="card-body">

                                <div id="example_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">

                                    <div class="row">
                                        <div class="col-sm-12">
                                            <table id="example" class="table dataTable no-footer" style="width: 100%;" role="grid" aria-describedby="example_info">
                                                <thead>
                                                    <tr role="row">
                                                        <th title="Field #1" class="sorting sorting_asc" tabindex="0" aria-controls="example" rowspan="1" colspan="1" aria-sort="ascending" aria-label="Name: activate to sort column descending" style="width: 287px;">Name</th>
                                                        <th title="Field #2" class="sorting" tabindex="0" aria-controls="example" rowspan="1" colspan="1" aria-label="Role: activate to sort column ascending" style="width: 429px;">Role</th>
                                                        <th title="Field #3" class="sorting" tabindex="0" aria-controls="example" rowspan="1" colspan="1" aria-label="Permission: activate to sort column ascending" style="width: 439px;">Permission</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <template v-for="rdata in course_data">
                                                        <tr class="odd" :key="rdata.id">
                                                            <td style="text-transform:capitalize" class="sorting_1">{{rdata.username}}</td>
                                                            <td style="text-transform:capitalize">{{rdata.role}}</td>
                                                            <td style="text-transform:capitalize">{{rdata.permission}}</td>

                                                        </tr>
                                                    </template>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                </div>
                                <!--end: Datatable-->
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>

</div>
</template>

<script>
import axios from 'axios'
import MobileHeader from '../../components/MobileHeader'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import Pagination from 'vue-pagination-2';

export default {
    name: 'Student',
    components: {
        Header,
        Footer,
        MobileHeader,
        Pagination,

    },
    data() {
        return {
            uid: localStorage.getItem("uid"),
            role: localStorage.getItem("role"),
            pop_val: false,
            edit_pop_val: false,
            course_name: null,
            edit_course_name: null,
            edit_cid: null,
            course_data: [],
            page: 1,
            totalCount: 0,
            newAry: [],
            perPage: 5,
            student_data: [],

            user_id: null,
            role_data: null,
            edit_check: false,
            view_check: false,

        }
    },
    mounted() {
        //console.log(localStorage.getItem("uid"))
        if (this.uid == null || this.uid == "") {
            this.$router.push("/");
        }
        this.getStudentList();
        this.getRoles();
    },
    watch: {
        page: function (vl) {
            this.changePage();
        }
    },
    methods: {
        changePage() {
            this.course_data = [];
            var preCount = (this.perPage * this.page);

            var temp = this.perPage;
            var newPP = (preCount) - temp;
            //console.log(newPP);
            for (var i = 0; i < temp; i++) {
                if (this.newAry[newPP]) {
                    this.course_data[i] = this.newAry[newPP];
                    newPP++;
                }

            }
        },

        allCourse() {
            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'lms_api.php',
                data: {
                    type: 'getAllTeam',
                    uid: this.uid,
                    role: this.role,
                }
            }).then(function (response) {
                //console.log(response.data)
                var data = response.data;
                ev.course_data = data.data;
                ev.newAry = data.data;
                ev.totalCount = data.count;
                ev.changePage();

            })
        },
        getStudentList() {
            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'lms_api.php',
                data: {
                    type: 'getStudentList',

                }
            }).then(function (response) {
                //console.log(response.data);
                var data = response.data;
                ev.student_data = data;
            })
        },
        save_role() {
            var ev = this;

            if (this.user_id == null || this.user_id == 'Select User' || this.user_id == "") {
                ev.$toasted.global.error({
                    message: 'PLease select user'
                });
            } else if (this.role_data == null || this.role_data == "") {
                ev.$toasted.global.error({
                    message: 'PLease select role'
                });
            } else if (this.edit_check == false && this.view_check == false) {
                ev.$toasted.global.error({
                    message: 'PLease select permissions'
                });
            } else {

                axios({
                    method: 'POST',
                    url: this.$root.URL_ROOT + 'lms_api.php',
                    data: {
                        type: 'save_role',
                        uid: this.user_id,
                        role: this.role_data,
                        edit_check: this.edit_check,
                        view_check: this.view_check,
                    }
                }).then(function (response) {
                    var data = response.data;
                    //console.log(data)
                    if (data.status == 'success') {
                        ev.$toasted.global.success({
                            message: data.msg
                        });
                        ev.getRoles();
                            ev.user_id=null;
                            ev.role_data=null;
                            ev.edit_check=false;
                            ev.view_check=false;
                    }
                })
            }
        },
        getRoles() {
            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'lms_api.php',
                data: {
                    type: 'getRoles',

                }
            }).then(function (response) {
                var data = response.data;
                if (data.status == 'success') {
                    ev.course_data = data.data;
                    ev.newAry = data.data;
                    ev.totalCount = data.count;
                    ev.changePage();
                }
            })
        }

    },
}
</script>

<style scoped>
@media only screen and (max-width: 600px) {

    .main-popup-overlay {
        padding-top: 30% !important;
        padding-left: 5% !important;
        padding-right: 5% !important;
    }
}

.btn-popup-cancel {
    float: right;
    width: 120px;
}

.main-popup {
    position: fixed;
    width: 100%;
    padding: 20%;
}

.popup-header {
    text-align: center;
}

.btn-div-popup {
    padding-top: 5%;

}

.btn-popup-save {
    float: left;
    width: 120px;

}

.content-start-popup {

    background: #ffffff;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 10%;
    padding-bottom: 10%;
    border-radius: 15px;
}

.main-popup-overlay {
    position: fixed;
    width: 100%;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: #0404046e;
    padding-top: 20%;
    padding-right: 30%;
    padding-left: 30%;
    z-index: 999999999;
}

.btn-top-table {
    display: inline-block;
    padding: 10px;
    width: 50%;
}

.table {
    width: 100%;

}

.table-dt {
    overflow: scroll;
}

.tbl-action {
    margin: 2px;
    border: unset;
}
</style>
