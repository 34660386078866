<template>
<div name="student">

    <MobileHeader></MobileHeader>
    <div class="d-flex flex-row flex-column-fluid page">
        <div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">

            <Header></Header>

            <!--begin::Content-->
            <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
                <!--begin::Entry-->
                <div class="d-flex flex-column-fluid">
                    <!--begin::Container-->
                    <!--begin::Dashboard-->

                    <div class="container">
                        <div class="card card-custom mt-5">
                            <!--begin::Header-->
                            <div class="card-header flex-wrap border-0 pt-6 pb-0">
                                <div class="card-title">
                                    <h3 class="card-label">Order Management
                                        <span class="d-block text-muted pt-2 font-size-sm">Order management</span></h3>
                                </div>
                                <div class="card-toolbar">

                                </div>
                            </div>
                            <!--end::Header-->
                            <div class="card-body">
                                <!--begin: Datatable-->
                                <div id="example_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                                    
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <table id="example" class="table dataTable no-footer" style="width: 100%;" role="grid" aria-describedby="example_info">
                                                <thead>
                                                    <tr role="row">
                                                        <th title="Field #1" class="sorting sorting_asc" tabindex="0" aria-controls="example" rowspan="1" colspan="1" aria-sort="ascending" aria-label="Payment ID: activate to sort column descending" style="width: 297px;">Payment ID</th>
                                                        <th title="Field #2" class="sorting" tabindex="0" aria-controls="example" rowspan="1" colspan="1" aria-label="Course: activate to sort column ascending" style="width: 207px;">Course</th>
                                                        <th title="Field #3" class="sorting" tabindex="0" aria-controls="example" rowspan="1" colspan="1" aria-label="Name: activate to sort column ascending" style="width: 184px;">Name</th>
                                                        <th title="Field #4" class="sorting" tabindex="0" aria-controls="example" rowspan="1" colspan="1" aria-label="Amount: activate to sort column ascending" style="width: 225px;">Amount</th>
                                                        <th title="Field #5" class="sorting" tabindex="0" aria-controls="example" rowspan="1" colspan="1" aria-label="Action: activate to sort column ascending" style="width: 191px;">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr class="odd">
                                                        <td valign="top" colspan="5" class="dataTables_empty">No data available in table</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    
                                </div>
                                <!--end: Datatable-->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
</template>

<script>
import axios from 'axios'
import MobileHeader from '../../components/MobileHeader'
import Header from '../../components/Header'
import Footer from '../../components/Footer'

export default {
    name: 'AddCourse',
    components: {
        Header,
        Footer,
        MobileHeader,

    },
    data() {
        return {
            uid: localStorage.getItem("uid"),
            role: localStorage.getItem("role"),
        }
    },
    mounted() {
        if (this.uid == null || this.uid == "") {
            this.$router.push("/");
        }
    },
    methods: {

    },
}
</script>

<style scoped>
</style>
