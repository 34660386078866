<template>
<div name="student">

    <MobileHeader></MobileHeader>
    <div class="d-flex flex-row flex-column-fluid page">
        <div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">

            <Header></Header>

            <!--begin::Content-->
            <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
                <!--begin::Entry-->
                <div class="d-flex flex-column-fluid">
                    <!--begin::Container-->
                    <!--begin::Dashboard-->

                    <div class="container">
                        <div class="card card-custom mt-5" v-if="open_edit_box==false">
                            <div class="card-header">
                                <div class="card-title">
                                    <router-link to="/addlibrary" v-if="role!=2" class="btn btn-primary font-weight-bolder">
                                        Add Video
                                    </router-link>
                                </div>

                                <div class="card-toolbar">
                                    <i class="fa fa-arrow-circle-right" v-if="vtype=='loom'" style="margin-right:10px;font-size:24px;color:#67538d"></i>
                                    <button style="margin-right: 15px;" :class="vtype=='loom'?'btn btn-primary active-btn font-weight-bolder ':'btn btn-primary font-weight-bolder'" @click="vtype='loom'">
                                        Loom Videos
                                    </button>
                                    <i class="fa fa-arrow-circle-right" v-if="vtype=='local'" style="margin-right:10px;font-size:24px;color:#67538d"></i>
                                    <button :class="vtype=='local'?'btn btn-primary active-btn font-weight-bolder':'btn btn-primary font-weight-bolder'" @click="vtype='local'">
                                        Uploaded Videos
                                    </button>

                                </div>

                            </div>
                            <div class="card-body">
                                <div class="tab-content">
                                    <div class="tab-pane fade active show" id="kt_tab_pane_1_2" role="tabpanel">

                                        <!--begin: Datatable-->

                                        <div id="kt_datatable_21_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                                            <div class="row">
                                                <div class="col-sm-8">
                                                    <h2 v-if="vtype=='local'">
                                                        Uploaded Video
                                                    </h2>
                                                    <h2 v-if="vtype=='loom'">
                                                        Loom Video
                                                    </h2>
                                                </div>

                                                <div class="col-sm-12">
                                                    <table class="table table-separate table-head-custom table-checkable dataTable no-footer dtr-inline" id="kt_datatable_21" role="grid" aria-describedby="kt_datatable_21_info" style="width: 1231px;">
                                                        <thead>
                                                            <tr role="row">
                                                                <th title="Field #1" class="sorting" tabindex="0" aria-controls="kt_datatable_21" rowspan="1" colspan="1" style="width: 194px;">Name</th>
                                                                <th title="Field #2" class="sorting_desc" tabindex="0" aria-controls="kt_datatable_21" rowspan="1" colspan="1" style="width: 246px;">Video</th>
                                                                <th title="Field #2" class="sorting_desc" tabindex="0" aria-controls="kt_datatable_21" rowspan="1" colspan="1" style="width: 246px;">Date</th>
                                                                <th v-if="role!=2" title="Field #2" class="sorting_desc" tabindex="0" aria-controls="kt_datatable_21" rowspan="1" colspan="1" style="width: 246px;">Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <template v-for="vdata in allVideo">
                                                                <tr class="odd" :key="vdata.id">
                                                                    <td>{{vdata.title}}</td>
                                                                    <td>
                                                                        <a v-if="vdata.video_type=='local'" target="_blank" :href="'https://player.vimeo.com/video/'+vdata.video">https://player.vimeo.com/video/{{vdata.video}}</a>
                                                                        <a v-if="vdata.video_type=='loom'" target="_blank" :href="vdata.video">{{vdata.video}}</a>

                                                                    </td>
                                                                    <td>
                                                                        {{vdata.udate}}
                                                                    </td>

                                                                    <td v-if="role!=2">
                                                                        <button class="edit-btn-clr label tbl-action label-inline label-light-danger font-weight-bold" @click="open_edit_box=true;getVideoDetail(vdata.id)">
                                                                            <i class="fa fa-edit" style="color:#ffff;"></i>
                                                                            <span class="hide">Edit</span>
                                                                        </button>
                                                                        <button class="delete-btn-clr label tbl-action label-inline label-light-danger font-weight-bold" @click="confirm_pop(vdata.id)">
                                                                            <i class="fa fa-trash" style="color:#ffff;"></i>
                                                                            <span class="hide">Delete</span>
                                                                        </button>
                                                                        <router-link v-if="role==10" :to="'/assignvideo/'+vdata.id" class="edit-btn-clr label tbl-action label-inline label-light-danger font-weight-bold" >
                                                                            <span>Assign Video</span>
                                                                        </router-link>
                                                                    </td>
                                                                </tr>
                                                            </template>

                                                        </tbody>

                                                    </table>
                                                    <pagination v-model="page" :records="totalCount" :per-page="perPage" />
                                                </div>
                                            </div>

                                        </div>
                                        <!--end: Datatable-->
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div class="card card-custom mt-5" v-else>
                            <div class="card-body">

                                <div class="container">

                                    <div class="card-body">
                                        <div class="form-group">
                                            <label>Title <span class="text-danger">*</span></label>
                                            <input type="text" class="form-control" v-model="title" placeholder="Enter title" />
                                        </div>
                                    </div>

                                    <div class="card-footer">
                                        <button type="reset" class="btn btn-primary mr-2" @click="edit_video">Submit</button>
                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
</template>

<script>
import axios from 'axios'
import MobileHeader from '../../components/MobileHeader'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import Pagination from 'vue-pagination-2';

export default {
    name: 'Todo',
    components: {
        Header,
        Footer,
        MobileHeader,
        Pagination

    },
    data() {
        return {
            uid: localStorage.getItem("uid"),
            role: localStorage.getItem("role"),
            allData: [],
            allVideo: [],
            open_edit_box: false,
            title: null,
            vid: null,
            page: 1,
            totalCount: 0,
            newAry: [],
            perPage: 10,
            vtype: 'loom',

        }
    },
    mounted() {
        if (this.uid == null || this.uid == "") {
            this.$router.push("/");
        }
        this.getVideoLibrary();

    },
    watch: {
        page: function (vl) {
            this.changePage();
        },
        vtype: function (vl) {
            this.getVideoLibrary();
        }
    },
    methods: {

        changePage() {
            this.allVideo = [];
            var preCount = (this.perPage * this.page);

            var temp = this.perPage;
            var newPP = (preCount) - temp;
            //console.log(newPP);
            for (var i = 0; i < temp; i++) {
                if (this.newAry[newPP]) {
                    this.allVideo[i] = this.newAry[newPP];
                    newPP++;
                }

            }
        },
        getVideoLibrary() {
            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'lms_api.php',
                data: {
                    type: 'videolibraryByType',
                    vtype: this.vtype,
                    uid: this.uid,
                }
            }).then(function (response) {
                var data = response.data;
                ev.allVideo = data.data;
                ev.newAry = data.data;
                ev.totalCount = data.count;
                ev.changePage();
                //console.log(data);
            })
        },
        getVideoDetail(ids) {
            var ev = this;
            this.vid = ids;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'lms_api.php',
                data: {
                    type: 'videoDetails',
                    vid: this.vid
                }
            }).then(function (response) {
                var data = response.data;
                ev.title = data.title;
                //console.log(data);

            })
        },
        edit_video() {
            var ev = this;
            if (this.title == null || this.title == "") {
                ev.$toasted.global.error({
                    message: "please enter title"
                });
            } else {
                axios({
                    method: 'POST',
                    url: this.$root.URL_ROOT + 'lms_api.php',
                    data: {
                        type: 'edit_video_title',
                        vid: this.vid,
                        title: this.title,
                    }
                }).then(function (response) {
                    var data = response.data;
                    if (data.status == "success") {
                        ev.$toasted.global.success({
                            message: data.msg
                        });
                        ev.title = null;
                        ev.open_edit_box = false;
                        ev.vid = null;
                        ev.getVideoLibrary();
                    } else {
                        ev.$toasted.global.error({
                            message: data.msg
                        });
                    }
                })
            }

        },
        confirm_pop(ids){
            this.$confirm("", "Are You Sure ?").then(() => 
                this.delete_video(ids)
            );
        },
        delete_video(ids) {
            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'lms_api.php',
                data: {
                    type: 'delete_video',
                    id: ids
                }
            }).then(function (response) {
                var data = response.data;
                if(data.status=="success"){
                   ev.getVideoLibrary();  
                    ev.$toasted.global.success({
                    message: data.msg
                }); 
                }
            })
        }
    },
}
</script>

<style scoped>
.active-btn {
    background-color: #F3F6F9 !important;
    color: #67538d !important;
}

.add-btn-s {
    background-color: #3798fb;
    border: none;
    margin: 1%;
}

.btn-top-table {
    display: inline-block;
    padding: 10px;
    width: 50%;
}

.table {
    width: 100%;

}

.table-dt {
    overflow: scroll;
}

.tbl-action {
    margin: 2px;
    border: unset;
}
</style>
