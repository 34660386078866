<template>
<div name="student">

    <MobileHeader></MobileHeader>
    <div class="d-flex flex-row flex-column-fluid page">
        <div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">

            <Header></Header>

            <!--begin::Content-->
            <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
                <!--begin::Entry-->
                <div class="d-flex flex-column-fluid">
                    <!--begin::Container-->
                    <!--begin::Dashboard-->

                    <div class="container">

                        <div class="card card-custom" id="sop">
                            <!--begin::Header-->
                            <div class="card-header flex-wrap border-0 pt-6 pb-0">
                                <div class="card-title">
                                    <h3 class="card-label">KPI's Management
                                        <span class="d-block text-muted pt-2 font-size-sm">KPI's management</span></h3>
                                </div>
                                <div class="card-toolbar">

                                    <router-link to="/assignkpis" >
                                    <button type="button" v-if="role!=5" class="btn btn-primary font-weight-bolder ml-5 pull-right">
                                            Assign KPI</button>
                                    </router-link>
                                    <router-link to="/viewassignkpi" >
                                    <button type="button" class="btn btn-primary font-weight-bolder ml-5 pull-right">
                                            All Assign KPI</button>
                                    </router-link>
                                    <router-link v-if="role!=5" to="/addkpidata" class="btn btn-primary btn-lg font-weight-bolder ml-5 pull-right" >
                                        Add KPI
                                    </router-link>
                                </div>
                            </div>
                            <!--end::Header-->
                            <!--begin::Body-->
                            <div class="card-body">
                                <!--begin: Datatable-->
                                <div id="example_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">

                                    <div class="row">
                                        <div class="col-sm-12">
                                            <table id="example" class="table dataTable no-footer" style="width: 100%;" role="grid" aria-describedby="example_info">
                                                <thead>
                                                    <tr role="row">
                                                        <th title="Field #1" style="width: 282px;" class="sorting sorting_asc" tabindex="0" aria-controls="example" rowspan="1" colspan="1" aria-sort="ascending" aria-label="Name: activate to sort column descending">Name</th>
                                                        <th title="Field #2" style="width: 282px;" class="sorting" tabindex="0" aria-controls="example" rowspan="1" colspan="1" aria-label="Assign: activate to sort column ascending">Assign</th>
                                                        <th title="Field #3" style="width: 282px;" class="sorting" tabindex="0" aria-controls="example" rowspan="1" colspan="1" aria-label="Progress: activate to sort column ascending">Progress</th>
                                                        <th title="Field #4" style="width: 282px;" class="sorting" tabindex="0" aria-controls="example" rowspan="1" colspan="1" aria-label="Action: activate to sort column ascending">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    <template v-for="sdata in allData">
                                                        <tr class="odd" :key="sdata.id">
                                                            <td class="sorting_1">{{sdata.kpi_name}}</td>
                                                            <td>{{sdata.assign}}</td>
                                                            <td>
                                                                <span v-if="sdata.progress==30" class="label label-inline font-weight-bold ml-5 first30" style="font-size:15px;">
                                                                    <b> Inccomplete 0-30% </b>
                                                                </span>
                                                                <span v-if="sdata.progress==70" class="label label-inline font-weight-bold ml-5 sec70" style="font-size:15px;">
                                                                    <b> Progressing 30%-70% </b>
                                                                </span>
                                                                <span v-if="sdata.progress==95" class="label label-inline font-weight-bold ml-5 trd90" style="font-size:15px;">
                                                                    <b> Progressing 70%-95% </b>
                                                                </span>
                                                                <span v-if="sdata.progress==100" class="label label-inline font-weight-bold ml-5 for100" style="font-size:15px;">
                                                                    <b> Progressing 100% </b>
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <router-link :to="'/viewkpis/'+sdata.id">
                                                                    <span class="label label-inline label-light-danger font-weight-bold ml-5">View</span>
                                                                </router-link>
                                                                <router-link v-if="role!=5" :to="'/editkpis/'+sdata.id">
                                                                    <span class="edit-btn-clr label label-inline label-light-danger font-weight-bold ml-5">Edit</span>
                                                                </router-link>
                                                                <a v-if="role!=5" @click="delete_sops(sdata.id)" class="mouse-point">
                                                                    <span class="delete-btn-clr label label-inline label-light-danger font-weight-bold ml-5">Delete</span>
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    </template>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                </div>
                                <!--end: Datatable-->
                            </div>
                            <!--end::Body-->
                        </div>

                        

                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
</template>

<script>
import axios from 'axios'
import MobileHeader from '../../components/MobileHeader'
import Header from '../../components/Header'
import Footer from '../../components/Footer'

export default {
    name: 'Kpis',
    components: {
        Header,
        Footer,
        MobileHeader,

    },
    data() {
        return {
            uid: localStorage.getItem("uid"),
            role: localStorage.getItem("role"),
            allData: [],
        }
    },
    mounted() {
        if (this.uid == null || this.uid == "") {
            this.$router.push("/");
        }
        this.getSchedule();
    },
    methods: {
        getSchedule() {
            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'lms_api.php',
                data: {
                    type: 'getKpisData',

                }
            }).then(function (response) {
                var data = response.data;
                ev.allData = data;
                //console.log(data);
            })
        },
        delete_sops(ids) {
            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'lms_api.php',
                data: {
                    type: 'delete_sops',
                    sid: ids,
                }
            }).then(function (response) {
                var data = response.data;
                if (data.status == 'success') {
                    ev.$toasted.global.success({
                        message: data.msg
                    });
                    ev.getSchedule();
                } else {
                    ev.$toasted.global.error({
                        message: data.msg
                    });
                }
            })
        }
    },
}
</script>

<style scoped>
.first30 {
    background: #F55A5A;
}

.sec70 {
    background: #FDBD7C;
}

.trd90 {
    background: #FFE599;
}

.for100 {
    background: #7ADB77;
}

.progress-h3 {
    padding-top: 10px;
    margin-top: 10px;
    border-radius: 20px;
    padding: 15px;
}

.mouse-point {
    cursor: pointer;
}

.add-btn-s {
    background-color: #3798fb;
    border: none;
    margin: 1%;
}

.btn-top-table {
    display: inline-block;
    padding: 10px;
    width: 50%;
}

.table {
    width: 100%;

}

.table-dt {
    overflow: scroll;
}

.tbl-action {
    margin: 2px;
    border: unset;
}
</style>
