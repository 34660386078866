<template>
<div name="student">

    <MobileHeader></MobileHeader>
    <div class="d-flex flex-row flex-column-fluid page">
        <div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">

            <Header></Header>

            <!--begin::Content-->
            <div class="content d-flex flex-column flex-column-fluid" id="kt_content">

                <!--begin::Entry-->
                <div class="d-flex flex-column-fluid">
                    <!--begin::Container-->
                    <!--begin::Dashboard-->

                    <div class="container">
                        <div class="row">
                            
                            <div class="col-md-12">
                                <div class="card card-custom mt-5">
                                    <div class="card-body">

                                        

                                        <div class="container">

                                            <div class="card-body">
                                                <div class="form-group">
                                                    <label>Video Title <span class="text-danger"></span></label>
                                                    <input type="text" class="form-control" v-model="title" disabled  />
                                                </div>

                                                <div class="form-group">
                                                    <label>Select Instructor <span class="text-danger">*</span></label>
                                                     <select class="form-control" v-model="assign_uid" >
                                                <option value="">Select </option>
                                                <template v-for="adata in allUser">
                                                    <option :key="adata.id" :value="adata.id">{{adata.username}}</option>
                                                </template>
                                            </select>
                                                </div>
                                    
                                            </div>

                                            <div class="card-footer">
                                                <button type="reset" class="btn btn-primary mr-2" @click="save_assign" >Submit</button>
                                                <button type="reset" class="hide btn btn-secondary">Cancel</button>

                                               
                                            </div>

                                             
                                        </div>

                                        

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

        </div>
    </div>

</div>
</template>

<script>
import axios from 'axios'
import MobileHeader from '../../components/MobileHeader'
import Header from '../../components/Header'
import Footer from '../../components/Footer'

import {
    VueEditor
} from "vue2-editor";

export default {
    name: 'AddLibrary',
    components: {
        Header,
        Footer,
        MobileHeader,
        VueEditor

    },
    data() {
        return {
            uid: localStorage.getItem("uid"),
            role: localStorage.getItem("role"),
            file: "",
            select_file_name: null,
            video_file: "",
            select_video_file: null,
            urr: null,
            percentage:0,
            upload_process:false,
            title: null,
            allUser:[],
            assign_uid:'',
        }
    },
    mounted() {
        if (this.uid == null || this.uid == "") {
            this.$router.push("/");
        }
        this.getAllUsers();
        this.getVideoDetail();

    },
    watch: {

    },
    methods: {
       
        getAllUsers(){
            var ev= this;
              axios({
                    method: 'POST',
                    url: this.$root.URL_ROOT + 'lms_api.php',
                    data: {
                        type: 'getAllTeacher',
                        role: this.role,
                        uid: this.uid,
                       
                    }
                }).then(function (response) {
                    var data = response.data;
                   // console.log(data);
                    ev.allUser=data;
                })
        },
        getVideoDetail(){
            var ev= this;
              axios({
                    method: 'POST',
                    url: this.$root.URL_ROOT + 'lms_api.php',
                    data: {
                        type: 'getVideoDetail',
                        vid: this.$route.params.id
                    }
                }).then(function (response) {
                    var data = response.data;
                    console.log(data);
                    ev.title=data.title;
                })
        },
        save_assign(){
               var ev= this;

               if(this.assign_uid==null || this.assign_uid==""){
                   ev.$toasted.global.error({
                        message: "please select instructor"
                   });
               }else{

                    axios({
                        method: 'POST',
                        url: this.$root.URL_ROOT + 'lms_api.php',
                        data: {
                            type: 'add_assign_video',
                            vid: this.$route.params.id,
                            uid: this.assign_uid,
                        }
                    }).then(function (response) {
                        var data = response.data;
                        console.log(data);
                        if(data.status =="success"){
                            ev.$toasted.global.success({
                                message: data.msg
                            });
                            ev.$router.push('/library');
                        }else{
                            ev.$toasted.global.error({
                                message: data.msg
                            });
                        }
                    })

               }
              
           
        }
    },
}
</script>

<style scoped>
.upload-area{
    cursor: pointer;
}
.process{
    font-size: 22px;
    font-weight: bold;
    color:#3698fb;
}
.select-file-span {
    color: #3698fb;
    margin-top: 20px;
    font-size: 16px;
}

.icon-upload {
    width: 40px;
    margin-bottom: 15px;
}

.upload-area {
    padding: 5%;
    text-align: center;
    border: 1px solid #3898fb;
    border-radius: 10px;
}

.icon-svg {
    height: 25px;
}

.btn-top-table {
    display: inline-block;
    padding: 10px;
    width: 50%;
}

.table {
    width: 100%;

}

.table-dt {
    overflow: scroll;
}

.tbl-action {
    margin: 2px;
    border: unset;
}
</style>
