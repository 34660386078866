<template>
<div>
    <MobileHeader></MobileHeader>
    <div class="d-flex flex-row flex-column-fluid page">
        <div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">

            <Header></Header>

            <!--begin::Content-->
            <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
                <!--begin::Entry-->
                <div class="d-flex flex-column-fluid">
                    <!--begin::Container-->
                    <div class="container">
                        <!--begin::Dashboard-->

                        <div class="row">
                            <div class="col-xl-6">
                                <!--begin::Mixed Widget 2-->
                                <div class="card card-custom bg-gray-100 gutter-b card-stretch">
                                    <!--begin::Header-->
                                    <div class="card-header border-0 bg-primary py-5" style="display:none;">
                                        <h3 class="card-title font-weight-bolder text-white">Earnings this Month</h3>

                                    </div>
                                    <!--end::Header-->
                                    <!--begin::Body-->
                                    <div class="card-body p-0 position-relative overflow-hidden">
                                        <!--begin::Chart bg-primary-->
                                        <div id="kt_mixed_widget_2_chart" class="card-rounded-bottom " style="height: 350px; min-height: 350px;">

                                            <div id="chart">
                                                <apexchart type="line" height="300" :options="chartOptions" :series="series"></apexchart>
                                            </div>
                                            
                                        </div>

                                        <!--end::Chart-->
                                        <!--begin::Stats-->
                                        <div class="card-spacer mt-n25">
                                            <!--begin::Row-->
                                            <div class="row m-0">
                                                <div class="col bg-white px-6 py-8 rounded-xl mr-7 mb-7">
                                                    <span class="svg-icon svg-icon-3x svg-icon-info d-block my-2">
                                                        <img src="../../assets/icons/today.png" style="width: 50px;height: 50px;display: block;margin-bottom: 10px;margin-top: -10px;" >                                               
                                                    </span>
                                                    <a href="#" class="text-info font-weight-bold font-size-h6 tooltipp">Today Enrollment<span class="tooltiptext">{{totals.enroll}}</span></a>
                                                </div>
                                                <div class="col bg-white px-6 py-8 rounded-xl mb-7">
                                                    <img src="../../assets/icons/7day.png" style="width:45px;display:block;margin-bottom:10px;" >
                                                    
                                                    <!--
                                                    <span class="svg-icon svg-icon-3x svg-icon-info d-block my-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                <rect x="0" y="0" width="24" height="24"></rect>
                                                                <rect fill="#000000" opacity="0.3" x="13" y="4" width="3" height="16" rx="1.5"></rect>
                                                                <rect fill="#000000" x="8" y="9" width="3" height="11" rx="1.5"></rect>
                                                                <rect fill="#000000" x="18" y="11" width="3" height="9" rx="1.5"></rect>
                                                                <rect fill="#000000" x="3" y="13" width="3" height="7" rx="1.5"></rect>
                                                            </g>
                                                        </svg>
                                                       
                                                    </span>-->
                                                    <a href="#" class="text-info font-weight-bold font-size-h6 tooltipp">This Week Newly Registered Instructor<span class="tooltiptext">{{totals.teacher}}</span></a>
                                                </div>
                                            </div>
                                            <!--end::Row-->
                                            <!--begin::Row-->
                                            <div class="row m-0">
                                                <div class="col bg-white px-6 py-8 rounded-xl mr-7">
                                                     <img src="../../assets/icons/30day.png" style="width:45px;display:block;margin-bottom:10px;" >
                                                    <a href="#" class="text-info font-weight-bold font-size-h6 tooltipp">This Week Newly Registered Student <span class="tooltiptext">{{totals.student}}</span></a>
                                                </div>
                                                <div class="col bg-white px-6 py-8 rounded-xl">
                                                   
                                                     <img src="../../assets/icons/sales.png" style="width:45px;display:block;margin-bottom:10px;" >
                                                    <a href="#" class="text-success font-weight-bold font-size-h6 mt-2 tooltipp">Total Student<span class="tooltiptext">{{totals.total_student}}</span></a>
                                                </div>
                                            </div>
                                            <!--end::Row-->
                                        </div>
                                        <!--end::Stats-->
                                        <div class="resize-triggers">
                                            <div class="expand-trigger">
                                                <div style="width: 414px; height: 453px;"></div>
                                            </div>
                                            <div class="contract-trigger"></div>
                                        </div>
                                    </div>
                                    <!--end::Body-->
                                </div>
                                <!--end::Mixed Widget 2-->
                            </div>
                            <div class="col-xl-4 hide">
                                <!--begin::Mixed Widget 18-->
                                <div class="card card-custom gutter-b card-stretch s">
                                    <!--begin::Header-->
                                    <div class="card-header border-0 pt-5">
                                        <div class="card-title font-weight-bolder">
                                            <div class="card-label">Weekly Student Enrollments
                                                <!--<div class="font-size-sm text-muted mt-2">890,344 Sales</div>-->
                                            </div>
                                        </div>
                                    </div>
                                    <!--end::Header-->
                                    <!--begin::Body-->
                                    <div class="card-body">
                                        <!--begin::Chart-->
                                        <div id="kt_mixed_widget_18_chart" style="height: 250px"></div>
                                        <!--end::Chart-->
                                        <!--begin::Items-->
                                        <div class="mt-n12 position-relative zindex-0">
                                            <!--begin::Widget Item-->
                                            <div class="d-flex align-items-center mb-8">
                                                <!--begin::Symbol-->
                                                <div class="symbol symbol-circle symbol-40 symbol-light mr-3 flex-shrink-0">
                                                    <div class="symbol-label">
                                                        <span class="svg-icon svg-icon-lg svg-icon-gray-500">
                                                            <!--begin::Svg Icon | path:assets/media/svg/icons/Media/Equalizer.svg-->
                                                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                    <rect x="0" y="0" width="24" height="24"></rect>
                                                                    <rect fill="#000000" opacity="0.3" x="13" y="4" width="3" height="16" rx="1.5"></rect>
                                                                    <rect fill="#000000" x="8" y="9" width="3" height="11" rx="1.5"></rect>
                                                                    <rect fill="#000000" x="18" y="11" width="3" height="9" rx="1.5"></rect>
                                                                    <rect fill="#000000" x="3" y="13" width="3" height="7" rx="1.5"></rect>
                                                                </g>
                                                            </svg>
                                                            <!--end::Svg Icon-->
                                                        </span>
                                                    </div>
                                                </div>
                                                <!--end::Symbol-->
                                                <!--begin::Title-->
                                                <div>
                                                    <a href="#" class="font-size-h6 text-dark-75 text-hover-primary font-weight-bolder">Most Sold Courses</a>
                                                    <div class="font-size-sm text-muted font-weight-bold mt-1"> NO COURSE </div>
                                                </div>
                                                <!--end::Title-->
                                            </div>
                                            <!--end::Widget Item-->
                                            <!--begin::Widget Item-->
                                            <div class="d-flex align-items-center mb-8">
                                                <!--begin::Symbol-->
                                                <div class="symbol symbol-circle symbol-40 symbol-light mr-3 flex-shrink-0">
                                                    <div class="symbol-label">
                                                        <span class="svg-icon svg-icon-lg svg-icon-gray-500">
                                                            <!--begin::Svg Icon | path:assets/media/svg/icons/Shopping/Chart-pie.svg-->
                                                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                    <rect x="0" y="0" width="24" height="24"></rect>
                                                                    <path d="M4.00246329,12.2004927 L13,14 L13,4.06189375 C16.9463116,4.55399184 20,7.92038235 20,12 C20,16.418278 16.418278,20 12,20 C7.64874861,20 4.10886412,16.5261253 4.00246329,12.2004927 Z" fill="#000000" opacity="0.3"></path>
                                                                    <path d="M3.0603968,10.0120794 C3.54712466,6.05992157 6.91622084,3 11,3 L11,11.6 L3.0603968,10.0120794 Z" fill="#000000"></path>
                                                                </g>
                                                            </svg>
                                                            <!--end::Svg Icon-->
                                                        </span>
                                                    </div>
                                                </div>
                                                <!--end::Symbol-->
                                                <!--begin::Title-->
                                                <div>
                                                    <a href="#" class="font-size-h6 text-dark-75 text-hover-primary font-weight-bolder">Total Revenue</a>
                                                    <div class="font-size-sm text-muted font-weight-bold mt-1">0</div>
                                                </div>
                                                <!--end::Title-->
                                            </div>
                                            <!--end::Widget Item-->
                                        </div>
                                        <!--end::Items-->
                                    </div>
                                    <!--end::Body-->
                                </div>
                                <!--end::Mixed Widget 18-->
                            </div>
                            <div class="col-xl-6">
                                <!--begin::List Widget 7-->
                                <div class="card card-custom gutter-b card-stretch s">
                                    <!--begin::Header-->
                                    <div class="card-header border-0">
                                        <h3 class="card-title font-weight-bolder text-dark new-head">Recent Enrollments</h3>
                                        <div class="card-toolbar">
                                        </div>
                                    </div>
                                    <!--end::Header-->
                                    <!--begin::Body-->
                                    <div class="card-body pt-0">
                                        <template v-for="(edata,key) in getEnrollData">
                                            <!--begin::Item-->
                                            <div :key="key" v-if="key<4" class="d-flex align-items-center flex-wrap mb-10">
                                                <!--begin::Symbol-->
                                                <div class="symbol symbol-50 symbol-light mr-5">
                                                    <span class="symbol-label" style="text-transform:capitalize;">
                                                        <span class="svg-icon svg-icon-2x svg-icon-success">
                                                            <!--begin::Svg Icon | path:/metronic/theme/html/demo7/dist/assets/media/svg/icons/Communication/Group.svg-->
                                                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                    <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                                                    <path d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                                                                    <path d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z" fill="#000000" fill-rule="nonzero"></path>
                                                                </g>
                                                            </svg>
                                                            <!--end::Svg Icon-->
                                                        </span>
                                                    </span>
                                                </div>
                                                <!--end::Symbol-->
                                                <!--begin::Text-->
                                                <div class="d-flex flex-column flex-grow-1 mr-2">
                                                    <a href="#" class="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1" style="text-transform:capitalize;">
                                                        {{edata.username}}</a>
                                                    <span class="text-muted font-weight-bold">
                                                        {{edata.city}}, {{edata.state}}
                                                    </span>
                                                </div>
                                                <!--end::Text-->

                                                <span class="label label-xl label-light label-inline my-lg-0 my-2 text-dark-50 font-weight-bolder"> $0
                                                </span>
                                            </div>
                                            <!--end::Item-->

                                        </template>

                                    </div>
                                    <!--end::Body-->

                                    <!--begin::Footer-->
                                    <div class="card-footer border-0">
                                        <router-link to="/Students" class="btn btn-light btn-xl" style="float:right;">View All
                                            <span class="symbol-label">
                                                <span class="svg-icon svg-icon-2x svg-icon-success">
                                                    <!--begin::Svg Icon | path:assets/media/svg/icons/Shopping/Cart3.svg-->
                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                            <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                                            <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)" x="11" y="5" width="2" height="14" rx="1"></rect>
                                                            <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fill-rule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)"></path>
                                                        </g>
                                                    </svg>
                                                    <!--end::Svg Icon-->
                                                </span>
                                            </span>
                                        </router-link>
                                    </div>
                                    <!--end::Footer-->
                                </div>
                                <!--end::List Widget 7-->
                            </div>
                        </div>

                        <div clas="row" style="display:flex;">
                            <div class="col-xl-6">
                                <!--begin::Base Table Widget 3-->
                                <div class="card card-custom gutter-b card-stretch" style="height:auto;">
                                    <!--begin::Header-->
                                    <div class="card-header border-0 pt-5">
                                        <h3 class="card-title align-items-start flex-column">
                                            <span class="card-label font-weight-bolder text-dark new-head">Task</span>
                                            <!--<span class="text-muted mt-3 font-weight-bold font-size-sm">More than 400+ new members</span>-->
                                        </h3>
                                        <div class="card-toolbar">
                                            <router-link to="/addtask">
                                                <button type="button" class="btn btn-light-primary btn-md py-2 font-weight-bolder" data-toggle="modal" data-target="#staticBackdrop1Task">
                                                    Create Task
                                                </button>
                                            </router-link>
                                        </div>
                                    </div>
                                    <!--end::Header-->
                                    <!--begin::Body-->
                                    <div class="card-body pt-2 pb-0">
                                        <!--begin::Table-->
                                        <div class="table-responsive">
                                            <table class="table table-borderless table-vertical-center">
                                                <thead>
                                                    <tr>
                                                        <th class="p-0"></th>
                                                        <th class="p-0"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <template v-for="(cdata,key) in getMyTask">
                                                        <tr v-if="key<4" :key="cdata.id">
                                                            <td class="pl-0 py-5">
                                                                <p class="text-dark new-head font-weight-bolder text-hover-primary mb-1 font-size-lg">{{cdata.name}}</p>
                                                            </td>
                                                            <td class="pl-0">
                                                                <a class="text-dark new-head new-head font-weight-bolder text-hover-primary mb-1 font-size-lg">{{cdata.due_date}}</a>
                                                            </td>
                                                        </tr>
                                                    </template>
                                                </tbody>
                                            </table>
                                        </div>
                                        <!--end::table-->
                                    </div>
                                    <!--begin::Body-->
                                </div>
                                <!--end::Base Table Widget 3-->
                            </div>
                            <div class="col-xl-6">
                                <!--begin::Base Table Widget 3-->

                                <div class="card card-custom gutter-b card-stretch" style="height:auto;">
                                    <!--begin::Header-->
                                    <div class="card-header border-0 pt-5">
                                        <h3 class="card-title align-items-start flex-column">
                                            <span class="card-label font-weight-bolder text-dark new-head">ToDo</span>
                                            <!--<span class="text-muted mt-3 font-weight-bold font-size-sm">More than 400+ new members</span>-->
                                        </h3>
                                        <div class="card-toolbar">
                                            <router-link to="/addtodo">
                                                <button type="button" class="btn btn-light-primary btn-md py-2 font-weight-bolder" data-toggle="modal" data-target="#staticBackdrop1">
                                                    Create ToDo
                                                </button>
                                            </router-link>

                                        </div>
                                    </div>
                                    <!--end::Header-->
                                    <!--begin::Body-->
                                    <div class="card-body pt-2 pb-0">
                                        <!--begin::Table-->
                                        <div class="table-responsive">
                                            <table class="table table-borderless table-vertical-center">
                                                <thead>
                                                    <tr>
                                                        <th class="p-0"></th>
                                                        <th class="p-0"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <template v-for="(cdata,key) in getMyTodo">
                                                        <tr v-if="key<4" :key="cdata.id">
                                                            <td class="pl-0 py-5">
                                                                <p class="text-dark new-head font-weight-bolder text-hover-primary mb-1 font-size-lg">{{cdata.name}}</p>
                                                            </td>
                                                            <td class="pl-0">
                                                                <a class="text-dark new-head font-weight-bolder text-hover-primary mb-1 font-size-lg">{{cdata.due_date}}</a>
                                                            </td>
                                                        </tr>
                                                    </template>
                                                </tbody>
                                            </table>
                                        </div>
                                        <!--end::table-->
                                    </div>
                                    <!--begin::Body-->
                                </div>

                            </div>
                        </div>

                        <div clas="row" style="display:flex;">
                            <div class="col-xl-6">
                                <!--begin::Base Table Widget 3-->
                                <div class="card card-custom gutter-b card-stretch" style="height:auto;">
                                    <!--begin::Header-->
                                    <div class="card-header border-0 pt-5">
                                        <h3 class="card-title align-items-start flex-column">
                                            <span class="card-label font-weight-bolder text-dark new-head">Course</span>
                                            <!--<span class="text-muted mt-3 font-weight-bold font-size-sm">More than 400+ new members</span>-->
                                        </h3>
                                        <div class="card-toolbar">
                                            <router-link to="/courses">
                                                <button type="button" class="btn btn-light-primary btn-md py-2 font-weight-bolder">Create Course</button>
                                            </router-link>

                                        </div>
                                    </div>
                                    <!--end::Header-->
                                    <!--begin::Body-->
                                    <div class="card-body pt-2 pb-0">
                                        <!--begin::Table-->
                                        <div class="table-responsive">
                                            <table class="table table-borderless table-vertical-center">
                                                <thead>
                                                    <tr>
                                                        <th class="p-0" style="width: 50px"></th>
                                                        <th class="p-0" style="min-width: 150px"></th>
                                                        <th class="p-0" style="min-width: 140px"></th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <template v-for="(cdata,key) in getCourse">
                                                        <tr v-if="key<4" :key="key">
                                                            <td class="pl-0 py-5">
                                                                <div class="symbol symbol-45 symbol-light-success">
                                                                    <span class="symbol-label">
                                                                        <router-link :to="'/adminpreview/'+cdata.id">
                                                                            <img :src="$root.URL_IMAGES+'Course/'+cdata.course_image" class="w-100 h-50 align-self-center">
                                                                        </router-link>
                                                                    </span>
                                                                </div>
                                                            </td>
                                                            <td class="pl-0">
                                                                <router-link :to="'/adminpreview/'+cdata.id" class="text-dark new-head font-weight-bolder text-hover-primary mb-1 font-size-lg">
                                                                    {{cdata.course_name}}
                                                                </router-link>
                                                            </td>
                                                            <td class="text-right">
                                                                <span class="text-muted font-weight-bold"><!--Free--></span>
                                                            </td>

                                                        </tr>
                                                    </template>

                                                    <tr>
                                                        <td class="text-right" colspan="4">
                                                            <router-link to="/courses" class="btn btn-light btn-xl">View All
                                                                <span class="symbol-label">
                                                                    <span class="svg-icon svg-icon-2x svg-icon-success">
                                                                        <!--begin::Svg Icon | path:assets/media/svg/icons/Shopping/Cart3.svg-->
                                                                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                                <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                                                                <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)" x="11" y="5" width="2" height="14" rx="1"></rect>
                                                                                <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fill-rule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)"></path>
                                                                            </g>
                                                                        </svg>
                                                                        <!--end::Svg Icon-->
                                                                    </span>
                                                                </span>
                                                            </router-link>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <!--end::table-->
                                    </div>
                                    <!--begin::Body-->
                                </div>
                                <!--end::Base Table Widget 3-->
                            </div>
                            <div class="col-xl-6">
                                <!--begin::Base Table Widget 3-->
                                <div class="card card-custom gutter-b card-stretch" style="height:auto;">
                                    <!--begin::Header-->
                                    <div class="card-header border-0 pt-5">
                                        <h3 class="card-title align-items-start flex-column">
                                            <span class="card-label font-weight-bolder text-dark new-head">Totals</span>
                                            <!--<span class="text-muted mt-3 font-weight-bold font-size-sm">More than 400+ new members</span>-->
                                        </h3>
                                        <div class="card-toolbar">
                                            <router-link to="/courses">
                                                <button type="button" class="btn btn-light-primary btn-md py-2 font-weight-bolder">Create Course</button>
                                            </router-link>

                                        </div>
                                    </div>
                                    <!--end::Header-->
                                    <!--begin::Body-->
                                    <div class="card-body pt-2 pb-0">
                                        <!--begin::Table-->
                                        <div class="table-responsive">
                                            <table class="table table-borderless table-vertical-center">
                                                <thead>
                                                    <tr>
                                                        <th class="p-0" style="width: 50px"></th>
                                                        <th class="p-0" style="min-width: 150px"></th>
                                                        <th class="p-0" style="min-width: 140px"></th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                        <tr>
                                                            <td class="pl-0 py-5">
                                                                <div class="symbol symbol-45 symbol-light-success">
                                                                    <span class="symbol-label">
                                                                        
                                                                    </span>
                                                                </div>
                                                            </td>
                                                            <td class="pl-0">
                                                                <div class="text-dark new-head font-weight-bolder text-hover-primary mb-1 font-size-lg">
                                                                    Total Enrollments : 
                                                                </div>
                                                            </td>
                                                            <td class="text-right">
                                                                <span class="text-muted font-weight-bold">{{totals.total_enrollment}}</span>
                                                            </td>

                                                        </tr>
                                                        <tr>
                                                            <td class="pl-0 py-5">
                                                                <div class="symbol symbol-45 symbol-light-success">
                                                                    <span class="symbol-label">
                                                                        
                                                                    </span>
                                                                </div>
                                                            </td>
                                                            <td class="pl-0">
                                                                <div class="text-dark new-head font-weight-bolder text-hover-primary mb-1 font-size-lg">
                                                                    Total Students : 
                                                                </div>
                                                            </td>
                                                            <td class="text-right">
                                                                <span class="text-muted font-weight-bold">{{totals.total_student}}</span>
                                                            </td>

                                                        </tr>
                                                        <tr>
                                                            <td class="pl-0 py-5">
                                                                <div class="symbol symbol-45 symbol-light-success">
                                                                    <span class="symbol-label">
                                                                        
                                                                    </span>
                                                                </div>
                                                            </td>
                                                            <td class="pl-0">
                                                                <div class="text-dark new-head font-weight-bolder text-hover-primary mb-1 font-size-lg">
                                                                    Total Courses : 
                                                                </div>
                                                            </td>
                                                            <td class="text-right">
                                                                <span class="text-muted font-weight-bold">{{totals.total_courses}}</span>
                                                            </td>

                                                        </tr>
                                                    

                                                    <tr>
                                                        <td class="text-right" colspan="4">
                                                            <router-link to="/courses" class="btn btn-light btn-xl">View All
                                                                <span class="symbol-label">
                                                                    <span class="svg-icon svg-icon-2x svg-icon-success">
                                                                        <!--begin::Svg Icon | path:assets/media/svg/icons/Shopping/Cart3.svg-->
                                                                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                                <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                                                                <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)" x="11" y="5" width="2" height="14" rx="1"></rect>
                                                                                <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fill-rule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)"></path>
                                                                            </g>
                                                                        </svg>
                                                                        <!--end::Svg Icon-->
                                                                    </span>
                                                                </span>
                                                            </router-link>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <!--end::table-->
                                    </div>
                                    <!--begin::Body-->
                                </div>
                                <!--end::Base Table Widget 3-->
                            </div>
                        </div>

                        <!--end::Dashboard-->
                    </div>
                    <!--end::Container-->
                </div>
                <!--end::Entry-->
            </div>
            <!--end::Content-->

            <Footer></Footer>

        </div>
    </div>

</div>
</template>

<script>
import axios from 'axios'
import MobileHeader from '../../components/MobileHeader'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import VueApexCharts from 'vue-apexcharts'
import Vue from 'vue'
Vue.use(VueApexCharts)

export default {
    name: 'AdminDashboard',
    components: {
        Header,
        Footer,
        MobileHeader,
        apexchart: VueApexCharts,

    },
    data() {
        return {
            uid: localStorage.getItem('uid'),
            role: localStorage.getItem('role'),
            getEnrollData: [],
            getCourse: [],
            getMyTodo: [],
            getMyTask: [],
            series: [{
                name: "$",
                data: [10, 41, 35, 51, 49, 62, 69, 91, 148, 122, 114, 122, 222]
            }],
            chartOptions: {
                chart: {
                    height: 350,
                    type: 'line',
                    zoom: {
                        enabled: false
                    }
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'straight'
                },
                title: {
                    text: 'Earnings this Month',
                    align: 'left'
                },
                grid: {
                    row: {
                        colors: ['#3798fb', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                    },
                },
                xaxis: {
                    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                }
            },
            totals:[],

        }
    },
    mounted() {
        window.scrollTo(0, 0)
        this.getEnroll();
        this.getLetestCourse();
        this.getMyTodoData();
        this.getMyTaskData();
        this.getTotals();
             
    },
    methods: {
        getTotals(){
            var ev = this;
            axios({
                method: "POST",
                url: this.$root.URL_ROOT + "lms_api.php",
                data: {
                    type: "getTotals",
                },
            }).then(function (response) {
                var data = response.data;
                console.log(data);
                ev.totals=data;
            })

        },
        getEnroll() {
            var ev = this;
            axios({
                method: "POST",
                url: this.$root.URL_ROOT + "lms_api.php",
                data: {
                    type: "getLetestEnroll",

                },
            }).then(function (response) {
                var data = response.data;
                ev.getEnrollData = data;
                //console.log(data);
            })
        },
        getLetestCourse() {
            var ev = this;
            axios({
                method: "POST",
                url: this.$root.URL_ROOT + "lms_api.php",
                data: {
                    type: "getLetestCourse",

                },
            }).then(function (response) {
                var data = response.data;
                ev.getCourse = data;
                //console.log(data);
            })
        },
        getMyTodoData() {
            var ev = this;
            axios({
                method: "POST",
                url: this.$root.URL_ROOT + "lms_api.php",
                data: {
                    type: "getSchedule",
                    uid: this.uid,
                    stype: 'todo'

                },
            }).then(function (response) {
                var data = response.data;
                ev.getMyTodo = data;
                //console.log("todo");
                //console.log(data);
            })
        },
        getMyTaskData() {
            var ev = this;
            axios({
                method: "POST",
                url: this.$root.URL_ROOT + "lms_api.php",
                data: {
                    type: "getSchedule",
                    uid: this.uid,
                    stype: 'task'

                },
            }).then(function (response) {
                var data = response.data;
                ev.getMyTask = data;
                //console.log("task");
                //console.log(data);
            })
        },
        getTotals(){
            var ev=this;
            axios({
                method: "POST",
                url: this.$root.URL_ROOT + "lms_api.php",
                data: {
                    type: "getTotals",
                   
                },
            }).then(function (response) {
                var data = response.data;
                console.log(data);
                ev.totals=data;
            })   
        }

    }
}
</script>
<style scoped>
.tooltiptext{
    float: right;
}
.text-muted{
    color: #67538d !important;
}
</style>
