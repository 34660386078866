<template>
<div name="student">

    <MobileHeader></MobileHeader>
    <div class="d-flex flex-row flex-column-fluid page">
        <div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">

            <Header></Header>

            <!--begin::Content-->
            <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
                <!--begin::Entry-->
                <div class="d-flex flex-column-fluid">
                    <!--begin::Container-->
                    <!--begin::Dashboard-->

                    <div class="container">
                        <div class="card card-custom gutter-b">
                            <!--begin::Body-->
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-xl-6">
                                        <div class="form-group">
                                            <label>KPI Name</label>
                                            <input type="text" class="form-control form-control-solid form-control-lg" v-model="kpi_name" placeholder="Titles">
                                        </div>
                                    </div>

                                    <div class="col-xl-6">
                                        <div class="form-group">
                                            <label>Team Name</label>
                                            <select class="form-control form-control-solid form-control-lg"  v-model="tid">
                                                <option value="">Select Team</option>
                                                <template v-for="tdata in teamData">
                                                    <option :key="tdata.id" :value="tdata.id">{{tdata.name}}</option>
                                                </template>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="col-xl-6">
                                        <div class="form-group">
                                            <label>Select Progress Indiactor</label>
                                            <select class="form-control form-control-solid form-control-lg" v-model="progress">
                                                <option value="">Select Progress Indiactor Type</option>
                                                <option value="30">Inccomplete 0-30%</option>
                                                <option value="70">Progressing 30%-70%</option>
                                                <option value="95">Progressing 70%-95%</option>
                                                <option value="100">Progressing 100% </option>

                                            </select>
                                        </div>
                                    </div>

                                    <div class="col-xl-6">
                                        <div class="form-group">
                                            <label>Select Quarter Type</label>
                                            <select class="form-control form-control-solid form-control-lg" v-model="quarter">
                                                <option value="">Select Quarter Type</option>
                                                <option value="January-March">January-March</option>
                                                <option value="April-June">April-June </option>
                                                <option value="July-September">July-September</option>
                                                <option value="October-December">October-December</option>

                                            </select>
                                        </div>
                                    </div>

                                </div>
                                <div class="col-xl-12">
                                    <div class="form-group">
                                        <label>Objective</label>
                                        <div class="form-group">
                                            <vue-editor v-model="description"></vue-editor>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-xl-12">
                                    <div class="form-group">
                                        <label>Key Results</label>
                                        <div class="form-group">
                                            <vue-editor v-model="results"></vue-editor>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="card-footer">
                                <button type="submit" class="btn btn-primary mr-2" @click="save_kpis">Submit</button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
</template>

<script>
import axios from 'axios'
import MobileHeader from '../../components/MobileHeader'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import {
    VueEditor
} from "vue2-editor";

export default {
    name: 'AddSops',
    components: {
        Header,
        Footer,
        MobileHeader,
        VueEditor,
    },
    data() {
        return {
            uid: localStorage.getItem("uid"),
            role: localStorage.getItem("role"),
            kpi_name: null,
            tid: "",
            description: null,
            teamData: [],
            quarter: "",
            results: null,
            progress: "",
           

        }
    },
    mounted() {
        if (this.uid == null || this.uid == "") {
            this.$router.push("/");
        }
        this.allTeam();
    },
    watch: {
        file_type: function (vl) {
            this.show_file = vl;
        }
    },
    methods: {
        upload_file() {
            var ev = this;
            this.file = this.$refs.file.files[0];
            let formData = new FormData();
            this.select_file_name = this.file.name;
        },
        save_kpis() {

            var ev = this;

            if (this.kpi_name == "" || this.kpi_name == null) {
                this.$toasted.global.error({
                    message: "Please Enter Kpi Name"
                });
            } else if (this.tid == "" || this.tid == null) {
                this.$toasted.global.error({
                    message: "Please Select Team"
                });
            } else if (this.progress == "" || this.progress == null) {
                this.$toasted.global.error({
                    message: "Please Select Progress Indiactor"
                });
            } else if (this.quarter == "" || this.quarter == null) {
                this.$toasted.global.error({
                    message: "Please Select Quarter"
                });
            } else if (this.description == "" || this.description == null) {
                this.$toasted.global.error({
                    message: "Please Enter Description"
                });
            } else if (this.results == "" || this.results == null) {
                this.$toasted.global.error({
                    message: "Please Select Key Results"
                });
            } else {

                axios({
                    method: 'POST',
                    url: this.$root.URL_ROOT + 'lms_api.php',
                    data: {
                        type: 'save_kpis_data',
                        kpi_name: this.kpi_name,
                        tid: this.tid,
                        progress: this.progress,
                        quarter: this.quarter,
                        objective: this.description,
                        results: this.results,
                    }
                }).then(function (response) {
                    var data = response.data;
                    if(data.status=="success"){
                        ev.$toasted.global.success({
                            message: data.msg,
                        });
                        ev.$router.push("/kpis");
                    }else{
                        ev.$toasted.global.error({
                            message: data.msg,
                        })
                    }

                })
            }

        },
        allTeam() {
            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'lms_api.php',
                data: {
                    type: 'team_all_data',
                }
            }).then(function (response) {
                var data = response.data;
                ev.teamData = data;

            })
        },
    },
}
</script>

<style scoped>
.btn-top-table {
    display: inline-block;
    padding: 10px;
    width: 50%;
}

.table {
    width: 100%;

}

.table-dt {
    overflow: scroll;
}

.tbl-action {
    margin: 2px;
    border: unset;
}
</style>
