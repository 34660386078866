<template>
<div name="student">

    <MobileHeader></MobileHeader>
    <div class="d-flex flex-row flex-column-fluid page">
        <div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">

            <Header></Header>

            <!--begin::Content-->
            <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
                <!--begin::Entry-->
                <div class="d-flex flex-column-fluid">
                    <!--begin::Container-->
                    <!--begin::Dashboard-->

                    <div class="container">
                        <div class="card card-custom mt-5">
                            <div class="card-body">
                                <!--begin::Search Form-->
                                <div class="mb-7">
                                    <div class="row align-items-center">
                                        <div class="col-lg-10 col-xl-10">
                                            <div class="row align-items-center">
                                                <div class="col-xl-12">
                                                    <div class="container">
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <button class="btn btn-primary btn-2 font-weight-bolder loom-btn"  ref="loom_btn" id="loomApiBtn">
                                                                    <i class="fa fa-video"></i>
                                                                    Start Recording</button>
                                                            </div>

                                                            <div class="col-12">
                                                                <div class="container card">
                                                                    <div class="card-body">
                                                                        <div class="form-group">
                                                                            <label>Title <span class="text-danger">*</span></label>
                                                                            <input type="text" v-model="title" placeholder="Enter title" class="form-control">
                                                                        </div>

                                                                        <div class="form-group">
                                                                            <label>Video Link <span class="text-danger">*</span></label>
                                                                            <input type="text" v-model="video_link" placeholder="Enter Video Link" class="form-control">
                                                                        </div>
                                                                        
                                                                    </div>

                                                                    <div class="card-footer">
                                                                        <button type="reset" class="btn btn-primary mr-2" @click="save_video_link">Submit</button>
                                                                        <button type="reset" class="hide btn btn-secondary">Cancel</button>
                                                                    </div>

                                                                    </div>
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-xl-6">
                                                    <video autoplay />
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <!--end: Search Form-->

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
</template>

<script>
import axios from 'axios'
import MobileHeader from '../../components/MobileHeader'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
//import '@/assets/js/loom.js';
import {
    isSupported,
    setup
} from "@loomhq/loom-sdk";

export default {
    name: 'LoomVideo',
    components: {
        Header,
        Footer,
        MobileHeader,
    },
    data() {
        return {
            uid: localStorage.getItem("uid"),
            role: localStorage.getItem("role"),
            title: null,
            video_link: null,

        }
    },
    mounted() {
        if (this.uid == null || this.uid == "") {
            this.$router.push("/");
        }
        this.init();
    },
    methods: {

        
        async init() {

            const {
                configureButton
            } = await setup({
                apiKey: "e720a71b-e5e5-4370-9fb9-708e99ddb002"
            });
            const root = document.getElementById("loomApiBtn");

            configureButton({
                element: root,
                hooks: {
                    onInsertClicked: (shareLink) => {
                       // alert("--- i was clicked")
                        console.log("clicked insert");
                        console.log(shareLink);
                        this.video_link=shareLink.sharedUrl;
                    },
                    onStart: () => console.log("start"),
                    onCancel: () => console.log("cancelled"),
                    onComplete: () => console.log("complete"),
                }
            });
        },
        save_video_link(){
            var ev=this;

            if(this.title==null || this.title==null){
                this.$toasted.global.error({
                    message: 'Please Enter Title'
                });
            }else if(this.video_link==null || this.video_link==null){
                this.$toasted.global.error({
                    message: 'Please Record video'
                });
            }else{

                 axios({
                    method: 'POST',
                    url: this.$root.URL_ROOT + 'lms_api.php',
                    data: {
                        type: 'save_video_link',
                        title: this.title,
                        video_link: this.video_link,
                        uid: this.uid,
                        role: this.role,
                    }
                }).then(function (response) {
                    var data=response.data;
                    console.log(data);
                    if(data.status=='success'){
                        ev.$toasted.global.success({
                            message: data.msg
                        });
                        ev.$router.push('/library');
                    }else{
                        ev.$toasted.global.error({
                            data: data.msg
                        })
                    }
                })

            } 
        }

    },
}
</script>

<style scoped>
.loom-btn{
    text-align: center;
    margin-bottom: 30px;
}
.pull-right {
    float: right;
}

.mouse-point {
    cursor: pointer;
}

.none-select {
    background-color: #f3f6fa;
    color: #449ffb;
    border: unset;
}
</style>
