<template>
<div name="student">

    <MobileHeader></MobileHeader>
    <div class="d-flex flex-row flex-column-fluid page">
        <div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">

            <Header></Header>

            <!--begin::Content-->
            <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
                <!--begin::Entry-->
                <div class="d-flex flex-column-fluid">
                    <!--begin::Container-->
                    <!--begin::Dashboard-->

                    <div class="container">

                        <div class="subheader py-lg-4 subheader-transparent" id="kt_subheader">
                            <div class="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                                <!--begin::Details-->
                                <div class="d-flex align-items-center flex-wrap mr-2">
                                    <!--begin::Title-->
                                    <h5 class="text-dark font-weight-bold mt-2 mb-2 mr-5">View Assigned SOP's</h5>
                                    <!--end::Title-->
                                </div>
                                <!--end::Details-->
                                <!--begin::Toolbar-->
                                <div class="d-flex align-items-center">
                                   
                                </div>
                                <!--end::Toolbar-->
                            </div>
                        </div>

                        <div class="card card-custom mt-5">

                            <div class="card-body">
                                <!--begin::Search Form-->
                                <div class="mb-7">
                                    <div class="row align-items-center">
                                        <div class="col-lg-9 col-xl-8">
                                            
                                        </div>
                                        <div class="col-lg-3 col-xl-4 mt-5 mt-lg-0">
                                            <!--<input type="submit" name="assign" value="Assign" class="btn btn-light-primary px-6 font-weight-bold">-->
                                            <router-link to="/assignsops" ><button style="float:right;" type="button" class="btn btn-primary font-weight-bolder ml-5">
                                                Assign SOP</button>
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                                <!--end: Search Form-->
                                <!--begin: Datatable-->
                                <div id="example_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                                    
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <table id="example" class="table dataTable no-footer" style="width: 100%;" role="grid" aria-describedby="example_info">
                                                <thead>

                                                    <tr role="row">
                                                        <th class="sorting sorting_asc" tabindex="0" rowspan="1" colspan="1" style="width: 188px;">Name</th>
                                                        <th class="sorting" tabindex="0" rowspan="1" colspan="1" style="width: 86px;">Sop</th>
                                                        <th class="sorting" tabindex="0" rowspan="1" colspan="1" style="width: 282px;">Enrollment Status</th>
                                                        <th class="sorting" tabindex="0" rowspan="1" colspan="1" style="width: 189px;">Expired On</th>
                                                        <th class="sorting" tabindex="0" rowspan="1" colspan="1" style="width: 122px;">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    <template v-for="adata in allData">
                                                        <tr :key="adata.id" class="odd">
                                                            <td class="sorting_1">{{adata.username}}</td>
                                                            <td>test</td>
                                                            <td>
                                                                <p class="label label-success label-pill label-inline mr-2" style="width:auto !important;">Going to expire</p>
                                                            </td>
                                                            <td>{{adata.exdate}}</td>
                                                            <td>
                                                                <a @click="delete_assign(adata.id)" class="mouse-point"><span class="label label-inline label-light-danger font-weight-bold ml-5">Delete</span></a>
                                                            </td>
                                                        </tr>
                                                    </template>
                                                    
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    
                                </div>
                                <!--end: Datatable-->
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
</template>

<script>
import axios from 'axios'
import MobileHeader from '../../components/MobileHeader'
import Header from '../../components/Header'
import Footer from '../../components/Footer'

export default {
    name: 'Sops',
    components: {
        Header,
        Footer,
        MobileHeader,

    },
    data() {
        return {
            uid: localStorage.getItem("uid"),
            role: localStorage.getItem("role"),
            allData: [],
            studentData: [],
            selected: [],
            allSelected: false,
            userIds: [],
            sops: "",
            exdate: null,
        }
    },
    mounted() {
        if (this.uid == null || this.uid == "") {
            this.$router.push("/");
        }
        this.getAllAssignSop();
    },
    methods: {
        
        getAllAssignSop() {
            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'lms_api.php',
                data: {
                    type: 'getAllAssignSop',
                }
            }).then(function (response) {
                var data = response.data;
                ev.allData = data.data;

            })
        },
        delete_assign(ids){
            
        }

    },
}
</script>

<style scoped>
.pull-right {
    float: right;
}

.mouse-point {
    cursor: pointer;
}

.add-btn-s {
    background-color: #3798fb;
    border: none;
    margin: 1%;
}

.btn-top-table {
    display: inline-block;
    padding: 10px;
    width: 50%;
}

.table {
    width: 100%;

}

.table-dt {
    overflow: scroll;
}

.tbl-action {
    margin: 2px;
    border: unset;
}
</style>
