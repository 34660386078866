<template>
<div name="student">

    <MobileHeader></MobileHeader>
    <div class="d-flex flex-row flex-column-fluid page">
        <div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">

            <Header></Header>

            <!--begin::Content-->
            <div class="content d-flex flex-column flex-column-fluid" id="kt_content">

                <!--begin::Entry-->
                <div class="d-flex flex-column-fluid">
                    <!--begin::Container-->
                    <!--begin::Dashboard-->

                    <div class="container">
                        <div class="card card-custom mt-5">
                            <div class="card-body">

                                <div class="mb-7">
                                    <div class="row align-items-center">
                                        <div class="col-lg-7 col-xl-7">
                                            <div class="row align-items-center">
                                                <div class="col-md-6 my-2 my-md-0">
                                                    <div class="input-icon hide">
                                                        <input type="text" class="form-control" placeholder="Search..." id="kt_datatable_search_query">
                                                        <span>
                                                            <i class="flaticon2-search-1 text-muted"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-5 col-xl-5 mt-5 mt-lg-0">

                                            <div class="btn-top-table">
                                                <router-link to="/addgroupcourse">
                                                <button type="button" class="btn btn-primary font-weight-bolder " >
                                                    <i class="fa fa-plus-circle" aria-hidden="true"></i>
                                                    Add Bundle Course
                                                </button>
                                                </router-link>
                                            </div>

                                            <div class="btn-top-table">
                                                <router-link to="/addassigngroup">
                                                    <button type="button" class="btn btn-primary btn-2 font-weight-bolder">
                                                        <span class="svg-icon svg-icon-primary svg-icon-2x"></span>
                                                        <i class="fa fa-eye" aria-hidden="true"></i>
                                                        Assign Bundle Course
                                                    </button>
                                                </router-link>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div class="table-dt">
                                    <table class="table table-hover">
                                        <thead>
                                            <tr>
                                                <th scope="col">Bundle Name</th>
                                                <th scope="col">Total Course</th>
                                                <th scope="col">Course</th>
                                                <th scope="col">Create Date</th>
                                                <th scope="col">Action</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            <template v-for="item in course_data">
                                                <tr :key="item.id">
                                                    <td>{{item.group_name}}</td>
                                                    <td>{{item.total_course}}</td>
                                                    <td>{{item.course_name}}</td>
                                                    <td>{{item.udate}}</td>                                            
                                                    <td>
                                                        <button class="label tbl-action label-inline label-light-danger font-weight-bold" @click="$router.push('/editgroupcourse/'+item.id)">
                                                            <span>Edit</span>
                                                        </button>
                                                        <button  @click="confirm_pop(item.id)" class="label tbl-action label-inline label-light-danger font-weight-bold delete-btn-clr">
                                                            <span>Delete</span>
                                                        </button>                                                   
                                                    </td>
                                                </tr>
                                            </template>

                                        </tbody>
                                    </table>

                                    <pagination v-model="page" :records="totalCount" :per-page="perPage" />

                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div class="main-popup-overlay" v-if="pop_val==true">

                    <div class="content-start-popup">

                        <div class="popup-header">
                            <h2>Enter Course Name</h2>
                        </div>
                        <div class="popup-main-content">
                            <div class="container">
                                <div class="form-data">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <input class="form-control" type="text" v-model="course_name" placeholder="Enter Course Name" />
                                        </div>

                                        <div class="col-md-12">
                                            <label class="label-imc">Select Course Image</label>
                                            <div class="row">
                                                <div class="col-6">
                                                    <div class="upload-btn-area" @click="$refs.file.click()">
                                                        <img src="../../assets/icons/upload_icon.svg" class="upload-icons-img">
                                                    </div>
                                                    <input class="hide" type="file" id="file" ref="file" @change="upload_file" accept="image/*" />
                                                </div>
                                                <div class="col-6">
                                                    <p class="select-img">{{select_image}}</p>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="col-md-12">
                                            <div class="btn-div-popup">
                                                <button class="btn btn-info btn-popup-save" @click="save_course">Save</button>
                                                <button class="btn btn-danger btn-popup-cancel" @click="pop_val=false;course_name=null;select_image=null;">Cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="main-popup-overlay" v-if="edit_pop_val==true">

                    <div class="content-start-popup">

                        <div class="popup-header">
                            <h2>Edit Course Name</h2>
                        </div>
                        <div class="popup-main-content">
                            <div class="container">
                                <div class="form-data">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <input class="form-control" type="text" v-model="edit_course_name" placeholder="Enter Course Name" />
                                        </div>
                                        <div class="col-md-12">
                                            <div class="btn-div-popup">
                                                <button class="btn btn-info btn-popup-save" @click="edit_course">Save</button>
                                                <button class="btn btn-danger btn-popup-cancel" @click="edit_pop_val=false;edit_course_name=null;edit_cid=null;">Cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>

</div>
</template>

<script>
import axios from 'axios'
import MobileHeader from '../../components/MobileHeader'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import Pagination from 'vue-pagination-2';

export default {
    name: 'Student',
    components: {
        Header,
        Footer,
        MobileHeader,
        Pagination,

    },
    data() {
        return {
            uid: localStorage.getItem("uid"),
            role: localStorage.getItem("role"),
            pop_val: false,
            edit_pop_val: false,
            course_name: null,
            edit_course_name: null,
            edit_cid: null,
            course_data: [],
            page: 1,
            totalCount: 0,
            newAry: [],
            perPage: 5,
            select_image: null,
            file: null,
        }
    },
    mounted() {
        if (this.uid == null || this.uid == "") {
            this.$router.push("/");
        }
        this.allCourse();
    },
    watch: {
        page: function (vl) {
            this.changePage();
        }
    },
    methods: {
        changePage() {
            this.course_data = [];
            var preCount = (this.perPage * this.page);

            var temp = this.perPage;
            var newPP = (preCount) - temp;
            //console.log(newPP);
            for (var i = 0; i < temp; i++) {
                if (this.newAry[newPP]) {
                    this.course_data[i] = this.newAry[newPP];
                    newPP++;
                }

            }
        },
        save_course_old() {
            var ev = this;

            if (this.course_name == null || this.course_name == "") {
                ev.$toasted.global.error({
                    message: "please enter course name"
                });
            } else {
                axios({
                    method: 'POST',
                    url: this.$root.URL_ROOT + 'lms_api.php',
                    data: {
                        type: 'course_save',
                        course_name: this.course_name,
                        uid: this.uid,
                        role: this.role,
                    }
                }).then(function (response) {
                    //console.log(response.data);
                    var data = response.data;
                    if (data.status == "success") {
                        ev.$toasted.global.success({
                            message: data.msg
                        });
                        ev.course_name = null;
                        ev.pop_val = false;
                        ev.allCourse();
                    } else {
                        ev.$toasted.global.error({
                            message: data.msg
                        });
                    }

                })
            }
        },
        allCourse() {
            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'lms_api.php',
                data: {
                    type: 'getGroupCourse',
                    uid: this.uid,
                    role: this.role,
                }
            }).then(function (response) {
                console.log(response.data)
                var data = response.data;
                ev.course_data = data.data;
                ev.newAry = data.data;
                ev.totalCount = data.count;
                ev.changePage();

            })
        },
        edit_course() {
            var ev = this;
            if (this.edit_course_name == "" || this.edit_course_name == null) {
                ev.$toasted.global.error({
                    message: "please enter course name"
                });
            } else {
                axios({
                    method: 'POST',
                    url: this.$root.URL_ROOT + 'lms_api.php',
                    data: {
                        type: 'edit_course',
                        course_name: this.edit_course_name,
                        cid: this.edit_cid,
                    }
                }).then(function (response) {
                    //console.log(response.data);
                    ev.edit_course_name = null;
                    ev.edit_cid = null;

                })
            }
        },
        upload_file() {
            var ev = this;
            this.file = this.$refs.file.files[0];

            let formData = new FormData();
            //console.log(this.file.name);
            this.select_image = this.file.name;
            //this.save_userimage();

        },
        save_course: function () {
            var ev = this;
            this.file = this.$refs.file.files[0];

            let formData = new FormData();

            formData.append('file', this.file);
            formData.append('uid', this.uid);
            formData.append('course_name', this.course_name);
            formData.append('role', this.role);
            if (this.course_name == null || this.course_name == "") {
                ev.$toasted.global.error({
                    message: "please enter course name"
                });
            } else if (this.file == undefined) {
                ev.$toasted.global.error({
                    message: "please upload image"
                });
            } else {

                axios.post(this.$root.URL_ROOT + 'lms_api.php?type=course_save', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(function (response) {
                        var data = response.data;
                        //console.log(data);
                        if (data.status == "success") {
                            ev.$toasted.global.success({
                                message: data.msg
                            });
                            ev.course_name = null;
                            ev.pop_val = false;
                            ev.allCourse();
                            ev.select_image = null;

                        } else {
                            ev.$toasted.global.error({
                                message: data.msg
                            });
                        }
                    })

            }

        },
        confirm_pop(ids){
            this.$confirm("", "Are You Sure ?").then(() => 
                this.delete_group(ids)
            );
        },
        delete_group(ids) {
            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'lms_api.php',
                data: {
                    type: 'delete_bundle',
                    id: ids
                }
            }).then(function (response) {
                var data = response.data;
                if(data.status=="success"){
                    ev.allCourse();  
                     ev.$toasted.global.success({
                        message: data.msg
                    }); 
                }
            })
        }
    },
}
</script>

<style scoped>

.edit-icon-course{
    cursor: pointer;
    color: #ee8133;
    font-size: 20px;
    margin-right: 10px;
}
.course-img {
    width: 100px;
    box-shadow: 0px 0px 5px 1px #929292;
}

.select-img {
    padding-top: 10px;
    color: #3898fb;
}

.upload-btn-area {
    background-color: #fff;
    padding: 5px;
    border: 1px solid #3898fb;
    border-radius: 10px;
    text-align: center;
    margin-top: 5px;
    cursor: pointer;
}

.label-imc {
    margin-top: 10px;
}

.upload-icons-img {
    width: 18%;
    text-align: center;
}

@media only screen and (max-width: 600px) {

    .main-popup-overlay {
        padding-top: 30% !important;
        padding-left: 5% !important;
        padding-right: 5% !important;
    }
}

.btn-popup-cancel {
    float: right;
    width: 120px;
}

.main-popup {
    position: fixed;
    width: 100%;
    padding: 20%;
}

.popup-header {
    text-align: center;
}

.btn-div-popup {
    padding-top: 5%;

}

.btn-popup-save {
    float: left;
    width: 120px;

}

.content-start-popup {

    background: #ffffff;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 10%;
    padding-bottom: 10%;
    border-radius: 15px;
}

.main-popup-overlay {
    position: fixed;
    width: 100%;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: #0404046e;
    padding-top: 20%;
    padding-right: 30%;
    padding-left: 30%;
    z-index: 999999999;
}

.btn-top-table {
    display: inline-block;
    padding: 10px;
    width: 50%;
}

.table {
    width: 100%;

}

.table-dt {
    overflow: scroll;
}

.tbl-action {
    margin: 2px;
    border: unset;
}
</style>
