<template>
<div name="student">
    <MobileHeader></MobileHeader>
    <div class="d-flex flex-row flex-column-fluid page">
        <div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
            <Header></Header>

            <!--begin::Content-->
            <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
                <!--begin::Entry-->
                <div class="d-flex flex-column-fluid">
                    <!--begin::Entry-->
                    <div class="d-flex flex-column-fluid">
                        <!--begin::Container-->
                        <div class="container">
                            <!--begin::Profile Personal Information-->
                            <div class="d-flex flex-row">
                                <!--begin::Aside-->
                                <div class="flex-row-auto offcanvas-mobile w-250px w-xxl-350px" id="kt_profile_aside">
                                    <!--begin::Profile Card-->
                                    <div class="card card-custom card-stretch">
                                        <!--begin::Body-->
                                        <div class="card-body pt-4">
                                            <!--begin::Toolbar-->
                                            <div class="d-flex justify-content-end">
                                                <div class="dropdown dropdown-inline">
                                                    <a href="#" class="btn btn-clean btn-hover-light-primary btn-sm btn-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    </a>
                                                </div>
                                            </div>
                                            <!--end::Toolbar-->
                                            <!--begin::User-->
                                            <div class="d-flex align-items-center">
                                                <div>
                                                    <a href="#" class="font-weight-bolder font-size-h5 text-dark-75 text-hover-primary">{{ StudentDetail.username }}</a>
                                                </div>
                                            </div>
                                            <!--end::User-->
                                            <!--begin::Contact-->
                                            <div class="py-9">
                                                <div class="d-flex align-items-center justify-content-between mb-2">
                                                    <span class="font-weight-bold mr-2">Email:</span>
                                                    <a href="#" class="text-muted text-hover-primary">{{
                              StudentDetail.email
                            }}</a>
                                                </div>
                                                <div class="d-flex align-items-center justify-content-between mb-2">
                                                    <span class="font-weight-bold mr-2">Phone:</span>
                                                    <span class="text-muted">{{
                              StudentDetail.mobile
                            }}</span>
                                                </div>
                                                <div class="d-flex align-items-center justify-content-between">
                                                    <span class="font-weight-bold mr-2">Location:</span>
                                                    <span class="text-muted">{{ StudentDetail.city}}</span>
                                                </div>
                                            </div>
                                            <!--end::Contact-->
                                            <!--begin::Nav-->
                                            <div class="navi navi-bold navi-hover navi-active navi-link-rounded">
                                                <div class="navi-item mb-2" @click="open_box = 'information'">
                                                    <a :class="open_box == 'information'? 'navi-link py-4 active': 'navi-link py-4'">
                                                        <span class="navi-icon mr-2">
                                                            <span class="svg-icon">
                                                                <!--begin::Svg Icon | path:assets/media/svg/icons/General/User.svg-->
                                                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                        <polygon points="0 0 24 0 24 24 0 24" />
                                                                        <path d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" />
                                                                        <path d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z" fill="#000000" fill-rule="nonzero" />
                                                                    </g>
                                                                </svg>
                                                                <!--end::Svg Icon-->
                                                            </span>
                                                        </span>
                                                        <span class="navi-text font-size-lg">Student Information</span>
                                                    </a>
                                                </div>

                                                <div class="navi-item mb-2" @click="open_box='change_password'">
                                                    <a :class="open_box == 'change_password'? 'navi-link py-4 active': 'navi-link py-4'">
                                                        <span class="navi-icon mr-2">
                                                            <span class="svg-icon">
                                                                <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Shield-user.svg-->
                                                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                        <rect x="0" y="0" width="24" height="24" />
                                                                        <path d="M4,4 L11.6314229,2.5691082 C11.8750185,2.52343403 12.1249815,2.52343403 12.3685771,2.5691082 L20,4 L20,13.2830094 C20,16.2173861 18.4883464,18.9447835 16,20.5 L12.5299989,22.6687507 C12.2057287,22.8714196 11.7942713,22.8714196 11.4700011,22.6687507 L8,20.5 C5.51165358,18.9447835 4,16.2173861 4,13.2830094 L4,4 Z" fill="#000000" opacity="0.3" />
                                                                        <path d="M12,11 C10.8954305,11 10,10.1045695 10,9 C10,7.8954305 10.8954305,7 12,7 C13.1045695,7 14,7.8954305 14,9 C14,10.1045695 13.1045695,11 12,11 Z" fill="#000000" opacity="0.3" />
                                                                        <path d="M7.00036205,16.4995035 C7.21569918,13.5165724 9.36772908,12 11.9907452,12 C14.6506758,12 16.8360465,13.4332455 16.9988413,16.5 C17.0053266,16.6221713 16.9988413,17 16.5815,17 C14.5228466,17 11.463736,17 7.4041679,17 C7.26484009,17 6.98863236,16.6619875 7.00036205,16.4995035 Z" fill="#000000" opacity="0.3" />
                                                                    </g>
                                                                </svg>
                                                                <!--end::Svg Icon-->
                                                            </span>
                                                        </span>
                                                        <span class="navi-text font-size-lg">Change Passwort</span>
                                                    </a>
                                                </div>

                                                <div class="navi-item mb-2" @click="open_box = 'enroll'">
                                                    <a data-toggle="tab" id="enroll" :class="open_box == 'enroll'? 'navi-link py-4 active': 'navi-link py-4'">
                                                        <span class="navi-icon mr-2">
                                                            <span class="svg-icon">
                                                                <!--begin::Svg Icon | path:/metronic/theme/html/demo7/dist/assets/media/svg/icons/Code/Compiling.svg-->
                                                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                        <rect x="0" y="0" width="24" height="24"></rect>
                                                                        <path d="M2.56066017,10.6819805 L4.68198052,8.56066017 C5.26776695,7.97487373 6.21751442,7.97487373 6.80330086,8.56066017 L8.9246212,10.6819805 C9.51040764,11.267767 9.51040764,12.2175144 8.9246212,12.8033009 L6.80330086,14.9246212 C6.21751442,15.5104076 5.26776695,15.5104076 4.68198052,14.9246212 L2.56066017,12.8033009 C1.97487373,12.2175144 1.97487373,11.267767 2.56066017,10.6819805 Z M14.5606602,10.6819805 L16.6819805,8.56066017 C17.267767,7.97487373 18.2175144,7.97487373 18.8033009,8.56066017 L20.9246212,10.6819805 C21.5104076,11.267767 21.5104076,12.2175144 20.9246212,12.8033009 L18.8033009,14.9246212 C18.2175144,15.5104076 17.267767,15.5104076 16.6819805,14.9246212 L14.5606602,12.8033009 C13.9748737,12.2175144 13.9748737,11.267767 14.5606602,10.6819805 Z" fill="#000000" opacity="0.3"></path>
                                                                        <path d="M8.56066017,16.6819805 L10.6819805,14.5606602 C11.267767,13.9748737 12.2175144,13.9748737 12.8033009,14.5606602 L14.9246212,16.6819805 C15.5104076,17.267767 15.5104076,18.2175144 14.9246212,18.8033009 L12.8033009,20.9246212 C12.2175144,21.5104076 11.267767,21.5104076 10.6819805,20.9246212 L8.56066017,18.8033009 C7.97487373,18.2175144 7.97487373,17.267767 8.56066017,16.6819805 Z M8.56066017,4.68198052 L10.6819805,2.56066017 C11.267767,1.97487373 12.2175144,1.97487373 12.8033009,2.56066017 L14.9246212,4.68198052 C15.5104076,5.26776695 15.5104076,6.21751442 14.9246212,6.80330086 L12.8033009,8.9246212 C12.2175144,9.51040764 11.267767,9.51040764 10.6819805,8.9246212 L8.56066017,6.80330086 C7.97487373,6.21751442 7.97487373,5.26776695 8.56066017,4.68198052 Z" fill="#000000"></path>
                                                                    </g>
                                                                </svg>
                                                                <!--end::Svg Icon-->
                                                            </span>
                                                        </span>
                                                        <span class="navi-text font-size-lg">Enrollments</span>
                                                    </a>
                                                </div>
                                            </div>
                                            <!--end::Nav-->
                                        </div>
                                        <!--end::Body-->
                                    </div>
                                    <!--end::Profile Card-->
                                </div>
                                <!--end::Aside-->

                                <!--begin::Content informtion -->
                                <div class="flex-row-fluid ml-lg-8" v-if="open_box == 'information'">
                                    <!--begin::Card-->
                                    <div class="card card-custom card-stretch">
                                        <!--begin::Header-->
                                        <div class="card-header py-3">
                                            <div class="card-title align-items-start flex-column">
                                                <h3 class="card-label font-weight-bolder text-dark">
                                                    Student Information
                                                </h3>
                                                <span class="text-muted font-weight-bold font-size-sm mt-1">Update Student informaiton</span>
                                            </div>
                                            <div class="card-toolbar">
                                                <button type="reset" class="btn btn-success mr-2" @click="update_student">
                                                    Save Changes
                                                </button>
                                            </div>
                                        </div>
                                        <!--end::Header-->
                                        <!--begin::Form-->
                                        <form class="form">
                                            <!--begin::Body-->
                                            <div class="card-body">
                                                <div class="row">
                                                    <label class="col-xl-3"></label>
                                                    <div class="col-lg-9 col-xl-6">
                                                        <h5 class="font-weight-bold mb-6">
                                                            Student Info
                                                        </h5>
                                                    </div>
                                                </div>

                                                <div class="form-group row">
                                                    <label class="col-xl-3 col-lg-3 col-form-label">Full Name</label>
                                                    <div class="col-lg-9 col-xl-6">
                                                        <input class="form-control form-control-lg form-control-solid" v-model="fullname" type="text" />
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <label class="col-xl-3"></label>
                                                    <div class="col-lg-9 col-xl-6">
                                                        <h5 class="font-weight-bold mt-10 mb-6">
                                                            Contact Info
                                                        </h5>
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <label class="col-xl-3 col-lg-3 col-form-label">Contact Phone</label>
                                                    <div class="col-lg-9 col-xl-6">
                                                        <div class="input-group input-group-lg input-group-solid">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">
                                                                    <i class="la la-phone"></i>
                                                                </span>
                                                            </div>
                                                            <input type="text" class="form-control form-control-lg form-control-solid" v-model="mobile" placeholder="Phone" />
                                                        </div>
                                                        <span class="form-text text-muted">We'll never share your email with anyone
                                                            else.</span>
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <label class="col-xl-3 col-lg-3 col-form-label">Email Address</label>
                                                    <div class="col-lg-9 col-xl-6">
                                                        <div class="input-group input-group-lg input-group-solid">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">
                                                                    <i class="la la-at"></i>
                                                                </span>
                                                            </div>
                                                            <input type="text" class="form-control form-control-lg form-control-solid" v-model="email" placeholder="Email" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!--end::Body-->
                                        </form>
                                        <!--end::Form-->
                                    </div>
                                </div>
                                <!--end::Content-->

                                <!--begin::Content enroll-->
                                <div class="flex-row-fluid ml-lg-8" v-if="open_box == 'enroll'">
                                    <div class="tab-pane fade active show" id="kt_tab_pane_7_3" role="tabpanel" aria-labelledby="kt_tab_pane_7_3">
                                        <div class="card card-custom">
                                            <div class="card-header mt-5">
                                                <!--begin::Search Form-->
                                                <div class="card-title" style="width: 80%">
                                                    <div class="form-group" style="width: 100%">
                                                        <label>Course select</label><br />
                                                        <div class=" show-tick dropup">

                                                            <select v-model="course_id" class="form-control">
                                                                <option value="">Select Course</option>
                                                                <template v-for="item in course_data">
                                                                    <option :key="item.id" :value="item.id">{{item.course_name}}</option>
                                                                </template>
                                                            </select>
                                                        </div>

                                                    </div>
                                                    <div class="form-group ml-4" style="width: 100%">
                                                        <label>Expiry Date</label>
                                                        <div class="input-group date">
                                                            <input type="date" v-model="course_exdate" class="form-control" placeholder="Select date" />

                                                        </div>
                                                    </div>

                                                </div>

                                                <div class="card-title" style="width:80%">
                                                    <div class="form-group" style="width: 100%">
                                                        <div v-if="options">
                                                            <label class="mr-3 mb-2 d-none d-md-block">Select Chapter :</label>
                                                            <div class="row container">
                                                                <template v-for="cdata in chapter_data">
                                                                    <div class="col-3" :key="cdata.id">
                                                                        <label>
                                                                            <input type="checkbox" id="nick" class="form-check-input" :value="cdata.id" v-model="checkedNames">
                                                                            {{cdata.chapter_name}}
                                                                        </label>
                                                                    </div>

                                                                </template>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                                <div class="card-toolbar">
                                                    <button class="btn btn-lg btn-light-primary px-6 font-weight-bold" @click="save_enroll">Enroll</button>
                                                </div>
                                            </div>

                                            <div class="card-body">
                                                <!--begin: Datatable-->
                                                <div class="datatable datatable-default datatable-bordered datatable-loaded">
                                                    <table class="datatable-bordered datatable-head-custom datatable-table" id="kt_datatable" style="display: block">
                                                        <thead class="datatable-head">
                                                            <tr class="datatable-row" style="left: 0px">

                                                                <th data-field="Course" data-autohide-disabled="false" class="datatable-cell datatable-cell-sort">
                                                                    <span style="width: 153px">Course</span>
                                                                </th>

                                                                <th data-field="Enrollment Status" data-autohide-disabled="false" class="datatable-cell datatable-cell-sort">
                                                                    <span style="width: 153px">Enrollment Status</span>
                                                                </th>
                                                                <th data-field="Expiry Date" class="datatable-cell datatable-cell-sort">
                                                                    <span style="width: 153px">Expiry Date</span>
                                                                </th>
                                                                <th data-field="Action" data-autohide-disabled="false" class="datatable-cell datatable-cell-sort">
                                                                    <span style="width: 153px">Action</span>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody style="" class="datatable-body">
                                                            <template v-for="cdata in enrollData">

                                                                <tr data-row="0" :key="cdata.id" class="datatable-row" style="left: 0px">

                                                                    <td data-field="Course" data-autohide-disabled="false" class="datatable-cell">
                                                                        <span style="width: 153px">
                                                                            {{cdata.course_name}}
                                                                        </span>
                                                                    </td>

                                                                    <td data-field="Enrollment Status" data-autohide-disabled="false" aria-label='<p class="label label-success label-pill label-inline mr-2" style="width:auto !important;">Active</p>' class="datatable-cell">
                                                                        <span style="width: 153px">
                                                                           
                                                                            <p v-if="cdata.status==10" class="label label-danger label-pill label-inline mr-2" style="width:auto !important;">
                                                                                Expire
                                                                            </p>
                                                                            <p v-if="cdata.status==5" class="label label-danger yellow-bg label-pill label-inline mr-2" style="width:auto !important;">
                                                                                Going to expire
                                                                            </p>
                                                                            <p v-if="cdata.status==1" class="label label-danger green-bg label-pill label-inline mr-2" style="width:auto !important;">
                                                                                Active
                                                                            </p>
                                                                        </span>
                                                                    </td>
                                                                    <td data-field="Expiry Date" aria-label="2021-05-29" class="datatable-cell">
                                                                        <span style="width: 153px">{{cdata.exdate}}</span>
                                                                    </td>

                                                                    <td>
                                                                        <button @click="unroll(cdata.id)" class="label tbl-action label-inline label-light-danger font-weight-bold bdr-none">
                                                                            <span>UNROLL FROM COURSE</span>
                                                                        </button>

                                                                    </td>

                                                                </tr>

                                                            </template>

                                                        </tbody>
                                                    </table>

                                                </div>
                                                <!--end: Datatable-->
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <!--end::Content-->

                                <!--begin::Content enroll-->
                                <div class="flex-row-fluid ml-lg-8" v-if="open_box == 'change_password'">
                                    <div class="tab-pane fade active show" id="kt_tab_pane_7_2" role="tabpanel" aria-labelledby="kt_tab_pane_7_2">
                                        <!--begin::Form-->

                                        <!--begin::Card-->
                                        <div class="card card-custom">
                                            <!--begin::Header-->
                                            <div class="card-header py-3">
                                                <div class="card-title align-items-start flex-column">
                                                    <h3 class="card-label font-weight-bolder text-dark">Change Password</h3>
                                                    <span class="text-muted font-weight-bold font-size-sm mt-1">Change your account password</span>
                                                </div>
                                                <div class="card-toolbar">
                                                    <button class="btn btn-success mr-2" @click="change_pass">Save Changes</button>

                                                </div>
                                            </div>
                                            <!--end::Header-->
                                            <div class="card-body">
                                                <!--begin::Alert-->

                                                <!--end::Alert-->
                                                <div class="form-group row">
                                                    <label class="col-xl-3 col-lg-3 col-form-label text-alert">New Password</label>
                                                    <div class="col-lg-9 col-xl-6">
                                                        <input type="password" class="form-control form-control-lg form-control-solid" v-model="new_password" name="password" placeholder="New password">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <!--end::Form-->
                                    </div>
                                </div>
                                <!--end::Content-->

                            </div>
                            <!--end::Profile Personal Information-->
                        </div>
                        <!--end::Container-->
                    </div>
                    <!--end::Entry-->
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from "axios";
import MobileHeader from "../../components/MobileHeader";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Pagination from "vue-pagination-2";

export default {
    name: "TeacherView",
    components: {
        Header,
        Footer,
        MobileHeader,
        Pagination,
    },
    data() {
        return {
            uid: localStorage.getItem("uid"),
            role: localStorage.getItem("role"),
            sid: this.$route.params.sid,
            StudentDetail: [],
            fullname: null,
            email: null,
            mobile: null,
            open_box: "information",
            course_data: [],
            enrollData: [],
            course_exdate: null,
            course_id: "",
            new_password: null,
            checkedNames: [],
            options: false,
        };
    },
    mounted() {
        if (this.uid == null || this.uid == "") {
            this.$router.push("/");
        }
        //console.log(this.sid);
        this.getStudentDetail();
        this.getCourse();
        this.getEnrollCourse();
    },
    watch: {
        course_id: function (vl) {
            this.getChapter();
        },
        checkedNames: function (vl) {
            //console.log(vl);
            this.checkedNames = vl;
            //console.log(this.checkedNames.length);
        }
    },
    methods: {
        getStudentDetail() {
            var ev = this;
            axios({
                method: "POST",
                url: this.$root.URL_ROOT + "lms_api.php",
                data: {
                    type: "getStudentDetail",
                    sid: this.sid,
                },
            }).then(function (response) {
                //console.log(response.data);
                var data = response.data;
                ev.StudentDetail = data;
                ev.fullname = ev.StudentDetail.username;
                ev.email = ev.StudentDetail.email;
                ev.mobile = ev.StudentDetail.mobile;
            });
        },
        update_student() {
            var ev = this;

            if (ev.fullname == null || ev.fullname == "") {
                ev.$toasted.global.error({
                    message: "please enter fullname",
                });
            } else if (ev.email == null || ev.email == "") {
                ev.$toasted.global.error({
                    message: "please enter email",
                });
            } else if (ev.mobile == null || ev.mobile == "") {
                ev.$toasted.global.error({
                    message: "please enter contact no. ",
                });
            } else {
                axios({
                    method: "POST",
                    url: this.$root.URL_ROOT + "lms_api.php",
                    data: {
                        type: "update_student",
                        sid: this.sid,
                        fullname: this.fullname,
                        email: this.email,
                        mobile: this.mobile,
                    },
                }).then(function (response) {
                    //console.log(response.data);
                    ev.getStudentDetail();
                    ev.$toasted.global.success({
                        message: "Update successfully",
                    });
                });
            }
        },
        getCourse() {
            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'lms_api.php',
                data: {
                    type: 'getAllCourseDetails',
                }
            }).then(function (response) {
                var data = response.data;
                ev.course_data = data;
            })
        },
        getEnrollCourse() {
            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'lms_api.php',
                data: {
                    type: 'getMyEnroll_new',
                    sid: this.sid,

                }
            }).then(function (response) {
                //console.log(response.data);
                var data = response.data;
                ev.enrollData = data.data;
            })
        },
        old_save_enroll() {
            var ev = this;
            if (this.course_id == null || this.course_id == "") {
                ev.$toasted.global.error({
                    message: "please select course"
                });
            } else if (this.course_exdate == null || this.course_exdate == "") {
                ev.$toasted.global.error({
                    message: "please select date"
                });
            } else {
                axios({
                    method: 'POST',
                    url: this.$root.URL_ROOT + 'lms_api.php',
                    data: {
                        type: 'save_enroll',
                        sid: this.sid,
                        course_id: this.course_id,
                        course_exdate: this.course_exdate,
                        uid: this.uid,
                    }
                }).then(function (response) {
                    var data = response.data;
                    if (data.status == "success") {
                        ev.course_exdate = null;
                        ev.course_id = null;
                        ev.getEnrollCourse();
                        ev.$toasted.global.success({
                            message: "Enroll Successfully"
                        });
                    }
                })
            }
        },
        change_pass() {
            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'lms_api.php',
                data: {
                    type: 'change_pass_student',
                    new_password: this.new_password,
                    sid: this.sid,

                }
            }).then(function (response) {
                var data = response.data;
                if (data.status == 'success') {
                    ev.new_password = null;
                    ev.$toasted.global.success({
                        message: "password change successfully"
                    });
                } else {
                    ev.$toasted.global.error({
                        message: "something wrong"
                    });
                }
            })
        },
        getChapter() {
            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'lms_api.php',
                data: {
                    type: 'getChapterList',
                    cid: this.course_id,
                }
            }).then(function (response) {
                //console.log(response.data);
                var data = response.data;
                if (data.count > 0) {
                    ev.chapter_data = data.data;
                    ev.options = true
                } else {
                    ev.$toasted.global.error({
                        message: "In this course don't have chapter"
                    });
                    //ev.chapter_data = [];
                    ev.options = false;
                    ev.chapter_id = null;
                    ev.checkedNames = [];
                }
            })
        },
        save_enroll() {
            var ev = this;

            if (this.course_id == 'Select Course' || this.course_id == '' || this.course_id == null) {
                ev.$toasted.global.error({
                    message: 'Select Course'
                });
            } else if (this.checkedNames.length < 1) {
                ev.$toasted.global.error({
                    message: 'Select Chapter'
                });
            } else if (this.course_exdate == "" || this.course_exdate == null) {
                ev.$toasted.global.error({
                    message: 'Select Expiry Date'
                });
            } else {
                axios({
                    method: 'POST',
                    url: this.$root.URL_ROOT + 'lms_api.php',
                    data: {
                        type: 'new_save_enroll_2',
                        course_id: this.course_id,
                        sid: this.sid,
                        uid: this.uid,
                        chapters: this.checkedNames,
                        course_exdate: this.course_exdate,
                    }
                }).then(function (response) {
                    var data = response.data;
                    //console.log(data);
                    if (data.status == 'success') {
                        ev.$toasted.global.success({
                            message: data.msg
                        });
                        //ev.$router.push("/assigncourse");
                        ev.getEnrollCourse();
                    } else {
                        ev.$toasted.global.error({
                            message: data.msg
                        });
                    }
                })
            }

        },
        unroll(ids) {
            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'lms_api.php',
                data: {
                    type: 'unroll',
                    cid: ids,
                }
            }).then(function (response) {
                var data = response.data;
                if (data.status == "success") {
                    ev.$toasted.global.success({
                        message: data.msg
                    });
                    ev.getEnrollCourse();
                } else {
                    ev.$toasted.global.error({
                        message: data.msg
                    });
                }
            })

        }
    },
};
</script>

<style scoped>
.bdr-none {
    border: none;
}

.navi-link {
    cursor: pointer;
}
</style>
