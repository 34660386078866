<template>
<div name="student">

    <MobileHeader></MobileHeader>
    <div class="d-flex flex-row flex-column-fluid page">
        <div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">

            <Header></Header>

            <!--begin::Content-->
            <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
                <!--begin::Entry-->
                <div class="d-flex flex-column-fluid">
                    <!--begin::Container-->
                    <!--begin::Dashboard-->

                    <div class="container">
                        <template v-for="adata in allData">
                            <div :key="adata.id" class="card card-custom gutter-b">
                                <!--begin::Body-->
                                <div class="card-body">
                                    <h2>{{adata.name}}</h2>
                                    
                                    <div class="container">
                                        <div class="d-flex mb-5">
                                        <div class="d-flex align-items-center mr-7">
                                            <span class="font-weight-bold mr-4">Due Date</span>
                                            <span class="btn btn-light-primary btn-sm font-weight-bold btn-upper btn-text">{{adata.due_date}}</span>
                                        </div>
                                        <div class="d-flex align-items-center mr-7">
                                            <span class="font-weight-bold mr-4">Start Time </span>
                                            <span class="btn btn-light-danger btn-sm font-weight-bold btn-upper btn-text dddd">{{adata.start_time}}</span>
                                        </div>

                                        <div class="d-flex align-items-center mr-7">
                                            <span class="font-weight-bold mr-4">End Time </span>
                                            <span class="btn btn-light-danger btn-sm font-weight-bold btn-upper btn-text dddd">{{adata.end_time}}</span>
                                        </div>

                                    </div>

                                        <label>Announcement</label>
                                        <p v-html="adata.description"></p>
                                    </div>

                                </div>

                            </div>
                        </template>   
                        <pagination v-model="page" :records="totalCount" :per-page="perPage" /> 
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
</template>

<script>
import axios from 'axios'
import MobileHeader from '../../components/MobileHeader'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import Pagination from 'vue-pagination-2';

import {
    VueEditor
} from "vue2-editor";

export default {
    name: 'ViewAnnouncement',
    components: {
        Header,
        Footer,
        MobileHeader,
        VueEditor,
        Pagination,
    },
    data() {
        return {
            uid: localStorage.getItem("uid"),
            role: localStorage.getItem("role"),
            allData: [],
             page: 1,
            totalCount: 0,
            newAry: [],
            perPage: 3,
        }
    },
    mounted() {
        if (this.uid == null || this.uid == "") {
            this.$router.push("/");
        }
        this.getSchedule();
    },
    watch:{
        page: function (vl) {
            this.changePage();
        },
    },
    methods: {
        changePage() {
            this.allData = [];
            var preCount = (this.perPage * this.page);

            var temp = this.perPage;
            var newPP = (preCount) - temp;
            //console.log(newPP);
            for (var i = 0; i < temp; i++) {
                if (this.newAry[newPP]) {
                    this.allData[i] = this.newAry[newPP];
                    newPP++;
                }

            }
        },
        getSchedule() {
            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'lms_api.php',
                data: {
                    type: 'getAnnouncement',
                    role: this.role,
                    uid: this.uid,
                    
                }
            }).then(function (response) {
                var data = response.data;
                ev.allData = data.data;
                ev.newAry=data.data;
                ev.totalCount=data.count;
                ev.changePage();
               //console.log(response.data);
            })
        }
    },
}
</script>

<style scoped>
.btn-top-table {
    display: inline-block;
    padding: 10px;
    width: 50%;
}

.table {
    width: 100%;

}

.table-dt {
    overflow: scroll;
}

.tbl-action {
    margin: 2px;
    border: unset;
}
</style>
