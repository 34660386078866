<template>
<div name="student">

    <MobileHeader></MobileHeader>
    <div class="d-flex flex-row flex-column-fluid page">
        <div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">

            <Header></Header>

            <!--begin::Content-->
            <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
                <!--begin::Entry-->
                <div class="d-flex flex-column-fluid">
                    <!--begin::Container-->
                    <!--begin::Dashboard-->

                    <div class="container">
                        
                        <div class="card card-custom gutter-b">
                            <!--begin::Body-->
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-xl-12">
                                        <div class="form-group">
                                            <label>Support Title</label>
                                            <input type="text" v-model="title" class="form-control form-control-solid form-control-lg" name="support_title" id="support_title" placeholder="Title">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label>Description</label>
                                    <div class="form-group">
                                        <vue-editor v-model="description"></vue-editor>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <button type="submit" @click="edit_send_request" class="btn btn-primary mr-2">Submit</button>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
</template>

<script>
import axios from 'axios'
import MobileHeader from '../../components/MobileHeader'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import {
    VueEditor
} from "vue2-editor";

export default {
    name: 'Support',
    components: {
        Header,
        Footer,
        MobileHeader,
        VueEditor,
    },
    data() {
        return {
            uid: localStorage.getItem("uid"),
            role: localStorage.getItem("role"),
            allData: [],
            check_div: 1,
            title: null,
            description: null,
        }
    },
    mounted() {
        if (this.uid == null || this.uid == "") {
            this.$router.push("/");
        }

        this.getSupportRequestId();
    },
    methods: {
        getSchedule() {
            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'lms_api.php',
                data: {
                    type: 'getSchedule',
                    role: this.role,
                    uid: this.uid,
                    stype: 'todo'

                }
            }).then(function (response) {
                var data = response.data;
                ev.allData = data;
                //console.log(data);
            })
        },
        send_request() {
            var ev = this;
            if (this.title == null || this.title == "") {
                ev.$toasted.global.error({
                    message: 'please enter title'
                });
            } else if (this.description == null || this.description == "") {
                ev.$toasted.global.success({
                    message: 'please enter description'
                });
            } else {
                axios({
                    method: 'POST',
                    url: this.$root.URL_ROOT + 'lms_api.php',
                    data: {
                        type: 'send_support_request',
                        role: this.role,
                        uid: this.uid,
                        title: this.title,
                        description: this.description,

                    }
                }).then(function (response) {
                    var data = response.data;
                    if (data.status == "success") {
                        ev.$toasted.global.success({
                            message: data.msg
                        });
                        ev.title=null;
                        ev.description=null;
                        
                    } else {
                        ev.$toasted.global.error({
                            message: data.msg
                        });
                    }
                })
            }
        },
        getSupportRequestId() {
            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'lms_api.php',
                data: {
                    type: 'getSupportRequestId',
                    sid: this.$route.params.sid,

                }
            }).then(function (response) {
                var data = response.data;
                //ev.allData = data;
                ev.title=data.title;
                ev.description=data.description;
                //console.log(data);
            })
        },
        edit_send_request() {
            var ev = this;
            if (this.title == null || this.title == "") {
                ev.$toasted.global.error({
                    message: 'please enter title'
                });
            } else if (this.description == null || this.description == "") {
                ev.$toasted.global.success({
                    message: 'please enter description'
                });
            } else {
                axios({
                    method: 'POST',
                    url: this.$root.URL_ROOT + 'lms_api.php',
                    data: {
                        type: 'edit_send_support_request',
                        title: this.title,
                        description: this.description,
                        sid: this.$route.params.sid

                    }
                }).then(function (response) {
                    var data = response.data;
                    if (data.status == "success") {
                        ev.$toasted.global.success({
                            message: data.msg
                        });
                        ev.$router.push('/supportlist');
                        
                    } else {
                        ev.$toasted.global.error({
                            message: data.msg
                        });
                    }
                })
            }
        },

    },
}
</script>

<style scoped>
.add-btn-s {
    background-color: #3798fb;
    border: none;
    margin: 1%;
}

.btn-top-table {
    display: inline-block;
    padding: 10px;
    width: 50%;
}

.table {
    width: 100%;

}

.table-dt {
    overflow: scroll;
}

.tbl-action {
    margin: 2px;
    border: unset;
}
</style>
