<template>
<div name="student">

    <MobileHeader></MobileHeader>
    <div class="d-flex flex-row flex-column-fluid page">
        <div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">

            <Header></Header>

            <!--begin::Content-->
            <div class="content d-flex flex-column flex-column-fluid" id="kt_content">

                <!--begin::Entry-->
                <div class="d-flex flex-column-fluid">
                    <!--begin::Container-->
                    <!--begin::Dashboard-->

                    <div class="container">
                        <div class="card card-custom mt-5">
                            <!--begin::Header-->
                            <div class="card-header flex-wrap border-0 pt-6 pb-0">
                                <div class="card-title">
                                    <h3 class="card-label">Support Request's Management

                                        <span class="d-block text-muted pt-2 font-size-sm">Support Request's Management
                                        </span></h3>
                                </div>

                            </div>
                            <!--end::Header-->
                            <div class="card-body">
                                <!--begin: Datatable-->

                                <div id="example_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">

                                    <div class="row">
                                        <div class="col-sm-12">
                                            <table id="example" class="table dataTable no-footer" style="width:100%" role="grid" aria-describedby="example_info">
                                                <thead>
                                                    <tr role="row">
                                                        <th title="Field #1" class="sorting sorting_desc" tabindex="0" aria-controls="example" rowspan="1" colspan="1" style="width: 117px;">Ttile</th>
                                                        <th title="Field #2" class="sorting" tabindex="0" aria-controls="example" rowspan="1" colspan="1" style="width: 396px;">Description</th>
                                                        <th title="Field #4" class="sorting" tabindex="0" aria-controls="example" rowspan="1" colspan="1" aria-label="Action: activate to sort column ascending" style="width: 323px;">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    <!-- Modal-->
                                                    <template v-for="tdata in course_data">
                                                        <tr class="odd" :key="tdata.id">
                                                            <td class="sorting_1">{{tdata.title}}</td>
                                                            <td class="sorting_1" v-html="tdata.description"></td>

                                                            <td>
                                                                <router-link :to="'/supportedit/'+tdata.id">
                                                                    <span class="edit-btn-clr label label-inline label-light-danger font-weight-bold">Edit </span>
                                                                </router-link>
                                                                <a @click="delete_support(tdata.id)" class="mouse-point">
                                                                    <span class="delete-btn-clr label label-inline label-light-danger font-weight-bold ml-5">Delete</span>
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    </template>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                </div>
                                <!--end: Datatable-->
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>

</div>
</template>

<script>
import axios from 'axios'
import MobileHeader from '../../components/MobileHeader'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import Pagination from 'vue-pagination-2';

export default {
    name: 'Student',
    components: {
        Header,
        Footer,
        MobileHeader,
        Pagination,

    },
    data() {
        return {
            uid: localStorage.getItem("uid"),
            role: localStorage.getItem("role"),
            pop_val: false,
            edit_pop_val: false,
            course_name: null,
            edit_course_name: null,
            edit_cid: null,
            course_data: [],
            page: 1,
            totalCount: 0,
            newAry: [],
            perPage: 5,
        }
    },
    mounted() {
        if (this.uid == null || this.uid == "") {
            this.$router.push("/");
        }
        this.allRequestList();
    },
    watch: {
        page: function (vl) {
            this.changePage();
        }
    },
    methods: {
        changePage() {
            this.course_data = [];
            var preCount = (this.perPage * this.page);

            var temp = this.perPage;
            var newPP = (preCount) - temp;
            //console.log(newPP);
            for (var i = 0; i < temp; i++) {
                if (this.newAry[newPP]) {
                    this.course_data[i] = this.newAry[newPP];
                    newPP++;
                }

            }
        },
        save_course() {
            var ev = this;

            if (this.course_name == null || this.course_name == "") {
                ev.$toasted.global.error({
                    message: "please enter course name"
                });
            } else {
                axios({
                    method: 'POST',
                    url: this.$root.URL_ROOT + 'lms_api.php',
                    data: {
                        type: 'course_save',
                        course_name: this.course_name,
                        uid: this.uid,
                        role: this.role,
                    }
                }).then(function (response) {
                    //console.log(response.data);
                    var data = response.data;
                    if (data.status == "success") {
                        ev.$toasted.global.success({
                            message: data.msg
                        });
                        ev.course_name = null;
                        ev.pop_val = false;
                        ev.allCourse();
                    } else {
                        ev.$toasted.global.error({
                            message: data.msg
                        });
                    }

                })
            }
        },
        allRequestList() {
            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'lms_api.php',
                data: {
                    type: 'allRequestList',

                }
            }).then(function (response) {
                //console.log(response.data)
                var data = response.data;
                ev.course_data = data.data;
                ev.newAry = data.data;
                ev.totalCount = data.count;
                ev.changePage();

            })
        },
        edit_course() {
            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'lms_api.php',
                data: {
                    type: 'edit_course',
                    course_name: this.edit_course_name,
                    cid: this.edit_cid,
                }
            }).then(function (response) {
                //console.log(response.data);
                ev.edit_course_name = null;
                ev.edit_cid = null;

            })
        },
        delete_support(ids) {
            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'lms_api.php',
                data: {
                    type: 'delete_support',
                    sid: ids,
                }
            }).then(function (response) {
                var data = response.data;
                if (data.status == "success") {
                    ev.$toasted.global.success({
                        message: data.msg
                    });
                    ev.allRequestList();
                }else{
                    ev.$toasted.global.error({
                        message: data.msg
                    });
                }
            })
        }
    },
}
</script>

<style scoped>
.mouse-point{
    cursor: pointer;
}
@media only screen and (max-width: 600px) {

    .main-popup-overlay {
        padding-top: 30% !important;
        padding-left: 5% !important;
        padding-right: 5% !important;
    }
}

.btn-popup-cancel {
    float: right;
    width: 120px;
}

.main-popup {
    position: fixed;
    width: 100%;
    padding: 20%;
}

.popup-header {
    text-align: center;
}

.btn-div-popup {
    padding-top: 5%;

}

.btn-popup-save {
    float: left;
    width: 120px;

}

.content-start-popup {

    background: #ffffff;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 10%;
    padding-bottom: 10%;
    border-radius: 15px;
}

.main-popup-overlay {
    position: fixed;
    width: 100%;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: #0404046e;
    padding-top: 20%;
    padding-right: 30%;
    padding-left: 30%;
    z-index: 999999999;
}

.btn-top-table {
    display: inline-block;
    padding: 10px;
    width: 50%;
}

.table {
    width: 100%;

}

.table-dt {
    overflow: scroll;
}

.tbl-action {
    margin: 2px;
    border: unset;
}
</style>
