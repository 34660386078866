<template>
<div name="student">

    <MobileHeader></MobileHeader>
    <div class="d-flex flex-row flex-column-fluid page">
        <div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">

            <Header></Header>

            <!--begin::Content-->
            <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
                <!--begin::Entry-->
                <div class="d-flex flex-column-fluid">
                    <!--begin::Container-->
                    <!--begin::Dashboard-->

                    <div class="container">
                        <div class="card card-custom gutter-b">
                            <!--begin::Body-->
                            <div class="card-body">

                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-xl-6">
                                            <div class="form-group">
                                                <label>SOP Name</label>
                                                <input type="text" class="form-control form-control-solid form-control-lg" disabled v-model="sop_name" id="schedule_name" placeholder="SOP Name">
                                            </div>
                                        </div>

                                        <div class="col-xl-6">
                                            <div class="form-group">
                                                <label>SOP Name</label>
                                                <input type="text" class="form-control form-control-solid form-control-lg" disabled v-model="sop_type" placeholder="SOP Type">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label>Description</label>
                                        <div class="form-group">
                                            <vue-editor disabled v-model="description"></vue-editor>
                                        </div>
                                    </div>
                                    <div class="row">

                                        <div class="col-xl-4">
                                            <div class="form-group">
                                                <label>Due Date</label>
                                                <div class="input-group date">
                                                    <input type="date" name="due_date" disabled class="form-control" v-model="due_date" placeholder="Select date">
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-xl-4">
                                            <div class="form-group">
                                                <label>Start File Type</label>
                                                <select class="form-control" disabled v-model="file_type">
                                                    <option value="">Select File Type</option>
                                                    <option value="image">Image</option>
                                                    <option value="audio">Audio</option>
                                                    <option value="pdf">PDF</option>
                                                    <option value="video">Video</option>

                                                </select>
                                            </div>
                                        </div>
                                       
                                        <div class="col-xl-6">
                                            <div v-if="allData.file_type=='image'">
                                                <img :src="$root.URL_IMAGES+'sop/'+allData.file">
                                            </div>

                                            <div v-if="allData.file_type=='audio'">
                                                <av-circle :audio-src="$root.URL_IMAGES+'sop/'+allData.file" :symmetric="true" :outline-width="0" :progress-width="5" :outline-meter-space="5" :playtime="true" playtime-font="18px Monaco">
                                                </av-circle>
                                            </div>

                                            <div v-if="allData.file_type=='pdf'">
                                                 <iframe class="pdf-view" :src="$root.URL_IMAGES+'sop/'+allData.file" type="application/pdf" data="[ImageURLHere]#zoom=65%&scrollbar=0&toolbar=0&navpanes=0"></iframe>
                                            </div>

                                            <div v-if="allData.file_type=='video'">
                                                <iframe :src="'https://player.vimeo.com/video/'+allData.video+'?badge=0&amp;autopause=0&amp;player_id=0&amp;'" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="width:100%;height:400px;" ></iframe>
                                            </div>


                                        </div>

                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
</template>

<script>
import axios from 'axios'
import MobileHeader from '../../components/MobileHeader'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import AudioVisual from 'vue-audio-visual'
import Vue from 'vue'
Vue.use(AudioVisual)
import {
    VueEditor
} from "vue2-editor";

export default {
    name: 'ViewSops',
    components: {
        Header,
        Footer,
        MobileHeader,
        VueEditor,
    },
    data() {
        return {
            uid: localStorage.getItem("uid"),
            role: localStorage.getItem("role"),
            schedule_name: null,
            description: null,
            due_date: null,
            start_time: null,
            end_time: null,
            allData: [],
            sop_name: null,
            sop_type: null,
            videoList: [],
            file_type: "",
            show_file: null,
            select_file_name: null,
            file: null,
            vid: "",
        }
    },
    mounted() {
        if (this.uid == null || this.uid == "") {
            this.$router.push("/");
        }
        this.getSchedule();
    },
    methods: {
        getSchedule() {
            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'lms_api.php',
                data: {
                    type: 'getSopsById',
                    role: this.role,
                    uid: this.uid,
                    sid: this.$route.params.id,
                }
            }).then(function (response) {
                var data = response.data;
                ev.allData = data;
                 ev.sop_name = data.sops_name;
                ev.sop_type = data.sops_type;
                ev.description = data.description;
                ev.due_date = data.due_date;
                ev.file_type= data.file_type;
                ev.vid= data.vid;
                ev.select_file_name=data.file;
            //console.log(data);
            })
        }
    },
}
</script>

<style scoped>
.name_heading {
    margin-left: 20px;
}

.value-spn {
    padding-left: 10px;
    color: #696767;
}

.heading-spn {
    font-weight: bold;
    color: #565656;
    padding: 15px;
}

.btn-top-table {
    display: inline-block;
    padding: 10px;
    width: 50%;
}

.table {
    width: 100%;

}

.table-dt {
    overflow: scroll;
}

.tbl-action {
    margin: 2px;
    border: unset;
}
</style>
