<template>
<div name="student">

    <MobileHeader></MobileHeader>
    <div class="d-flex flex-row flex-column-fluid page">
        <div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">

            <Header></Header>

            <!--begin::Content-->
            <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
                <!--begin::Entry-->
                <div class="d-flex flex-column-fluid">
                    <!--begin::Container-->
                    <!--begin::Dashboard-->

                    <div class="container">
                        <div class="card card-custom mt-5">
                            <!--begin::Header-->
                            <div class="card-header flex-wrap border-0 pt-6 pb-0">
                                <div class="card-title">
                                    <h3 class="card-label">Edit Bundle Course's
                                        <span class="d-block text-muted pt-2 font-size-sm">Edit Bundle Course's</span>
                                    </h3>
                                </div>
                                <div class="card-toolbar">
                                </div>
                            </div>
                            <!--end::Header-->
                            <div class="card-body">
                                <!--begin::Search Form-->
                                <div class="mb-7">
                                    <div class="row align-items-center nw-row">
                                        <div class="col-lg-12 col-xl-12">
                                            <div class="row align-items-center ">

                                                <div class="col-md-12 my-2 ">
                                                    <div>
                                                        <label class="mr-3 mb-2 d-none d-md-block">Bundle Name:</label>
                                                        <input type="text" class="form-control" v-model="group_name" placeholder="Enter Group Name"/>

                                                    </div>
                                                </div>
                                                <div class="col-md-12 my-2 mrg-top-10">
                                                    <div>
                                                        <label class="mr-3 mb-2 d-none d-md-block">Course:</label>

                                                        <multiselect v-model="value" :options="options" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" placeholder="Pick some" label="name" track-by="name" :preselect-first="true">
                                                            <template slot="selection" slot-scope="{ values, isOpen }">
                                                                <span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">
                                                                    {{ values.length }} Course Selected
                                                                </span>
                                                            </template>
                                                        </multiselect>

                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-xl-12 mt-5 mt-lg-0 mg-10-mk">
                                            <input type="submit" name="assign" value="Save Group" @click="save_group"  class="btn btn-primary font-weight-bold">
                                        </div>
                                    </div>
                                    <div class="row align-items-center" id="week1"></div>
                                </div>
                                <!--end: Search Form-->

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
</template>

<script>
import axios from 'axios'
import MobileHeader from '../../components/MobileHeader'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import Multiselect from 'vue-multiselect'
import '../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css'

export default {
    name: 'Task',
    components: {
        Header,
        Footer,
        MobileHeader,
        Multiselect,
    },
    data() {
        return {
            uid: localStorage.getItem("uid"),
            role: localStorage.getItem("role"),
            course_data: [],
            options:[],
            value:[],
            group_name:null,
        }
    },
    mounted() {
        if (this.uid == null || this.uid == "") {
            this.$router.push("/");
        }
        this.getCourse();
        this.getGroup();
    },
    watch: {
        
    },
    methods: {
        nameWithLang ({ name, language }) {
          return `${name} — [${language}]`
        },
        getCourse() {
            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'lms_api.php',
                data: {
                    type: 'dropDownCourse',

                }
            }).then(function (response) {
            //console.log(response.data);
                var data = response.data;
                ev.course_data = data;
                ev.options=data;
            })
        },
        getGroup(){
            var ev=this;
             axios({
                    method: 'POST',
                    url: this.$root.URL_ROOT + 'lms_api.php',
                    data: {
                        type: 'get_group',
                        gid:  this.$route.params.id,
                    }
                }).then(function (response) {
                    var data = response.data;
                    console.log(data);
                    ev.value =data.value;
                    ev.group_name =data.data.group_name;
                })

        },
        save_group() {
            var ev = this;
            
            if (this.group_name == '' || this.group_name == null) {
                ev.$toasted.global.error({
                    message: 'Enter Group Name'
                });
            } else if (this.value == null || this.value.length ==0 || this.value.length<0) {
                ev.$toasted.global.error({
                    message: 'Selcet Course'
                });
            } else {
                axios({
                    method: 'POST',
                    url: this.$root.URL_ROOT + 'lms_api.php',
                    data: {
                        type: 'edit_group',
                        uid: this.uid,
                        role: this.role,
                        course: this.value,
                        group_name: this.group_name,
                        gid: this.$route.params.id,
                    }
                }).then(function (response) {
                    var data = response.data;
                    //console.log(data);
                    if (data.status == 'success') {
                        ev.$toasted.global.success({
                            message: data.msg
                        });
                        ev.$router.push("/groupcourse");
                    } else {
                        ev.$toasted.global.error({
                            message: data.msg
                        });
                    }
                })
            }

        },
        
    },
}
</script>

<style scoped>
.mg-10-mk{
    margin-top:20px !important;
}
.nw-row{
    padding-bottom: 5%;
}
.mrg-top-10 {
    margin-top: 10px;
}

.add-btn-s {
    background-color: #3798fb;
    border: none;
    margin: 1%;
}

.btn-top-table {
    display: inline-block;
    padding: 10px;
    width: 50%;
}

.table {
    width: 100%;

}

.table-dt {
    overflow: scroll;
}

.tbl-action {
    margin: 2px;
    border: unset;
}

</style>
