import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Toasted from 'vue-toasted'
import { store } from './store/store'
import { loadProgressBar } from 'axios-progress-bar'
import 'axios-progress-bar/dist/nprogress.css'
import Sortable from 'vue-sortable'

Vue.use(Sortable)
import $ from 'jQuery'
let Vimeo = require('vimeo').Vimeo;


import js2 from '@/assets/plugins/custom/prismjs/prismjs.bundle.js';
window.js2 = js2;
import js3 from '@/assets/js/scripts.bundle.js';
window.js3 = js3;

Vue.use(Toasted)
    //Vue.use(VuePreloaders)
loadProgressBar()
const error_options = {
    type: 'error',
    position: 'bottom-center',
    singleton: true,
    theme: 'bubble',
    duration: 5000,
    onClick: (e, toastObject) => {
        toastObject.goAway(0)
    }
}
const success_options = {
    type: 'success',
    singleton: true,
    position: 'bottom-center',
    duration: 5000,
    onClick: (e, toastObject) => {
        toastObject.goAway(0)
    }
}
Vue.toasted.register('error',
    (payload) => {
        // if there is no message passed show default message
        if (!payload.message) {
            return 'Oops.. Something Went Wrong..'
        }
        // if there is a message show it with the message
        return payload.message
    },
    error_options
)
Vue.toasted.register('success',
    (payload) => {
        // if there is no message passed show default message
        if (!payload.message) {
            return 'Greate.. Proceed Successfully..'
        }
        // if there is a message show it with the message
        return payload.message
    },
    success_options
)
Vue.config.productionTip = false
new Vue({
    data: {
        HomeUrl: 'https://lms.impact-school.com/',
        //URL_ROOT: 'http://help.impact-school.com/LMS_api/api/',
        URL_ROOT: 'https://help.impact-school.com/LMS_api/api/',
        URL_IMAGES: 'https://help.impact-school.com/LMS_api/api/uploads/',

        client_id: '470240a3b30cb3d48f2a330356231ce503a226c1',
        client_secret: 'XNj+ZaUpKL/2kWFv7QYe2GH6Vh0YoJuuwXbXkc52HineP7wz4mX6hQyk2dJUL6Mxd31TgqJlER98vq1G3qWoob7sgZetXQAyLb1HeHOtNIsM3xNd/jt8DROz+hH3T5IO',
        access_token: 'a18b6d63a477f3d2c5e5b9b8d01448b4',

        client: new Vimeo('552781a897c019edb1a4705a259008fde1d351d5',
            'u4vOcge41G+lnyO9V4yYag8XZVYpQ/BNCTPINzOfnQrTUCLfRI1J7vHhpDd0MN0uJu4ji6sY9yoG/dv+5ZZGK3uc15I87K4YGLsZOfADRPvcfMRKzY7aAmyY8XIF0zbb',
            'ca2e480dd300b57c1900f707f793e3fb'),
        // opencagedata
        OC_API: '83e8e742fcec49fbbbcd7cfca4397a53',
        auth: { Authorization: localStorage.getItem('token') }

    },
    store,
    router,
    render: h => h(App)
}).$mount('#app')