<template>
<div name="student">

    <MobileHeader></MobileHeader>
    <div class="d-flex flex-row flex-column-fluid page">
        <div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">

            <Header></Header>

            <!--begin::Content-->
            <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
                <!--begin::Entry-->
                <div class="d-flex flex-column-fluid">
                    <!--begin::Container-->
                    <!--begin::Dashboard-->

                    <div class="container">
                        <div class="flex-row pt-5 pb-5" id="sop">
                            <router-link to="/addschedule" class="btn btn-info add-btn-s">Add Schedule</router-link>
                            <!--begin::Row-->
                            <template v-for="sdata in allData">  
                                <div class="card card-custom gutter-b" :key="sdata.id">
                                    <div class="card-header">
                                        <div class="card-title">
                                            <h3 class="card-label">{{sdata.name}}</h3>
                                        </div>
                                        <div class="card-toolbar">
                                            <router-link :to="'/viewschedule/'+sdata.id" ><span class="label label-inline label-light-danger font-weight-bold ml-5">View</span></router-link>
                                            <router-link :to="'/editschedule/'+sdata.id" ><span class="edit-btn-clr label label-inline label-light-danger font-weight-bold ml-5">Edit</span></router-link>
                                            <a style="cursor: pointer;" @click="confirm_pop(sdata.id)" ><span class="delete-btn-clr label label-inline label-light-danger font-weight-bold ml-5">Delete</span></a>
                                        </div>
                                    </div>
                                </div>
                            </template>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
</template>

<script>
import axios from 'axios'
import MobileHeader from '../../components/MobileHeader'
import Header from '../../components/Header'
import Footer from '../../components/Footer'

export default {
    name: 'AddCourse',
    components: {
        Header,
        Footer,
        MobileHeader,

    },
    data() {
        return {
            uid: localStorage.getItem("uid"),
            role: localStorage.getItem("role"),
            allData: [],
        }
    },
    mounted() {
        if (this.uid == null || this.uid == "") {
            this.$router.push("/");
        }
        this.getSchedule();
    },
    methods: {
        confirm_pop(ids) {
            this.$confirm("", "Are You Sure ?").then(() =>
                this.delete_todo(ids)
            );
        },
        delete_todo(ids) {
            var ev = this;

            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'lms_api.php',
                data: {
                    type: 'deleteTodo',
                    id: ids,
                }
            }).then(function (response) {

                var data = response.data;
                if (data.status == 'success') {
                    ev.$toasted.global.success({
                        message: data.msg,
                    });
                    ev.getSchedule();
                } else {
                    ev.$toasted.global.error({
                        message: data.msg,
                    });
                }
            })

        },
        getSchedule() {
            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'lms_api.php',
                data: {
                    type: 'getSchedule',
                    role: this.role,
                    uid: this.uid,
                    stype:'schedule',
                }
            }).then(function (response) {
                var data = response.data;
                ev.allData = data;
                //console.log(data);
            })
        }
    },
}
</script>

<style scoped>
.add-btn-s {
    background-color: #3798fb;
    border: none;
    margin: 1%;
}

.btn-top-table {
    display: inline-block;
    padding: 10px;
    width: 50%;
}

.table {
    width: 100%;

}

.table-dt {
    overflow: scroll;
}

.tbl-action {
    margin: 2px;
    border: unset;
}
</style>
