<template>
<div class="row">

    <div class="col-7">
        <h3>Draggable {{ draggingInfo }}</h3>

        <draggable tag="ul" :list="list" class="list-group" handle=".handle">
            <li class="list-group-item" v-for="(element) in list" :key="element.id">
                <i class="fa fa-align-justify handle"></i>

                <span class="text">{{ element.chapter_name }} </span>

                <input type="text" class="form-control" v-model="element.chapter_name" />

            </li>
        </draggable>

        <draggable tag="ul" :list="list" class="list-group" handle=".handle">

            <template v-for="(items,index) in list">
                <div :key="index" class="wizard-steps">
                    <div class="accordion accordion-toggle-arrow" id="accordionExample1">
                        <!--begin::Wizard Step 1 Nav-->
                        <div class="card">
                            <div class="card-header">
                                <div class="card-title" data-toggle="collapse" data-target="#collapseOne11"  aria-expanded="false">
                                     <i class="fa fa-align-justify handle"></i>
                                    {{items.chapter_name}}
                                </div>
                            </div>

                            <div id="collapseOne11" class="collapse" data-parent="#accordionExample1" style="">
                                <div class="card-body">

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </template>
        </draggable>
    </div>

    <!--<rawDisplayer class="col-3" :value="list" title="List" />--->
</div>
</template>

<script>
let id = 3;
import axios from 'axios'
import draggable from 'vuedraggable'
export default {
    name: "handle",
    display: "Handle",
    instruction: "Drag using the handle icon",
    order: 5,
    components: {
        draggable
    },
    data() {
        return {
            list: [{
                    name: "John",
                    text: "",
                    id: 0
                },
                {
                    name: "Joao",
                    text: "",
                    id: 1
                },
                {
                    name: "Jean",
                    text: "",
                    id: 2
                }
            ],
            dragging: false,
            course_data: [],
            chapter: [],
            allChapter:[],
        };
    },
    computed: {
        draggingInfo() {

            return this.dragging ? "under drag" : "";

        }
    },
    mounted() {
        this.getAllChapter();
    },
    watch: {
        list: function (vl) {
            //console.log(vl);
        }
    },
    methods: {
        getAllChapter() {
            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'lms_api.php',
                data: {
                    type: 'getAllChapter',
                    cid: 3,

                }
            }).then(function (response) {
                var data = response.data;
                //console.log(data);
                ev.course_data = data.course;
                //console.log(ev.course_data)

                ev.allChapter = data.chapter;
                ev.list = data.chapter;

            })

        },

    }
};
</script>

<style scoped>
.button {
    margin-top: 35px;
}

.handle {
    float: left;
    padding-top: 8px;
    padding-bottom: 8px;
}

.close {
    float: right;
    padding-top: 8px;
    padding-bottom: 8px;
}

input {
    display: inline-block;
    width: 50%;
}

.text {
    margin: 20px;
}
</style>
